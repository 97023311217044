.usage-dashboard-container .usage-summary-container{
    border-bottom: 1px solid var(--border-bd-1);
}
.usage-list-view .list-view-header{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--secondary-color);
    padding: 8px;
}
.usage-list-view .header-font-icon{
    display: inline-grid;
    width: 15px;
    height: 15px;
    color: var(--font-color);
}
.usage-summary-container .summary-box-container{
    padding: 24px;
    color: var(--font-color);
}
.usage-list-view .list-view-header{
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1 1;
    padding: 0 1.5rem;
}
.usage-list-view .list-view-header .date-filter{
    align-items: center;
    display: flex;
    margin-left: auto;
}

.summary-box-container .card-title{
    color: var(--secondary-color);
}
.usage-summary-box .usage-border{
    border: 1px solid var(--primary-color);
}
.usage-summary-box ._loading_overlay_overlay{
    background: unset;
}
.date-filter-toggle,
.date-filter-toggle:hover,
.date-filter-toggle:active,
.date-filter-toggle:visited{
    color: var(--primary-color);
}
.current-plan-container{
    color: var(--font-color);
}
.current-plan-container .header-content{
    display: flex;
    align-items: center;
}
.current-plan-container .body-content .plan-name{
    font-weight: 600;
    font-size: 16px;
}
.body-content .plan-amount .label{
    font-size: 25px;
    font-weight: 600;
}
.body-content .plan-amount .value, 
.current-plan-container .plan-desc{
    font-size: 16px;
}

.body-content .plan-desc .value,
.footer-content .footer-header{
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline !important;
}
.usage-summary-box .body-number{
    font-size: 40px;
    font-weight: 500;
}
.usage-summary-box .body-desc{
    font-size: 18px;
    font-weight: 500;
}
.footer-content .footer-body{
    /* gap: 2px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.current-plan-container .cancel-sub-btn,
.cancel-subscription-btn-container .cancel-sub-btn {
    color: var(--w-color);
    background-color: var(--invalid-color);
    border: unset;
}
.current-plan-container .cancel-sub-btn:disabled,
.cancel-subscription-btn-container .cancel-sub-btn:disabled {
    cursor: not-allowed !important;
    color: var(--font-color);
    background-color: var(--invalid-color);
    border: unset;
    pointer-events: all;
}
.truncated-text {
    white-space: pre;       /* Keep the text in one line */
    overflow: hidden;       /* Hide overflow content */
    text-overflow: ellipsis; /* Show ellipsis when the content is too long */
    padding: 20px;
    cursor: pointer;
}
.current-plan-container .recommended-tag{
    margin-left: 10px;
    background: var(--theme-main);
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--w-color);
    border-radius: 10px;
    border-color: var(--theme-main);
    padding: 2px 8px 2px 8px;
}
.recommended-tag.disabled {
    background: var(--invalid-color);
    border-color: var(--invalid-color);
}

.react-datepicker__aria-live{
    display: none !important;
}
.current-plan-container .sub-header{
    font-size: 22px;
    text-align: center;
}
.current-plan-container .sub-body{
    text-align: center;
}
.current-plan-container .sub-content{
    text-align: center;
}
.usage-summary-body{
    display: flex;
    align-items: baseline;
    gap: 5px;
}
.usage-summary-body .no-content{
    margin-top: 3px;
    margin-bottom: unset;
    margin-left: 0;
    margin-right: unset;
    font-size: 2.7rem !important;
}
.usage-summary-body .txt-content{
    margin-top: 3px !important;
    font-size: 25px;
    font-weight: 500;
}
.usage-summary-cont .period{
    color: var(--bg-gradiant);
}
.billing-content-wrapper{
    font-size: 15.5px;
}
.billing-content-wrapper .billing-subscription-table{
    border-left: 0.1px solid #e9ecef !important;
    border-right: 0.1px solid #e9ecef !important;
    border-radius: 5px;
    box-shadow: 0px 4px 9px 0px rgba(224, 228, 240) !important;
}
.billing-content-wrapper .billing-subscription-table  th, .billing-subscription-table thead td{
    font-size: 12px !important;
    padding: 15px;
}
.billing-content-wrapper .billing-subscription-table  th{
    background-color: var(--white-bg) !important;
    color: var(--font-color)
}
  
.billing-subscription-table thead tr{
    background-color: var(--table-header-bg) !important;
    color: var(--font-color);
}
.no-border-table thead tr,
.no-border-table thead td,
.no-border-table tbody tr,
.no-border-table th {
  border-width: 0px !important;
}
.table-hover > tbody > tr:hover{
    --bs-table-accent-bg: var(--white-bg);
    color: var(--font-color);
}
.no-border-table tbody tr {
  border-bottom-width: 1px !important;
}
.summary-box-container .summary-box-header,
.usage-dashboard-container .usage-list-header{
    font-size: 15.5px;
    display: flex;
    color: var(--font-color);
}

.current-plan-container 
.current-plan-notification{
    background-color: ivory;
    color: #000;
    font-weight: 400;
    padding: 15px
}

.current-plan-notification .header-desc{
    font-size: 14px;
    color: var(--font-color);
    line-height: 1.3;
}
.account-pricing-container .sub-pricing-header{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
}
.sub-pricing-header .header-subscription{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    display: flex;
    gap: 8px;
} 
.account-pricing-container .sub-pricing-header{
    align-items: center;
    display: flex;
    justify-content: center;
}
.sub-pricing-header .subheading{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--secondary-color);
}
.plan-title .recommended-tag{
    background: var(--white-bg);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--label-font) ;
    border: 1px solid var(--white-bg);
    padding: 2px 8px 2px 8px;
    border-radius: 6px;
}
.dark-theme .plan-title .recommended-tag{
    background: var(--selected-plan) !important;
}
.error-message {
    color: var(--invalid-color);
    margin-top: 15px;
    font-size: 14px;
}

.checkout-box-container .input-group-text{
    color: var(--font-color);
    background-color: var(--white-bg);
}

.checkout-box-container .input-group{
    flex-wrap: unset
}

.payment-list-box .download-invoice{
    display: flex;
    gap: 10px;
}
.checkout-span-group{
    gap: 20px;
    display: flex;
}
.manage-sub-row{
    width: 90%;
}
.manage-sub-span{
    font-size: 12px;
    display: flex;
} 
.manage-sub-img{
    height: 30px;
    border-radius: 10px;
}
.manage-sub-loader{
    margin-left: 50%;
    margin-top: 25%;
}
.pricing-no-data{
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}

.mnge-sub-grp{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.checkout-grp{
    padding-right: 6px;
    padding-top: 15px;
    padding-left: 12px;
    display: flex;
    align-items: center;
}

.checkout-card{
    padding-right: 3px;
}

.mnge-sub-spin{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    width: 80%;
}
.mnge-spin{
    width: 1.5rem;
    height: 1.5rem;
}
.chat-sinppet-wid{
    width: 70%;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
}
.success-tick-icon{
    font-size: 10rem;
    color: var(--shadow-theme-g);
    width: 500px;
}