.vm-modal-lg {
    max-width: 650px;
    width: 650px;
    height: 100%;
    margin-bottom: 0;
    margin-right: 0;
    margin-top: 0;
}

.vm-modal-lg .modal-content,
.vm-modal-sg .modal-content {
    min-height: 100vh;
}

.field-details.data-form .field-section {
    margin-top: 10px !important;
}

.m-contacts-w{
    width: 425px !important;
}
.ml-3 {
    margin-left: 1rem;
}

.list-header__main {
    display: flex;
    justify-content: flex-end;
    height: 50px;
    border-bottom: 1px solid var(--border-bd-1);

}

.list-header__main .header-title {
    font-size: 16px;
    color: var(--font-color) !important;
}

.listheader-content {
    transition: .3s cubic-bezier(.55, .06, .68, .19);

}

.listheader-content.visible {

    transform: translateY(0)
}

.listheader-content.non-visible {
    transform: translateY(-190%)
}

.p-fixed {
    position: fixed;
}

.custom-col-md-3 {
    width: 20% !important;
}

.custom-1-col-md-3 {
    width: 18% !important;
}

.custom-2-col-md-3 {
    width: 22% !important;
}

.custom-3-col-md-3 {
    width: 15% !important;
}

.badge-info {
    color: var(--info-color);
    background-color: var(--w-color);
    border: 1px solid var(--info-color);
}
.badge-warning {
    color: var(--warning-color);
    background-color: var(--w-color);
    border: 1px solid var(--warning-color);
}
.badge-success {
    color: var(--success-color);
    background-color: var(--w-color);
    border: 1px solid var(--success-color);
}
.badge-danger {
    color: var(--invalid-color);
    background-color: var(--w-color);
    border: 1px solid var(--invalid-color);
}

.badge {
    font-weight: bold;
    text-transform: uppercase;
    padding: 5px 10px;
    min-width: 19px;
}

.import-step-indicator .step-item {
    font-size: 13px;
    padding: 7px;
    text-align: center;
    color: var(--font-color);
}

.import-step-indicator .step-item.active .step-item-number {
    background-color: var(--primary-background);
    border-color: var(--primary-background);
    color: var(--w-color);
}

.import-step-indicator .step-item .step-item-number {
    border: 1px solid var(--border-bd-1);
    border-radius: 50%;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 38px;
    margin-bottom: 6px;
    text-transform: uppercase;
    width: 40px;
}

.dropzone {
    background-color: var(--upload-bg);
    border: 2px dashed var(--border-bd-1);
    border-radius: 5px;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    transition: background-color .2s ease;
}

.dropzone-content i {
    color: #a0a0a0;
    font-size: 40px;
}

.dropzone-content p {
    color: #a0a0a0;
    margin-top: 10px;
}

.meta-fields .description {
    color: #555;
    display: inline-block;
    font-size: 12px;
    margin-top: 5px;
}

.ball-clip-rotate>div {
    border-color: var(--primary-background) !important;
}

.form-preview-document {
    align-items: center;
    border-radius: 5px;
    color: var(--import-uploader);
    display: flex;
    font-size: 13px;
    padding: 4px 8px;
    width: 100%;
    background-color: var(--upload-bg);
}

.bx {
    vertical-align: middle;
}

.bx-md {
    font-size: 2rem !important;
}

.import-step-indicator .step-item.completed .step-item-number {
    background-color: var(--import-uploader);
    border-color: var(--import-uploader);
    color: var(--w-color);
}

.import-data-1,
.import-data-2,
.import-data-3 {
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.import-data-2 .data-middle {
    overflow: visible;
    word-wrap: break-word;
    white-space: normal;
    width: 100%;
}

.import-data-3 .basic-multi-select {
    width: 100%;
}
.import-data-3 .wa-select__menu-list {
    height: 8rem !important;
    overflow-y: scroll !important;
}
  

.import-data-1 {
    width: 25%;
}

.import-data-2 {
    width: 35%;
}

.import-data-3 {
    width: 40%;
}

.csv-import-stats {
    height: 150px;
}

.h-100-p {
    height: 150px !important;
}

.import-processing-panel {
    display: grid;
    place-items: center;
    padding-top: 35px;
}

.import-stats-info {}

.processing-info .td {
    font-size: .75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--import-info-th);
    padding: .3125rem .625rem;
    font-weight: 600;
}

.processing-info .td.lite {
    font-weight: normal;
    text-transform: lowercase;
}

.fields-container .field-section {
    padding: .5rem 0.5rem;
    display: flex;
    align-items: center;
    width: 90%;
    background-color: var(--white-bg);
    border: 1px solid var(--border-bd-1);
    border-radius: 4px;
    max-height: 3.4rem;
}
.fields-container .field-section .col-md-6 .form-label{
    width: 100%;
    border-right: 1px solid var(--border-bd-1);
}
.fields-container .field-section .form-label,
.vm-modal-sg .form-label,
.mass-update .form-label
{
    margin-bottom: unset !important;
}

.h-80 {
    height: 80vh;
}

.h-90 {
    height: 90vh;
}

.vm-modal-sg {
    max-width: 450px;
    width: 450px;
    height: 100%;
    margin-bottom: 0;
    margin-right: 0;
    margin-top: 0;
}

.custom-field-container {
    width: 550px;
    margin: auto;
}

.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    /* Space for the checkbox */
    cursor: pointer;
    user-select: none;
    line-height: 20px;
    /* Aligns label text */
}

/* Hide the default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create the custom checkbox */
.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--white-bg);
    border-radius: 4px;
    border: 1px solid #888;
    /* Optional border */
    transition: background-color 0.3s, border-color 0.3s;
}

/* Change background when checked */
.custom-checkbox input:checked+.checkmark {
    background-color: var(--primary-background);
    /* Green */
    border-color: var(--primary-background);
}

/* Add the checkmark */
.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Show the checkmark when checked */
.custom-checkbox input:checked+.checkmark:after {
    display: block;
}
.add-bg{
    color: var(--theme-main);
    font-size: 18px;
}
.remove-bg{
    color: var(--login-box-error);
    font-size: 18px;
}

.mobile-contacts .mb-btn{
    width: 115px !important;
}
.cus-pag-spn{
    display: flex;
    align-items: center;
    gap: 5px;
}

.pagination {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cus-table-btn.active{
    background-color: var(--theme-main);
    border-color: var(--theme-main);
    color: var(--white-bg);
}
.dark-cus-table-btn.active{
    color: var(--btn-theme-color) !important;
    background-color: var(--primary-background) !important;
    border-color: var(--primary-background) !important;
}

.cus-table-btn {
    background-color: var(--white-bg);
    border-color: var(--selected-plan);
    color: var(--font-color);
    justify-content: center;
    display: flex;
    width: 16%;
    height: 33px;
}
.cus-table-btn:hover{
    color: var(--white-bg) !important;
    background-color: var(--primary-background) !important;
    border-color: var(--primary-background) !important;
}
.dark-theme button.cus-table-btn{
    color:var(--w-color) !important;
}
.dark-theme button.cus-table-btn:hover{
    color:var(--w-color) !important;
}

.cus-table-btn:disabled{
    background-color: var(--white-bg);
    color: var(--font-color);
}

.dark-theme .import-data-1,
.import-data-2,
.import-data-3 {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    color: var(--font-color);
}

.dark-theme .import-step-indicator .step-item .step-item-number {
    border: 1px solid var(--border-bd-1);
    border-radius: 50%;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 38px;
    margin-bottom: 6px;
    text-transform: uppercase;
    width: 40px;
    color: var(--font-color);
}

.dark-theme .processing-info .td {
    font-size: .75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--font-color);
    padding: .3125rem .625rem;
    font-weight: 600;
}

.custom-container-scroll {
    overflow-y: scroll;
    overflow-x: scroll;
    height: 510px;
    width: 504px;
    display: flex;
}

.badge-info-status-sub{
    color: var(--success-color);
    background-color: var(--w-color);
    border: 1px solid var(--success-color);
}
.badge-info-status-unsub{
    color: var(--warning-color);
    background-color: var(--w-color);
    border: 1px solid var(--warning-color);
}

.fixed-header-container{
    position: fixed;
    width:80vw;
    z-index: 1000;
    background-color: var(--white-bg);
}
.contact-data-container{
    position: relative;
    padding-top: 50px !important;
}

.cus-pag-spn .wa-select__control {
    min-height: 1px !important;
    height: 34px !important;
    padding: 0;
}

.cus-text-color{
    color: var(--font-color);
}