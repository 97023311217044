.filter-view-container {
  display: flex;
  flex-direction: column;
}

.popper-animation,
.sm-custom-popover .popover-view-content,
.insert-email-template-popper .popover-view-content,
.custom-view-popover popper-content .ngxp__container .popover-view-content,
popper-content .ngxp__container.ngxp__animation,
[data-hover="dropdown"]:hover>.dropdown-menu,
.open .dropdown-menu {
  -webkit-animation: 0.15s zoomIn !important;
  animation: 0.15s zoomIn !important;
}
.custom-view-popover popper-content .ngxp__container {
  padding: 0 !important;
  margin-left: 0;
}

popper-content .ngxp__container[x-placement^="bottom-start"] {
  margin-top: 8px;
}


.popover-view-content {
  width: 300px;
  /* background: #ffffff; */
  font-size: 14px;
  box-shadow: 0 -5px 10px var(--dark-grey);
}

.custom-view-popover popper-content .ngxp__container .popover-view-content {
  z-index: 99;
  width: 300px;
  background: var(--white-bg);
  font-size: 14px;
  box-shadow: 0 -5px 10px var(--dark-grey);
}

popper-content .ngxp__container {
  z-index: 999;
  background: var(--white-bg);
  box-shadow: 0 2px 10px var(--dark-grey);
  border: 1px solid #d5dee5;
  max-width: 500px;
  padding: 15px;
}
.ngxp__container {
  display: none;
  position: absolute;
  border-radius: 3px;
  border: 1px solid grey;
  box-shadow: 0 0 2px rgb(0 0 0 / 50%);
  padding: 10px;
}

.popper-animation,
.sm-custom-popover .popover-view-content,
.insert-email-template-popper .popover-view-content,
.custom-view-popover popper-content .ngxp__container .popover-view-content,
popper-content .ngxp__container.ngxp__animation,
[data-hover="dropdown"]:hover>.dropdown-menu,
.open .dropdown-menu,
.ct-dropbtn-box .popover-view-content {
  -webkit-animation: 0.15s zoomIn !important;
  animation: 0.15s zoomIn !important;
}

.popover-view-content .popover-list {
  max-height: 400px;
  min-height: 86px;
  overflow: auto;
  overflow-x: hidden;
  width: 300px;
}

.popover-view-content .popover-list {
  max-height: 250px;
}

.popover-view-content ul {
  margin: 0;
  padding: 5px 0 10px;
  /* border-bottom: 1px solid #dfe1e6; */
}

.popover-view-content ul ol {
  margin: 5px 0 0;
  padding: 7px 20px;
  color: #172b4d;
  font-size: 12px;
  font-weight: 600;
}


.popover-view-content ul li {
  list-style: none;
  position: relative;
  cursor: pointer;
}

.popover-view-content ul li .icon-option {
  display: inline-block;
  position: absolute;
  right: 10px;
  z-index: 99;
  top: 7px;
  padding: 0;
  width: 24px;
  text-align: center;
}

.popover-view-content ul.fav-view-container li a {
  flex: 1;
}

.popover-view-content ul li>a .icon-check {
  position: absolute;
  right: 16px;
  top: 15px;
  font-size: 11px;
}

.popover-view-content ul li .icon-option.star-on-hover {
  right: 38px;
}

.popover-view-content ul li>a {
  font-size: 14px;
  color: #172b4d;
  padding: 12px 60px 12px 20px;
  display: block;
}

.popover-view-content ul li.show-on-hover:hover {
  background: var(--theme-main);
  color: var(--white-bg) !important;
}

.sm-custom-popover .popover-view-content ul li .icon-option.star-on-hover .icon-star,
.sm-custom-popover .popover-view-content ul li .icon-option.star-on-hover .activityType15:before {
  display: none;
}

.popover-view-content ul{
  padding-bottom: 5px !important
}
.popover-view-content {
  width: auto !important;
}
.filter-view-popup{
    position: relative !important;
}

.filter-view-popup {
  position: absolute;
  width: 100% !important;
  box-shadow: none !important;
}

.filter-view-popup .popover-list {
  width: 100% !important;
  display: none;
}

.filter-view-popup.filter-focus {
  border: 1px solid var(--font-color) !important;
  border-radius: 5px;
}

.filter-view-popup .popover-list.show {
  display: block;
}

.filter-view-popup .popper-search-block input {
  padding: 6px !important;
  padding-left: 10px !important;
}

.filter-view-popup .popper-search-block input:focus {
  border: 0px solid #999999 !important;
  margin: 0;
  padding: 5px !important;
  box-shadow: none !important;
}

.filter-view-popup .template-list-items {
  line-height: 0.2;
}

.template-list-items {
  position: relative;
  cursor: pointer;
  display: block;
  /* line-height: 1.2; */
  color: var(--dark-grey);
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid var(--calls-bg);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.template-list-items:hover {
  background-color: var(--calls-bg);
}

.filter-view-popup.filter-focus {
  border: 1px solid var(--border-bd-1) !important;
  border-radius: 4px;
}
.filter-focus .filter-dropdown{
  border-color: var(--theme-main);
  border-bottom-width: 2px;
}
.filter-dropdown{
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}
.filter-focus .filter-dropdown{
  border-color: var(--theme-main);
  border-bottom-width: 2px;
}
.filter-panel .fp-dd-divider{
  align-self: stretch;
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 6px;
  margin-top: 6px;
  box-sizing: border-box;
}
.filter-panel .custom-dropdown-arrow{
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: hsl(0, 0%, 80%);
  padding: 10px;
  box-sizing: border-box;
}

.popper-search-block {
  position: relative;
}

.popper-search-block input {
  border: 0px solid #edf0f7 !important;
  margin: 0;
  padding: 10px 30px 10px 10px !important;
  box-sizing: border-box;
}

.popper-search-block:after {
  content: "\e997";
  font-family: SalesmateIcons;
  position: absolute;
  right: 20px;
  top: 17px;
  color: #8993a4;
}
.popover-list {
  min-height: auto !important;
  width: auto !important;
}

.popover-list .selected{
  color: var(--white-bg) !important;
  background-color: var(--theme-main);
}

.unset-zindex-select .basic-multi-select {
  z-index: unset !important;
}
.filter-component .fc-field{
  margin-right: 5px;
}
.filter-component .fc-box .dIB{
  display: block !important;
  min-height: 35px !important;
}
.dIB{
  display: inline-block;
}
.custom-view-popover .selected-text {
  float: left;
  max-width: 150px;
}
.custom-view-popover .popover-action{
    display: flex;
    align-items: center;
}

.custom-view-popover popper-content .ngxp__container {
  padding: 0 !important;
  margin-left: 0;
}
.filter-component .custom-view-popover {
  height: 35px !important;
}

.filter-component .custom-view-popover .ct-dropbtn-link{
  height: 38px !important;
  display: block;
  padding-top: 0.2rem;
}
.pR,
.pr {
  position: relative;
}
.ct-dropbtn-txt
{
    line-height: 1.8rem;
    min-height: 2.4rem;
    font-size: 0.88rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.ct-dropbtn-link, 
.ct-dropbtn-link:hover,
.ct-dropbtn-link:visited,
.ct-dropbtn-link:focus,
.ct-dropbtn-link:active{
  border: 1px solid var(--border-bd-1) !important;
  border-radius: 4px;
  padding: 0.3rem 0.8rem;
  color: var(--font-color);
}
.ct-dropbtn-search-block{
    padding: 0.15rem 0.2rem;
    line-height: 1.8rem;
    min-height: 2.8rem;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background: var(--white-bg);
}
.popover-view-content .popover-list{
  max-height: 150px !important;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.ngxp__container{
  width: auto;
  min-width: 150px !important;
}
.popup-show {
  display: block;
  opacity: 1;
  position: absolute;
  will-change: top, left;
  top: 38px;
  left: 0px;
}
.filter-component .fc-header{
  max-width: 100%;
  font-weight: 700;
}
.filter-component .fc-header-hz{
  max-width: 100%;
  margin-right: 5px;
  font-size: 15px;
  width: 35% !important;
}
.filter-component .fc-remove-hz{
  margin-top:5px;
  color: var(--invalid-color);
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}
.filter-component .fc-add-hz{
  margin-top:5px;
  color:green;
  font-size: 16px;
  cursor: pointer;
}
.filter-component .fc-field{
  margin-right: 5px;
  /*margin-bottom: 5px;*/
}
.filter-component .errors{
  color: var(--invalid-color);
}
.form-control:disabled, .wa-select__control--is-disabled{
  background-color: var(--disabled-input) !important;
  cursor: not-allowed;
}

.dark-theme .popover-view-content ul li>a {
  font-size: 14px;
  color: var(--font-color) !important;
  padding: 12px 60px 12px 20px;
  display: block;
}

.dark-theme .filter-dropdown{
  border-color: var(--border-bd-1) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.dark-theme .template-list-items {
  position: relative;
  cursor: pointer;
  display: block;
  /* line-height: 1.2; */
  color: var(--font-color);
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid var(--calls-bg);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.dark-theme .popover-view-content ul li.show-on-hover:hover {
  background: var(--primary-background);
  color: var(--font-color) !important;
}

.dark-theme .popover-list .selected{
  color: var(--w-color) !important;
  background-color: var(--primary-background);
}

.dark-theme popper-content .ngxp__container {
  z-index: 999;
  background: var(--white-bg);
  box-shadow: 0 2px 10px var(--dark-grey);
  border: 1px solid #181818;
  max-width: 500px;
  padding: 15px;
}

.filter-component .dark-fc-add-hz{
  margin-top:5px;
  color:var(--selected-menu-color);
  font-size: 16px;
  cursor: pointer;
}