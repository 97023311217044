:root {
    --icon-bg: #F3F4F6;
    --white-bg: #FFFFFF;
    --dark-bg : #0000001A;
    --gray-200: #E5E7EB;
    --domain-btn-bg: #f1f1f1;
}
.header-main-body {
    align-items: center;
    display: flex;
    margin-left: auto;
    height: 100%;
    padding: 10px;
    text-align: left;
}

.more-icon {
    display: inline-block;
    width: 54px;
    height: 24px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="#6B7280"/></svg>') no-repeat center;
    background-size: contain;
}  

.agent-list {
    width: 100%;
    background-color: var(--white-bg);
    display: flex;
    padding: 8px;
    align-items: center;
    height: 35px;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.agent-test-btn,
.agent-test-btn:focus,
.agent-test-btn:hover,
.agent-test-btn:active,
.agent-test-btn:visited {
    color: var(--font-color);
    background-color: var(--white-bg);
    /* border: 1px solid var(--gray-200); */
    border: none !important;
    border-radius: 8px;
}

.agent-test-btn .down-arrow{
    color: #6B7280;
    font-size: 12px;
}

.agent-container .user-pic{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 30px;
    /* background: var(--white-bg); */
    border-radius: 50%;
}

.user-pic span{
    font-size: 20px;
}

.agent-container .data-row-container{
    padding: 15px 1rem;
}

.agent-container .selected-bg, 
.agent-container .agent-list:hover{
    /* background-color: var(--primary-background) !important; */
     background-color: var(--theme-menu-bg) !important;
    color: var(--font-color) !important;
}

.user-pic .agent-img{
    /*height: 15px;
    width: 24px;*/
}
.agent-main-container .container-heading{
    gap: 10px;
}
.agent-header .dropdown-item:active{
    background-color: var(--icon-bg);
    color: var(--primary-color);
}
.agent-header .agent-dropdown-lists img{
    width: 20px;
    height: 20px;
}

.agent-dropdown-lists .dropdown-list, .agent-config-contaner .form-info-label{
    gap: 10px
}
.popover-info-btn{
    cursor: pointer;
    margin-left: 5px;
    color: #495057;
    width: 17px;
    height: 17px;
}
.agent-config-container .submit-btn{
    background: var(--primary-color);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--icon-bg);
    border: none;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 2px -1px var(--dark-bg);
    box-shadow: 0px 1px 3px 0px var(--dark-bg);
}
.agent-config-container .label{
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
    display: flex;
    align-items: center;
    position: relative;
}
.app-header__content, .app-sidebar .scrollbar-container{
    background-color: var(--white-bg) !important;
}
.app-header__content{
    border-bottom: 1px solid var(--border-bd-1) !important;
}
.app-theme-white.fixed-header .app-header__logo{
    background: unset !important;
}
.form-control{
    border-color: var(--border-bd-1);
}
.popover-info-btn:active{
    border: unset;
}
.agent-config-container .agent-config-form{
    padding: 1.5rem;
}
.agent-config-form .agent-textarea{
    height: 270px;
    min-height: 270px;
    resize: none;
    scrollbar-width: none;
}
.agent-config-form .agent-config-input{
    border-radius: 10px !important;
}

.custome-show-toact-icon{
    width: 40px;
    height: 40px;
}
.Toastify__toast-body{
    gap: 15px;
}

.agent .agent-list .user-name, .agent-main-no-rec-found{
    font-weight: 500;
    font-size: 14px;
    color: var(--font-color);
}
.agent-main-no-rec-found{
    margin: auto;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-top: none;
}
.agent-config-form textarea.form-control{
    min-height: 400px !important;
}
.agent-config-container{
    height: 84vh;
    overflow-y: scroll;
    scrollbar-width: none;
}
.card-br{
    border: 1px solid var(--border-bd-1);
    border-radius: 10px;
}
.pb-6{
    padding-bottom: 2.5rem;
}
.profile-title-info{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-bd-1);
}
.agency-details .card-body{
    padding-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.agents-general-desc{
    font-size: 14px !important;
    margin-top: 4px !important;
    color: var(--description-font) !important;
}
.general-div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 24px;
}
.delete-sub-account-popup{
    display: flex;
    justify-content:left;
}
.provider-top{
    margin-top: 20px !important;
}
.provider-header{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--font-color);
    display: flex;
}

.agent-icon{
    font-size: 30px;
}
.agent-call-icon{
    font-size: 20px;
}
.agent-config-form textarea.agent-greeting{
    min-height: 60px !important;
}
.custom-domain-record{
    background-color: var(--white-bg) !important;
    color: var(--text-light) !important;
    border: none;
    font-size: 14px;
    font-weight: 400;
    /* color: var(--black-color) !important;
    border-color: var(--border-bd-1) !important; */
}
.sb-sts-btn-grp{
    display: flex;
    gap: 10px;
}
.sb-sts-tbl{
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 5px 0;
}
.txt-ovrflw{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.sub-sts-active {
    background-color: var(--primary-background);
    color: #FFFFFF;
}
.sub-sts-pym-fail {
    background-color: #DC143C; 
    color: #FFFFFF;
}
.sub-sts-apierror{
    background-color: #FF8C00; 
    color: #FFFFFF;

}
.sub-sts-suspend{
    background-color: #C0C0C0;
    color: #FFFFFF;

}
.sub-sts-maintanace{
    background-color: #ffcc00; 
    color: #FFFFFF;

}

.custom-table-wrapper {
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: Arial, sans-serif;
}

.cus-tbl-bdy::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.cus-tbl-bdy {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.custom-table {
    width: 100%;
}
.dark-theme .custom-table {
    color: var(--font-color) !important;
}
.custom-row {
    display: flex;
    padding: 5px 0;
    align-items: center;
}

.custom-header {
    background: #f7f7f7;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
}
.dark-theme .custom-header{
    background-color: var(--theme-secondary-bg) !important;
}

.custom-header-cell {
    flex: 1;
    text-align: center;
    padding: 10px;
}

.custom-cell {
    flex: 1;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.voice-model-icon {
    margin-right: 8px;
}

.custom-table-body .custom-data-row {
    cursor: pointer;
    transition: background 0.3s;
}

.custom-table-body .custom-data-row:hover {
    background: #f9f9f9;
}

.icon-cell {
    width: 50px;
    text-align: center;
    padding: 5px;
}

.icon-cell.selected {
    width: 30px;
}

.custom-data-row.selected {
    background-color: #f0f0f0;
}

.dark-theme .custom-data-row.selected{
    background-color: var(--menu-hover-bg) !important;
} 

.dark-theme .custom-table-body .custom-data-row:hover {
    background: var(--menu-hover-bg) !important;
}

.voice-model-icon{
    font-size: 15px;
    display: flex;
    align-items: center;
    color: var(--theme-main)
}

.voice-model-wid{
    width: 150%;
}

.icon-cell-wid{
    max-width: 5%;
}
.voice-drop-wid{
    width: 26%;
}
.voice-drop{
    gap: 15px;
    padding-bottom: 20px;
}
.voice-modal-bdy{
    width: 95%;
    margin: auto;
}

.cus-no-records{
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 20%;
    margin: auto;
}
.vm-voice-cnt{
    font-size: 14px;
    font-weight: 400;
    color: darkgray;
    padding-left: 2px;
}
.field-desc {
    color: var(--tab-color)
}
.btn-background{
    background : var(--primary-background) !important;
}
.cus-tbl-bdy{
    max-height: 300px;
    overflow-y: auto;
}

.custom-modal-footer {
    display: inline-grid;
}
.cursor-poiter{
    cursor: pointer;
}
.agent-model-wid {
    width: 100%;  /* Default width for small screens */
    right: 0;     /* Default positioning */
    position: relative;  /* Ensure positioning works */
    scrollbar-width: none;
}
  @media (min-width: 768px) {
    .agent-model-wid {
      width: 150%;  /* Adjust width for medium screens */
      right: 100px; /* Adjust right positioning */
    }
  }
  
  @media (min-width: 1200px) {
    .agent-model-wid {
      width: 210%;  /* Large screen width */
      right: 245px; /* Large screen right position */
    }
  }
.agent-badge{
    background: var(--og-bg);
    padding: 4px 8px;
    display: flex;
    gap: 8px;
    border-radius: 6px;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    justify-content: center;
    color: var(--font-color);
    width: fit-content;
}
.agent-name-div{
    font-weight: 500;
    font-size: 16px;
    color: var(--font-color);
}

.agent-btn-icon{
    color: var(--font-color);
}
.agent-btn{
    background-color: var(--white-bg);
    border-color : var(--font-color);
    color: var(--font-color);
}
.agent-btn:hover{
    background-color: var(--menu-hover-bg);
    color: var(--font-color);
}

.agent-tabs-wrapper {
    padding: 16px 0px 16px 24px;
    border-bottom: 1px solid #D1D5DB;
}
.agent-action-tabs {
    gap: 8px;
    display: flex;
    padding: 4px;
    list-style: none;
    cursor: pointer;
    background-color: #F3F4F6;
    border-radius: 8px;
    width: -moz-fit-content;
    width: fit-content;
}
.agent-active-tab {
    background-color: #FFFFFF;
    border-radius: 8px;
    color: var(--primary-color);
    box-shadow: 0px 1px 2px -1px #0000001A;
    box-shadow: 0px 1px 3px 0px #0000001A;
}
.agent-tab{
    padding: 4px 12px 4px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: var(--secondary-color);
    height: 36px;
    display: flex;
    align-items: center;
}
.agent-header-title{
    font-size: 16px;
    color: var(--font-color);
    font-weight: 500;
}
.agent-div-container{
    border-bottom: 1px solid var(--border-bd-1);
}
.slider-container {
    position: relative;
    width: 100%;
    margin-top: 20px;
}
.custom-slider {
    width: 100%;
    height: 8px;
    background: var(--menu-hover-bg);
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    cursor: pointer;
}
.tooltip {
    position: absolute;
    top: -30px;
    transform: translateX(-50%);
    background-color: var(--border-bd-1);
    color: var(--font-color);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    visibility: visible;
    opacity: 1;
}
.marks-container {
    position: absolute;
    top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.mark {
    position: absolute;
    top: 5px;
    transform: translateX(-50%);
}
.mark-label {
    font-size: 12px;
    color: #555;
}
.custom-slider::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--white-bg); /* Initial color of the thumb */
    border: 1px solid var(--theme-main);
    -webkit-appearance: none;
    cursor: pointer;
    transition: background 0.3s ease, border 0.3s ease;
}
.custom-slider::-webkit-slider-thumb:hover {
    background: var(--white-bg);
}

.agent-create-btn{
    background-color: var(--theme-secondary-bg) !important;
    color: var(--font-color) !important;
}
.agent-create-btn-spn{
    align-items: center;
    display: flex;
    gap: 5px;
    width: 70%;
}

.agent-create-btn-spn-1{
    align-items: center;
    display: flex;
    gap: 5px;
    width: 100%;
}

.form-input-templates{
    width: 890px;
    border: none;
    height: 28px;
    font-weight: 400;
    font-size: 15px;
    margin-top: -5px;
}
.form-input-templates:focus{
    width: 890px;
    border: none !important;
    box-shadow: 0 0 0 0 !important;
}

.p-component {
    font-family: var(--bs-font-sans-serif);
    font-size: 0.88rem;
    font-weight: 400;
    /* padding: 0.375rem 0.75rem; */
    line-height: 0.7 !important;
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--font-color) !important;
    background-color: var(--white-bg) !important;
    border-radius: 0.25rem;
}
.p-mention-input{
    width: 100% !important;
}

.textbox-mention {
    resize: none; /* Prevent resizing */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent wrapping */
    height: auto; /* Let it expand if needed */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
} 

.email-model-wid {
    width: 70%;  /* Default width for small screens */
    right: 0;     /* Default positioning */
    position: relative;  /* Ensure positioning works */
    scrollbar-width: none;
}
  @media (min-width: 768px) {
    .email-model-wid {
      width: 120%;  /* Adjust width for medium screens */
      right: 100px; /* Adjust right positioning */
    }
  }
  
  @media (min-width: 1200px) {
    .email-model-wid {
      width: 170%;  /* Large screen width */
      right: 146px;; /* Large screen right position */
    }
  }

.email-model-footer{
    display: flex;
    justify-content: center;
}

.email-footer-save{
    background: var(--font-color);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    width: 160px !important;
    justify-content: center;
    text-align: left;
    color: var(--icon-bg);
    border: none;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 1px 2px -1px #0000001A;
    box-shadow: 0px 1px 3px 0px
}
.custom-domain-record:hover .custom-domain-record:focus{
    color : var(--text-light) !important;
}

.slider-tooltip{
    transform: translateX(-50%);
    padding: 5px !important;
    background-color: var(--white-bg) !important;
    color: var(--font-color) !important;
    border-radius: 5px;
    font-size: 12px;
}

.no-rec-agent-create{
    gap: 50rem;
}
.agency-setting-title{
    color: var(--font-color);
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
[class*="custom-mention-style"] {
    line-height: 1.5 !important;
}
.sub-setting-web-title{
    font-weight: 500 !important;
}

.dark-popover-info-btn{
    cursor: pointer;
    margin-left: 5px;
    color: #ffffff !important;
    width: 17px;
    height: 17px;
}