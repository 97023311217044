
.app-login-box .cratio-logo {
    margin-bottom: 2rem;
}

.cratio-logo{
    height: 25px;
    width: 100px;
}

.btn-primary {
    color: var(--white-bg);
    background-color: var(--primary-btn) !important;
    border-color: var(--primary-btn) !important;
}
.btn-primary:hover{
    background-color: #0a9900 !important;
    border-color: #0a9900 !important;
}

.app-login-box .errors{
    color: var(--login-box-error);
  }
.app-login-box .btn-secondary{
    background-color: var(--login-box) !important;
    border-color:  var(--login-box) !important;
}
.app-login-box .btn-link{
    color:  var(--login-box)!important;
}

.app-login-box h4 div, .app-login-box .h4 div{
    opacity: 1 !important;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-items: center !important;
}
.col-lg-7 {
    flex: 0 0 auto;
    width: 55.33%;
}
.col-lg-5 {
flex: 0 0 auto;
width: 44.67%;
}
.d-grid {
    display: grid !important;
  }
  .d-md-grid {
    display: grid !important;
  }
.d-lg-block {
    display: block !important;
}
.bg-dark-shadow {
    background-image:linear-gradient(135deg, var(--bg-gradiant) 0%, var(--bg-gradiant-dark) 100%) !important
}
.bg-img-new {
    box-sizing: border-box;
    background-image: url('../assets/utils/images/voicemetrics.png');
    position:relative;
    left: 0;
    padding-top: 65px;
    width: 400px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size:contain;
}
.vm-btn{
background-color: var(--dark-color);
color: var(--white-bg);
height: 38px;
border-radius: 0.3rem;
font-weight: 500;
text-align: center;
font-size: 0.8rem;
}
.vm-btn-hover:hover{
color: var(--white-bg) !important;
background-color:var(--domain-color) !important;
border-color: var(--domain-color) !important;
}
.vm-btn-link{
color:var(--primary-background) !important;
}
.vm-secondary-btn{
width:130px;
padding-left: 5px;
}
.mt-4 {
    margin-top: 1.5rem !important;
}
.d-flex-alone {
    display: flex !important;
}
.justify-center {
    justify-content: center;
}
.social-btn {
    position: relative;
    width: 250px;
    border: 1px solid var(--text-light);
    border-radius: 6px;
    font-size: 16px;
    align-items: center;
    background-color: var(--white-bg);
    height: 52px;
    transition: box-shadow .15 ease-in-out, background-color .15s ease-in-out;
    cursor: pointer;
    color: var(--social-btn);
    margin-bottom: 8px;
    display: flex;
    outline: 0;
    padding: 0 8px 0 52px;
}
.social-btn:hover {
    color: var(--social-btn);
    box-shadow: inset 0 0 0 150px #0000001a;
}
.social-logo-wrapper {
    position: absolute;
    left: 26px;
    top: 50%;
    transform: translate(-50%) translateY(-50%);
}
.social-logo {
    width: 20px;
    height: 20px;
    display: inline-block;
}
.social-text {
    text-align: left;
    position: relative;
}
.divider-wrapper{
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    border: none;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 24px 0 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    vertical-align: baseline;
  }
  .divider-wrapper:before, .divider-wrapper:after {
    content: "";
    border-bottom: 1px solid var(--text-light);
    flex: 1 0 auto;
    height: .5em;
    margin: 0;
  }
.divider-wrapper .g-divider {
    text-align: center;
    flex: .2 0 auto;
    margin: 0;
    height: 12px;
}
.app-login-2{
    padding-top: 3px;;
}
.app-logo-inverse{
    height: auto !important;
    width: auto !important;
    /*background-image: url('../assets/utils/images/logo.png') !important;
    background-size: cover !important;*/
    background-image: unset !important;
    background: unset !important;
}
.app-logo-inverse img{
    height: auto !important;
    width: auto !important;
    background-size: cover !important;
}
.contact-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 24px;
    box-sizing: border-box;
}
.le-form-row {
    display: flex;
    align-items: center;
    margin: 0;
}
.input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    /*border: 1px solid #ccc;*/
    border-radius: 10px; 
    padding: 0;
}
.search-input {
    padding-left: 30px; 
    border-radius: 5px;
}
.search-icon {
    position: absolute;
    padding-left: 8px; 
}
.dark-theme .divider{
    background-color: #1c1e21 !important;
}