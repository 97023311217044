/*!
*   _____       _             __                 _____                               ______ _       _   _
*  |_   _|     | |           / _|               |_   _|                             |  ____| |     | | (_)
*    | |  _ __ | |_ ___ _ __| |_ __ _  ___ ___    | |  ___ ___  _ __  ___   ______  | |__  | | __ _| |_ _  ___ ___  _ __
*    | | | '_ \| __/ _ \ '__|  _/ _` |/ __/ _ \   | | / __/ _ \| '_ \/ __| |______| |  __| | |/ _` | __| |/ __/ _ \| '_ \
*   _| |_| | | | ||  __/ |  | || (_| | (_|  __/  _| || (_| (_) | | | \__ \          | |    | | (_| | |_| | (_| (_) | | | |
*  |_____|_| |_|\__\___|_|  |_| \__,_|\___\___| |_____\___\___/|_| |_|___/          |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*                         UIcons 2.6.0 - https://www.flaticon.com/uicons/interface-icons
*/
@font-face {
    font-family: "uicons-solid-straight";
    src: url("https://cdn-uicons.flaticon.com/2.6.0/uicons-solid-straight/webfonts/uicons-solid-straight.woff2") format("woff2"),
    url("https://cdn-uicons.flaticon.com/2.6.0/uicons-solid-straight/webfonts/uicons-solid-straight.woff") format("woff"),
    url("https://cdn-uicons.flaticon.com/2.6.0/uicons-solid-straight/webfonts/uicons-solid-straight.eot#iefix") format("embedded-opentype");
    font-display: swap;
}
    i[class^="fi-ss-"]:before, i[class*=" fi-ss-"]:before, span[class^="fi-ss-"]:before, span[class*="fi-ss-"]:before {
font-family: uicons-solid-straight !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-ss-0:before {
    content: "\e001";
    }
        .fi-ss-00s-music-disc:before {
    content: "\e002";
    }
        .fi-ss-1:before {
    content: "\e003";
    }
        .fi-ss-2:before {
    content: "\e007";
    }
        .fi-ss-3:before {
    content: "\e009";
    }
        .fi-ss-360-degrees:before {
    content: "\e00b";
    }
        .fi-ss-4:before {
    content: "\e00d";
    }
        .fi-ss-404:before {
    content: "\e00f";
    }
        .fi-ss-5:before {
    content: "\e010";
    }
        .fi-ss-6:before {
    content: "\e013";
    }
        .fi-ss-60s-music-disc:before {
    content: "\e014";
    }
        .fi-ss-7:before {
    content: "\e015";
    }
        .fi-ss-70s-music-disc:before {
    content: "\e016";
    }
        .fi-ss-8:before {
    content: "\e017";
    }
        .fi-ss-80s-music-disc:before {
    content: "\e018";
    }
        .fi-ss-9:before {
    content: "\e019";
    }
        .fi-ss-90s-music-disc:before {
    content: "\e01a";
    }
        .fi-ss-a:before {
    content: "\e01b";
    }
        .fi-ss-abacus:before {
    content: "\e01c";
    }
        .fi-ss-accident:before {
    content: "\e01f";
    }
        .fi-ss-acorn:before {
    content: "\e021";
    }
        .fi-ss-ad:before {
    content: "\e023";
    }
        .fi-ss-ad-forbidden:before {
    content: "\e024";
    }
        .fi-ss-ad-paid:before {
    content: "\e025";
    }
        .fi-ss-add:before {
    content: "\e026";
    }
        .fi-ss-add-document:before {
    content: "\e028";
    }
        .fi-ss-add-folder:before {
    content: "\e029";
    }
        .fi-ss-add-image:before {
    content: "\e02a";
    }
        .fi-ss-address-book:before {
    content: "\e02b";
    }
        .fi-ss-address-card:before {
    content: "\e02c";
    }
        .fi-ss-admin:before {
    content: "\e02d";
    }
        .fi-ss-admin-alt:before {
    content: "\e02e";
    }
        .fi-ss-age:before {
    content: "\e038";
    }
        .fi-ss-age-alt:before {
    content: "\e039";
    }
        .fi-ss-age-restriction-eighteen:before {
    content: "\e03a";
    }
        .fi-ss-age-restriction-seven:before {
    content: "\e03b";
    }
        .fi-ss-age-restriction-six:before {
    content: "\e03c";
    }
        .fi-ss-age-restriction-sixteen:before {
    content: "\e03d";
    }
        .fi-ss-age-restriction-thirteen:before {
    content: "\e03f";
    }
        .fi-ss-age-restriction-three:before {
    content: "\e040";
    }
        .fi-ss-age-restriction-twelve:before {
    content: "\e041";
    }
        .fi-ss-age-restriction-twenty-one:before {
    content: "\e042";
    }
        .fi-ss-age-restriction-zero:before {
    content: "\e043";
    }
        .fi-ss-air-conditioner:before {
    content: "\e046";
    }
        .fi-ss-air-freshener:before {
    content: "\e047";
    }
        .fi-ss-air-pollution:before {
    content: "\e048";
    }
        .fi-ss-airplane-journey:before {
    content: "\e04a";
    }
        .fi-ss-airplane-window-open:before {
    content: "\e04b";
    }
        .fi-ss-airplay:before {
    content: "\e04c";
    }
        .fi-ss-alarm-clock:before {
    content: "\e04d";
    }
        .fi-ss-alarm-exclamation:before {
    content: "\e04e";
    }
        .fi-ss-alarm-plus:before {
    content: "\e04f";
    }
        .fi-ss-alarm-snooze:before {
    content: "\e050";
    }
        .fi-ss-album:before {
    content: "\e051";
    }
        .fi-ss-album-circle-plus:before {
    content: "\e052";
    }
        .fi-ss-album-circle-user:before {
    content: "\e053";
    }
        .fi-ss-album-collection:before {
    content: "\e054";
    }
        .fi-ss-algorithm:before {
    content: "\e056";
    }
        .fi-ss-alicorn:before {
    content: "\e057";
    }
        .fi-ss-alien:before {
    content: "\e058";
    }
        .fi-ss-align-center:before {
    content: "\e05a";
    }
        .fi-ss-align-justify:before {
    content: "\e05b";
    }
        .fi-ss-align-left:before {
    content: "\e05c";
    }
        .fi-ss-align-slash:before {
    content: "\e05d";
    }
        .fi-ss-ambulance:before {
    content: "\e05f";
    }
        .fi-ss-amp-guitar:before {
    content: "\e063";
    }
        .fi-ss-amusement-park:before {
    content: "\e065";
    }
        .fi-ss-analyse:before {
    content: "\e066";
    }
        .fi-ss-analyse-alt:before {
    content: "\e067";
    }
        .fi-ss-anatomical-heart:before {
    content: "\e069";
    }
        .fi-ss-anchor:before {
    content: "\e06a";
    }
        .fi-ss-angel:before {
    content: "\e06c";
    }
        .fi-ss-angle:before {
    content: "\e06d";
    }
        .fi-ss-angle-90:before {
    content: "\e06e";
    }
        .fi-ss-angle-circle-down:before {
    content: "\e06f";
    }
        .fi-ss-angle-circle-left:before {
    content: "\e070";
    }
        .fi-ss-angle-circle-right:before {
    content: "\e071";
    }
        .fi-ss-angle-circle-up:before {
    content: "\e072";
    }
        .fi-ss-angle-double-left:before {
    content: "\e073";
    }
        .fi-ss-angle-double-right:before {
    content: "\e074";
    }
        .fi-ss-angle-double-small-down:before {
    content: "\e075";
    }
        .fi-ss-angle-double-small-left:before {
    content: "\e076";
    }
        .fi-ss-angle-double-small-right:before {
    content: "\e077";
    }
        .fi-ss-angle-double-small-up:before {
    content: "\e078";
    }
        .fi-ss-angle-down:before {
    content: "\e079";
    }
        .fi-ss-angle-left:before {
    content: "\e07a";
    }
        .fi-ss-angle-right:before {
    content: "\e07b";
    }
        .fi-ss-angle-small-down:before {
    content: "\e07c";
    }
        .fi-ss-angle-small-left:before {
    content: "\e07d";
    }
        .fi-ss-angle-small-right:before {
    content: "\e07e";
    }
        .fi-ss-angle-small-up:before {
    content: "\e07f";
    }
        .fi-ss-angle-square-down:before {
    content: "\e080";
    }
        .fi-ss-angle-square-left:before {
    content: "\e081";
    }
        .fi-ss-angle-square-right:before {
    content: "\e082";
    }
        .fi-ss-angle-square-up:before {
    content: "\e083";
    }
        .fi-ss-angle-up:before {
    content: "\e084";
    }
        .fi-ss-angles-up-down:before {
    content: "\e085";
    }
        .fi-ss-angry:before {
    content: "\e086";
    }
        .fi-ss-animated-icon:before {
    content: "\e088";
    }
        .fi-ss-ankh:before {
    content: "\e089";
    }
        .fi-ss-answer:before {
    content: "\e08a";
    }
        .fi-ss-answer-alt:before {
    content: "\e08b";
    }
        .fi-ss-apartment:before {
    content: "\e08c";
    }
        .fi-ss-aperture:before {
    content: "\e08d";
    }
        .fi-ss-api:before {
    content: "\e08e";
    }
        .fi-ss-app-notification:before {
    content: "\e090";
    }
        .fi-ss-apple-books:before {
    content: "\e093";
    }
        .fi-ss-apple-core:before {
    content: "\e094";
    }
        .fi-ss-apple-crate:before {
    content: "\e095";
    }
        .fi-ss-apple-whole:before {
    content: "\e097";
    }
        .fi-ss-apps:before {
    content: "\e098";
    }
        .fi-ss-apps-add:before {
    content: "\e099";
    }
        .fi-ss-apps-delete:before {
    content: "\e09a";
    }
        .fi-ss-apps-sort:before {
    content: "\e09b";
    }
        .fi-ss-archive:before {
    content: "\e09c";
    }
        .fi-ss-archway:before {
    content: "\e09d";
    }
        .fi-ss-arrow-alt-circle-down:before {
    content: "\e09f";
    }
        .fi-ss-arrow-alt-circle-left:before {
    content: "\e0a0";
    }
        .fi-ss-arrow-alt-circle-right:before {
    content: "\e0a1";
    }
        .fi-ss-arrow-alt-circle-up:before {
    content: "\e0a2";
    }
        .fi-ss-arrow-alt-down:before {
    content: "\e0a3";
    }
        .fi-ss-arrow-alt-from-bottom:before {
    content: "\e0a4";
    }
        .fi-ss-arrow-alt-from-left:before {
    content: "\e0a5";
    }
        .fi-ss-arrow-alt-from-right:before {
    content: "\e0a6";
    }
        .fi-ss-arrow-alt-from-top:before {
    content: "\e0a7";
    }
        .fi-ss-arrow-alt-left:before {
    content: "\e0a8";
    }
        .fi-ss-arrow-alt-right:before {
    content: "\e0a9";
    }
        .fi-ss-arrow-alt-square-down:before {
    content: "\e0aa";
    }
        .fi-ss-arrow-alt-square-left:before {
    content: "\e0ab";
    }
        .fi-ss-arrow-alt-square-right:before {
    content: "\e0ac";
    }
        .fi-ss-arrow-alt-square-up:before {
    content: "\e0ad";
    }
        .fi-ss-arrow-alt-to-bottom:before {
    content: "\e0ae";
    }
        .fi-ss-arrow-alt-to-left:before {
    content: "\e0af";
    }
        .fi-ss-arrow-alt-to-right:before {
    content: "\e0b0";
    }
        .fi-ss-arrow-alt-to-top:before {
    content: "\e0b1";
    }
        .fi-ss-arrow-alt-up:before {
    content: "\e0b2";
    }
        .fi-ss-arrow-circle-down:before {
    content: "\e0b3";
    }
        .fi-ss-arrow-circle-left:before {
    content: "\e0b4";
    }
        .fi-ss-arrow-circle-right:before {
    content: "\e0b5";
    }
        .fi-ss-arrow-circle-up:before {
    content: "\e0b6";
    }
        .fi-ss-arrow-comparison:before {
    content: "\e0b7";
    }
        .fi-ss-arrow-down:before {
    content: "\e0b8";
    }
        .fi-ss-arrow-down-from-arc:before {
    content: "\e0ba";
    }
        .fi-ss-arrow-down-from-dotted-line:before {
    content: "\e0bb";
    }
        .fi-ss-arrow-down-left:before {
    content: "\e0bc";
    }
        .fi-ss-arrow-down-small-big:before {
    content: "\e0bd";
    }
        .fi-ss-arrow-down-strenght:before {
    content: "\e0be";
    }
        .fi-ss-arrow-down-to-dotted-line:before {
    content: "\e0bf";
    }
        .fi-ss-arrow-down-to-square:before {
    content: "\e0c0";
    }
        .fi-ss-arrow-down-triangle-square:before {
    content: "\e0c1";
    }
        .fi-ss-arrow-from-bottom:before {
    content: "\e0c2";
    }
        .fi-ss-arrow-from-left:before {
    content: "\e0c3";
    }
        .fi-ss-arrow-from-right:before {
    content: "\e0c4";
    }
        .fi-ss-arrow-from-top:before {
    content: "\e0c5";
    }
        .fi-ss-arrow-left:before {
    content: "\e0c6";
    }
        .fi-ss-arrow-left-from-arc:before {
    content: "\e0c7";
    }
        .fi-ss-arrow-left-from-line:before {
    content: "\e0c8";
    }
        .fi-ss-arrow-left-to-arc:before {
    content: "\e0c9";
    }
        .fi-ss-arrow-progress:before {
    content: "\e0ca";
    }
        .fi-ss-arrow-progress-alt:before {
    content: "\e0cb";
    }
        .fi-ss-arrow-right:before {
    content: "\e0cc";
    }
        .fi-ss-arrow-right-to-bracket:before {
    content: "\e0cd";
    }
        .fi-ss-arrow-right-to-city:before {
    content: "\e0ce";
    }
        .fi-ss-arrow-small-down:before {
    content: "\e0cf";
    }
        .fi-ss-arrow-small-left:before {
    content: "\e0d0";
    }
        .fi-ss-arrow-small-right:before {
    content: "\e0d1";
    }
        .fi-ss-arrow-small-up:before {
    content: "\e0d2";
    }
        .fi-ss-arrow-square-down:before {
    content: "\e0d3";
    }
        .fi-ss-arrow-square-left:before {
    content: "\e0d4";
    }
        .fi-ss-arrow-square-right:before {
    content: "\e0d5";
    }
        .fi-ss-arrow-square-up:before {
    content: "\e0d6";
    }
        .fi-ss-arrow-to-bottom:before {
    content: "\e0d7";
    }
        .fi-ss-arrow-to-left:before {
    content: "\e0d8";
    }
        .fi-ss-arrow-to-right:before {
    content: "\e0d9";
    }
        .fi-ss-arrow-to-top:before {
    content: "\e0da";
    }
        .fi-ss-arrow-trend-down:before {
    content: "\e0db";
    }
        .fi-ss-arrow-trend-up:before {
    content: "\e0dc";
    }
        .fi-ss-arrow-turn-down-left:before {
    content: "\e0dd";
    }
        .fi-ss-arrow-turn-down-right:before {
    content: "\e0de";
    }
        .fi-ss-arrow-turn-left-up:before {
    content: "\e0e0";
    }
        .fi-ss-arrow-up:before {
    content: "\e0e2";
    }
        .fi-ss-arrow-up-from-dotted-line:before {
    content: "\e0e3";
    }
        .fi-ss-arrow-up-from-ground-water:before {
    content: "\e0e4";
    }
        .fi-ss-arrow-up-from-square:before {
    content: "\e0e5";
    }
        .fi-ss-arrow-up-left:before {
    content: "\e0e6";
    }
        .fi-ss-arrow-up-left-from-circle:before {
    content: "\e0e7";
    }
        .fi-ss-arrow-up-right:before {
    content: "\e0e8";
    }
        .fi-ss-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\e0e9";
    }
        .fi-ss-arrow-up-right-from-square:before {
    content: "\e0ea";
    }
        .fi-ss-arrow-up-small-big:before {
    content: "\e0eb";
    }
        .fi-ss-arrow-up-square-triangle:before {
    content: "\e0ec";
    }
        .fi-ss-arrow-up-to-arc:before {
    content: "\e0ed";
    }
        .fi-ss-arrow-up-to-dotted-line:before {
    content: "\e0ee";
    }
        .fi-ss-arrow-upward-growth-crypto:before {
    content: "\e0ef";
    }
        .fi-ss-arrows:before {
    content: "\e0f0";
    }
        .fi-ss-arrows-alt:before {
    content: "\e0f1";
    }
        .fi-ss-arrows-alt-h:before {
    content: "\e0f2";
    }
        .fi-ss-arrows-alt-v:before {
    content: "\e0f3";
    }
        .fi-ss-arrows-cross:before {
    content: "\e0f4";
    }
        .fi-ss-arrows-down-curve:before {
    content: "\e0f5";
    }
        .fi-ss-arrows-down-to-people:before {
    content: "\e0f6";
    }
        .fi-ss-arrows-from-dotted-line:before {
    content: "\e0f7";
    }
        .fi-ss-arrows-from-line:before {
    content: "\e0f8";
    }
        .fi-ss-arrows-h:before {
    content: "\e0f9";
    }
        .fi-ss-arrows-h-copy:before {
    content: "\e0fa";
    }
        .fi-ss-arrows-repeat:before {
    content: "\e0fb";
    }
        .fi-ss-arrows-repeat-1:before {
    content: "\e0fc";
    }
        .fi-ss-arrows-retweet:before {
    content: "\e0fd";
    }
        .fi-ss-arrows-split-right-and-left:before {
    content: "\e0ff";
    }
        .fi-ss-arrows-split-up-and-left:before {
    content: "\e100";
    }
        .fi-ss-arrows-to-circle:before {
    content: "\e101";
    }
        .fi-ss-arrows-to-dotted-line:before {
    content: "\e102";
    }
        .fi-ss-arrows-to-eye:before {
    content: "\e103";
    }
        .fi-ss-arrows-to-line:before {
    content: "\e104";
    }
        .fi-ss-artificial-intelligence:before {
    content: "\e105";
    }
        .fi-ss-assept-document:before {
    content: "\e107";
    }
        .fi-ss-assessment:before {
    content: "\e108";
    }
        .fi-ss-assessment-alt:before {
    content: "\e109";
    }
        .fi-ss-assign:before {
    content: "\e10a";
    }
        .fi-ss-assistive-listening-systems:before {
    content: "\e10b";
    }
        .fi-ss-asterik:before {
    content: "\e10c";
    }
        .fi-ss-at:before {
    content: "\e110";
    }
        .fi-ss-attention-detail:before {
    content: "\e115";
    }
        .fi-ss-attribution-pen:before {
    content: "\e116";
    }
        .fi-ss-attribution-pencil:before {
    content: "\e117";
    }
        .fi-ss-aubergine:before {
    content: "\e118";
    }
        .fi-ss-auction:before {
    content: "\e119";
    }
        .fi-ss-audience-megaphone:before {
    content: "\e11a";
    }
        .fi-ss-audio-description-slash:before {
    content: "\e11b";
    }
        .fi-ss-audit:before {
    content: "\e11c";
    }
        .fi-ss-audit-alt:before {
    content: "\e11d";
    }
        .fi-ss-austral-sign:before {
    content: "\e11f";
    }
        .fi-ss-australia:before {
    content: "\e120";
    }
        .fi-ss-australia-country-code:before {
    content: "\e121";
    }
        .fi-ss-australia-flag:before {
    content: "\e122";
    }
        .fi-ss-auto-pilot:before {
    content: "\e123";
    }
        .fi-ss-auto-reply:before {
    content: "\e124";
    }
        .fi-ss-auto-sync:before {
    content: "\e125";
    }
        .fi-ss-avocado:before {
    content: "\e126";
    }
        .fi-ss-award:before {
    content: "\e127";
    }
        .fi-ss-axe:before {
    content: "\e129";
    }
        .fi-ss-axe-battle:before {
    content: "\e12a";
    }
        .fi-ss-b:before {
    content: "\e12b";
    }
        .fi-ss-baby:before {
    content: "\e12c";
    }
        .fi-ss-baby-carriage:before {
    content: "\e12d";
    }
        .fi-ss-back-up:before {
    content: "\e12e";
    }
        .fi-ss-background:before {
    content: "\e12f";
    }
        .fi-ss-backpack:before {
    content: "\e130";
    }
        .fi-ss-bacon:before {
    content: "\e131";
    }
        .fi-ss-bacteria:before {
    content: "\e132";
    }
        .fi-ss-bacterium:before {
    content: "\e133";
    }
        .fi-ss-badge:before {
    content: "\e134";
    }
        .fi-ss-badge-check:before {
    content: "\e135";
    }
        .fi-ss-badge-dollar:before {
    content: "\e136";
    }
        .fi-ss-badge-leaf:before {
    content: "\e137";
    }
        .fi-ss-badge-percent:before {
    content: "\e138";
    }
        .fi-ss-badge-sheriff:before {
    content: "\e139";
    }
        .fi-ss-badger-honey:before {
    content: "\e13a";
    }
        .fi-ss-badget-check-alt:before {
    content: "\e13b";
    }
        .fi-ss-badminton:before {
    content: "\e13c";
    }
        .fi-ss-bag-map-pin:before {
    content: "\e13d";
    }
        .fi-ss-bag-seedling:before {
    content: "\e13e";
    }
        .fi-ss-bag-shopping-minus:before {
    content: "\e13f";
    }
        .fi-ss-bags-shopping:before {
    content: "\e141";
    }
        .fi-ss-baguette:before {
    content: "\e142";
    }
        .fi-ss-bahai:before {
    content: "\e143";
    }
        .fi-ss-baht-sign:before {
    content: "\e144";
    }
        .fi-ss-balance-scale-left:before {
    content: "\e145";
    }
        .fi-ss-balance-scale-right:before {
    content: "\e146";
    }
        .fi-ss-balcony:before {
    content: "\e147";
    }
        .fi-ss-ball-pile:before {
    content: "\e149";
    }
        .fi-ss-ballet-dance:before {
    content: "\e14b";
    }
        .fi-ss-balloon:before {
    content: "\e14c";
    }
        .fi-ss-balloons:before {
    content: "\e14d";
    }
        .fi-ss-ballot:before {
    content: "\e14e";
    }
        .fi-ss-ballot-check:before {
    content: "\e14f";
    }
        .fi-ss-ban:before {
    content: "\e150";
    }
        .fi-ss-ban-bug:before {
    content: "\e151";
    }
        .fi-ss-banana:before {
    content: "\e152";
    }
        .fi-ss-band-aid:before {
    content: "\e153";
    }
        .fi-ss-bandage-wound:before {
    content: "\e155";
    }
        .fi-ss-bangladeshi-taka-sign:before {
    content: "\e156";
    }
        .fi-ss-banjo:before {
    content: "\e157";
    }
        .fi-ss-bank:before {
    content: "\e158";
    }
        .fi-ss-bank-app:before {
    content: "\e159";
    }
        .fi-ss-banner:before {
    content: "\e15a";
    }
        .fi-ss-banner-2:before {
    content: "\e15b";
    }
        .fi-ss-banner-3:before {
    content: "\e15c";
    }
        .fi-ss-banner-4:before {
    content: "\e15d";
    }
        .fi-ss-banner-5:before {
    content: "\e15e";
    }
        .fi-ss-barber-pole:before {
    content: "\e15f";
    }
        .fi-ss-barber-shop:before {
    content: "\e160";
    }
        .fi-ss-barcode:before {
    content: "\e161";
    }
        .fi-ss-barcode-read:before {
    content: "\e162";
    }
        .fi-ss-barcode-scan:before {
    content: "\e163";
    }
        .fi-ss-barefoot:before {
    content: "\e164";
    }
        .fi-ss-bars-filter:before {
    content: "\e165";
    }
        .fi-ss-bars-progress:before {
    content: "\e166";
    }
        .fi-ss-bars-sort:before {
    content: "\e167";
    }
        .fi-ss-bars-staggered:before {
    content: "\e168";
    }
        .fi-ss-baseball:before {
    content: "\e169";
    }
        .fi-ss-baseball-alt:before {
    content: "\e16a";
    }
        .fi-ss-basket-shopping-minus:before {
    content: "\e16c";
    }
        .fi-ss-basket-shopping-plus:before {
    content: "\e16d";
    }
        .fi-ss-basket-shopping-simple:before {
    content: "\e16e";
    }
        .fi-ss-basketball:before {
    content: "\e16f";
    }
        .fi-ss-basketball-hoop:before {
    content: "\e170";
    }
        .fi-ss-bat:before {
    content: "\e171";
    }
        .fi-ss-bath:before {
    content: "\e172";
    }
        .fi-ss-battery-100:before {
    content: "\e173";
    }
        .fi-ss-battery-bolt:before {
    content: "\e174";
    }
        .fi-ss-battery-empty:before {
    content: "\e175";
    }
        .fi-ss-battery-exclamation:before {
    content: "\e176";
    }
        .fi-ss-battery-full:before {
    content: "\e177";
    }
        .fi-ss-battery-half:before {
    content: "\e178";
    }
        .fi-ss-battery-quarter:before {
    content: "\e179";
    }
        .fi-ss-battery-slash:before {
    content: "\e17a";
    }
        .fi-ss-battery-three-quarters:before {
    content: "\e17b";
    }
        .fi-ss-beacon:before {
    content: "\e17c";
    }
        .fi-ss-beauty-mask:before {
    content: "\e17d";
    }
        .fi-ss-bed:before {
    content: "\e17e";
    }
        .fi-ss-bed-alt:before {
    content: "\e17f";
    }
        .fi-ss-bed-bunk:before {
    content: "\e180";
    }
        .fi-ss-bed-empty:before {
    content: "\e181";
    }
        .fi-ss-bed-pulse:before {
    content: "\e182";
    }
        .fi-ss-bee:before {
    content: "\e183";
    }
        .fi-ss-beer:before {
    content: "\e184";
    }
        .fi-ss-beer-mug-empty:before {
    content: "\e185";
    }
        .fi-ss-bell:before {
    content: "\e187";
    }
        .fi-ss-bell-concierge:before {
    content: "\e188";
    }
        .fi-ss-bell-notification-call:before {
    content: "\e189";
    }
        .fi-ss-bell-notification-social-media:before {
    content: "\e18a";
    }
        .fi-ss-bell-ring:before {
    content: "\e18b";
    }
        .fi-ss-bell-school:before {
    content: "\e18c";
    }
        .fi-ss-bell-school-slash:before {
    content: "\e18d";
    }
        .fi-ss-bell-slash:before {
    content: "\e18e";
    }
        .fi-ss-bells:before {
    content: "\e18f";
    }
        .fi-ss-bench-tree:before {
    content: "\e190";
    }
        .fi-ss-benefit:before {
    content: "\e191";
    }
        .fi-ss-benefit-diamond:before {
    content: "\e192";
    }
        .fi-ss-benefit-diamond-alt:before {
    content: "\e193";
    }
        .fi-ss-benefit-hand:before {
    content: "\e194";
    }
        .fi-ss-benefit-increase:before {
    content: "\e195";
    }
        .fi-ss-benefit-porcent:before {
    content: "\e196";
    }
        .fi-ss-betamax:before {
    content: "\e197";
    }
        .fi-ss-bible:before {
    content: "\e198";
    }
        .fi-ss-bicycle-journey:before {
    content: "\e199";
    }
        .fi-ss-big-drop:before {
    content: "\e19a";
    }
        .fi-ss-bike:before {
    content: "\e19b";
    }
        .fi-ss-bike-moving:before {
    content: "\e19c";
    }
        .fi-ss-bike-path:before {
    content: "\e19d";
    }
        .fi-ss-biking:before {
    content: "\e19e";
    }
        .fi-ss-biking-mountain:before {
    content: "\e19f";
    }
        .fi-ss-bill-arrow:before {
    content: "\e1a0";
    }
        .fi-ss-billiard:before {
    content: "\e1a1";
    }
        .fi-ss-bin-bottles:before {
    content: "\e1a2";
    }
        .fi-ss-binary:before {
    content: "\e1a3";
    }
        .fi-ss-binary-circle-check:before {
    content: "\e1a4";
    }
        .fi-ss-binary-cloud:before {
    content: "\e1a5";
    }
        .fi-ss-binary-lock:before {
    content: "\e1a6";
    }
        .fi-ss-binary-slash:before {
    content: "\e1a7";
    }
        .fi-ss-binoculars:before {
    content: "\e1a8";
    }
        .fi-ss-bio:before {
    content: "\e1a9";
    }
        .fi-ss-bio-leaves:before {
    content: "\e1aa";
    }
        .fi-ss-biohazard:before {
    content: "\e1ab";
    }
        .fi-ss-bird:before {
    content: "\e1ac";
    }
        .fi-ss-bitcoin-sign:before {
    content: "\e1af";
    }
        .fi-ss-blanket:before {
    content: "\e1b1";
    }
        .fi-ss-blender:before {
    content: "\e1b2";
    }
        .fi-ss-blender-phone:before {
    content: "\e1b3";
    }
        .fi-ss-blinds:before {
    content: "\e1b4";
    }
        .fi-ss-blinds-open:before {
    content: "\e1b5";
    }
        .fi-ss-blinds-raised:before {
    content: "\e1b6";
    }
        .fi-ss-block:before {
    content: "\e1b7";
    }
        .fi-ss-block-brick:before {
    content: "\e1b8";
    }
        .fi-ss-block-brick-fire:before {
    content: "\e1b9";
    }
        .fi-ss-block-microphone:before {
    content: "\e1ba";
    }
        .fi-ss-block-question:before {
    content: "\e1bb";
    }
        .fi-ss-block-quote:before {
    content: "\e1bc";
    }
        .fi-ss-blockchain-3:before {
    content: "\e1bf";
    }
        .fi-ss-blog-pencil:before {
    content: "\e1c0";
    }
        .fi-ss-blog-text:before {
    content: "\e1c1";
    }
        .fi-ss-blood:before {
    content: "\e1c3";
    }
        .fi-ss-blood-dropper:before {
    content: "\e1c4";
    }
        .fi-ss-blood-test-tube:before {
    content: "\e1c5";
    }
        .fi-ss-blood-test-tube-alt:before {
    content: "\e1c6";
    }
        .fi-ss-blossom:before {
    content: "\e1c7";
    }
        .fi-ss-blueberries:before {
    content: "\e1c8";
    }
        .fi-ss-blueprint:before {
    content: "\e1c9";
    }
        .fi-ss-bluetooth-alt:before {
    content: "\e1cb";
    }
        .fi-ss-bluetooth-circle:before {
    content: "\e1cc";
    }
        .fi-ss-bold:before {
    content: "\e1cd";
    }
        .fi-ss-bolt:before {
    content: "\e1ce";
    }
        .fi-ss-bolt-auto:before {
    content: "\e1cf";
    }
        .fi-ss-bolt-slash:before {
    content: "\e1d0";
    }
        .fi-ss-bomb:before {
    content: "\e1d1";
    }
        .fi-ss-bone:before {
    content: "\e1d2";
    }
        .fi-ss-bone-break:before {
    content: "\e1d3";
    }
        .fi-ss-bong:before {
    content: "\e1d4";
    }
        .fi-ss-bonus:before {
    content: "\e1d5";
    }
        .fi-ss-bonus-alt:before {
    content: "\e1d6";
    }
        .fi-ss-bonus-star:before {
    content: "\e1d7";
    }
        .fi-ss-book:before {
    content: "\e1d8";
    }
        .fi-ss-book-alt:before {
    content: "\e1d9";
    }
        .fi-ss-book-arrow-right:before {
    content: "\e1da";
    }
        .fi-ss-book-arrow-up:before {
    content: "\e1db";
    }
        .fi-ss-book-atlas:before {
    content: "\e1dc";
    }
        .fi-ss-book-bookmark:before {
    content: "\e1dd";
    }
        .fi-ss-book-circle-arrow-right:before {
    content: "\e1de";
    }
        .fi-ss-book-circle-arrow-up:before {
    content: "\e1df";
    }
        .fi-ss-book-copy:before {
    content: "\e1e0";
    }
        .fi-ss-book-dead:before {
    content: "\e1e1";
    }
        .fi-ss-book-font:before {
    content: "\e1e2";
    }
        .fi-ss-book-heart:before {
    content: "\e1e3";
    }
        .fi-ss-book-medical:before {
    content: "\e1e4";
    }
        .fi-ss-book-open-cover:before {
    content: "\e1e5";
    }
        .fi-ss-book-open-reader:before {
    content: "\e1e6";
    }
        .fi-ss-book-plus:before {
    content: "\e1e7";
    }
        .fi-ss-book-quran:before {
    content: "\e1e8";
    }
        .fi-ss-book-section:before {
    content: "\e1e9";
    }
        .fi-ss-book-spells:before {
    content: "\e1ec";
    }
        .fi-ss-book-tanakh:before {
    content: "\e1ed";
    }
        .fi-ss-book-user:before {
    content: "\e1ee";
    }
        .fi-ss-book-world:before {
    content: "\e1ef";
    }
        .fi-ss-Booking:before {
    content: "\e1f0";
    }
        .fi-ss-bookmark:before {
    content: "\e1f1";
    }
        .fi-ss-bookmark-slash:before {
    content: "\e1f2";
    }
        .fi-ss-books:before {
    content: "\e1f3";
    }
        .fi-ss-books-medical:before {
    content: "\e1f4";
    }
        .fi-ss-boot:before {
    content: "\e1f5";
    }
        .fi-ss-boot-heeled:before {
    content: "\e1f6";
    }
        .fi-ss-booth-curtain:before {
    content: "\e1f7";
    }
        .fi-ss-border-all:before {
    content: "\e1f9";
    }
        .fi-ss-border-bottom:before {
    content: "\e1fa";
    }
        .fi-ss-border-center-h:before {
    content: "\e1fb";
    }
        .fi-ss-border-center-v:before {
    content: "\e1fc";
    }
        .fi-ss-border-inner:before {
    content: "\e1fd";
    }
        .fi-ss-border-left:before {
    content: "\e1fe";
    }
        .fi-ss-border-none:before {
    content: "\e1ff";
    }
        .fi-ss-border-outer:before {
    content: "\e200";
    }
        .fi-ss-border-right:before {
    content: "\e201";
    }
        .fi-ss-border-style:before {
    content: "\e202";
    }
        .fi-ss-border-style-alt:before {
    content: "\e203";
    }
        .fi-ss-border-top:before {
    content: "\e204";
    }
        .fi-ss-boss:before {
    content: "\e205";
    }
        .fi-ss-bottle:before {
    content: "\e206";
    }
        .fi-ss-bottle-droplet:before {
    content: "\e207";
    }
        .fi-ss-bow-arrow:before {
    content: "\e209";
    }
        .fi-ss-bowl-chopsticks:before {
    content: "\e20a";
    }
        .fi-ss-bowl-chopsticks-noodles:before {
    content: "\e20b";
    }
        .fi-ss-bowl-rice:before {
    content: "\e20c";
    }
        .fi-ss-bowl-scoop:before {
    content: "\e20d";
    }
        .fi-ss-bowl-scoops:before {
    content: "\e20e";
    }
        .fi-ss-bowl-soft-serve:before {
    content: "\e20f";
    }
        .fi-ss-bowl-spoon:before {
    content: "\e210";
    }
        .fi-ss-bowling:before {
    content: "\e211";
    }
        .fi-ss-bowling-ball:before {
    content: "\e212";
    }
        .fi-ss-bowling-pins:before {
    content: "\e213";
    }
        .fi-ss-box:before {
    content: "\e214";
    }
        .fi-ss-box-alt:before {
    content: "\e215";
    }
        .fi-ss-box-ballot:before {
    content: "\e216";
    }
        .fi-ss-box-check:before {
    content: "\e217";
    }
        .fi-ss-box-circle-check:before {
    content: "\e218";
    }
        .fi-ss-box-dollar:before {
    content: "\e219";
    }
        .fi-ss-box-fragile:before {
    content: "\e21a";
    }
        .fi-ss-box-heart:before {
    content: "\e21b";
    }
        .fi-ss-box-open:before {
    content: "\e21c";
    }
        .fi-ss-box-open-full:before {
    content: "\e21d";
    }
        .fi-ss-box-tissue:before {
    content: "\e21e";
    }
        .fi-ss-box-up:before {
    content: "\e21f";
    }
        .fi-ss-boxes:before {
    content: "\e220";
    }
        .fi-ss-boxing-glove:before {
    content: "\e221";
    }
        .fi-ss-bracket-curly:before {
    content: "\e222";
    }
        .fi-ss-bracket-curly-right:before {
    content: "\e223";
    }
        .fi-ss-bracket-round:before {
    content: "\e224";
    }
        .fi-ss-bracket-round-right:before {
    content: "\e225";
    }
        .fi-ss-bracket-square:before {
    content: "\e226";
    }
        .fi-ss-bracket-square-right:before {
    content: "\e227";
    }
        .fi-ss-brackets-curly:before {
    content: "\e228";
    }
        .fi-ss-brackets-round:before {
    content: "\e229";
    }
        .fi-ss-brackets-square:before {
    content: "\e22a";
    }
        .fi-ss-braille:before {
    content: "\e22b";
    }
        .fi-ss-braille-a:before {
    content: "\e22c";
    }
        .fi-ss-braille-b:before {
    content: "\e22d";
    }
        .fi-ss-braille-c:before {
    content: "\e22e";
    }
        .fi-ss-braille-d:before {
    content: "\e22f";
    }
        .fi-ss-braille-e:before {
    content: "\e230";
    }
        .fi-ss-braille-g:before {
    content: "\e231";
    }
        .fi-ss-braille-h:before {
    content: "\e232";
    }
        .fi-ss-braille-i:before {
    content: "\e233";
    }
        .fi-ss-braille-j:before {
    content: "\e234";
    }
        .fi-ss-braille-k:before {
    content: "\e235";
    }
        .fi-ss-braille-l:before {
    content: "\e236";
    }
        .fi-ss-braille-m:before {
    content: "\e237";
    }
        .fi-ss-braille-n:before {
    content: "\e238";
    }
        .fi-ss-braille-n-alt:before {
    content: "\e239";
    }
        .fi-ss-braille-o:before {
    content: "\e23a";
    }
        .fi-ss-braille-p:before {
    content: "\e23b";
    }
        .fi-ss-braille-q:before {
    content: "\e23c";
    }
        .fi-ss-braille-r:before {
    content: "\e23d";
    }
        .fi-ss-braille-s:before {
    content: "\e23e";
    }
        .fi-ss-braille-t:before {
    content: "\e23f";
    }
        .fi-ss-braille-u:before {
    content: "\e240";
    }
        .fi-ss-braille-v:before {
    content: "\e241";
    }
        .fi-ss-braille-w:before {
    content: "\e242";
    }
        .fi-ss-braille-x:before {
    content: "\e243";
    }
        .fi-ss-braille-y:before {
    content: "\e244";
    }
        .fi-ss-braille-z:before {
    content: "\e245";
    }
        .fi-ss-brain:before {
    content: "\e246";
    }
        .fi-ss-brain-bulb:before {
    content: "\e247";
    }
        .fi-ss-brain-circuit:before {
    content: "\e248";
    }
        .fi-ss-brain-lightning:before {
    content: "\e249";
    }
        .fi-ss-brain-stress:before {
    content: "\e24a";
    }
        .fi-ss-brake-warning:before {
    content: "\e24b";
    }
        .fi-ss-branching:before {
    content: "\e24c";
    }
        .fi-ss-brand:before {
    content: "\e24d";
    }
        .fi-ss-branding:before {
    content: "\e24e";
    }
        .fi-ss-brazil:before {
    content: "\e24f";
    }
        .fi-ss-brazil-flag:before {
    content: "\e250";
    }
        .fi-ss-brazil-flag-circle:before {
    content: "\e251";
    }
        .fi-ss-bread:before {
    content: "\e252";
    }
        .fi-ss-bread-loaf:before {
    content: "\e253";
    }
        .fi-ss-bread-slice:before {
    content: "\e254";
    }
        .fi-ss-bread-slice-butter:before {
    content: "\e255";
    }
        .fi-ss-bridge:before {
    content: "\e256";
    }
        .fi-ss-bridge-circle-check:before {
    content: "\e257";
    }
        .fi-ss-bridge-collapse:before {
    content: "\e258";
    }
        .fi-ss-bridge-suspension:before {
    content: "\e259";
    }
        .fi-ss-bridge-water:before {
    content: "\e25a";
    }
        .fi-ss-briefcase:before {
    content: "\e25c";
    }
        .fi-ss-briefcase-arrow-right:before {
    content: "\e25d";
    }
        .fi-ss-briefcase-blank:before {
    content: "\e25e";
    }
        .fi-ss-brightness:before {
    content: "\e25f";
    }
        .fi-ss-brightness-low:before {
    content: "\e260";
    }
        .fi-ss-bring-forward:before {
    content: "\e261";
    }
        .fi-ss-bring-front:before {
    content: "\e262";
    }
        .fi-ss-broadcast-tower:before {
    content: "\e263";
    }
        .fi-ss-broccoli:before {
    content: "\e264";
    }
        .fi-ss-broken-arm:before {
    content: "\e265";
    }
        .fi-ss-broken-chain-link-wrong:before {
    content: "\e266";
    }
        .fi-ss-broken-image:before {
    content: "\e267";
    }
        .fi-ss-broken-leg:before {
    content: "\e268";
    }
        .fi-ss-broken-nail:before {
    content: "\e269";
    }
        .fi-ss-broom:before {
    content: "\e26a";
    }
        .fi-ss-broom-ball:before {
    content: "\e26b";
    }
        .fi-ss-browser:before {
    content: "\e26c";
    }
        .fi-ss-browser-ui:before {
    content: "\e26f";
    }
        .fi-ss-browsers:before {
    content: "\e270";
    }
        .fi-ss-brush:before {
    content: "\e271";
    }
        .fi-ss-bubble-discussion:before {
    content: "\e272";
    }
        .fi-ss-bucket:before {
    content: "\e273";
    }
        .fi-ss-budget:before {
    content: "\e274";
    }
        .fi-ss-budget-alt:before {
    content: "\e275";
    }
        .fi-ss-bug:before {
    content: "\e276";
    }
        .fi-ss-bug-slash:before {
    content: "\e277";
    }
        .fi-ss-bugs:before {
    content: "\e278";
    }
        .fi-ss-build:before {
    content: "\e279";
    }
        .fi-ss-build-alt:before {
    content: "\e27a";
    }
        .fi-ss-builder:before {
    content: "\e27b";
    }
        .fi-ss-building:before {
    content: "\e27c";
    }
        .fi-ss-building-circle-arrow-right:before {
    content: "\e27d";
    }
        .fi-ss-building-ngo:before {
    content: "\e27e";
    }
        .fi-ss-bulb:before {
    content: "\e27f";
    }
        .fi-ss-bulb-speech-bubble:before {
    content: "\e280";
    }
        .fi-ss-bullet:before {
    content: "\e281";
    }
        .fi-ss-bullhorn:before {
    content: "\e282";
    }
        .fi-ss-bullseye:before {
    content: "\e283";
    }
        .fi-ss-bullseye-arrow:before {
    content: "\e284";
    }
        .fi-ss-bullseye-pointer:before {
    content: "\e285";
    }
        .fi-ss-burger-alt:before {
    content: "\e286";
    }
        .fi-ss-burger-fries:before {
    content: "\e287";
    }
        .fi-ss-burger-glass:before {
    content: "\e288";
    }
        .fi-ss-burrito:before {
    content: "\e28b";
    }
        .fi-ss-burst:before {
    content: "\e28c";
    }
        .fi-ss-bus:before {
    content: "\e28d";
    }
        .fi-ss-bus-alt:before {
    content: "\e28e";
    }
        .fi-ss-business-time:before {
    content: "\e28f";
    }
        .fi-ss-Butter:before {
    content: "\e291";
    }
        .fi-ss-butterfly:before {
    content: "\e292";
    }
        .fi-ss-c:before {
    content: "\e293";
    }
        .fi-ss-cabin:before {
    content: "\e294";
    }
        .fi-ss-cactus:before {
    content: "\e296";
    }
        .fi-ss-cage-empty:before {
    content: "\e297";
    }
        .fi-ss-cake-birthday:before {
    content: "\e298";
    }
        .fi-ss-cake-slice:before {
    content: "\e299";
    }
        .fi-ss-cake-wedding:before {
    content: "\e29a";
    }
        .fi-ss-calculator:before {
    content: "\e29b";
    }
        .fi-ss-calculator-bill:before {
    content: "\e29d";
    }
        .fi-ss-calculator-math-tax:before {
    content: "\e29e";
    }
        .fi-ss-calculator-money:before {
    content: "\e29f";
    }
        .fi-ss-calculator-simple:before {
    content: "\e2a0";
    }
        .fi-ss-calendar:before {
    content: "\e2a1";
    }
        .fi-ss-calendar-arrow-down:before {
    content: "\e2a2";
    }
        .fi-ss-calendar-arrow-up:before {
    content: "\e2a3";
    }
        .fi-ss-calendar-birhtday-cake:before {
    content: "\e2a4";
    }
        .fi-ss-calendar-call:before {
    content: "\e2a5";
    }
        .fi-ss-calendar-check:before {
    content: "\e2a6";
    }
        .fi-ss-calendar-clock:before {
    content: "\e2a7";
    }
        .fi-ss-calendar-day:before {
    content: "\e2a8";
    }
        .fi-ss-calendar-days:before {
    content: "\e2a9";
    }
        .fi-ss-calendar-event-tax:before {
    content: "\e2aa";
    }
        .fi-ss-calendar-exclamation:before {
    content: "\e2ab";
    }
        .fi-ss-calendar-gavel-legal:before {
    content: "\e2ac";
    }
        .fi-ss-calendar-heart:before {
    content: "\e2ad";
    }
        .fi-ss-calendar-image:before {
    content: "\e2ae";
    }
        .fi-ss-calendar-lines:before {
    content: "\e2af";
    }
        .fi-ss-calendar-lines-pen:before {
    content: "\e2b0";
    }
        .fi-ss-calendar-minus:before {
    content: "\e2b1";
    }
        .fi-ss-calendar-payment-loan:before {
    content: "\e2b2";
    }
        .fi-ss-calendar-pen:before {
    content: "\e2b3";
    }
        .fi-ss-calendar-plus:before {
    content: "\e2b4";
    }
        .fi-ss-calendar-salary:before {
    content: "\e2b5";
    }
        .fi-ss-calendar-shift-swap:before {
    content: "\e2b6";
    }
        .fi-ss-calendar-star:before {
    content: "\e2b7";
    }
        .fi-ss-calendar-swap:before {
    content: "\e2b8";
    }
        .fi-ss-calendar-update:before {
    content: "\e2b9";
    }
        .fi-ss-calendar-week:before {
    content: "\e2ba";
    }
        .fi-ss-calendar-xmark:before {
    content: "\e2bb";
    }
        .fi-ss-calendars:before {
    content: "\e2bc";
    }
        .fi-ss-call-duration:before {
    content: "\e2bd";
    }
        .fi-ss-call-forbidden:before {
    content: "\e2be";
    }
        .fi-ss-call-history:before {
    content: "\e2bf";
    }
        .fi-ss-call-incoming:before {
    content: "\e2c0";
    }
        .fi-ss-call-missed:before {
    content: "\e2c1";
    }
        .fi-ss-call-outgoing:before {
    content: "\e2c2";
    }
        .fi-ss-camcorder:before {
    content: "\e2c3";
    }
        .fi-ss-camera:before {
    content: "\e2c4";
    }
        .fi-ss-camera-cctv:before {
    content: "\e2c5";
    }
        .fi-ss-camera-movie:before {
    content: "\e2c7";
    }
        .fi-ss-camera-retro:before {
    content: "\e2c8";
    }
        .fi-ss-camera-rotate:before {
    content: "\e2c9";
    }
        .fi-ss-camera-security:before {
    content: "\e2ca";
    }
        .fi-ss-camera-slash:before {
    content: "\e2cb";
    }
        .fi-ss-camera-viewfinder:before {
    content: "\e2cc";
    }
        .fi-ss-campfire:before {
    content: "\e2cd";
    }
        .fi-ss-camping:before {
    content: "\e2ce";
    }
        .fi-ss-can-food:before {
    content: "\e2cf";
    }
        .fi-ss-candle-holder:before {
    content: "\e2d0";
    }
        .fi-ss-candle-lotus-yoga:before {
    content: "\e2d1";
    }
        .fi-ss-candle-pose-yoga:before {
    content: "\e2d2";
    }
        .fi-ss-candy:before {
    content: "\e2d4";
    }
        .fi-ss-candy-alt:before {
    content: "\e2d5";
    }
        .fi-ss-candy-bar:before {
    content: "\e2d6";
    }
        .fi-ss-candy-cane:before {
    content: "\e2d7";
    }
        .fi-ss-candy-corn:before {
    content: "\e2d8";
    }
        .fi-ss-candy-sweet:before {
    content: "\e2d9";
    }
        .fi-ss-Cannabis:before {
    content: "\e2da";
    }
        .fi-ss-canned-food:before {
    content: "\e2db";
    }
        .fi-ss-cannon:before {
    content: "\e2dc";
    }
        .fi-ss-capsules:before {
    content: "\e2dd";
    }
        .fi-ss-car:before {
    content: "\e2df";
    }
        .fi-ss-car-alt:before {
    content: "\e2e0";
    }
        .fi-ss-car-battery:before {
    content: "\e2e1";
    }
        .fi-ss-car-bolt:before {
    content: "\e2e2";
    }
        .fi-ss-car-building:before {
    content: "\e2e3";
    }
        .fi-ss-car-bump:before {
    content: "\e2e4";
    }
        .fi-ss-car-bus:before {
    content: "\e2e5";
    }
        .fi-ss-car-circle-bolt:before {
    content: "\e2e6";
    }
        .fi-ss-car-crash:before {
    content: "\e2e7";
    }
        .fi-ss-car-garage:before {
    content: "\e2e8";
    }
        .fi-ss-car-journey:before {
    content: "\e2e9";
    }
        .fi-ss-car-mechanic:before {
    content: "\e2ea";
    }
        .fi-ss-car-rear:before {
    content: "\e2eb";
    }
        .fi-ss-car-side:before {
    content: "\e2ec";
    }
        .fi-ss-car-side-bolt:before {
    content: "\e2ed";
    }
        .fi-ss-car-tilt:before {
    content: "\e2ee";
    }
        .fi-ss-car-tunnel:before {
    content: "\e2ef";
    }
        .fi-ss-car-wash:before {
    content: "\e2f0";
    }
        .fi-ss-caravan:before {
    content: "\e2f1";
    }
        .fi-ss-caravan-alt:before {
    content: "\e2f2";
    }
        .fi-ss-carbon-cloud:before {
    content: "\e2f3";
    }
        .fi-ss-carbon-cloud-arrow-down:before {
    content: "\e2f4";
    }
        .fi-ss-carbon-footprint:before {
    content: "\e2f5";
    }
        .fi-ss-card-club:before {
    content: "\e2f6";
    }
        .fi-ss-card-diamond:before {
    content: "\e2f7";
    }
        .fi-ss-card-heart:before {
    content: "\e2f8";
    }
        .fi-ss-card-spade:before {
    content: "\e2f9";
    }
        .fi-ss-cardinal-compass:before {
    content: "\e2fa";
    }
        .fi-ss-cards-blank:before {
    content: "\e2fc";
    }
        .fi-ss-career-growth:before {
    content: "\e2fe";
    }
        .fi-ss-career-path:before {
    content: "\e2ff";
    }
        .fi-ss-caret-circle-down:before {
    content: "\e300";
    }
        .fi-ss-caret-circle-right:before {
    content: "\e302";
    }
        .fi-ss-caret-circle-up:before {
    content: "\e303";
    }
        .fi-ss-caret-down:before {
    content: "\e304";
    }
        .fi-ss-caret-left:before {
    content: "\e305";
    }
        .fi-ss-caret-quare-up:before {
    content: "\e306";
    }
        .fi-ss-caret-right:before {
    content: "\e307";
    }
        .fi-ss-caret-square-down:before {
    content: "\e308";
    }
        .fi-ss-caret-square-left:before {
    content: "\e309";
    }
        .fi-ss-caret-square-left_1:before {
    content: "\e30a";
    }
        .fi-ss-caret-square-right:before {
    content: "\e30b";
    }
        .fi-ss-caret-up:before {
    content: "\e30d";
    }
        .fi-ss-carrot:before {
    content: "\e30e";
    }
        .fi-ss-cars:before {
    content: "\e30f";
    }
        .fi-ss-cars-crash:before {
    content: "\e310";
    }
        .fi-ss-cart-arrow-down:before {
    content: "\e311";
    }
        .fi-ss-cart-minus:before {
    content: "\e312";
    }
        .fi-ss-cart-shopping-fast:before {
    content: "\e313";
    }
        .fi-ss-case-study:before {
    content: "\e314";
    }
        .fi-ss-cash-register:before {
    content: "\e315";
    }
        .fi-ss-cassette-tape:before {
    content: "\e316";
    }
        .fi-ss-cassette-vhs:before {
    content: "\e317";
    }
        .fi-ss-castle:before {
    content: "\e318";
    }
        .fi-ss-cat:before {
    content: "\e319";
    }
        .fi-ss-cat-dog:before {
    content: "\e31a";
    }
        .fi-ss-cat-head:before {
    content: "\e31b";
    }
        .fi-ss-cat-space:before {
    content: "\e31c";
    }
        .fi-ss-catalog:before {
    content: "\e31d";
    }
        .fi-ss-catalog-alt:before {
    content: "\e31e";
    }
        .fi-ss-catalog-magazine:before {
    content: "\e31f";
    }
        .fi-ss-category:before {
    content: "\e320";
    }
        .fi-ss-category-alt:before {
    content: "\e321";
    }
        .fi-ss-cauldron:before {
    content: "\e322";
    }
        .fi-ss-cedi-sign:before {
    content: "\e327";
    }
        .fi-ss-cello:before {
    content: "\e328";
    }
        .fi-ss-cent-sign:before {
    content: "\e329";
    }
        .fi-ss-chair:before {
    content: "\e32b";
    }
        .fi-ss-chair-director:before {
    content: "\e32c";
    }
        .fi-ss-chair-office:before {
    content: "\e32d";
    }
        .fi-ss-chalkboard:before {
    content: "\e32e";
    }
        .fi-ss-chalkboard-user:before {
    content: "\e32f";
    }
        .fi-ss-challenge:before {
    content: "\e330";
    }
        .fi-ss-challenge-alt:before {
    content: "\e331";
    }
        .fi-ss-channel:before {
    content: "\e332";
    }
        .fi-ss-charging-station:before {
    content: "\e334";
    }
        .fi-ss-chart-area:before {
    content: "\e335";
    }
        .fi-ss-chart-bar-holding-hand:before {
    content: "\e338";
    }
        .fi-ss-chart-bullet:before {
    content: "\e339";
    }
        .fi-ss-chart-candlestick:before {
    content: "\e33a";
    }
        .fi-ss-chart-connected:before {
    content: "\e33b";
    }
        .fi-ss-chart-gantt:before {
    content: "\e33c";
    }
        .fi-ss-chart-histogram:before {
    content: "\e33d";
    }
        .fi-ss-chart-kanban:before {
    content: "\e33e";
    }
        .fi-ss-chart-line-up:before {
    content: "\e33f";
    }
        .fi-ss-chart-line-up-down:before {
    content: "\e340";
    }
        .fi-ss-chart-mixed:before {
    content: "\e341";
    }
        .fi-ss-chart-mixed-up-circle-currency:before {
    content: "\e342";
    }
        .fi-ss-chart-mixed-up-circle-dollar:before {
    content: "\e343";
    }
        .fi-ss-chart-network:before {
    content: "\e344";
    }
        .fi-ss-chart-pie:before {
    content: "\e345";
    }
        .fi-ss-chart-pie-alt:before {
    content: "\e346";
    }
        .fi-ss-chart-pie-simple-circle-currency:before {
    content: "\e347";
    }
        .fi-ss-chart-pie-simple-circle-dollar:before {
    content: "\e348";
    }
        .fi-ss-chart-pyramid:before {
    content: "\e349";
    }
        .fi-ss-chart-radar:before {
    content: "\e34a";
    }
        .fi-ss-chart-scatter:before {
    content: "\e34b";
    }
        .fi-ss-chart-scatter-3d:before {
    content: "\e34c";
    }
        .fi-ss-chart-scatter-bubble:before {
    content: "\e34d";
    }
        .fi-ss-chart-set-theory:before {
    content: "\e34e";
    }
        .fi-ss-chart-simple:before {
    content: "\e34f";
    }
        .fi-ss-chart-simple-horizontal:before {
    content: "\e350";
    }
        .fi-ss-chart-tree:before {
    content: "\e351";
    }
        .fi-ss-chart-tree-map:before {
    content: "\e352";
    }
        .fi-ss-chart-user:before {
    content: "\e353";
    }
        .fi-ss-chart-waterfall:before {
    content: "\e354";
    }
        .fi-ss-chat-arrow-down:before {
    content: "\e355";
    }
        .fi-ss-chat-arrow-grow:before {
    content: "\e356";
    }
        .fi-ss-chat-bubble-call:before {
    content: "\e357";
    }
        .fi-ss-chatbot:before {
    content: "\e358";
    }
        .fi-ss-chatbot-speech-bubble:before {
    content: "\e359";
    }
        .fi-ss-cheap:before {
    content: "\e35a";
    }
        .fi-ss-cheap-bill:before {
    content: "\e35b";
    }
        .fi-ss-cheap-dollar:before {
    content: "\e35c";
    }
        .fi-ss-cheap-stack:before {
    content: "\e35d";
    }
        .fi-ss-cheap-stack-dollar:before {
    content: "\e35e";
    }
        .fi-ss-check:before {
    content: "\e35f";
    }
        .fi-ss-check-circle:before {
    content: "\e360";
    }
        .fi-ss-check-double:before {
    content: "\e361";
    }
        .fi-ss-check-in-calendar:before {
    content: "\e362";
    }
        .fi-ss-check-out-calendar:before {
    content: "\e363";
    }
        .fi-ss-checkbox:before {
    content: "\e364";
    }
        .fi-ss-checklist-task-budget:before {
    content: "\e365";
    }
        .fi-ss-cheese:before {
    content: "\e366";
    }
        .fi-ss-cheese-alt:before {
    content: "\e367";
    }
        .fi-ss-cheeseburger:before {
    content: "\e368";
    }
        .fi-ss-cherry:before {
    content: "\e369";
    }
        .fi-ss-chess:before {
    content: "\e36a";
    }
        .fi-ss-chess-bishop:before {
    content: "\e36b";
    }
        .fi-ss-chess-board:before {
    content: "\e36c";
    }
        .fi-ss-chess-clock:before {
    content: "\e36d";
    }
        .fi-ss-chess-clock-alt:before {
    content: "\e36e";
    }
        .fi-ss-chess-king:before {
    content: "\e36f";
    }
        .fi-ss-chess-king-alt:before {
    content: "\e370";
    }
        .fi-ss-chess-knight:before {
    content: "\e371";
    }
        .fi-ss-chess-knight-alt:before {
    content: "\e372";
    }
        .fi-ss-chess-pawn:before {
    content: "\e373";
    }
        .fi-ss-chess-pawn-alt:before {
    content: "\e374";
    }
        .fi-ss-chess-piece:before {
    content: "\e375";
    }
        .fi-ss-chess-queen:before {
    content: "\e376";
    }
        .fi-ss-chess-queen-alt:before {
    content: "\e377";
    }
        .fi-ss-chess-rook:before {
    content: "\e378";
    }
        .fi-ss-chess-rook-alt:before {
    content: "\e379";
    }
        .fi-ss-chevron-double-down:before {
    content: "\e37a";
    }
        .fi-ss-chevron-double-up:before {
    content: "\e37b";
    }
        .fi-ss-child:before {
    content: "\e37c";
    }
        .fi-ss-child-head:before {
    content: "\e37d";
    }
        .fi-ss-chimney:before {
    content: "\e37e";
    }
        .fi-ss-chip:before {
    content: "\e37f";
    }
        .fi-ss-chocolate:before {
    content: "\e380";
    }
        .fi-ss-choir-singing:before {
    content: "\e382";
    }
        .fi-ss-choose:before {
    content: "\e383";
    }
        .fi-ss-choose-alt:before {
    content: "\e384";
    }
        .fi-ss-church:before {
    content: "\e386";
    }
        .fi-ss-circle:before {
    content: "\e387";
    }
        .fi-ss-circle-0:before {
    content: "\e388";
    }
        .fi-ss-circle-1:before {
    content: "\e389";
    }
        .fi-ss-circle-2:before {
    content: "\e38a";
    }
        .fi-ss-circle-3:before {
    content: "\e38b";
    }
        .fi-ss-circle-4:before {
    content: "\e38c";
    }
        .fi-ss-circle-5:before {
    content: "\e38d";
    }
        .fi-ss-circle-6:before {
    content: "\e38e";
    }
        .fi-ss-circle-7:before {
    content: "\e38f";
    }
        .fi-ss-circle-8:before {
    content: "\e390";
    }
        .fi-ss-circle-9:before {
    content: "\e391";
    }
        .fi-ss-circle-a:before {
    content: "\e392";
    }
        .fi-ss-circle-b:before {
    content: "\e394";
    }
        .fi-ss-circle-bolt:before {
    content: "\e395";
    }
        .fi-ss-circle-book-open:before {
    content: "\e396";
    }
        .fi-ss-circle-bookmark:before {
    content: "\e397";
    }
        .fi-ss-circle-c:before {
    content: "\e398";
    }
        .fi-ss-circle-calendar:before {
    content: "\e399";
    }
        .fi-ss-circle-camera:before {
    content: "\e39a";
    }
        .fi-ss-circle-d:before {
    content: "\e39c";
    }
        .fi-ss-circle-dashed:before {
    content: "\e39d";
    }
        .fi-ss-circle-divide:before {
    content: "\e39e";
    }
        .fi-ss-circle-e:before {
    content: "\e3a0";
    }
        .fi-ss-circle-ellipsis:before {
    content: "\e3a1";
    }
        .fi-ss-circle-ellipsis-vertical:before {
    content: "\e3a2";
    }
        .fi-ss-circle-envelope:before {
    content: "\e3a3";
    }
        .fi-ss-circle-exclamation-check:before {
    content: "\e3a4";
    }
        .fi-ss-circle-f:before {
    content: "\e3a5";
    }
        .fi-ss-circle-g:before {
    content: "\e3a6";
    }
        .fi-ss-circle-h:before {
    content: "\e3a7";
    }
        .fi-ss-circle-half:before {
    content: "\e3a8";
    }
        .fi-ss-circle-half-stroke:before {
    content: "\e3a9";
    }
        .fi-ss-circle-heart:before {
    content: "\e3aa";
    }
        .fi-ss-circle-i:before {
    content: "\e3ab";
    }
        .fi-ss-circle-j:before {
    content: "\e3ac";
    }
        .fi-ss-circle-k:before {
    content: "\e3ad";
    }
        .fi-ss-circle-l:before {
    content: "\e3ae";
    }
        .fi-ss-circle-m:before {
    content: "\e3af";
    }
        .fi-ss-circle-microphone:before {
    content: "\e3b0";
    }
        .fi-ss-circle-microphone-lines:before {
    content: "\e3b1";
    }
        .fi-ss-circle-n:before {
    content: "\e3b2";
    }
        .fi-ss-circle-nodes:before {
    content: "\e3b3";
    }
        .fi-ss-circle-o:before {
    content: "\e3b4";
    }
        .fi-ss-circle-overlap:before {
    content: "\e3b5";
    }
        .fi-ss-circle-p:before {
    content: "\e3b6";
    }
        .fi-ss-circle-phone:before {
    content: "\e3b8";
    }
        .fi-ss-circle-phone-flip:before {
    content: "\e3b9";
    }
        .fi-ss-circle-phone-hangup:before {
    content: "\e3ba";
    }
        .fi-ss-circle-q:before {
    content: "\e3bc";
    }
        .fi-ss-circle-quarter:before {
    content: "\e3bd";
    }
        .fi-ss-circle-quarters-alt:before {
    content: "\e3bf";
    }
        .fi-ss-circle-r:before {
    content: "\e3c0";
    }
        .fi-ss-circle-s:before {
    content: "\e3c1";
    }
        .fi-ss-circle-small:before {
    content: "\e3c2";
    }
        .fi-ss-circle-star:before {
    content: "\e3c3";
    }
        .fi-ss-circle-t:before {
    content: "\e3c5";
    }
        .fi-ss-circle-three-quarters:before {
    content: "\e3c6";
    }
        .fi-ss-circle-trash:before {
    content: "\e3c7";
    }
        .fi-ss-circle-u:before {
    content: "\e3c8";
    }
        .fi-ss-circle-user:before {
    content: "\e3c9";
    }
        .fi-ss-circle-v:before {
    content: "\e3ca";
    }
        .fi-ss-circle-video:before {
    content: "\e3cb";
    }
        .fi-ss-circle-w:before {
    content: "\e3cc";
    }
        .fi-ss-circle-waveform-lines:before {
    content: "\e3cd";
    }
        .fi-ss-circle-wifi-circle-wifi:before {
    content: "\e3ce";
    }
        .fi-ss-circle-x:before {
    content: "\e3cf";
    }
        .fi-ss-circle-xmark:before {
    content: "\e3d0";
    }
        .fi-ss-circle-y:before {
    content: "\e3d1";
    }
        .fi-ss-circle-z:before {
    content: "\e3d2";
    }
        .fi-ss-citrus:before {
    content: "\e3d3";
    }
        .fi-ss-citrus-slice:before {
    content: "\e3d4";
    }
        .fi-ss-city:before {
    content: "\e3d5";
    }
        .fi-ss-clapper-open:before {
    content: "\e3d6";
    }
        .fi-ss-clapperboard:before {
    content: "\e3d7";
    }
        .fi-ss-clapperboard-play:before {
    content: "\e3d8";
    }
        .fi-ss-clarinet:before {
    content: "\e3d9";
    }
        .fi-ss-claw-marks:before {
    content: "\e3da";
    }
        .fi-ss-clear-alt:before {
    content: "\e3dd";
    }
        .fi-ss-clip:before {
    content: "\e3de";
    }
        .fi-ss-clip-file:before {
    content: "\e3df";
    }
        .fi-ss-clip-mail:before {
    content: "\e3e0";
    }
        .fi-ss-clipboard:before {
    content: "\e3e1";
    }
        .fi-ss-clipboard-check:before {
    content: "\e3e2";
    }
        .fi-ss-clipboard-exclamation:before {
    content: "\e3e3";
    }
        .fi-ss-clipboard-list:before {
    content: "\e3e4";
    }
        .fi-ss-clipboard-list-check:before {
    content: "\e3e5";
    }
        .fi-ss-clipboard-prescription:before {
    content: "\e3e6";
    }
        .fi-ss-clipboard-question:before {
    content: "\e3e7";
    }
        .fi-ss-clipboard-user:before {
    content: "\e3e8";
    }
        .fi-ss-clipoard-wrong:before {
    content: "\e3e9";
    }
        .fi-ss-clock:before {
    content: "\e3ea";
    }
        .fi-ss-clock-desk:before {
    content: "\e3eb";
    }
        .fi-ss-clock-eight-thirty:before {
    content: "\e3ec";
    }
        .fi-ss-clock-eleven:before {
    content: "\e3ed";
    }
        .fi-ss-clock-eleven-thirty:before {
    content: "\e3ee";
    }
        .fi-ss-clock-five:before {
    content: "\e3ef";
    }
        .fi-ss-clock-five-thirty:before {
    content: "\e3f0";
    }
        .fi-ss-clock-four-thirty:before {
    content: "\e3f1";
    }
        .fi-ss-clock-future-past:before {
    content: "\e3f2";
    }
        .fi-ss-clock-nine:before {
    content: "\e3f3";
    }
        .fi-ss-clock-nine-thirty:before {
    content: "\e3f4";
    }
        .fi-ss-clock-one:before {
    content: "\e3f5";
    }
        .fi-ss-clock-one-thirty:before {
    content: "\e3f6";
    }
        .fi-ss-clock-seven:before {
    content: "\e3f7";
    }
        .fi-ss-clock-seven-thirty:before {
    content: "\e3f8";
    }
        .fi-ss-clock-six:before {
    content: "\e3f9";
    }
        .fi-ss-clock-six-thirty:before {
    content: "\e3fa";
    }
        .fi-ss-clock-ten:before {
    content: "\e3fb";
    }
        .fi-ss-clock-ten-thirty:before {
    content: "\e3fc";
    }
        .fi-ss-clock-three:before {
    content: "\e3fd";
    }
        .fi-ss-clock-three-thirty:before {
    content: "\e3fe";
    }
        .fi-ss-clock-time-tracking:before {
    content: "\e3ff";
    }
        .fi-ss-clock-twelve:before {
    content: "\e400";
    }
        .fi-ss-clock-twelve-thirty:before {
    content: "\e401";
    }
        .fi-ss-clock-two:before {
    content: "\e402";
    }
        .fi-ss-clock-two-thirty:before {
    content: "\e403";
    }
        .fi-ss-clock-up-arrow:before {
    content: "\e404";
    }
        .fi-ss-clone:before {
    content: "\e405";
    }
        .fi-ss-closed-captioning-slash:before {
    content: "\e406";
    }
        .fi-ss-clothes-hanger:before {
    content: "\e407";
    }
        .fi-ss-clothing-rack:before {
    content: "\e408";
    }
        .fi-ss-cloud:before {
    content: "\e409";
    }
        .fi-ss-cloud-back-up:before {
    content: "\e40a";
    }
        .fi-ss-cloud-back-up-alt:before {
    content: "\e40b";
    }
        .fi-ss-cloud-check:before {
    content: "\e40c";
    }
        .fi-ss-cloud-code:before {
    content: "\e40d";
    }
        .fi-ss-cloud-data:before {
    content: "\e40e";
    }
        .fi-ss-cloud-disabled:before {
    content: "\e40f";
    }
        .fi-ss-cloud-download:before {
    content: "\e410";
    }
        .fi-ss-cloud-download-alt:before {
    content: "\e411";
    }
        .fi-ss-cloud-download-circle:before {
    content: "\e412";
    }
        .fi-ss-cloud-drizzle:before {
    content: "\e413";
    }
        .fi-ss-cloud-exclamation:before {
    content: "\e414";
    }
        .fi-ss-cloud-gear-automation:before {
    content: "\e416";
    }
        .fi-ss-cloud-hail:before {
    content: "\e417";
    }
        .fi-ss-cloud-hail-mixed:before {
    content: "\e418";
    }
        .fi-ss-cloud-lock:before {
    content: "\e419";
    }
        .fi-ss-cloud-meatball:before {
    content: "\e41a";
    }
        .fi-ss-cloud-moon:before {
    content: "\e41c";
    }
        .fi-ss-cloud-moon-rain:before {
    content: "\e41d";
    }
        .fi-ss-cloud-question:before {
    content: "\e41f";
    }
        .fi-ss-cloud-rain:before {
    content: "\e420";
    }
        .fi-ss-cloud-rainbow:before {
    content: "\e421";
    }
        .fi-ss-cloud-share:before {
    content: "\e422";
    }
        .fi-ss-cloud-showers:before {
    content: "\e423";
    }
        .fi-ss-cloud-showers-heavy:before {
    content: "\e424";
    }
        .fi-ss-cloud-showers-water:before {
    content: "\e425";
    }
        .fi-ss-cloud-sleet:before {
    content: "\e427";
    }
        .fi-ss-cloud-snow:before {
    content: "\e428";
    }
        .fi-ss-cloud-sun:before {
    content: "\e429";
    }
        .fi-ss-cloud-sun-rain:before {
    content: "\e42a";
    }
        .fi-ss-cloud-upload:before {
    content: "\e42b";
    }
        .fi-ss-cloud-upload-alt:before {
    content: "\e42c";
    }
        .fi-ss-cloud-upload-circle:before {
    content: "\e42d";
    }
        .fi-ss-clouds:before {
    content: "\e430";
    }
        .fi-ss-clouds-moon:before {
    content: "\e431";
    }
        .fi-ss-clouds-sun:before {
    content: "\e432";
    }
        .fi-ss-clover-alt:before {
    content: "\e433";
    }
        .fi-ss-club:before {
    content: "\e434";
    }
        .fi-ss-cocktail:before {
    content: "\e437";
    }
        .fi-ss-cocktail-alt:before {
    content: "\e438";
    }
        .fi-ss-coconut:before {
    content: "\e439";
    }
        .fi-ss-code-branch:before {
    content: "\e43a";
    }
        .fi-ss-code-commit:before {
    content: "\e43b";
    }
        .fi-ss-code-compare:before {
    content: "\e43c";
    }
        .fi-ss-code-fork:before {
    content: "\e43d";
    }
        .fi-ss-code-merge:before {
    content: "\e43e";
    }
        .fi-ss-code-pull-request:before {
    content: "\e43f";
    }
        .fi-ss-code-pull-request-closed:before {
    content: "\e440";
    }
        .fi-ss-code-pull-request-draft:before {
    content: "\e441";
    }
        .fi-ss-code-simple:before {
    content: "\e442";
    }
        .fi-ss-coffee:before {
    content: "\e443";
    }
        .fi-ss-coffee-bean:before {
    content: "\e444";
    }
        .fi-ss-coffee-beans:before {
    content: "\e445";
    }
        .fi-ss-coffee-heart:before {
    content: "\e446";
    }
        .fi-ss-coffee-maker:before {
    content: "\e447";
    }
        .fi-ss-coffee-pot:before {
    content: "\e448";
    }
        .fi-ss-coffin:before {
    content: "\e449";
    }
        .fi-ss-coffin-cross:before {
    content: "\e44a";
    }
        .fi-ss-coin:before {
    content: "\e44b";
    }
        .fi-ss-coin-up-arrow:before {
    content: "\e44c";
    }
        .fi-ss-coins:before {
    content: "\e44d";
    }
        .fi-ss-colon-sign:before {
    content: "\e44f";
    }
        .fi-ss-columns-3:before {
    content: "\e450";
    }
        .fi-ss-comet:before {
    content: "\e451";
    }
        .fi-ss-command:before {
    content: "\e453";
    }
        .fi-ss-comment:before {
    content: "\e454";
    }
        .fi-ss-comment-alt:before {
    content: "\e455";
    }
        .fi-ss-comment-alt-check:before {
    content: "\e456";
    }
        .fi-ss-comment-alt-dots:before {
    content: "\e457";
    }
        .fi-ss-comment-alt-edit:before {
    content: "\e458";
    }
        .fi-ss-comment-alt-medical:before {
    content: "\e459";
    }
        .fi-ss-comment-alt-middle:before {
    content: "\e45a";
    }
        .fi-ss-comment-alt-middle-top:before {
    content: "\e45b";
    }
        .fi-ss-comment-alt-minus:before {
    content: "\e45c";
    }
        .fi-ss-comment-alt-music:before {
    content: "\e45d";
    }
        .fi-ss-comment-arrow-down:before {
    content: "\e460";
    }
        .fi-ss-comment-arrow-up:before {
    content: "\e461";
    }
        .fi-ss-comment-arrow-up-right:before {
    content: "\e462";
    }
        .fi-ss-comment-check:before {
    content: "\e463";
    }
        .fi-ss-comment-code:before {
    content: "\e464";
    }
        .fi-ss-comment-dollar:before {
    content: "\e465";
    }
        .fi-ss-comment-dots:before {
    content: "\e466";
    }
        .fi-ss-comment-exclamation:before {
    content: "\e467";
    }
        .fi-ss-comment-heart:before {
    content: "\e468";
    }
        .fi-ss-comment-image:before {
    content: "\e469";
    }
        .fi-ss-comment-info:before {
    content: "\e46a";
    }
        .fi-ss-comment-medical:before {
    content: "\e46b";
    }
        .fi-ss-comment-minus:before {
    content: "\e46c";
    }
        .fi-ss-comment-pen:before {
    content: "\e46d";
    }
        .fi-ss-comment-question:before {
    content: "\e46f";
    }
        .fi-ss-comment-quote:before {
    content: "\e470";
    }
        .fi-ss-comment-slash:before {
    content: "\e471";
    }
        .fi-ss-comment-smile:before {
    content: "\e472";
    }
        .fi-ss-comment-sms:before {
    content: "\e473";
    }
        .fi-ss-comment-text:before {
    content: "\e474";
    }
        .fi-ss-comment-user:before {
    content: "\e475";
    }
        .fi-ss-comment-xmark:before {
    content: "\e476";
    }
        .fi-ss-comments:before {
    content: "\e477";
    }
        .fi-ss-comments-dollar:before {
    content: "\e478";
    }
        .fi-ss-comments-question:before {
    content: "\e479";
    }
        .fi-ss-comments-question-check:before {
    content: "\e47a";
    }
        .fi-ss-compass-alt:before {
    content: "\e47b";
    }
        .fi-ss-compass-east:before {
    content: "\e47c";
    }
        .fi-ss-compass-north:before {
    content: "\e47d";
    }
        .fi-ss-compass-slash:before {
    content: "\e47e";
    }
        .fi-ss-compass-south:before {
    content: "\e47f";
    }
        .fi-ss-compass-west:before {
    content: "\e480";
    }
        .fi-ss-completed:before {
    content: "\e481";
    }
        .fi-ss-compliance:before {
    content: "\e482";
    }
        .fi-ss-compliance-clipboard:before {
    content: "\e483";
    }
        .fi-ss-compliance-document:before {
    content: "\e484";
    }
        .fi-ss-compress:before {
    content: "\e485";
    }
        .fi-ss-compress-alt:before {
    content: "\e486";
    }
        .fi-ss-computer:before {
    content: "\e487";
    }
        .fi-ss-computer-classic:before {
    content: "\e488";
    }
        .fi-ss-computer-mouse:before {
    content: "\e489";
    }
        .fi-ss-computer-speaker:before {
    content: "\e48a";
    }
        .fi-ss-concierge-bell:before {
    content: "\e48b";
    }
        .fi-ss-condition:before {
    content: "\e48d";
    }
        .fi-ss-condition-alt:before {
    content: "\e48e";
    }
        .fi-ss-confetti:before {
    content: "\e48f";
    }
        .fi-ss-confidential-discussion:before {
    content: "\e490";
    }
        .fi-ss-console-controller:before {
    content: "\e492";
    }
        .fi-ss-constellation:before {
    content: "\e493";
    }
        .fi-ss-contact-lens-eyes:before {
    content: "\e494";
    }
        .fi-ss-container-storage:before {
    content: "\e495";
    }
        .fi-ss-convert-document:before {
    content: "\e496";
    }
        .fi-ss-convert-shapes:before {
    content: "\e497";
    }
        .fi-ss-conveyor-belt:before {
    content: "\e498";
    }
        .fi-ss-conveyor-belt-alt:before {
    content: "\e499";
    }
        .fi-ss-conveyor-belt-arm:before {
    content: "\e49a";
    }
        .fi-ss-conveyor-belt-empty:before {
    content: "\e49b";
    }
        .fi-ss-cookie:before {
    content: "\e49c";
    }
        .fi-ss-cookie-alt:before {
    content: "\e49d";
    }
        .fi-ss-copy:before {
    content: "\e49e";
    }
        .fi-ss-copy-alt:before {
    content: "\e49f";
    }
        .fi-ss-copy-image:before {
    content: "\e4a0";
    }
        .fi-ss-copyright:before {
    content: "\e4a1";
    }
        .fi-ss-corn:before {
    content: "\e4a2";
    }
        .fi-ss-corporate:before {
    content: "\e4a4";
    }
        .fi-ss-corporate-alt:before {
    content: "\e4a5";
    }
        .fi-ss-couch:before {
    content: "\e4a6";
    }
        .fi-ss-court-sport:before {
    content: "\e4a9";
    }
        .fi-ss-cow:before {
    content: "\e4aa";
    }
        .fi-ss-cow-alt:before {
    content: "\e4ab";
    }
        .fi-ss-cowbell:before {
    content: "\e4ac";
    }
        .fi-ss-cowbell-circle-plus:before {
    content: "\e4ad";
    }
        .fi-ss-cowbell-more:before {
    content: "\e4ae";
    }
        .fi-ss-crab:before {
    content: "\e4af";
    }
        .fi-ss-crate-empty:before {
    content: "\e4b0";
    }
        .fi-ss-cream:before {
    content: "\e4b1";
    }
        .fi-ss-credit-card:before {
    content: "\e4c1";
    }
        .fi-ss-credit-card-buyer:before {
    content: "\e4c2";
    }
        .fi-ss-credit-card-eye:before {
    content: "\e4c3";
    }
        .fi-ss-cricket:before {
    content: "\e4c7";
    }
        .fi-ss-CRM:before {
    content: "\e4c8";
    }
        .fi-ss-crm-alt:before {
    content: "\e4c9";
    }
        .fi-ss-crm-computer:before {
    content: "\e4ca";
    }
        .fi-ss-croissant:before {
    content: "\e4cb";
    }
        .fi-ss-cross:before {
    content: "\e4cd";
    }
        .fi-ss-cross-circle:before {
    content: "\e4ce";
    }
        .fi-ss-cross-religion:before {
    content: "\e4cf";
    }
        .fi-ss-cross-small:before {
    content: "\e4d0";
    }
        .fi-ss-crow:before {
    content: "\e4d2";
    }
        .fi-ss-crown:before {
    content: "\e4d3";
    }
        .fi-ss-crutch:before {
    content: "\e4d4";
    }
        .fi-ss-crutches:before {
    content: "\e4d5";
    }
        .fi-ss-cruzeiro-sign:before {
    content: "\e4d6";
    }
        .fi-ss-crypto-calendar:before {
    content: "\e4d7";
    }
        .fi-ss-cryptocurrency:before {
    content: "\e4d8";
    }
        .fi-ss-crystal-ball:before {
    content: "\e4d9";
    }
        .fi-ss-cube:before {
    content: "\e4dc";
    }
        .fi-ss-cubes:before {
    content: "\e4dd";
    }
        .fi-ss-cubes-stacked:before {
    content: "\e4de";
    }
        .fi-ss-cucumber:before {
    content: "\e4df";
    }
        .fi-ss-cup-straw:before {
    content: "\e4e0";
    }
        .fi-ss-cup-straw-swoosh:before {
    content: "\e4e1";
    }
        .fi-ss-cup-togo:before {
    content: "\e4e2";
    }
        .fi-ss-cupcake:before {
    content: "\e4e3";
    }
        .fi-ss-cupcake-alt:before {
    content: "\e4e4";
    }
        .fi-ss-curling:before {
    content: "\e4e5";
    }
        .fi-ss-cursor:before {
    content: "\e4e6";
    }
        .fi-ss-cursor-finger:before {
    content: "\e4e7";
    }
        .fi-ss-cursor-plus:before {
    content: "\e4e8";
    }
        .fi-ss-cursor-text:before {
    content: "\e4e9";
    }
        .fi-ss-cursor-text-alt:before {
    content: "\e4ea";
    }
        .fi-ss-curve:before {
    content: "\e4eb";
    }
        .fi-ss-curve-alt:before {
    content: "\e4ec";
    }
        .fi-ss-curve-arrow:before {
    content: "\e4ed";
    }
        .fi-ss-custard:before {
    content: "\e4ee";
    }
        .fi-ss-customer-care:before {
    content: "\e4ef";
    }
        .fi-ss-customer-service:before {
    content: "\e4f0";
    }
        .fi-ss-customization:before {
    content: "\e4f1";
    }
        .fi-ss-customization-cogwheel:before {
    content: "\e4f2";
    }
        .fi-ss-customize:before {
    content: "\e4f3";
    }
        .fi-ss-customize-computer:before {
    content: "\e4f4";
    }
        .fi-ss-customize-edit:before {
    content: "\e4f5";
    }
        .fi-ss-CV:before {
    content: "\e4f6";
    }
        .fi-ss-cvv-card:before {
    content: "\e4f7";
    }
        .fi-ss-d:before {
    content: "\e4f8";
    }
        .fi-ss-dagger:before {
    content: "\e4f9";
    }
        .fi-ss-daily-calendar:before {
    content: "\e4fa";
    }
        .fi-ss-daisy:before {
    content: "\e4fc";
    }
        .fi-ss-daisy-alt:before {
    content: "\e4fd";
    }
        .fi-ss-damage:before {
    content: "\e4fe";
    }
        .fi-ss-dark-mode:before {
    content: "\e4ff";
    }
        .fi-ss-dark-mode-alt:before {
    content: "\e500";
    }
        .fi-ss-dart:before {
    content: "\e501";
    }
        .fi-ss-dashboard:before {
    content: "\e503";
    }
        .fi-ss-dashboard-monitor:before {
    content: "\e504";
    }
        .fi-ss-dashboard-panel:before {
    content: "\e505";
    }
        .fi-ss-data-transfer:before {
    content: "\e507";
    }
        .fi-ss-database:before {
    content: "\e508";
    }
        .fi-ss-database-cloud-circle:before {
    content: "\e509";
    }
        .fi-ss-debt:before {
    content: "\e50a";
    }
        .fi-ss-decision-choice:before {
    content: "\e50c";
    }
        .fi-ss-deer:before {
    content: "\e50d";
    }
        .fi-ss-deer-rudolph:before {
    content: "\e50e";
    }
        .fi-ss-degree-credential:before {
    content: "\e510";
    }
        .fi-ss-delete:before {
    content: "\e511";
    }
        .fi-ss-delete-document:before {
    content: "\e512";
    }
        .fi-ss-delete-right:before {
    content: "\e513";
    }
        .fi-ss-delete-user:before {
    content: "\e514";
    }
        .fi-ss-democrat:before {
    content: "\e518";
    }
        .fi-ss-department:before {
    content: "\e519";
    }
        .fi-ss-department-structure:before {
    content: "\e51a";
    }
        .fi-ss-deposit:before {
    content: "\e51b";
    }
        .fi-ss-deposit-alt:before {
    content: "\e51c";
    }
        .fi-ss-description:before {
    content: "\e51d";
    }
        .fi-ss-description-alt:before {
    content: "\e51e";
    }
        .fi-ss-desk:before {
    content: "\e51f";
    }
        .fi-ss-desktop-arrow-down:before {
    content: "\e520";
    }
        .fi-ss-desktop-wallpaper:before {
    content: "\e521";
    }
        .fi-ss-detergent:before {
    content: "\e522";
    }
        .fi-ss-devices:before {
    content: "\e525";
    }
        .fi-ss-dewpoint:before {
    content: "\e526";
    }
        .fi-ss-dharmachakra:before {
    content: "\e527";
    }
        .fi-ss-diagram-cells:before {
    content: "\e528";
    }
        .fi-ss-diagram-lean-canvas:before {
    content: "\e529";
    }
        .fi-ss-diagram-nested:before {
    content: "\e52a";
    }
        .fi-ss-diagram-next:before {
    content: "\e52b";
    }
        .fi-ss-diagram-predecessor:before {
    content: "\e52c";
    }
        .fi-ss-diagram-previous:before {
    content: "\e52d";
    }
        .fi-ss-diagram-project:before {
    content: "\e52e";
    }
        .fi-ss-diagram-sankey:before {
    content: "\e52f";
    }
        .fi-ss-diagram-subtask:before {
    content: "\e530";
    }
        .fi-ss-diagram-successor:before {
    content: "\e531";
    }
        .fi-ss-diagram-venn:before {
    content: "\e532";
    }
        .fi-ss-dial:before {
    content: "\e533";
    }
        .fi-ss-dial-high:before {
    content: "\e534";
    }
        .fi-ss-dial-low:before {
    content: "\e535";
    }
        .fi-ss-dial-max:before {
    content: "\e536";
    }
        .fi-ss-dial-med:before {
    content: "\e537";
    }
        .fi-ss-dial-med-low:before {
    content: "\e538";
    }
        .fi-ss-dial-min:before {
    content: "\e539";
    }
        .fi-ss-dial-off:before {
    content: "\e53a";
    }
        .fi-ss-dialogue-exchange:before {
    content: "\e53b";
    }
        .fi-ss-diamond:before {
    content: "\e53c";
    }
        .fi-ss-diamond-exclamation:before {
    content: "\e53d";
    }
        .fi-ss-diamond-turn-right:before {
    content: "\e53e";
    }
        .fi-ss-diary-bookmark-down:before {
    content: "\e53f";
    }
        .fi-ss-diary-bookmarks:before {
    content: "\e540";
    }
        .fi-ss-diary-clasp:before {
    content: "\e541";
    }
        .fi-ss-dice:before {
    content: "\e542";
    }
        .fi-ss-dice-alt:before {
    content: "\e543";
    }
        .fi-ss-dice-d10:before {
    content: "\e544";
    }
        .fi-ss-dice-d12:before {
    content: "\e545";
    }
        .fi-ss-dice-d20:before {
    content: "\e546";
    }
        .fi-ss-dice-d4:before {
    content: "\e547";
    }
        .fi-ss-dice-d6:before {
    content: "\e548";
    }
        .fi-ss-dice-d8:before {
    content: "\e549";
    }
        .fi-ss-dice-four:before {
    content: "\e54a";
    }
        .fi-ss-dice-one:before {
    content: "\e54b";
    }
        .fi-ss-dice-six:before {
    content: "\e54c";
    }
        .fi-ss-dice-three:before {
    content: "\e54d";
    }
        .fi-ss-dice-two:before {
    content: "\e54e";
    }
        .fi-ss-dictionary:before {
    content: "\e54f";
    }
        .fi-ss-dictionary-alt:before {
    content: "\e550";
    }
        .fi-ss-dictionary-open:before {
    content: "\e551";
    }
        .fi-ss-digging:before {
    content: "\e553";
    }
        .fi-ss-digital-payment:before {
    content: "\e558";
    }
        .fi-ss-digital-tachograph:before {
    content: "\e559";
    }
        .fi-ss-digital-wallet:before {
    content: "\e55a";
    }
        .fi-ss-dinner:before {
    content: "\e55c";
    }
        .fi-ss-diploma:before {
    content: "\e55d";
    }
        .fi-ss-direction-signal:before {
    content: "\e55e";
    }
        .fi-ss-direction-signal-arrow:before {
    content: "\e55f";
    }
        .fi-ss-disc-drive:before {
    content: "\e560";
    }
        .fi-ss-discover:before {
    content: "\e565";
    }
        .fi-ss-discussion-group:before {
    content: "\e567";
    }
        .fi-ss-discussion-idea:before {
    content: "\e568";
    }
        .fi-ss-disease:before {
    content: "\e569";
    }
        .fi-ss-disk:before {
    content: "\e56a";
    }
        .fi-ss-display-arrow-down:before {
    content: "\e56c";
    }
        .fi-ss-display-chart-up:before {
    content: "\e56e";
    }
        .fi-ss-display-code:before {
    content: "\e56f";
    }
        .fi-ss-display-medical:before {
    content: "\e570";
    }
        .fi-ss-display-slash:before {
    content: "\e571";
    }
        .fi-ss-distribute-spacing-horizontal:before {
    content: "\e572";
    }
        .fi-ss-distribute-spacing-vertical:before {
    content: "\e573";
    }
        .fi-ss-divide:before {
    content: "\e575";
    }
        .fi-ss-dizzy:before {
    content: "\e576";
    }
        .fi-ss-dj-disc:before {
    content: "\e577";
    }
        .fi-ss-dna:before {
    content: "\e578";
    }
        .fi-ss-do-not-disturb:before {
    content: "\e579";
    }
        .fi-ss-do-not-disturb-doorknob:before {
    content: "\e57a";
    }
        .fi-ss-do-not-enter:before {
    content: "\e57b";
    }
        .fi-ss-doctor:before {
    content: "\e57d";
    }
        .fi-ss-document:before {
    content: "\e57f";
    }
        .fi-ss-document-circle-wrong:before {
    content: "\e581";
    }
        .fi-ss-document-folder-gear:before {
    content: "\e583";
    }
        .fi-ss-document-gavel:before {
    content: "\e584";
    }
        .fi-ss-document-gear:before {
    content: "\e586";
    }
        .fi-ss-document-nft:before {
    content: "\e587";
    }
        .fi-ss-document-paid:before {
    content: "\e588";
    }
        .fi-ss-document-signed:before {
    content: "\e589";
    }
        .fi-ss-dog:before {
    content: "\e58b";
    }
        .fi-ss-dog-bowl-empty:before {
    content: "\e58c";
    }
        .fi-ss-dog-leashed:before {
    content: "\e58d";
    }
        .fi-ss-dollar:before {
    content: "\e58e";
    }
        .fi-ss-dolly-flatbed:before {
    content: "\e58f";
    }
        .fi-ss-dolly-flatbed-alt:before {
    content: "\e590";
    }
        .fi-ss-dolly-flatbed-empty:before {
    content: "\e591";
    }
        .fi-ss-dolphin:before {
    content: "\e592";
    }
        .fi-ss-domino-effect:before {
    content: "\e593";
    }
        .fi-ss-donate:before {
    content: "\e594";
    }
        .fi-ss-dong-sign:before {
    content: "\e595";
    }
        .fi-ss-donut:before {
    content: "\e596";
    }
        .fi-ss-door-closed:before {
    content: "\e597";
    }
        .fi-ss-door-open:before {
    content: "\e598";
    }
        .fi-ss-dorm-room:before {
    content: "\e599";
    }
        .fi-ss-dot-circle:before {
    content: "\e59a";
    }
        .fi-ss-dot-pending:before {
    content: "\e59b";
    }
        .fi-ss-dove:before {
    content: "\e59c";
    }
        .fi-ss-down:before {
    content: "\e59d";
    }
        .fi-ss-down-from-bracket:before {
    content: "\e59e";
    }
        .fi-ss-down-left:before {
    content: "\e59f";
    }
        .fi-ss-down-left-and-up-right-to-center:before {
    content: "\e5a0";
    }
        .fi-ss-down-right:before {
    content: "\e5a1";
    }
        .fi-ss-down-to-line:before {
    content: "\e5a2";
    }
        .fi-ss-download:before {
    content: "\e5a3";
    }
        .fi-ss-drafting-compass:before {
    content: "\e5a5";
    }
        .fi-ss-dragon:before {
    content: "\e5a6";
    }
        .fi-ss-draw-polygon:before {
    content: "\e5a7";
    }
        .fi-ss-draw-square:before {
    content: "\e5a8";
    }
        .fi-ss-drawer:before {
    content: "\e5a9";
    }
        .fi-ss-drawer-alt:before {
    content: "\e5aa";
    }
        .fi-ss-drawer-empty:before {
    content: "\e5ab";
    }
        .fi-ss-dreidel:before {
    content: "\e5ac";
    }
        .fi-ss-dress:before {
    content: "\e5ad";
    }
        .fi-ss-drink-alt:before {
    content: "\e5b0";
    }
        .fi-ss-driver-man:before {
    content: "\e5b1";
    }
        .fi-ss-driver-woman:before {
    content: "\e5b2";
    }
        .fi-ss-drone:before {
    content: "\e5b3";
    }
        .fi-ss-drone-alt:before {
    content: "\e5b4";
    }
        .fi-ss-drone-front:before {
    content: "\e5b5";
    }
        .fi-ss-drop-down:before {
    content: "\e5b6";
    }
        .fi-ss-dropdown:before {
    content: "\e5b8";
    }
        .fi-ss-dropdown-bar:before {
    content: "\e5b9";
    }
        .fi-ss-dropdown-select:before {
    content: "\e5ba";
    }
        .fi-ss-drum:before {
    content: "\e5bc";
    }
        .fi-ss-drum-steelpan:before {
    content: "\e5bd";
    }
        .fi-ss-drumstick:before {
    content: "\e5be";
    }
        .fi-ss-drumstick-bite:before {
    content: "\e5bf";
    }
        .fi-ss-dryer:before {
    content: "\e5c1";
    }
        .fi-ss-dryer-alt:before {
    content: "\e5c2";
    }
        .fi-ss-duck:before {
    content: "\e5c4";
    }
        .fi-ss-dumbbell-fitness:before {
    content: "\e5c5";
    }
        .fi-ss-dumbbell-horizontal:before {
    content: "\e5c6";
    }
        .fi-ss-dumbbell-ray:before {
    content: "\e5c7";
    }
        .fi-ss-dumbbell-weightlifting:before {
    content: "\e5c8";
    }
        .fi-ss-dumpster:before {
    content: "\e5c9";
    }
        .fi-ss-dumpster-fire:before {
    content: "\e5ca";
    }
        .fi-ss-dungeon:before {
    content: "\e5cb";
    }
        .fi-ss-duplicate:before {
    content: "\e5cc";
    }
        .fi-ss-duration:before {
    content: "\e5cd";
    }
        .fi-ss-duration-alt:before {
    content: "\e5ce";
    }
        .fi-ss-e:before {
    content: "\e5cf";
    }
        .fi-ss-e-learning:before {
    content: "\e5d0";
    }
        .fi-ss-ear:before {
    content: "\e5d1";
    }
        .fi-ss-ear-deaf:before {
    content: "\e5d2";
    }
        .fi-ss-ear-muffs:before {
    content: "\e5d3";
    }
        .fi-ss-ear-sound:before {
    content: "\e5d4";
    }
        .fi-ss-earbuds:before {
    content: "\e5d5";
    }
        .fi-ss-earnings:before {
    content: "\e5d6";
    }
        .fi-ss-earth-africa:before {
    content: "\e5d7";
    }
        .fi-ss-earth-americas:before {
    content: "\e5d8";
    }
        .fi-ss-earth-asia:before {
    content: "\e5d9";
    }
        .fi-ss-earth-europa:before {
    content: "\e5da";
    }
        .fi-ss-eclipse:before {
    content: "\e5dc";
    }
        .fi-ss-eclipse-alt:before {
    content: "\e5dd";
    }
        .fi-ss-eco-electric:before {
    content: "\e5de";
    }
        .fi-ss-edit:before {
    content: "\e5df";
    }
        .fi-ss-edit-alt:before {
    content: "\e5e0";
    }
        .fi-ss-edit-message:before {
    content: "\e5e1";
    }
        .fi-ss-effect:before {
    content: "\e5e2";
    }
        .fi-ss-egg:before {
    content: "\e5e3";
    }
        .fi-ss-egg-fried:before {
    content: "\e5e4";
    }
        .fi-ss-eiffel-tower:before {
    content: "\e5e5";
    }
        .fi-ss-eject:before {
    content: "\e5e6";
    }
        .fi-ss-elephant:before {
    content: "\e5e8";
    }
        .fi-ss-elevator:before {
    content: "\e5e9";
    }
        .fi-ss-email-pending:before {
    content: "\e5eb";
    }
        .fi-ss-email-refresh:before {
    content: "\e5ec";
    }
        .fi-ss-emergency-call:before {
    content: "\e5ed";
    }
        .fi-ss-employee-alt:before {
    content: "\e5ee";
    }
        .fi-ss-employee-handbook:before {
    content: "\e5ef";
    }
        .fi-ss-employee-man:before {
    content: "\e5f0";
    }
        .fi-ss-employee-man-alt:before {
    content: "\e5f1";
    }
        .fi-ss-employees:before {
    content: "\e5f4";
    }
        .fi-ss-employees-woman-man:before {
    content: "\e5f5";
    }
        .fi-ss-empty-set:before {
    content: "\e5f6";
    }
        .fi-ss-endless-loop:before {
    content: "\e5f7";
    }
        .fi-ss-engine:before {
    content: "\e5f9";
    }
        .fi-ss-engine-warning:before {
    content: "\e5fa";
    }
        .fi-ss-english:before {
    content: "\e5fb";
    }
        .fi-ss-enter:before {
    content: "\e5fc";
    }
        .fi-ss-entertainment:before {
    content: "\e5fd";
    }
        .fi-ss-envelope:before {
    content: "\e5fe";
    }
        .fi-ss-envelope-ban:before {
    content: "\e5ff";
    }
        .fi-ss-envelope-bulk:before {
    content: "\e600";
    }
        .fi-ss-envelope-dot:before {
    content: "\e601";
    }
        .fi-ss-envelope-download:before {
    content: "\e602";
    }
        .fi-ss-envelope-heart:before {
    content: "\e603";
    }
        .fi-ss-envelope-marker:before {
    content: "\e604";
    }
        .fi-ss-envelope-open:before {
    content: "\e605";
    }
        .fi-ss-envelope-open-dollar:before {
    content: "\e606";
    }
        .fi-ss-envelope-open-text:before {
    content: "\e607";
    }
        .fi-ss-envelope-plus:before {
    content: "\e608";
    }
        .fi-ss-envelopes:before {
    content: "\e609";
    }
        .fi-ss-equality:before {
    content: "\e60b";
    }
        .fi-ss-equals:before {
    content: "\e60c";
    }
        .fi-ss-equestrian-statue:before {
    content: "\e60d";
    }
        .fi-ss-equipment:before {
    content: "\e60e";
    }
        .fi-ss-eraser:before {
    content: "\e60f";
    }
        .fi-ss-error-camera:before {
    content: "\e610";
    }
        .fi-ss-escalator:before {
    content: "\e611";
    }
        .fi-ss-ethernet:before {
    content: "\e613";
    }
        .fi-ss-euro:before {
    content: "\e615";
    }
        .fi-ss-europe-flag:before {
    content: "\e616";
    }
        .fi-ss-europe-map:before {
    content: "\e617";
    }
        .fi-ss-european-union:before {
    content: "\e618";
    }
        .fi-ss-excavator:before {
    content: "\e61a";
    }
        .fi-ss-exchange:before {
    content: "\e61b";
    }
        .fi-ss-exchange-alt:before {
    content: "\e61c";
    }
        .fi-ss-exchange-cryptocurrency:before {
    content: "\e61d";
    }
        .fi-ss-exclamation:before {
    content: "\e61e";
    }
        .fi-ss-exit:before {
    content: "\e621";
    }
        .fi-ss-exit-alt:before {
    content: "\e622";
    }
        .fi-ss-expand:before {
    content: "\e623";
    }
        .fi-ss-expand-arrows:before {
    content: "\e624";
    }
        .fi-ss-expand-arrows-alt:before {
    content: "\e625";
    }
        .fi-ss-expense:before {
    content: "\e626";
    }
        .fi-ss-expense-bill:before {
    content: "\e627";
    }
        .fi-ss-explosion:before {
    content: "\e629";
    }
        .fi-ss-external-hard-drive:before {
    content: "\e62a";
    }
        .fi-ss-external-world:before {
    content: "\e62b";
    }
        .fi-ss-eye:before {
    content: "\e62c";
    }
        .fi-ss-eye-alert:before {
    content: "\e62d";
    }
        .fi-ss-eye-arrow-progress:before {
    content: "\e62e";
    }
        .fi-ss-eye-crossed:before {
    content: "\e62f";
    }
        .fi-ss-eye-dropper-half:before {
    content: "\e631";
    }
        .fi-ss-eyes:before {
    content: "\e633";
    }
        .fi-ss-f:before {
    content: "\e634";
    }
        .fi-ss-fabric:before {
    content: "\e635";
    }
        .fi-ss-face-angry-horns:before {
    content: "\e636";
    }
        .fi-ss-face-anguished:before {
    content: "\e637";
    }
        .fi-ss-face-anxious-sweat:before {
    content: "\e638";
    }
        .fi-ss-face-astonished:before {
    content: "\e639";
    }
        .fi-ss-face-awesome:before {
    content: "\e63a";
    }
        .fi-ss-face-beam-hand-over-mouth:before {
    content: "\e63b";
    }
        .fi-ss-face-confounded:before {
    content: "\e63c";
    }
        .fi-ss-face-confused:before {
    content: "\e63d";
    }
        .fi-ss-face-cowboy-hat:before {
    content: "\e63e";
    }
        .fi-ss-face-disappointed:before {
    content: "\e63f";
    }
        .fi-ss-face-disguise:before {
    content: "\e640";
    }
        .fi-ss-face-downcast-sweat:before {
    content: "\e641";
    }
        .fi-ss-face-drooling:before {
    content: "\e642";
    }
        .fi-ss-face-explode:before {
    content: "\e643";
    }
        .fi-ss-face-expressionless:before {
    content: "\e644";
    }
        .fi-ss-face-eyes-xmarks:before {
    content: "\e645";
    }
        .fi-ss-face-fearful:before {
    content: "\e646";
    }
        .fi-ss-face-glasses:before {
    content: "\e647";
    }
        .fi-ss-face-grin-tongue-wink:before {
    content: "\e648";
    }
        .fi-ss-face-hand-yawn:before {
    content: "\e649";
    }
        .fi-ss-face-head-bandage:before {
    content: "\e64a";
    }
        .fi-ss-face-hushed:before {
    content: "\e64b";
    }
        .fi-ss-face-icicles:before {
    content: "\e64c";
    }
        .fi-ss-face-lying:before {
    content: "\e64d";
    }
        .fi-ss-face-mask:before {
    content: "\e64e";
    }
        .fi-ss-face-monocle:before {
    content: "\e64f";
    }
        .fi-ss-face-nauseated:before {
    content: "\e650";
    }
        .fi-ss-face-nose-steam:before {
    content: "\e651";
    }
        .fi-ss-face-party:before {
    content: "\e652";
    }
        .fi-ss-face-pensive:before {
    content: "\e653";
    }
        .fi-ss-face-persevering:before {
    content: "\e654";
    }
        .fi-ss-face-pleading:before {
    content: "\e655";
    }
        .fi-ss-face-raised-eyebrow:before {
    content: "\e656";
    }
        .fi-ss-face-relieved:before {
    content: "\e657";
    }
        .fi-ss-face-sad-sweat:before {
    content: "\e658";
    }
        .fi-ss-face-scream:before {
    content: "\e659";
    }
        .fi-ss-face-shush:before {
    content: "\e65a";
    }
        .fi-ss-face-sleeping:before {
    content: "\e65b";
    }
        .fi-ss-face-sleepy:before {
    content: "\e65c";
    }
        .fi-ss-face-smile-halo:before {
    content: "\e65d";
    }
        .fi-ss-face-smile-hearts:before {
    content: "\e65e";
    }
        .fi-ss-face-smile-horns:before {
    content: "\e65f";
    }
        .fi-ss-face-smile-tear:before {
    content: "\e660";
    }
        .fi-ss-face-smile-tongue:before {
    content: "\e661";
    }
        .fi-ss-face-smile-upside-down:before {
    content: "\e662";
    }
        .fi-ss-face-smiling-hands:before {
    content: "\e663";
    }
        .fi-ss-face-smirking:before {
    content: "\e664";
    }
        .fi-ss-face-sunglasses:before {
    content: "\e665";
    }
        .fi-ss-face-sunglasses-alt:before {
    content: "\e666";
    }
        .fi-ss-face-swear:before {
    content: "\e667";
    }
        .fi-ss-face-thermometer:before {
    content: "\e668";
    }
        .fi-ss-face-thinking:before {
    content: "\e669";
    }
        .fi-ss-face-tissue:before {
    content: "\e66a";
    }
        .fi-ss-face-tongue-money:before {
    content: "\e66b";
    }
        .fi-ss-face-tongue-sweat:before {
    content: "\e66c";
    }
        .fi-ss-face-unamused:before {
    content: "\e66d";
    }
        .fi-ss-face-viewfinder:before {
    content: "\e66e";
    }
        .fi-ss-face-vomit:before {
    content: "\e66f";
    }
        .fi-ss-face-weary:before {
    content: "\e670";
    }
        .fi-ss-face-woozy:before {
    content: "\e671";
    }
        .fi-ss-face-worried:before {
    content: "\e672";
    }
        .fi-ss-face-zany:before {
    content: "\e673";
    }
        .fi-ss-face-zipper:before {
    content: "\e674";
    }
        .fi-ss-facial-massage:before {
    content: "\e677";
    }
        .fi-ss-fail:before {
    content: "\e678";
    }
        .fi-ss-falafel:before {
    content: "\e679";
    }
        .fi-ss-family:before {
    content: "\e67a";
    }
        .fi-ss-family-dress:before {
    content: "\e67b";
    }
        .fi-ss-family-pants:before {
    content: "\e67c";
    }
        .fi-ss-fan:before {
    content: "\e67d";
    }
        .fi-ss-fan-table:before {
    content: "\e67e";
    }
        .fi-ss-farfalle:before {
    content: "\e67f";
    }
        .fi-ss-farm:before {
    content: "\e680";
    }
        .fi-ss-faucet:before {
    content: "\e681";
    }
        .fi-ss-faucet-drip:before {
    content: "\e682";
    }
        .fi-ss-fax:before {
    content: "\e683";
    }
        .fi-ss-feather:before {
    content: "\e684";
    }
        .fi-ss-feather-pointed:before {
    content: "\e685";
    }
        .fi-ss-features:before {
    content: "\e686";
    }
        .fi-ss-features-alt:before {
    content: "\e687";
    }
        .fi-ss-fee:before {
    content: "\e689";
    }
        .fi-ss-fee-receipt:before {
    content: "\e68b";
    }
        .fi-ss-feedback:before {
    content: "\e68c";
    }
        .fi-ss-feedback-alt:before {
    content: "\e68d";
    }
        .fi-ss-feedback-cycle-loop:before {
    content: "\e68e";
    }
        .fi-ss-feedback-hand:before {
    content: "\e68f";
    }
        .fi-ss-feedback-review:before {
    content: "\e690";
    }
        .fi-ss-fence:before {
    content: "\e693";
    }
        .fi-ss-field-hockey:before {
    content: "\e695";
    }
        .fi-ss-fighter-jet:before {
    content: "\e696";
    }
        .fi-ss-file:before {
    content: "\e698";
    }
        .fi-ss-file-ai:before {
    content: "\e699";
    }
        .fi-ss-file-audio:before {
    content: "\e69a";
    }
        .fi-ss-file-binary:before {
    content: "\e69b";
    }
        .fi-ss-file-chart-line:before {
    content: "\e69c";
    }
        .fi-ss-file-chart-pie:before {
    content: "\e69d";
    }
        .fi-ss-file-circle-info:before {
    content: "\e69e";
    }
        .fi-ss-file-cloud:before {
    content: "\e69f";
    }
        .fi-ss-file-code:before {
    content: "\e6a0";
    }
        .fi-ss-file-csv:before {
    content: "\e6a2";
    }
        .fi-ss-file-download:before {
    content: "\e6a4";
    }
        .fi-ss-file-edit:before {
    content: "\e6a5";
    }
        .fi-ss-file-eps:before {
    content: "\e6a6";
    }
        .fi-ss-file-excel:before {
    content: "\e6a7";
    }
        .fi-ss-file-exclamation:before {
    content: "\e6a8";
    }
        .fi-ss-file-export:before {
    content: "\e6a9";
    }
        .fi-ss-file-image:before {
    content: "\e6ab";
    }
        .fi-ss-file-import:before {
    content: "\e6ac";
    }
        .fi-ss-file-invoice:before {
    content: "\e6ad";
    }
        .fi-ss-file-invoice-dollar:before {
    content: "\e6ae";
    }
        .fi-ss-file-loop:before {
    content: "\e6b0";
    }
        .fi-ss-file-medical:before {
    content: "\e6b2";
    }
        .fi-ss-file-medical-alt:before {
    content: "\e6b3";
    }
        .fi-ss-file-minus:before {
    content: "\e6b4";
    }
        .fi-ss-file-pdf:before {
    content: "\e6b5";
    }
        .fi-ss-file-powerpoint:before {
    content: "\e6b8";
    }
        .fi-ss-file-prescription:before {
    content: "\e6b9";
    }
        .fi-ss-file-psd:before {
    content: "\e6ba";
    }
        .fi-ss-file-question:before {
    content: "\e6bb";
    }
        .fi-ss-file-recycle:before {
    content: "\e6bc";
    }
        .fi-ss-file-signature:before {
    content: "\e6bd";
    }
        .fi-ss-file-spreadsheet:before {
    content: "\e6bf";
    }
        .fi-ss-file-upload:before {
    content: "\e6c0";
    }
        .fi-ss-file-user:before {
    content: "\e6c1";
    }
        .fi-ss-file-video:before {
    content: "\e6c2";
    }
        .fi-ss-file-word:before {
    content: "\e6c3";
    }
        .fi-ss-file-xls:before {
    content: "\e6c4";
    }
        .fi-ss-file-zip-alt:before {
    content: "\e6c6";
    }
        .fi-ss-file-zip-save:before {
    content: "\e6c7";
    }
        .fi-ss-file-zipper:before {
    content: "\e6c8";
    }
        .fi-ss-files-medical:before {
    content: "\e6ca";
    }
        .fi-ss-fill:before {
    content: "\e6cb";
    }
        .fi-ss-film:before {
    content: "\e6cc";
    }
        .fi-ss-film-canister:before {
    content: "\e6cd";
    }
        .fi-ss-film-slash:before {
    content: "\e6ce";
    }
        .fi-ss-films:before {
    content: "\e6cf";
    }
        .fi-ss-filter:before {
    content: "\e6d0";
    }
        .fi-ss-filter-list:before {
    content: "\e6d1";
    }
        .fi-ss-filter-slash:before {
    content: "\e6d2";
    }
        .fi-ss-filters:before {
    content: "\e6d3";
    }
        .fi-ss-finger-droplet:before {
    content: "\e6d4";
    }
        .fi-ss-finger-nail:before {
    content: "\e6d5";
    }
        .fi-ss-fingerprint:before {
    content: "\e6d6";
    }
        .fi-ss-fingerprint-remove:before {
    content: "\e6d7";
    }
        .fi-ss-fingerprint-security-risk:before {
    content: "\e6d8";
    }
        .fi-ss-fingerprint-verified:before {
    content: "\e6d9";
    }
        .fi-ss-fire-burner:before {
    content: "\e6da";
    }
        .fi-ss-fire-extinguisher:before {
    content: "\e6db";
    }
        .fi-ss-fire-flame-curved:before {
    content: "\e6dc";
    }
        .fi-ss-fire-flame-simple:before {
    content: "\e6dd";
    }
        .fi-ss-fire-hydrant:before {
    content: "\e6de";
    }
        .fi-ss-fire-smoke:before {
    content: "\e6df";
    }
        .fi-ss-fireplace:before {
    content: "\e6e2";
    }
        .fi-ss-first:before {
    content: "\e6e5";
    }
        .fi-ss-first-award:before {
    content: "\e6e6";
    }
        .fi-ss-first-laurel:before {
    content: "\e6e7";
    }
        .fi-ss-first-medal:before {
    content: "\e6e8";
    }
        .fi-ss-fish:before {
    content: "\e6e9";
    }
        .fi-ss-fish-bones:before {
    content: "\e6ea";
    }
        .fi-ss-fish-cooked:before {
    content: "\e6eb";
    }
        .fi-ss-fishing-rod:before {
    content: "\e6ec";
    }
        .fi-ss-fist-move:before {
    content: "\e6ed";
    }
        .fi-ss-fitness-watch:before {
    content: "\e6ee";
    }
        .fi-ss-flag:before {
    content: "\e6ef";
    }
        .fi-ss-flag-alt:before {
    content: "\e6f0";
    }
        .fi-ss-flag-checkered:before {
    content: "\e6f1";
    }
        .fi-ss-flag-usa:before {
    content: "\e6f2";
    }
        .fi-ss-flame:before {
    content: "\e6f3";
    }
        .fi-ss-flashlight:before {
    content: "\e6f4";
    }
        .fi-ss-flask:before {
    content: "\e6f5";
    }
        .fi-ss-flask-gear:before {
    content: "\e6f6";
    }
        .fi-ss-flask-poison:before {
    content: "\e6f7";
    }
        .fi-ss-flask-potion:before {
    content: "\e6f8";
    }
        .fi-ss-flatbread:before {
    content: "\e6f9";
    }
        .fi-ss-flatbread-stuffed:before {
    content: "\e6fa";
    }
        .fi-ss-flip-horizontal:before {
    content: "\e6ff";
    }
        .fi-ss-floor:before {
    content: "\e701";
    }
        .fi-ss-floor-alt:before {
    content: "\e702";
    }
        .fi-ss-floor-layer:before {
    content: "\e703";
    }
        .fi-ss-floppy-disk-circle-arrow-right:before {
    content: "\e704";
    }
        .fi-ss-floppy-disk-circle-xmark:before {
    content: "\e705";
    }
        .fi-ss-floppy-disk-pen:before {
    content: "\e706";
    }
        .fi-ss-floppy-disks:before {
    content: "\e707";
    }
        .fi-ss-florin-sign:before {
    content: "\e708";
    }
        .fi-ss-flowchart:before {
    content: "\e709";
    }
        .fi-ss-flower:before {
    content: "\e70a";
    }
        .fi-ss-flower-butterfly:before {
    content: "\e70c";
    }
        .fi-ss-flower-daffodil:before {
    content: "\e70d";
    }
        .fi-ss-flower-tulip:before {
    content: "\e70e";
    }
        .fi-ss-flushed:before {
    content: "\e70f";
    }
        .fi-ss-flute:before {
    content: "\e711";
    }
        .fi-ss-flux-capacitor:before {
    content: "\e712";
    }
        .fi-ss-fly-insect:before {
    content: "\e713";
    }
        .fi-ss-flying-disc:before {
    content: "\e714";
    }
        .fi-ss-fm-radio:before {
    content: "\e715";
    }
        .fi-ss-fog:before {
    content: "\e716";
    }
        .fi-ss-folder:before {
    content: "\e717";
    }
        .fi-ss-folder-archive:before {
    content: "\e718";
    }
        .fi-ss-folder-camera:before {
    content: "\e71a";
    }
        .fi-ss-folder-directory:before {
    content: "\e71b";
    }
        .fi-ss-folder-download:before {
    content: "\e71c";
    }
        .fi-ss-folder-fire:before {
    content: "\e71d";
    }
        .fi-ss-folder-gear:before {
    content: "\e71e";
    }
        .fi-ss-folder-heart:before {
    content: "\e720";
    }
        .fi-ss-folder-loop:before {
    content: "\e722";
    }
        .fi-ss-folder-math:before {
    content: "\e724";
    }
        .fi-ss-folder-minus:before {
    content: "\e726";
    }
        .fi-ss-folder-music:before {
    content: "\e727";
    }
        .fi-ss-folder-open:before {
    content: "\e728";
    }
        .fi-ss-folder-plus-circle:before {
    content: "\e729";
    }
        .fi-ss-folder-times:before {
    content: "\e72a";
    }
        .fi-ss-folder-tree:before {
    content: "\e72b";
    }
        .fi-ss-folder-upload:before {
    content: "\e72c";
    }
        .fi-ss-folder-xmark:before {
    content: "\e72e";
    }
        .fi-ss-folder-xmark-circle:before {
    content: "\e72f";
    }
        .fi-ss-folders:before {
    content: "\e730";
    }
        .fi-ss-follow-folder:before {
    content: "\e731";
    }
        .fi-ss-followcollection:before {
    content: "\e732";
    }
        .fi-ss-following:before {
    content: "\e733";
    }
        .fi-ss-fondue-pot:before {
    content: "\e734";
    }
        .fi-ss-football:before {
    content: "\e739";
    }
        .fi-ss-footprint:before {
    content: "\e73a";
    }
        .fi-ss-foreign-language-audio:before {
    content: "\e73b";
    }
        .fi-ss-forest:before {
    content: "\e73c";
    }
        .fi-ss-forest-alt:before {
    content: "\e73d";
    }
        .fi-ss-fork:before {
    content: "\e73e";
    }
        .fi-ss-fork-spaghetti:before {
    content: "\e73f";
    }
        .fi-ss-forklift:before {
    content: "\e740";
    }
        .fi-ss-form:before {
    content: "\e741";
    }
        .fi-ss-fort:before {
    content: "\e742";
    }
        .fi-ss-forward:before {
    content: "\e743";
    }
        .fi-ss-forward-fast:before {
    content: "\e744";
    }
        .fi-ss-fox:before {
    content: "\e747";
    }
        .fi-ss-frame:before {
    content: "\e748";
    }
        .fi-ss-franc-sign:before {
    content: "\e749";
    }
        .fi-ss-free:before {
    content: "\e74a";
    }
        .fi-ss-free-delivery:before {
    content: "\e74b";
    }
        .fi-ss-freemium:before {
    content: "\e74c";
    }
        .fi-ss-french:before {
    content: "\e74f";
    }
        .fi-ss-french-fries:before {
    content: "\e750";
    }
        .fi-ss-friday:before {
    content: "\e752";
    }
        .fi-ss-frog:before {
    content: "\e753";
    }
        .fi-ss-frown:before {
    content: "\e754";
    }
        .fi-ss-ftp:before {
    content: "\e755";
    }
        .fi-ss-fuel-gauge:before {
    content: "\e756";
    }
        .fi-ss-function:before {
    content: "\e757";
    }
        .fi-ss-function-process:before {
    content: "\e759";
    }
        .fi-ss-function-square:before {
    content: "\e75a";
    }
        .fi-ss-funnel-dollar:before {
    content: "\e75b";
    }
        .fi-ss-funnel-money:before {
    content: "\e75c";
    }
        .fi-ss-fusilli:before {
    content: "\e75d";
    }
        .fi-ss-g:before {
    content: "\e75e";
    }
        .fi-ss-galaxy:before {
    content: "\e75f";
    }
        .fi-ss-galaxy-alt:before {
    content: "\e760";
    }
        .fi-ss-galaxy-planet:before {
    content: "\e761";
    }
        .fi-ss-galaxy-star:before {
    content: "\e762";
    }
        .fi-ss-gallery:before {
    content: "\e763";
    }
        .fi-ss-gallery-thumbnails:before {
    content: "\e764";
    }
        .fi-ss-game:before {
    content: "\e765";
    }
        .fi-ss-game-board-alt:before {
    content: "\e766";
    }
        .fi-ss-gamepad:before {
    content: "\e768";
    }
        .fi-ss-games:before {
    content: "\e769";
    }
        .fi-ss-garage:before {
    content: "\e76a";
    }
        .fi-ss-garage-car:before {
    content: "\e76b";
    }
        .fi-ss-garage-open:before {
    content: "\e76c";
    }
        .fi-ss-garlic:before {
    content: "\e76d";
    }
        .fi-ss-garlic-alt:before {
    content: "\e76e";
    }
        .fi-ss-gas-pump:before {
    content: "\e76f";
    }
        .fi-ss-gas-pump-alt:before {
    content: "\e770";
    }
        .fi-ss-gas-pump-slash:before {
    content: "\e771";
    }
        .fi-ss-gauge-circle-bolt:before {
    content: "\e772";
    }
        .fi-ss-gauge-circle-minus:before {
    content: "\e773";
    }
        .fi-ss-gauge-circle-plus:before {
    content: "\e774";
    }
        .fi-ss-gauze-bandage:before {
    content: "\e775";
    }
        .fi-ss-gavel:before {
    content: "\e776";
    }
        .fi-ss-gay-couple:before {
    content: "\e777";
    }
        .fi-ss-gears:before {
    content: "\e77a";
    }
        .fi-ss-gem:before {
    content: "\e77b";
    }
        .fi-ss-general:before {
    content: "\e77c";
    }
        .fi-ss-german:before {
    content: "\e77d";
    }
        .fi-ss-ghost:before {
    content: "\e77e";
    }
        .fi-ss-gif:before {
    content: "\e77f";
    }
        .fi-ss-gif-square:before {
    content: "\e781";
    }
        .fi-ss-gift:before {
    content: "\e782";
    }
        .fi-ss-gift-box-benefits:before {
    content: "\e783";
    }
        .fi-ss-gift-card:before {
    content: "\e784";
    }
        .fi-ss-gifts:before {
    content: "\e785";
    }
        .fi-ss-gingerbread-man:before {
    content: "\e786";
    }
        .fi-ss-glass:before {
    content: "\e789";
    }
        .fi-ss-glass-champagne:before {
    content: "\e78a";
    }
        .fi-ss-glass-cheers:before {
    content: "\e78b";
    }
        .fi-ss-glass-citrus:before {
    content: "\e78c";
    }
        .fi-ss-glass-empty:before {
    content: "\e78d";
    }
        .fi-ss-glass-half:before {
    content: "\e78e";
    }
        .fi-ss-glass-water-droplet:before {
    content: "\e78f";
    }
        .fi-ss-glass-whiskey:before {
    content: "\e790";
    }
        .fi-ss-glass-whiskey-rocks:before {
    content: "\e791";
    }
        .fi-ss-glasses:before {
    content: "\e792";
    }
        .fi-ss-globe:before {
    content: "\e793";
    }
        .fi-ss-globe-alt:before {
    content: "\e794";
    }
        .fi-ss-globe-pointer:before {
    content: "\e795";
    }
        .fi-ss-globe-snow:before {
    content: "\e796";
    }
        .fi-ss-globe-user:before {
    content: "\e797";
    }
        .fi-ss-goal-net:before {
    content: "\e798";
    }
        .fi-ss-golf:before {
    content: "\e799";
    }
        .fi-ss-golf-ball:before {
    content: "\e79a";
    }
        .fi-ss-golf-club:before {
    content: "\e79b";
    }
        .fi-ss-golf-hole:before {
    content: "\e79c";
    }
        .fi-ss-gopuram:before {
    content: "\e7a0";
    }
        .fi-ss-government-budget:before {
    content: "\e7a1";
    }
        .fi-ss-government-flag:before {
    content: "\e7a2";
    }
        .fi-ss-government-user:before {
    content: "\e7a3";
    }
        .fi-ss-gps-navigation:before {
    content: "\e7a4";
    }
        .fi-ss-graduation-cap:before {
    content: "\e7a5";
    }
        .fi-ss-gramophone:before {
    content: "\e7a6";
    }
        .fi-ss-grape:before {
    content: "\e7a7";
    }
        .fi-ss-graph-curve:before {
    content: "\e7a8";
    }
        .fi-ss-graphic-style:before {
    content: "\e7a9";
    }
        .fi-ss-graphic-tablet:before {
    content: "\e7aa";
    }
        .fi-ss-grass:before {
    content: "\e7ab";
    }
        .fi-ss-grate:before {
    content: "\e7ac";
    }
        .fi-ss-grate-droplet:before {
    content: "\e7ad";
    }
        .fi-ss-greater-than:before {
    content: "\e7ae";
    }
        .fi-ss-greater-than-equal:before {
    content: "\e7af";
    }
        .fi-ss-greek-helmet:before {
    content: "\e7b0";
    }
        .fi-ss-grid:before {
    content: "\e7b1";
    }
        .fi-ss-grid-alt:before {
    content: "\e7b2";
    }
        .fi-ss-grid-dividers:before {
    content: "\e7b3";
    }
        .fi-ss-grill:before {
    content: "\e7b4";
    }
        .fi-ss-grill-hot-alt:before {
    content: "\e7b5";
    }
        .fi-ss-grimace:before {
    content: "\e7b6";
    }
        .fi-ss-grin:before {
    content: "\e7b7";
    }
        .fi-ss-grin-alt:before {
    content: "\e7b8";
    }
        .fi-ss-grin-beam:before {
    content: "\e7b9";
    }
        .fi-ss-grin-beam-sweat:before {
    content: "\e7ba";
    }
        .fi-ss-grin-hearts:before {
    content: "\e7bb";
    }
        .fi-ss-grin-squint:before {
    content: "\e7bc";
    }
        .fi-ss-grin-squint-tears:before {
    content: "\e7bd";
    }
        .fi-ss-grin-stars:before {
    content: "\e7be";
    }
        .fi-ss-grin-tears:before {
    content: "\e7bf";
    }
        .fi-ss-grin-tongue:before {
    content: "\e7c0";
    }
        .fi-ss-grin-tongue-squint:before {
    content: "\e7c1";
    }
        .fi-ss-grin-tongue-wink:before {
    content: "\e7c2";
    }
        .fi-ss-grin-wink:before {
    content: "\e7c3";
    }
        .fi-ss-grip-dots:before {
    content: "\e7c4";
    }
        .fi-ss-grip-dots-vertical:before {
    content: "\e7c5";
    }
        .fi-ss-grip-horizontal:before {
    content: "\e7c6";
    }
        .fi-ss-grip-lines:before {
    content: "\e7c7";
    }
        .fi-ss-grip-lines-vertical:before {
    content: "\e7c8";
    }
        .fi-ss-grip-vertical:before {
    content: "\e7c9";
    }
        .fi-ss-grocery-bag:before {
    content: "\e7ca";
    }
        .fi-ss-grocery-basket:before {
    content: "\e7cb";
    }
        .fi-ss-group-arrows-rotate:before {
    content: "\e7cc";
    }
        .fi-ss-group-call:before {
    content: "\e7cd";
    }
        .fi-ss-group-community-social-media:before {
    content: "\e7ce";
    }
        .fi-ss-growth-chart-invest:before {
    content: "\e7cf";
    }
        .fi-ss-guarani-sign:before {
    content: "\e7d0";
    }
        .fi-ss-guide:before {
    content: "\e7d1";
    }
        .fi-ss-guide-alt:before {
    content: "\e7d2";
    }
        .fi-ss-guitar:before {
    content: "\e7d3";
    }
        .fi-ss-guitar-electric:before {
    content: "\e7d4";
    }
        .fi-ss-guitars:before {
    content: "\e7d5";
    }
        .fi-ss-gun-squirt:before {
    content: "\e7d6";
    }
        .fi-ss-gym:before {
    content: "\e7d7";
    }
        .fi-ss-h:before {
    content: "\e7d8";
    }
        .fi-ss-h-square:before {
    content: "\e7d9";
    }
        .fi-ss-h1:before {
    content: "\e7da";
    }
        .fi-ss-h2:before {
    content: "\e7db";
    }
        .fi-ss-h3:before {
    content: "\e7dc";
    }
        .fi-ss-h4:before {
    content: "\e7dd";
    }
        .fi-ss-hair-clipper:before {
    content: "\e7de";
    }
        .fi-ss-hamburger:before {
    content: "\e7df";
    }
        .fi-ss-hamburger-soda:before {
    content: "\e7e0";
    }
        .fi-ss-hammer:before {
    content: "\e7e1";
    }
        .fi-ss-hammer-brush:before {
    content: "\e7e2";
    }
        .fi-ss-hammer-crash:before {
    content: "\e7e3";
    }
        .fi-ss-hammer-war:before {
    content: "\e7e4";
    }
        .fi-ss-hamsa:before {
    content: "\e7e5";
    }
        .fi-ss-hand:before {
    content: "\e7e6";
    }
        .fi-ss-hand-back-fist:before {
    content: "\e7e7";
    }
        .fi-ss-hand-back-point-down:before {
    content: "\e7e8";
    }
        .fi-ss-hand-back-point-left:before {
    content: "\e7e9";
    }
        .fi-ss-hand-back-point-ribbon:before {
    content: "\e7ea";
    }
        .fi-ss-hand-back-point-right:before {
    content: "\e7eb";
    }
        .fi-ss-hand-bandage-wound:before {
    content: "\e7ec";
    }
        .fi-ss-hand-bill:before {
    content: "\e7ed";
    }
        .fi-ss-hand-dots:before {
    content: "\e7ee";
    }
        .fi-ss-hand-fingers-crossed:before {
    content: "\e7ef";
    }
        .fi-ss-hand-fist:before {
    content: "\e7f0";
    }
        .fi-ss-hand-heart:before {
    content: "\e7f1";
    }
        .fi-ss-hand-holding-box:before {
    content: "\e7f2";
    }
        .fi-ss-hand-holding-document:before {
    content: "\e7f3";
    }
        .fi-ss-hand-holding-droplet:before {
    content: "\e7f4";
    }
        .fi-ss-hand-holding-heart:before {
    content: "\e7f5";
    }
        .fi-ss-hand-holding-magic:before {
    content: "\e7f6";
    }
        .fi-ss-hand-holding-medical:before {
    content: "\e7f7";
    }
        .fi-ss-hand-holding-seeding:before {
    content: "\e7f8";
    }
        .fi-ss-hand-holding-skull:before {
    content: "\e7fa";
    }
        .fi-ss-hand-holding-usd:before {
    content: "\e7fb";
    }
        .fi-ss-hand-holding-water:before {
    content: "\e7fc";
    }
        .fi-ss-hand-horns:before {
    content: "\e7fd";
    }
        .fi-ss-hand-key:before {
    content: "\e7fe";
    }
        .fi-ss-hand-lizard:before {
    content: "\e7ff";
    }
        .fi-ss-hand-love:before {
    content: "\e800";
    }
        .fi-ss-hand-middle-finger:before {
    content: "\e801";
    }
        .fi-ss-hand-paper:before {
    content: "\e802";
    }
        .fi-ss-hand-peace:before {
    content: "\e803";
    }
        .fi-ss-hand-point-ribbon:before {
    content: "\e804";
    }
        .fi-ss-hand-present:before {
    content: "\e805";
    }
        .fi-ss-hand-scissors:before {
    content: "\e806";
    }
        .fi-ss-hand-sparkles:before {
    content: "\e807";
    }
        .fi-ss-hand-spock:before {
    content: "\e808";
    }
        .fi-ss-hand-wave:before {
    content: "\e809";
    }
        .fi-ss-handcuffs:before {
    content: "\e80a";
    }
        .fi-ss-handmade:before {
    content: "\e80b";
    }
        .fi-ss-hands-brain:before {
    content: "\e80c";
    }
        .fi-ss-hands-bubbles:before {
    content: "\e80d";
    }
        .fi-ss-hands-clapping:before {
    content: "\e80e";
    }
        .fi-ss-hands-heart:before {
    content: "\e80f";
    }
        .fi-ss-hands-holding:before {
    content: "\e810";
    }
        .fi-ss-hands-holding-diamond:before {
    content: "\e811";
    }
        .fi-ss-hands-together:before {
    content: "\e812";
    }
        .fi-ss-hands-together-heart:before {
    content: "\e813";
    }
        .fi-ss-hands-usd:before {
    content: "\e815";
    }
        .fi-ss-handshake:before {
    content: "\e816";
    }
        .fi-ss-handshake-angle:before {
    content: "\e817";
    }
        .fi-ss-handshake-deal-loan:before {
    content: "\e818";
    }
        .fi-ss-handshake-house:before {
    content: "\e819";
    }
        .fi-ss-handshake-simple-slash:before {
    content: "\e81a";
    }
        .fi-ss-handshake-trust:before {
    content: "\e81b";
    }
        .fi-ss-hard-hat:before {
    content: "\e81d";
    }
        .fi-ss-hashtag-lock:before {
    content: "\e820";
    }
        .fi-ss-hastag:before {
    content: "\e822";
    }
        .fi-ss-hat-beach:before {
    content: "\e823";
    }
        .fi-ss-hat-birthday:before {
    content: "\e824";
    }
        .fi-ss-hat-chef:before {
    content: "\e825";
    }
        .fi-ss-hat-cowboy:before {
    content: "\e826";
    }
        .fi-ss-hat-cowboy-side:before {
    content: "\e827";
    }
        .fi-ss-hat-santa:before {
    content: "\e828";
    }
        .fi-ss-hat-winter:before {
    content: "\e829";
    }
        .fi-ss-hat-witch:before {
    content: "\e82a";
    }
        .fi-ss-hat-wizard:before {
    content: "\e82b";
    }
        .fi-ss-hdd:before {
    content: "\e82d";
    }
        .fi-ss-head-side:before {
    content: "\e82e";
    }
        .fi-ss-head-side-brain:before {
    content: "\e82f";
    }
        .fi-ss-head-side-cough:before {
    content: "\e830";
    }
        .fi-ss-head-side-cough-slash:before {
    content: "\e831";
    }
        .fi-ss-head-side-headphones:before {
    content: "\e832";
    }
        .fi-ss-head-side-heart:before {
    content: "\e833";
    }
        .fi-ss-head-side-mask:before {
    content: "\e834";
    }
        .fi-ss-head-side-medical:before {
    content: "\e835";
    }
        .fi-ss-head-side-thinking:before {
    content: "\e836";
    }
        .fi-ss-head-side-virus:before {
    content: "\e837";
    }
        .fi-ss-head-vr:before {
    content: "\e838";
    }
        .fi-ss-heading:before {
    content: "\e839";
    }
        .fi-ss-headphones:before {
    content: "\e83a";
    }
        .fi-ss-headset:before {
    content: "\e83b";
    }
        .fi-ss-heart:before {
    content: "\e83c";
    }
        .fi-ss-heart-arrow:before {
    content: "\e83d";
    }
        .fi-ss-heart-brain:before {
    content: "\e83e";
    }
        .fi-ss-heart-circle-user:before {
    content: "\e83f";
    }
        .fi-ss-heart-crack:before {
    content: "\e840";
    }
        .fi-ss-heart-half:before {
    content: "\e841";
    }
        .fi-ss-heart-half-stroke:before {
    content: "\e842";
    }
        .fi-ss-heart-health-muscle:before {
    content: "\e843";
    }
        .fi-ss-heart-lock:before {
    content: "\e844";
    }
        .fi-ss-heart-partner-handshake:before {
    content: "\e845";
    }
        .fi-ss-heart-rate:before {
    content: "\e846";
    }
        .fi-ss-heart-slash:before {
    content: "\e847";
    }
        .fi-ss-heart-upside-down:before {
    content: "\e848";
    }
        .fi-ss-heat:before {
    content: "\e849";
    }
        .fi-ss-helicopter-side:before {
    content: "\e84a";
    }
        .fi-ss-helmet-battle:before {
    content: "\e84b";
    }
        .fi-ss-hexagon:before {
    content: "\e84c";
    }
        .fi-ss-hexagon-check:before {
    content: "\e84d";
    }
        .fi-ss-hexagon-divide:before {
    content: "\e84e";
    }
        .fi-ss-hexagon-exclamation:before {
    content: "\e84f";
    }
        .fi-ss-high-definition:before {
    content: "\e851";
    }
        .fi-ss-high-five:before {
    content: "\e852";
    }
        .fi-ss-high-five-celebration-yes:before {
    content: "\e853";
    }
        .fi-ss-highlighter:before {
    content: "\e854";
    }
        .fi-ss-highlighter-line:before {
    content: "\e855";
    }
        .fi-ss-hiking:before {
    content: "\e856";
    }
        .fi-ss-hiking-boot:before {
    content: "\e857";
    }
        .fi-ss-hippo:before {
    content: "\e859";
    }
        .fi-ss-hockey-mask:before {
    content: "\e85a";
    }
        .fi-ss-hockey-puck:before {
    content: "\e85b";
    }
        .fi-ss-hockey-stick-puck:before {
    content: "\e85c";
    }
        .fi-ss-hockey-sticks:before {
    content: "\e85d";
    }
        .fi-ss-holding-hand-brain:before {
    content: "\e85e";
    }
        .fi-ss-holding-hand-dinner:before {
    content: "\e85f";
    }
        .fi-ss-holding-hand-direction:before {
    content: "\e860";
    }
        .fi-ss-holding-hand-gear:before {
    content: "\e861";
    }
        .fi-ss-holding-hand-key:before {
    content: "\e862";
    }
        .fi-ss-holding-hand-nft:before {
    content: "\e863";
    }
        .fi-ss-holding-hand-revenue:before {
    content: "\e864";
    }
        .fi-ss-holly-berry:before {
    content: "\e865";
    }
        .fi-ss-home:before {
    content: "\e866";
    }
        .fi-ss-home-heart:before {
    content: "\e868";
    }
        .fi-ss-home-location:before {
    content: "\e869";
    }
        .fi-ss-home-location-alt:before {
    content: "\e86a";
    }
        .fi-ss-honey-pot:before {
    content: "\e86c";
    }
        .fi-ss-hood-cloak:before {
    content: "\e86d";
    }
        .fi-ss-horizontal-rule:before {
    content: "\e86e";
    }
        .fi-ss-horse:before {
    content: "\e86f";
    }
        .fi-ss-horse-head:before {
    content: "\e870";
    }
        .fi-ss-horse-saddle:before {
    content: "\e871";
    }
        .fi-ss-horseshoe:before {
    content: "\e872";
    }
        .fi-ss-horseshoe-broken:before {
    content: "\e873";
    }
        .fi-ss-hose:before {
    content: "\e874";
    }
        .fi-ss-hose-reel:before {
    content: "\e875";
    }
        .fi-ss-hospital:before {
    content: "\e876";
    }
        .fi-ss-hospital-symbol:before {
    content: "\e877";
    }
        .fi-ss-hospital-user:before {
    content: "\e878";
    }
        .fi-ss-hospitals:before {
    content: "\e879";
    }
        .fi-ss-hot-tub:before {
    content: "\e87a";
    }
        .fi-ss-hotdog:before {
    content: "\e87b";
    }
        .fi-ss-hotel:before {
    content: "\e87c";
    }
        .fi-ss-hourglass:before {
    content: "\e87e";
    }
        .fi-ss-hourglass-end:before {
    content: "\e87f";
    }
        .fi-ss-hourglass-start:before {
    content: "\e880";
    }
        .fi-ss-house-blank:before {
    content: "\e881";
    }
        .fi-ss-house-building:before {
    content: "\e882";
    }
        .fi-ss-house-chimney:before {
    content: "\e883";
    }
        .fi-ss-house-chimney-blank:before {
    content: "\e884";
    }
        .fi-ss-house-chimney-crack:before {
    content: "\e885";
    }
        .fi-ss-house-chimney-heart:before {
    content: "\e886";
    }
        .fi-ss-house-chimney-medical:before {
    content: "\e887";
    }
        .fi-ss-house-chimney-user:before {
    content: "\e888";
    }
        .fi-ss-house-chimney-window:before {
    content: "\e889";
    }
        .fi-ss-house-circle-check:before {
    content: "\e88a";
    }
        .fi-ss-house-circle-exclamation:before {
    content: "\e88b";
    }
        .fi-ss-house-circle-xmark:before {
    content: "\e88c";
    }
        .fi-ss-house-crack:before {
    content: "\e88d";
    }
        .fi-ss-house-crack-alt:before {
    content: "\e88e";
    }
        .fi-ss-house-day:before {
    content: "\e88f";
    }
        .fi-ss-house-fire:before {
    content: "\e890";
    }
        .fi-ss-house-flag:before {
    content: "\e891";
    }
        .fi-ss-house-flood:before {
    content: "\e892";
    }
        .fi-ss-house-key:before {
    content: "\e893";
    }
        .fi-ss-house-laptop:before {
    content: "\e894";
    }
        .fi-ss-house-leave:before {
    content: "\e895";
    }
        .fi-ss-house-lock:before {
    content: "\e896";
    }
        .fi-ss-house-medical:before {
    content: "\e897";
    }
        .fi-ss-house-night:before {
    content: "\e898";
    }
        .fi-ss-house-return:before {
    content: "\e899";
    }
        .fi-ss-house-signal:before {
    content: "\e89a";
    }
        .fi-ss-house-tree:before {
    content: "\e89b";
    }
        .fi-ss-house-tsunami:before {
    content: "\e89c";
    }
        .fi-ss-house-turret:before {
    content: "\e89d";
    }
        .fi-ss-house-user:before {
    content: "\e89e";
    }
        .fi-ss-house-window:before {
    content: "\e89f";
    }
        .fi-ss-hr:before {
    content: "\e8a0";
    }
        .fi-ss-hr-group:before {
    content: "\e8a1";
    }
        .fi-ss-hr-person:before {
    content: "\e8a2";
    }
        .fi-ss-hryvnia:before {
    content: "\e8a3";
    }
        .fi-ss-humanitarian-mission:before {
    content: "\e8a7";
    }
        .fi-ss-humidity:before {
    content: "\e8a8";
    }
        .fi-ss-hundred-points:before {
    content: "\e8a9";
    }
        .fi-ss-hurricane:before {
    content: "\e8aa";
    }
        .fi-ss-hyperloop:before {
    content: "\e8ab";
    }
        .fi-ss-i:before {
    content: "\e8ad";
    }
        .fi-ss-ice-cream:before {
    content: "\e8af";
    }
        .fi-ss-icicles:before {
    content: "\e8b1";
    }
        .fi-ss-icon-star:before {
    content: "\e8b2";
    }
        .fi-ss-id-badge:before {
    content: "\e8b4";
    }
        .fi-ss-id-card-clip-alt:before {
    content: "\e8b5";
    }
        .fi-ss-idea-exchange:before {
    content: "\e8b6";
    }
        .fi-ss-igloo:before {
    content: "\e8b7";
    }
        .fi-ss-image-slash:before {
    content: "\e8ba";
    }
        .fi-ss-images:before {
    content: "\e8bb";
    }
        .fi-ss-images-user:before {
    content: "\e8bc";
    }
        .fi-ss-improve-user:before {
    content: "\e8be";
    }
        .fi-ss-inbox:before {
    content: "\e8bf";
    }
        .fi-ss-inbox-full:before {
    content: "\e8c0";
    }
        .fi-ss-inbox-in:before {
    content: "\e8c1";
    }
        .fi-ss-inbox-out:before {
    content: "\e8c2";
    }
        .fi-ss-inboxes:before {
    content: "\e8c3";
    }
        .fi-ss-incense-sticks-yoga:before {
    content: "\e8c4";
    }
        .fi-ss-incognito:before {
    content: "\e8c5";
    }
        .fi-ss-indent:before {
    content: "\e8c7";
    }
        .fi-ss-india-map:before {
    content: "\e8c9";
    }
        .fi-ss-indian-rupee-sign:before {
    content: "\e8ca";
    }
        .fi-ss-industry-alt:before {
    content: "\e8cb";
    }
        .fi-ss-industry-windows:before {
    content: "\e8cc";
    }
        .fi-ss-infinity:before {
    content: "\e8cd";
    }
        .fi-ss-info:before {
    content: "\e8ce";
    }
        .fi-ss-info-guide:before {
    content: "\e8cf";
    }
        .fi-ss-information:before {
    content: "\e8d0";
    }
        .fi-ss-inhaler:before {
    content: "\e8d1";
    }
        .fi-ss-input-numeric:before {
    content: "\e8d2";
    }
        .fi-ss-input-pipe:before {
    content: "\e8d3";
    }
        .fi-ss-input-text:before {
    content: "\e8d4";
    }
        .fi-ss-insert:before {
    content: "\e8d5";
    }
        .fi-ss-insert-alt:before {
    content: "\e8d6";
    }
        .fi-ss-insert-arrows:before {
    content: "\e8d7";
    }
        .fi-ss-insert-button-circle:before {
    content: "\e8d8";
    }
        .fi-ss-insert-credit-card:before {
    content: "\e8d9";
    }
        .fi-ss-insert-square:before {
    content: "\e8da";
    }
        .fi-ss-insight:before {
    content: "\e8db";
    }
        .fi-ss-insight-alt:before {
    content: "\e8dc";
    }
        .fi-ss-insight-head:before {
    content: "\e8dd";
    }
        .fi-ss-integral:before {
    content: "\e8e1";
    }
        .fi-ss-interactive:before {
    content: "\e8e5";
    }
        .fi-ss-interlining:before {
    content: "\e8e6";
    }
        .fi-ss-internet-speed-wifi:before {
    content: "\e8e7";
    }
        .fi-ss-interpersonal-skill:before {
    content: "\e8e8";
    }
        .fi-ss-interrogation:before {
    content: "\e8e9";
    }
        .fi-ss-intersection:before {
    content: "\e8ea";
    }
        .fi-ss-introduction:before {
    content: "\e8eb";
    }
        .fi-ss-introduction-handshake:before {
    content: "\e8ec";
    }
        .fi-ss-inventory-alt:before {
    content: "\e8ed";
    }
        .fi-ss-invest:before {
    content: "\e8ee";
    }
        .fi-ss-investment:before {
    content: "\e8ef";
    }
        .fi-ss-invite:before {
    content: "\e8f1";
    }
        .fi-ss-invite-alt:before {
    content: "\e8f2";
    }
        .fi-ss-iot:before {
    content: "\e8f3";
    }
        .fi-ss-iot-alt:before {
    content: "\e8f4";
    }
        .fi-ss-ip-address:before {
    content: "\e8f5";
    }
        .fi-ss-iron:before {
    content: "\e8f6";
    }
        .fi-ss-island-tropical:before {
    content: "\e8f7";
    }
        .fi-ss-issue-loupe:before {
    content: "\e8f8";
    }
        .fi-ss-it:before {
    content: "\e8f9";
    }
        .fi-ss-it-alt:before {
    content: "\e8fa";
    }
        .fi-ss-it-computer.:before {
    content: "\e8fc";
    }
        .fi-ss-italian:before {
    content: "\e8fd";
    }
        .fi-ss-italian-coffee-maker:before {
    content: "\e8fe";
    }
        .fi-ss-italian-lira-sign:before {
    content: "\e8ff";
    }
        .fi-ss-italic:before {
    content: "\e900";
    }
        .fi-ss-j:before {
    content: "\e902";
    }
        .fi-ss-jam:before {
    content: "\e904";
    }
        .fi-ss-japanese:before {
    content: "\e906";
    }
        .fi-ss-jar-alt:before {
    content: "\e907";
    }
        .fi-ss-jar-wheat:before {
    content: "\e908";
    }
        .fi-ss-javascript:before {
    content: "\e90a";
    }
        .fi-ss-joint:before {
    content: "\e90e";
    }
        .fi-ss-joker:before {
    content: "\e90f";
    }
        .fi-ss-journal:before {
    content: "\e911";
    }
        .fi-ss-journal-alt:before {
    content: "\e912";
    }
        .fi-ss-journey:before {
    content: "\e913";
    }
        .fi-ss-joystick:before {
    content: "\e914";
    }
        .fi-ss-jpg:before {
    content: "\e915";
    }
        .fi-ss-jug:before {
    content: "\e918";
    }
        .fi-ss-jug-alt:before {
    content: "\e919";
    }
        .fi-ss-jug-bottle:before {
    content: "\e91a";
    }
        .fi-ss-jumping-rope:before {
    content: "\e91b";
    }
        .fi-ss-k:before {
    content: "\e91c";
    }
        .fi-ss-kaaba:before {
    content: "\e91d";
    }
        .fi-ss-kangaroo:before {
    content: "\e91e";
    }
        .fi-ss-kayak:before {
    content: "\e91f";
    }
        .fi-ss-kazoo:before {
    content: "\e920";
    }
        .fi-ss-kerning:before {
    content: "\e921";
    }
        .fi-ss-key:before {
    content: "\e922";
    }
        .fi-ss-key-car:before {
    content: "\e923";
    }
        .fi-ss-key-hole:before {
    content: "\e924";
    }
        .fi-ss-key-lock-crypto:before {
    content: "\e925";
    }
        .fi-ss-key-skeleton-left-right:before {
    content: "\e926";
    }
        .fi-ss-keyboard:before {
    content: "\e927";
    }
        .fi-ss-keyboard-brightness:before {
    content: "\e928";
    }
        .fi-ss-keyboard-brightness-low:before {
    content: "\e929";
    }
        .fi-ss-keyboard-down:before {
    content: "\e92a";
    }
        .fi-ss-keyboard-left:before {
    content: "\e92b";
    }
        .fi-ss-keynote:before {
    content: "\e92c";
    }
        .fi-ss-kidneys:before {
    content: "\e92e";
    }
        .fi-ss-kip-sign:before {
    content: "\e930";
    }
        .fi-ss-kiss:before {
    content: "\e931";
    }
        .fi-ss-kiss-beam:before {
    content: "\e932";
    }
        .fi-ss-kiss-wink-heart:before {
    content: "\e933";
    }
        .fi-ss-kitchen-set:before {
    content: "\e934";
    }
        .fi-ss-kite:before {
    content: "\e935";
    }
        .fi-ss-kiwi-bird:before {
    content: "\e936";
    }
        .fi-ss-kiwi-fruit:before {
    content: "\e937";
    }
        .fi-ss-knife:before {
    content: "\e938";
    }
        .fi-ss-knife-kitchen:before {
    content: "\e939";
    }
        .fi-ss-knitting:before {
    content: "\e93a";
    }
        .fi-ss-knot-rope:before {
    content: "\e93b";
    }
        .fi-ss-korean:before {
    content: "\e93c";
    }
        .fi-ss-kpi:before {
    content: "\e93d";
    }
        .fi-ss-kpi-evaluation:before {
    content: "\e93e";
    }
        .fi-ss-l:before {
    content: "\e93f";
    }
        .fi-ss-lab-coat:before {
    content: "\e940";
    }
        .fi-ss-label:before {
    content: "\e941";
    }
        .fi-ss-lacrosse-stick:before {
    content: "\e942";
    }
        .fi-ss-lacrosse-stick-ball:before {
    content: "\e943";
    }
        .fi-ss-lambda:before {
    content: "\e944";
    }
        .fi-ss-lamp:before {
    content: "\e945";
    }
        .fi-ss-lamp-desk:before {
    content: "\e946";
    }
        .fi-ss-lamp-floor:before {
    content: "\e947";
    }
        .fi-ss-lamp-street:before {
    content: "\e948";
    }
        .fi-ss-land-layer-location:before {
    content: "\e949";
    }
        .fi-ss-land-layers:before {
    content: "\e94a";
    }
        .fi-ss-land-location:before {
    content: "\e94b";
    }
        .fi-ss-land-mine-on:before {
    content: "\e94c";
    }
        .fi-ss-landmark-alt:before {
    content: "\e94d";
    }
        .fi-ss-language:before {
    content: "\e94e";
    }
        .fi-ss-language-exchange:before {
    content: "\e94f";
    }
        .fi-ss-laptop:before {
    content: "\e950";
    }
        .fi-ss-laptop-arrow-down:before {
    content: "\e951";
    }
        .fi-ss-laptop-binary:before {
    content: "\e952";
    }
        .fi-ss-laptop-code:before {
    content: "\e953";
    }
        .fi-ss-laptop-medical:before {
    content: "\e954";
    }
        .fi-ss-laptop-mobile:before {
    content: "\e955";
    }
        .fi-ss-laptop-slash:before {
    content: "\e956";
    }
        .fi-ss-lari-sign:before {
    content: "\e957";
    }
        .fi-ss-lasso:before {
    content: "\e958";
    }
        .fi-ss-lasso-sparkles:before {
    content: "\e959";
    }
        .fi-ss-last-square:before {
    content: "\e95a";
    }
        .fi-ss-laugh:before {
    content: "\e95b";
    }
        .fi-ss-laugh-beam:before {
    content: "\e95c";
    }
        .fi-ss-laugh-squint:before {
    content: "\e95d";
    }
        .fi-ss-laugh-wink:before {
    content: "\e95e";
    }
        .fi-ss-laurel-user:before {
    content: "\e95f";
    }
        .fi-ss-lawyer-man:before {
    content: "\e960";
    }
        .fi-ss-lawyer-woman:before {
    content: "\e961";
    }
        .fi-ss-layer-minus:before {
    content: "\e962";
    }
        .fi-ss-layer-plus:before {
    content: "\e963";
    }
        .fi-ss-layers:before {
    content: "\e964";
    }
        .fi-ss-layout-fluid:before {
    content: "\e965";
    }
        .fi-ss-lead:before {
    content: "\e966";
    }
        .fi-ss-lead-funnel:before {
    content: "\e967";
    }
        .fi-ss-lead-management:before {
    content: "\e968";
    }
        .fi-ss-leader:before {
    content: "\e969";
    }
        .fi-ss-leader-alt:before {
    content: "\e96a";
    }
        .fi-ss-leader-speech:before {
    content: "\e96b";
    }
        .fi-ss-leaderboard:before {
    content: "\e96c";
    }
        .fi-ss-leaderboard-alt:before {
    content: "\e96d";
    }
        .fi-ss-leaderboard-trophy:before {
    content: "\e96e";
    }
        .fi-ss-leadership:before {
    content: "\e96f";
    }
        .fi-ss-leadership-alt:before {
    content: "\e970";
    }
        .fi-ss-leaf:before {
    content: "\e971";
    }
        .fi-ss-leaf-heart:before {
    content: "\e972";
    }
        .fi-ss-leaf-maple:before {
    content: "\e973";
    }
        .fi-ss-leaf-oak:before {
    content: "\e974";
    }
        .fi-ss-leafy-green:before {
    content: "\e975";
    }
        .fi-ss-leave:before {
    content: "\e976";
    }
        .fi-ss-left:before {
    content: "\e977";
    }
        .fi-ss-left-from-bracket:before {
    content: "\e978";
    }
        .fi-ss-legal:before {
    content: "\e979";
    }
        .fi-ss-legal-case:before {
    content: "\e97a";
    }
        .fi-ss-lemon:before {
    content: "\e97b";
    }
        .fi-ss-lesbian-couple:before {
    content: "\e97c";
    }
        .fi-ss-less-than:before {
    content: "\e97d";
    }
        .fi-ss-less-than-equal:before {
    content: "\e97e";
    }
        .fi-ss-lesson:before {
    content: "\e97f";
    }
        .fi-ss-lesson-class:before {
    content: "\e980";
    }
        .fi-ss-letter-case:before {
    content: "\e981";
    }
        .fi-ss-lettuce:before {
    content: "\e982";
    }
        .fi-ss-level-down:before {
    content: "\e983";
    }
        .fi-ss-level-down-alt:before {
    content: "\e984";
    }
        .fi-ss-level-up:before {
    content: "\e985";
    }
        .fi-ss-level-up-alt:before {
    content: "\e986";
    }
        .fi-ss-license:before {
    content: "\e987";
    }
        .fi-ss-life:before {
    content: "\e988";
    }
        .fi-ss-life-ring:before {
    content: "\e989";
    }
        .fi-ss-light-ceiling:before {
    content: "\e98a";
    }
        .fi-ss-light-emergency:before {
    content: "\e98b";
    }
        .fi-ss-light-emergency-on:before {
    content: "\e98c";
    }
        .fi-ss-light-switch:before {
    content: "\e98d";
    }
        .fi-ss-light-switch-off:before {
    content: "\e98e";
    }
        .fi-ss-light-switch-on:before {
    content: "\e98f";
    }
        .fi-ss-lightbulb-cfl:before {
    content: "\e990";
    }
        .fi-ss-lightbulb-cfl-on:before {
    content: "\e991";
    }
        .fi-ss-lightbulb-dollar:before {
    content: "\e993";
    }
        .fi-ss-lightbulb-exclamation:before {
    content: "\e994";
    }
        .fi-ss-lightbulb-head:before {
    content: "\e997";
    }
        .fi-ss-lightbulb-on:before {
    content: "\e998";
    }
        .fi-ss-lightbulb-question:before {
    content: "\e999";
    }
        .fi-ss-lightbulb-setting:before {
    content: "\e99a";
    }
        .fi-ss-lightbulb-slash:before {
    content: "\e99b";
    }
        .fi-ss-lighthouse:before {
    content: "\e99d";
    }
        .fi-ss-lights-holiday:before {
    content: "\e99e";
    }
        .fi-ss-limit-hand:before {
    content: "\e99f";
    }
        .fi-ss-limit-speedometer:before {
    content: "\e9a0";
    }
        .fi-ss-line-width:before {
    content: "\e9a2";
    }
        .fi-ss-link:before {
    content: "\e9a3";
    }
        .fi-ss-link-alt:before {
    content: "\e9a4";
    }
        .fi-ss-link-horizontal:before {
    content: "\e9a5";
    }
        .fi-ss-link-horizontal-slash:before {
    content: "\e9a6";
    }
        .fi-ss-link-slash:before {
    content: "\e9a7";
    }
        .fi-ss-link-slash-alt:before {
    content: "\e9a8";
    }
        .fi-ss-lion:before {
    content: "\e9aa";
    }
        .fi-ss-lion-head:before {
    content: "\e9ab";
    }
        .fi-ss-lips:before {
    content: "\e9ac";
    }
        .fi-ss-lips-silence:before {
    content: "\e9ad";
    }
        .fi-ss-lipstick:before {
    content: "\e9ae";
    }
        .fi-ss-lira-sign:before {
    content: "\e9af";
    }
        .fi-ss-list:before {
    content: "\e9b1";
    }
        .fi-ss-list-check:before {
    content: "\e9b2";
    }
        .fi-ss-list-dropdown:before {
    content: "\e9b3";
    }
        .fi-ss-list-music:before {
    content: "\e9b4";
    }
        .fi-ss-list-timeline:before {
    content: "\e9b5";
    }
        .fi-ss-litecoin-sign:before {
    content: "\e9b7";
    }
        .fi-ss-live:before {
    content: "\e9b8";
    }
        .fi-ss-live-alt:before {
    content: "\e9b9";
    }
        .fi-ss-loading:before {
    content: "\e9ba";
    }
        .fi-ss-loan:before {
    content: "\e9bb";
    }
        .fi-ss-lobster:before {
    content: "\e9bc";
    }
        .fi-ss-location-alt:before {
    content: "\e9be";
    }
        .fi-ss-location-arrow:before {
    content: "\e9bf";
    }
        .fi-ss-location-crosshairs:before {
    content: "\e9c0";
    }
        .fi-ss-location-crosshairs-slash:before {
    content: "\e9c1";
    }
        .fi-ss-location-dot-slash:before {
    content: "\e9c2";
    }
        .fi-ss-location-exclamation:before {
    content: "\e9c3";
    }
        .fi-ss-location-pin-call:before {
    content: "\e9c4";
    }
        .fi-ss-lock:before {
    content: "\e9c5";
    }
        .fi-ss-lock-alt:before {
    content: "\e9c6";
    }
        .fi-ss-lock-hashtag:before {
    content: "\e9c7";
    }
        .fi-ss-lock-open-alt:before {
    content: "\e9c8";
    }
        .fi-ss-locust:before {
    content: "\e9c9";
    }
        .fi-ss-loop-square:before {
    content: "\e9cc";
    }
        .fi-ss-loveseat:before {
    content: "\e9ce";
    }
        .fi-ss-low-vision:before {
    content: "\e9cf";
    }
        .fi-ss-luchador:before {
    content: "\e9d0";
    }
        .fi-ss-luggage-cart:before {
    content: "\e9d1";
    }
        .fi-ss-luggage-rolling:before {
    content: "\e9d2";
    }
        .fi-ss-lungs:before {
    content: "\e9d3";
    }
        .fi-ss-lungs-virus:before {
    content: "\e9d4";
    }
        .fi-ss-m:before {
    content: "\e9d5";
    }
        .fi-ss-mace:before {
    content: "\e9d6";
    }
        .fi-ss-magnet:before {
    content: "\e9dd";
    }
        .fi-ss-magnet-user:before {
    content: "\e9de";
    }
        .fi-ss-magnifying-glass-binary:before {
    content: "\e9e0";
    }
        .fi-ss-magnifying-glass-eye:before {
    content: "\e9e1";
    }
        .fi-ss-magnifying-glass-wave:before {
    content: "\e9e2";
    }
        .fi-ss-mail-plus-circle:before {
    content: "\e9e4";
    }
        .fi-ss-mailbox:before {
    content: "\e9e5";
    }
        .fi-ss-mailbox-envelope:before {
    content: "\e9e6";
    }
        .fi-ss-mailbox-flag-up:before {
    content: "\e9e7";
    }
        .fi-ss-makeup-brush:before {
    content: "\e9e9";
    }
        .fi-ss-man-head:before {
    content: "\e9ec";
    }
        .fi-ss-man-scientist:before {
    content: "\e9ed";
    }
        .fi-ss-manat-sign:before {
    content: "\e9ee";
    }
        .fi-ss-mandolin:before {
    content: "\e9ef";
    }
        .fi-ss-mango:before {
    content: "\e9f0";
    }
        .fi-ss-manhole:before {
    content: "\e9f1";
    }
        .fi-ss-map:before {
    content: "\e9f2";
    }
        .fi-ss-map-location-track:before {
    content: "\e9f3";
    }
        .fi-ss-map-marker:before {
    content: "\e9f4";
    }
        .fi-ss-map-marker-check:before {
    content: "\e9f5";
    }
        .fi-ss-map-marker-cross:before {
    content: "\e9f6";
    }
        .fi-ss-map-marker-edit:before {
    content: "\e9f7";
    }
        .fi-ss-map-marker-home:before {
    content: "\e9f9";
    }
        .fi-ss-map-marker-minus:before {
    content: "\e9fa";
    }
        .fi-ss-map-marker-plus:before {
    content: "\e9fb";
    }
        .fi-ss-map-marker-question:before {
    content: "\e9fc";
    }
        .fi-ss-map-marker-slash:before {
    content: "\e9fd";
    }
        .fi-ss-map-marker-smile:before {
    content: "\e9fe";
    }
        .fi-ss-map-pin:before {
    content: "\e9ff";
    }
        .fi-ss-map-point:before {
    content: "\ea00";
    }
        .fi-ss-marker:before {
    content: "\ea01";
    }
        .fi-ss-marker-time:before {
    content: "\ea02";
    }
        .fi-ss-marketplace:before {
    content: "\ea03";
    }
        .fi-ss-marketplace-alt:before {
    content: "\ea04";
    }
        .fi-ss-marketplace-store:before {
    content: "\ea05";
    }
        .fi-ss-marriage-proposal:before {
    content: "\ea06";
    }
        .fi-ss-mars:before {
    content: "\ea08";
    }
        .fi-ss-mars-double:before {
    content: "\ea0a";
    }
        .fi-ss-mars-stroke-right:before {
    content: "\ea0b";
    }
        .fi-ss-mars-stroke-up:before {
    content: "\ea0c";
    }
        .fi-ss-martini-glass-citrus:before {
    content: "\ea0d";
    }
        .fi-ss-martini-glass-empty:before {
    content: "\ea0e";
    }
        .fi-ss-mask-carnival:before {
    content: "\ea10";
    }
        .fi-ss-mask-face:before {
    content: "\ea11";
    }
        .fi-ss-mask-snorkel:before {
    content: "\ea12";
    }
        .fi-ss-massage:before {
    content: "\ea13";
    }
        .fi-ss-master-plan:before {
    content: "\ea14";
    }
        .fi-ss-master-plan-integrate:before {
    content: "\ea15";
    }
        .fi-ss-match-fire:before {
    content: "\ea16";
    }
        .fi-ss-mattress-pillow:before {
    content: "\ea17";
    }
        .fi-ss-measuring-tape:before {
    content: "\ea19";
    }
        .fi-ss-meat:before {
    content: "\ea1a";
    }
        .fi-ss-medal:before {
    content: "\ea1b";
    }
        .fi-ss-medical-star:before {
    content: "\ea1d";
    }
        .fi-ss-medicine:before {
    content: "\ea1e";
    }
        .fi-ss-meditation:before {
    content: "\ea1f";
    }
        .fi-ss-meeting:before {
    content: "\ea21";
    }
        .fi-ss-meeting-alt:before {
    content: "\ea22";
    }
        .fi-ss-megaphone:before {
    content: "\ea23";
    }
        .fi-ss-megaphone-announcement-leader:before {
    content: "\ea24";
    }
        .fi-ss-megaphone-sound-waves:before {
    content: "\ea25";
    }
        .fi-ss-meh:before {
    content: "\ea26";
    }
        .fi-ss-meh-blank:before {
    content: "\ea27";
    }
        .fi-ss-meh-rolling-eyes:before {
    content: "\ea28";
    }
        .fi-ss-melon:before {
    content: "\ea29";
    }
        .fi-ss-melon-alt:before {
    content: "\ea2a";
    }
        .fi-ss-member-list:before {
    content: "\ea2b";
    }
        .fi-ss-member-search:before {
    content: "\ea2c";
    }
        .fi-ss-membership:before {
    content: "\ea2d";
    }
        .fi-ss-membership-vip:before {
    content: "\ea2e";
    }
        .fi-ss-memo:before {
    content: "\ea2f";
    }
        .fi-ss-memo-circle-check:before {
    content: "\ea30";
    }
        .fi-ss-memo-pad:before {
    content: "\ea31";
    }
        .fi-ss-memory:before {
    content: "\ea32";
    }
        .fi-ss-menu-burger:before {
    content: "\ea33";
    }
        .fi-ss-menu-dots:before {
    content: "\ea34";
    }
        .fi-ss-menu-dots-vertical:before {
    content: "\ea35";
    }
        .fi-ss-mercury:before {
    content: "\ea36";
    }
        .fi-ss-message-alert:before {
    content: "\ea37";
    }
        .fi-ss-message-arrow-down:before {
    content: "\ea38";
    }
        .fi-ss-message-arrow-up:before {
    content: "\ea39";
    }
        .fi-ss-message-arrow-up-right:before {
    content: "\ea3a";
    }
        .fi-ss-message-bot:before {
    content: "\ea3b";
    }
        .fi-ss-message-code:before {
    content: "\ea3c";
    }
        .fi-ss-message-dollar:before {
    content: "\ea3d";
    }
        .fi-ss-message-heart:before {
    content: "\ea3e";
    }
        .fi-ss-message-image:before {
    content: "\ea3f";
    }
        .fi-ss-message-question:before {
    content: "\ea41";
    }
        .fi-ss-message-quote:before {
    content: "\ea42";
    }
        .fi-ss-message-slash:before {
    content: "\ea43";
    }
        .fi-ss-message-sms:before {
    content: "\ea44";
    }
        .fi-ss-message-star:before {
    content: "\ea45";
    }
        .fi-ss-message-text:before {
    content: "\ea46";
    }
        .fi-ss-message-xmark:before {
    content: "\ea47";
    }
        .fi-ss-messages:before {
    content: "\ea48";
    }
        .fi-ss-messages-dollar:before {
    content: "\ea49";
    }
        .fi-ss-messages-question:before {
    content: "\ea4a";
    }
        .fi-ss-meteor:before {
    content: "\ea4c";
    }
        .fi-ss-meter:before {
    content: "\ea4d";
    }
        .fi-ss-meter-bolt:before {
    content: "\ea4e";
    }
        .fi-ss-meter-droplet:before {
    content: "\ea4f";
    }
        .fi-ss-meter-fire:before {
    content: "\ea50";
    }
        .fi-ss-method:before {
    content: "\ea51";
    }
        .fi-ss-microchip:before {
    content: "\ea52";
    }
        .fi-ss-microchip-ai:before {
    content: "\ea53";
    }
        .fi-ss-microphone:before {
    content: "\ea54";
    }
        .fi-ss-microphone-alt:before {
    content: "\ea55";
    }
        .fi-ss-microphone-slash:before {
    content: "\ea58";
    }
        .fi-ss-microscope:before {
    content: "\ea59";
    }
        .fi-ss-microwave:before {
    content: "\ea5d";
    }
        .fi-ss-milk:before {
    content: "\ea5e";
    }
        .fi-ss-milk-alt:before {
    content: "\ea5f";
    }
        .fi-ss-mill:before {
    content: "\ea60";
    }
        .fi-ss-mill-sign:before {
    content: "\ea61";
    }
        .fi-ss-mind-share:before {
    content: "\ea62";
    }
        .fi-ss-minus:before {
    content: "\ea63";
    }
        .fi-ss-minus-circle:before {
    content: "\ea64";
    }
        .fi-ss-minus-hexagon:before {
    content: "\ea65";
    }
        .fi-ss-minus-small:before {
    content: "\ea66";
    }
        .fi-ss-mirror:before {
    content: "\ea68";
    }
        .fi-ss-mirror-user:before {
    content: "\ea69";
    }
        .fi-ss-mistletoe:before {
    content: "\ea6a";
    }
        .fi-ss-mix:before {
    content: "\ea6b";
    }
        .fi-ss-mixer:before {
    content: "\ea6c";
    }
        .fi-ss-mobile:before {
    content: "\ea6d";
    }
        .fi-ss-mobile-4g:before {
    content: "\ea6e";
    }
        .fi-ss-mobile-5g:before {
    content: "\ea6f";
    }
        .fi-ss-mobile-button:before {
    content: "\ea71";
    }
        .fi-ss-mobile-hand:before {
    content: "\ea72";
    }
        .fi-ss-mobile-message:before {
    content: "\ea73";
    }
        .fi-ss-mobile-notch:before {
    content: "\ea74";
    }
        .fi-ss-mockup:before {
    content: "\ea77";
    }
        .fi-ss-mode:before {
    content: "\ea78";
    }
        .fi-ss-mode-alt:before {
    content: "\ea79";
    }
        .fi-ss-mode-landscape:before {
    content: "\ea7a";
    }
        .fi-ss-mode-portrait:before {
    content: "\ea7b";
    }
        .fi-ss-model-cube:before {
    content: "\ea7c";
    }
        .fi-ss-model-cube-arrows:before {
    content: "\ea7d";
    }
        .fi-ss-model-cube-space:before {
    content: "\ea7e";
    }
        .fi-ss-module:before {
    content: "\ea7f";
    }
        .fi-ss-monday:before {
    content: "\ea80";
    }
        .fi-ss-money:before {
    content: "\ea81";
    }
        .fi-ss-money-bill-simple:before {
    content: "\ea82";
    }
        .fi-ss-money-bill-transfer:before {
    content: "\ea83";
    }
        .fi-ss-money-bill-wave:before {
    content: "\ea84";
    }
        .fi-ss-money-bill-wave-alt:before {
    content: "\ea85";
    }
        .fi-ss-money-bills:before {
    content: "\ea86";
    }
        .fi-ss-money-bills-simple:before {
    content: "\ea87";
    }
        .fi-ss-money-check:before {
    content: "\ea88";
    }
        .fi-ss-money-check-edit:before {
    content: "\ea89";
    }
        .fi-ss-money-check-edit-alt:before {
    content: "\ea8a";
    }
        .fi-ss-money-coin-transfer:before {
    content: "\ea8b";
    }
        .fi-ss-money-from-bracket:before {
    content: "\ea8c";
    }
        .fi-ss-money-gears:before {
    content: "\ea8d";
    }
        .fi-ss-money-income:before {
    content: "\ea8e";
    }
        .fi-ss-money-simple-from-bracket:before {
    content: "\ea8f";
    }
        .fi-ss-money-transfer-alt:before {
    content: "\ea90";
    }
        .fi-ss-money-transfer-coin-arrow:before {
    content: "\ea91";
    }
        .fi-ss-money-transfer-smartphone:before {
    content: "\ea92";
    }
        .fi-ss-money-wings:before {
    content: "\ea93";
    }
        .fi-ss-monkey:before {
    content: "\ea94";
    }
        .fi-ss-monument:before {
    content: "\ea95";
    }
        .fi-ss-moon:before {
    content: "\ea96";
    }
        .fi-ss-moon-stars:before {
    content: "\ea97";
    }
        .fi-ss-moped:before {
    content: "\ea98";
    }
        .fi-ss-mortar-pestle:before {
    content: "\ea99";
    }
        .fi-ss-mortgage:before {
    content: "\ea9a";
    }
        .fi-ss-mosque:before {
    content: "\ea9b";
    }
        .fi-ss-mosque-alt:before {
    content: "\ea9c";
    }
        .fi-ss-mosque-moon:before {
    content: "\ea9d";
    }
        .fi-ss-mosquito:before {
    content: "\ea9e";
    }
        .fi-ss-mosquito-net:before {
    content: "\ea9f";
    }
        .fi-ss-motorcycle:before {
    content: "\eaa2";
    }
        .fi-ss-mound:before {
    content: "\eaa3";
    }
        .fi-ss-mountain:before {
    content: "\eaa4";
    }
        .fi-ss-mountain-city:before {
    content: "\eaa5";
    }
        .fi-ss-mountains:before {
    content: "\eaa7";
    }
        .fi-ss-mouse:before {
    content: "\eaa8";
    }
        .fi-ss-mouse-field:before {
    content: "\eaa9";
    }
        .fi-ss-mouse-pointer-heart:before {
    content: "\eaaa";
    }
        .fi-ss-mov-file:before {
    content: "\eaab";
    }
        .fi-ss-move-to-folder:before {
    content: "\eaac";
    }
        .fi-ss-move-to-folder-2:before {
    content: "\eaad";
    }
        .fi-ss-moving:before {
    content: "\eaae";
    }
        .fi-ss-mower:before {
    content: "\eaaf";
    }
        .fi-ss-mp3-file:before {
    content: "\eab0";
    }
        .fi-ss-mp3-player:before {
    content: "\eab1";
    }
        .fi-ss-mp4-file:before {
    content: "\eab2";
    }
        .fi-ss-mug:before {
    content: "\eab3";
    }
        .fi-ss-mug-alt:before {
    content: "\eab4";
    }
        .fi-ss-mug-hot:before {
    content: "\eab5";
    }
        .fi-ss-mug-hot-alt:before {
    content: "\eab6";
    }
        .fi-ss-mug-marshmallows:before {
    content: "\eab7";
    }
        .fi-ss-mug-tea:before {
    content: "\eab8";
    }
        .fi-ss-mug-tea-alt:before {
    content: "\eab9";
    }
        .fi-ss-mug-tea-saucer:before {
    content: "\eaba";
    }
        .fi-ss-multiple:before {
    content: "\eabb";
    }
        .fi-ss-multiple-alt:before {
    content: "\eabc";
    }
        .fi-ss-multitasking:before {
    content: "\eabd";
    }
        .fi-ss-muscle:before {
    content: "\eabe";
    }
        .fi-ss-mushroom:before {
    content: "\eabf";
    }
        .fi-ss-mushroom-alt:before {
    content: "\eac0";
    }
        .fi-ss-mushroom-cloud:before {
    content: "\eac1";
    }
        .fi-ss-music:before {
    content: "\eac2";
    }
        .fi-ss-music-alt:before {
    content: "\eac3";
    }
        .fi-ss-music-file:before {
    content: "\eac5";
    }
        .fi-ss-music-magnifying-glass:before {
    content: "\eac6";
    }
        .fi-ss-music-note:before {
    content: "\eac7";
    }
        .fi-ss-music-note-slash:before {
    content: "\eac8";
    }
        .fi-ss-music-slash:before {
    content: "\eac9";
    }
        .fi-ss-n:before {
    content: "\eacb";
    }
        .fi-ss-naira-sign:before {
    content: "\eacc";
    }
        .fi-ss-narwhal:before {
    content: "\eace";
    }
        .fi-ss-navigation:before {
    content: "\eacf";
    }
        .fi-ss-nesting-dolls:before {
    content: "\ead0";
    }
        .fi-ss-network:before {
    content: "\ead3";
    }
        .fi-ss-network-analytic:before {
    content: "\ead4";
    }
        .fi-ss-network-cloud:before {
    content: "\ead5";
    }
        .fi-ss-network-cloud-computer:before {
    content: "\ead6";
    }
        .fi-ss-network-user:before {
    content: "\ead7";
    }
        .fi-ss-neuter:before {
    content: "\ead8";
    }
        .fi-ss-newsletter-subscribe:before {
    content: "\eadb";
    }
        .fi-ss-newspaper:before {
    content: "\eadc";
    }
        .fi-ss-newspaper-open:before {
    content: "\eade";
    }
        .fi-ss-nfc:before {
    content: "\eadf";
    }
        .fi-ss-nfc-lock:before {
    content: "\eae0";
    }
        .fi-ss-nfc-magnifying-glass:before {
    content: "\eae1";
    }
        .fi-ss-nfc-pen:before {
    content: "\eae2";
    }
        .fi-ss-nfc-slash:before {
    content: "\eae4";
    }
        .fi-ss-nfc-trash:before {
    content: "\eae5";
    }
        .fi-ss-nft-square:before {
    content: "\eae8";
    }
        .fi-ss-night-day:before {
    content: "\eae9";
    }
        .fi-ss-no-attention:before {
    content: "\eaea";
    }
        .fi-ss-no-fee:before {
    content: "\eaeb";
    }
        .fi-ss-no-food:before {
    content: "\eaec";
    }
        .fi-ss-no-iron:before {
    content: "\eaed";
    }
        .fi-ss-no-people:before {
    content: "\eaee";
    }
        .fi-ss-no-smoking:before {
    content: "\eaef";
    }
        .fi-ss-noise-cancelling-headphones:before {
    content: "\eaf2";
    }
        .fi-ss-noodles:before {
    content: "\eaf3";
    }
        .fi-ss-nose:before {
    content: "\eaf4";
    }
        .fi-ss-not-equal:before {
    content: "\eaf5";
    }
        .fi-ss-not-found:before {
    content: "\eaf6";
    }
        .fi-ss-not-found-alt:before {
    content: "\eaf7";
    }
        .fi-ss-not-found-magnifying-glass:before {
    content: "\eaf8";
    }
        .fi-ss-notdef:before {
    content: "\eaf9";
    }
        .fi-ss-note:before {
    content: "\eafa";
    }
        .fi-ss-note-medical:before {
    content: "\eafb";
    }
        .fi-ss-note-sticky:before {
    content: "\eafc";
    }
        .fi-ss-notebook:before {
    content: "\eafd";
    }
        .fi-ss-notebook-alt:before {
    content: "\eafe";
    }
        .fi-ss-notes:before {
    content: "\eaff";
    }
        .fi-ss-notes-medical:before {
    content: "\eb00";
    }
        .fi-ss-o:before {
    content: "\eb09";
    }
        .fi-ss-object-exclude:before {
    content: "\eb0a";
    }
        .fi-ss-object-group:before {
    content: "\eb0b";
    }
        .fi-ss-object-intersect:before {
    content: "\eb0c";
    }
        .fi-ss-object-subtract:before {
    content: "\eb0d";
    }
        .fi-ss-object-ungroup:before {
    content: "\eb0e";
    }
        .fi-ss-object-union:before {
    content: "\eb0f";
    }
        .fi-ss-objects-column:before {
    content: "\eb11";
    }
        .fi-ss-octagon:before {
    content: "\eb12";
    }
        .fi-ss-octagon-check:before {
    content: "\eb13";
    }
        .fi-ss-octagon-divide:before {
    content: "\eb14";
    }
        .fi-ss-octagon-exclamation:before {
    content: "\eb15";
    }
        .fi-ss-octagon-minus:before {
    content: "\eb16";
    }
        .fi-ss-octagon-plus:before {
    content: "\eb17";
    }
        .fi-ss-octagon-xmark:before {
    content: "\eb18";
    }
        .fi-ss-office-chair:before {
    content: "\eb1a";
    }
        .fi-ss-oil-can:before {
    content: "\eb1b";
    }
        .fi-ss-oil-temp:before {
    content: "\eb1c";
    }
        .fi-ss-old-people:before {
    content: "\eb1e";
    }
        .fi-ss-olive:before {
    content: "\eb1f";
    }
        .fi-ss-olive-branch-dove:before {
    content: "\eb20";
    }
        .fi-ss-olive-oil:before {
    content: "\eb21";
    }
        .fi-ss-olives:before {
    content: "\eb22";
    }
        .fi-ss-om:before {
    content: "\eb23";
    }
        .fi-ss-omega:before {
    content: "\eb24";
    }
        .fi-ss-on-air-square:before {
    content: "\eb25";
    }
        .fi-ss-onboarding:before {
    content: "\eb26";
    }
        .fi-ss-onion:before {
    content: "\eb27";
    }
        .fi-ss-opacity:before {
    content: "\eb28";
    }
        .fi-ss-open-mail-clip:before {
    content: "\eb29";
    }
        .fi-ss-operating-system-upgrade:before {
    content: "\eb2a";
    }
        .fi-ss-operation:before {
    content: "\eb2b";
    }
        .fi-ss-order-history:before {
    content: "\eb2e";
    }
        .fi-ss-organization-chart:before {
    content: "\eb2f";
    }
        .fi-ss-ornament:before {
    content: "\eb30";
    }
        .fi-ss-otp:before {
    content: "\eb31";
    }
        .fi-ss-otter:before {
    content: "\eb32";
    }
        .fi-ss-outdent:before {
    content: "\eb34";
    }
        .fi-ss-oval:before {
    content: "\eb35";
    }
        .fi-ss-oval-alt:before {
    content: "\eb36";
    }
        .fi-ss-oven:before {
    content: "\eb37";
    }
        .fi-ss-overline:before {
    content: "\eb38";
    }
        .fi-ss-overview:before {
    content: "\eb39";
    }
        .fi-ss-p:before {
    content: "\eb3a";
    }
        .fi-ss-package:before {
    content: "\eb3b";
    }
        .fi-ss-padlock-check:before {
    content: "\eb3c";
    }
        .fi-ss-page-break:before {
    content: "\eb3d";
    }
        .fi-ss-pager:before {
    content: "\eb3e";
    }
        .fi-ss-paid:before {
    content: "\eb3f";
    }
        .fi-ss-paint:before {
    content: "\eb40";
    }
        .fi-ss-paint-roller:before {
    content: "\eb42";
    }
        .fi-ss-paintbrush-pencil:before {
    content: "\eb44";
    }
        .fi-ss-palette:before {
    content: "\eb45";
    }
        .fi-ss-pallet:before {
    content: "\eb46";
    }
        .fi-ss-pallet-alt:before {
    content: "\eb47";
    }
        .fi-ss-pan:before {
    content: "\eb48";
    }
        .fi-ss-pan-food:before {
    content: "\eb49";
    }
        .fi-ss-pan-frying:before {
    content: "\eb4a";
    }
        .fi-ss-pancakes:before {
    content: "\eb4b";
    }
        .fi-ss-panorama:before {
    content: "\eb4f";
    }
        .fi-ss-paper-plane:before {
    content: "\eb50";
    }
        .fi-ss-paper-plane-launch:before {
    content: "\eb51";
    }
        .fi-ss-paper-plane-top:before {
    content: "\eb52";
    }
        .fi-ss-paperclip-vertical:before {
    content: "\eb53";
    }
        .fi-ss-parachute-box:before {
    content: "\eb54";
    }
        .fi-ss-paragraph:before {
    content: "\eb55";
    }
        .fi-ss-paragraph-left:before {
    content: "\eb56";
    }
        .fi-ss-parking:before {
    content: "\eb57";
    }
        .fi-ss-parking-circle:before {
    content: "\eb58";
    }
        .fi-ss-parking-circle-slash:before {
    content: "\eb59";
    }
        .fi-ss-parking-slash:before {
    content: "\eb5a";
    }
        .fi-ss-party:before {
    content: "\eb5c";
    }
        .fi-ss-party-bell:before {
    content: "\eb5d";
    }
        .fi-ss-party-horn:before {
    content: "\eb5e";
    }
        .fi-ss-passenger-plane:before {
    content: "\eb5f";
    }
        .fi-ss-passport:before {
    content: "\eb60";
    }
        .fi-ss-password:before {
    content: "\eb61";
    }
        .fi-ss-password-alt:before {
    content: "\eb62";
    }
        .fi-ss-password-computer:before {
    content: "\eb63";
    }
        .fi-ss-password-email:before {
    content: "\eb64";
    }
        .fi-ss-password-lock:before {
    content: "\eb65";
    }
        .fi-ss-password-smartphone:before {
    content: "\eb66";
    }
        .fi-ss-paste:before {
    content: "\eb67";
    }
        .fi-ss-pattern:before {
    content: "\eb69";
    }
        .fi-ss-pause:before {
    content: "\eb6a";
    }
        .fi-ss-pause-circle:before {
    content: "\eb6b";
    }
        .fi-ss-pause-square:before {
    content: "\eb6c";
    }
        .fi-ss-paw:before {
    content: "\eb6d";
    }
        .fi-ss-paw-claws:before {
    content: "\eb6e";
    }
        .fi-ss-paw-heart:before {
    content: "\eb6f";
    }
        .fi-ss-payment-pos:before {
    content: "\eb70";
    }
        .fi-ss-payroll:before {
    content: "\eb72";
    }
        .fi-ss-payroll-calendar:before {
    content: "\eb73";
    }
        .fi-ss-payroll-check:before {
    content: "\eb74";
    }
        .fi-ss-peace:before {
    content: "\eb75";
    }
        .fi-ss-peach:before {
    content: "\eb76";
    }
        .fi-ss-peanut:before {
    content: "\eb77";
    }
        .fi-ss-peanuts:before {
    content: "\eb78";
    }
        .fi-ss-peapod:before {
    content: "\eb79";
    }
        .fi-ss-pear:before {
    content: "\eb7a";
    }
        .fi-ss-pedestal:before {
    content: "\eb7b";
    }
        .fi-ss-pen-circle:before {
    content: "\eb7c";
    }
        .fi-ss-pen-clip:before {
    content: "\eb7d";
    }
        .fi-ss-pen-clip-slash:before {
    content: "\eb7e";
    }
        .fi-ss-pen-fancy:before {
    content: "\eb7f";
    }
        .fi-ss-pen-fancy-slash:before {
    content: "\eb80";
    }
        .fi-ss-pen-field:before {
    content: "\eb81";
    }
        .fi-ss-pen-nib:before {
    content: "\eb82";
    }
        .fi-ss-pen-nib-slash:before {
    content: "\eb83";
    }
        .fi-ss-pen-slash:before {
    content: "\eb84";
    }
        .fi-ss-pen-square:before {
    content: "\eb85";
    }
        .fi-ss-pen-swirl:before {
    content: "\eb86";
    }
        .fi-ss-pencil:before {
    content: "\eb87";
    }
        .fi-ss-pencil-paintbrush:before {
    content: "\eb88";
    }
        .fi-ss-pencil-ruler:before {
    content: "\eb89";
    }
        .fi-ss-pencil-slash:before {
    content: "\eb8a";
    }
        .fi-ss-pending:before {
    content: "\eb8b";
    }
        .fi-ss-pennant:before {
    content: "\eb8c";
    }
        .fi-ss-people:before {
    content: "\eb8d";
    }
        .fi-ss-people-arrows-left-right:before {
    content: "\eb8e";
    }
        .fi-ss-people-carry-box:before {
    content: "\eb8f";
    }
        .fi-ss-people-dress:before {
    content: "\eb90";
    }
        .fi-ss-people-network-partner:before {
    content: "\eb93";
    }
        .fi-ss-people-pants:before {
    content: "\eb94";
    }
        .fi-ss-people-poll:before {
    content: "\eb95";
    }
        .fi-ss-people-pulling:before {
    content: "\eb96";
    }
        .fi-ss-people-roof:before {
    content: "\eb98";
    }
        .fi-ss-pepper:before {
    content: "\eb99";
    }
        .fi-ss-pepper-alt:before {
    content: "\eb9a";
    }
        .fi-ss-pepper-hot:before {
    content: "\eb9b";
    }
        .fi-ss-percent-10:before {
    content: "\eb9c";
    }
        .fi-ss-percent-100:before {
    content: "\eb9d";
    }
        .fi-ss-percent-20:before {
    content: "\eb9e";
    }
        .fi-ss-percent-25:before {
    content: "\eb9f";
    }
        .fi-ss-percent-30:before {
    content: "\eba0";
    }
        .fi-ss-percent-40:before {
    content: "\eba1";
    }
        .fi-ss-percent-50:before {
    content: "\eba2";
    }
        .fi-ss-percent-60:before {
    content: "\eba3";
    }
        .fi-ss-percent-70:before {
    content: "\eba4";
    }
        .fi-ss-percent-75:before {
    content: "\eba5";
    }
        .fi-ss-percent-80:before {
    content: "\eba6";
    }
        .fi-ss-percent-90:before {
    content: "\eba7";
    }
        .fi-ss-percentage:before {
    content: "\eba8";
    }
        .fi-ss-person-battery:before {
    content: "\ebac";
    }
        .fi-ss-person-burst:before {
    content: "\ebae";
    }
        .fi-ss-person-carry-box:before {
    content: "\ebaf";
    }
        .fi-ss-person-circle-check:before {
    content: "\ebb1";
    }
        .fi-ss-person-circle-exclamation:before {
    content: "\ebb2";
    }
        .fi-ss-person-circle-minus:before {
    content: "\ebb3";
    }
        .fi-ss-person-circle-plus:before {
    content: "\ebb4";
    }
        .fi-ss-person-circle-question:before {
    content: "\ebb5";
    }
        .fi-ss-person-circle-xmark:before {
    content: "\ebb6";
    }
        .fi-ss-person-cv:before {
    content: "\ebb7";
    }
        .fi-ss-person-dolly:before {
    content: "\ebb8";
    }
        .fi-ss-person-dolly-empty:before {
    content: "\ebb9";
    }
        .fi-ss-person-dragging-bag:before {
    content: "\ebba";
    }
        .fi-ss-person-dress:before {
    content: "\ebbb";
    }
        .fi-ss-person-dress-simple:before {
    content: "\ebbd";
    }
        .fi-ss-person-luggage:before {
    content: "\ebc1";
    }
        .fi-ss-person-lunge:before {
    content: "\ebc2";
    }
        .fi-ss-person-pilates:before {
    content: "\ebc6";
    }
        .fi-ss-person-praying:before {
    content: "\ebc7";
    }
        .fi-ss-person-pregnant:before {
    content: "\ebc8";
    }
        .fi-ss-person-seat:before {
    content: "\ebcb";
    }
        .fi-ss-person-seat-reclined:before {
    content: "\ebcc";
    }
        .fi-ss-person-shelter:before {
    content: "\ebcd";
    }
        .fi-ss-person-sign:before {
    content: "\ebce";
    }
        .fi-ss-person-simple:before {
    content: "\ebcf";
    }
        .fi-ss-person-stress:before {
    content: "\ebd0";
    }
        .fi-ss-person-walking-with-cane:before {
    content: "\ebd6";
    }
        .fi-ss-peseta-sign:before {
    content: "\ebd7";
    }
        .fi-ss-peso-sign:before {
    content: "\ebd8";
    }
        .fi-ss-pets:before {
    content: "\ebd9";
    }
        .fi-ss-pharmacy:before {
    content: "\ebdb";
    }
        .fi-ss-phone-call:before {
    content: "\ebde";
    }
        .fi-ss-phone-cross:before {
    content: "\ebdf";
    }
        .fi-ss-phone-flip:before {
    content: "\ebe0";
    }
        .fi-ss-phone-guide:before {
    content: "\ebe1";
    }
        .fi-ss-phone-heart-message:before {
    content: "\ebe2";
    }
        .fi-ss-phone-office:before {
    content: "\ebe3";
    }
        .fi-ss-phone-pause:before {
    content: "\ebe4";
    }
        .fi-ss-phone-plus:before {
    content: "\ebe5";
    }
        .fi-ss-phone-rotary:before {
    content: "\ebe6";
    }
        .fi-ss-phone-slash:before {
    content: "\ebe7";
    }
        .fi-ss-photo-capture:before {
    content: "\ebe8";
    }
        .fi-ss-photo-film-music:before {
    content: "\ebea";
    }
        .fi-ss-photo-video:before {
    content: "\ebeb";
    }
        .fi-ss-physics:before {
    content: "\ebf2";
    }
        .fi-ss-Pi:before {
    content: "\ebf3";
    }
        .fi-ss-piano:before {
    content: "\ebf4";
    }
        .fi-ss-piano-keyboard:before {
    content: "\ebf5";
    }
        .fi-ss-pickaxe:before {
    content: "\ebf6";
    }
        .fi-ss-picking:before {
    content: "\ebf7";
    }
        .fi-ss-picking-box:before {
    content: "\ebf8";
    }
        .fi-ss-picnic:before {
    content: "\ebf9";
    }
        .fi-ss-picpeople:before {
    content: "\ebfa";
    }
        .fi-ss-picpeople-filled:before {
    content: "\ebfb";
    }
        .fi-ss-picture:before {
    content: "\ebfc";
    }
        .fi-ss-pie:before {
    content: "\ebfd";
    }
        .fi-ss-pig:before {
    content: "\ebff";
    }
        .fi-ss-pig-bank-bulb:before {
    content: "\ec00";
    }
        .fi-ss-pig-face:before {
    content: "\ec01";
    }
        .fi-ss-piggy-bank:before {
    content: "\ec02";
    }
        .fi-ss-piggy-bank-budget:before {
    content: "\ec03";
    }
        .fi-ss-pills:before {
    content: "\ec04";
    }
        .fi-ss-pin-to-pin:before {
    content: "\ec05";
    }
        .fi-ss-piñata:before {
    content: "\ec06";
    }
        .fi-ss-pineapple:before {
    content: "\ec07";
    }
        .fi-ss-pineapple-alt:before {
    content: "\ec08";
    }
        .fi-ss-pipe-smoking:before {
    content: "\ec0b";
    }
        .fi-ss-pipette:before {
    content: "\ec0c";
    }
        .fi-ss-pisa-tower:before {
    content: "\ec0d";
    }
        .fi-ss-pizza-slice:before {
    content: "\ec0f";
    }
        .fi-ss-place-of-worship:before {
    content: "\ec10";
    }
        .fi-ss-plagiarism:before {
    content: "\ec11";
    }
        .fi-ss-plan:before {
    content: "\ec12";
    }
        .fi-ss-plan-strategy:before {
    content: "\ec13";
    }
        .fi-ss-plane:before {
    content: "\ec14";
    }
        .fi-ss-plane-alt:before {
    content: "\ec15";
    }
        .fi-ss-plane-arrival:before {
    content: "\ec16";
    }
        .fi-ss-plane-departure:before {
    content: "\ec17";
    }
        .fi-ss-plane-prop:before {
    content: "\ec18";
    }
        .fi-ss-plane-slash:before {
    content: "\ec19";
    }
        .fi-ss-plane-tail:before {
    content: "\ec1a";
    }
        .fi-ss-planet-moon:before {
    content: "\ec1d";
    }
        .fi-ss-planet-ringed:before {
    content: "\ec1e";
    }
        .fi-ss-plant-care:before {
    content: "\ec1f";
    }
        .fi-ss-plant-growth:before {
    content: "\ec20";
    }
        .fi-ss-plant-seed-invest:before {
    content: "\ec21";
    }
        .fi-ss-plant-wilt:before {
    content: "\ec22";
    }
        .fi-ss-plate:before {
    content: "\ec23";
    }
        .fi-ss-plate-eating:before {
    content: "\ec24";
    }
        .fi-ss-plate-empty:before {
    content: "\ec25";
    }
        .fi-ss-plate-utensils:before {
    content: "\ec26";
    }
        .fi-ss-plate-wheat:before {
    content: "\ec27";
    }
        .fi-ss-play:before {
    content: "\ec28";
    }
        .fi-ss-play-alt:before {
    content: "\ec29";
    }
        .fi-ss-play-circle:before {
    content: "\ec2a";
    }
        .fi-ss-play-microphone:before {
    content: "\ec2b";
    }
        .fi-ss-play-pause:before {
    content: "\ec2c";
    }
        .fi-ss-plug:before {
    content: "\ec2e";
    }
        .fi-ss-plug-alt:before {
    content: "\ec2f";
    }
        .fi-ss-plug-cable:before {
    content: "\ec30";
    }
        .fi-ss-plug-circle-bolt:before {
    content: "\ec31";
    }
        .fi-ss-plug-circle-check:before {
    content: "\ec32";
    }
        .fi-ss-plug-circle-exclamation:before {
    content: "\ec33";
    }
        .fi-ss-plug-circle-minus:before {
    content: "\ec34";
    }
        .fi-ss-plug-circle-plus:before {
    content: "\ec35";
    }
        .fi-ss-plug-connection:before {
    content: "\ec36";
    }
        .fi-ss-plus:before {
    content: "\ec37";
    }
        .fi-ss-plus-hexagon:before {
    content: "\ec38";
    }
        .fi-ss-plus-minus:before {
    content: "\ec39";
    }
        .fi-ss-plus-small:before {
    content: "\ec3a";
    }
        .fi-ss-png-file:before {
    content: "\ec3b";
    }
        .fi-ss-podcast:before {
    content: "\ec3c";
    }
        .fi-ss-podium:before {
    content: "\ec3d";
    }
        .fi-ss-podium-star:before {
    content: "\ec3e";
    }
        .fi-ss-podium-victory-leader:before {
    content: "\ec3f";
    }
        .fi-ss-point-of-sale:before {
    content: "\ec40";
    }
        .fi-ss-point-of-sale-bill:before {
    content: "\ec41";
    }
        .fi-ss-point-of-sale-signal:before {
    content: "\ec42";
    }
        .fi-ss-pointer-loading:before {
    content: "\ec44";
    }
        .fi-ss-pointer-text:before {
    content: "\ec45";
    }
        .fi-ss-police-box:before {
    content: "\ec47";
    }
        .fi-ss-polish-bottle:before {
    content: "\ec48";
    }
        .fi-ss-polish-brush:before {
    content: "\ec49";
    }
        .fi-ss-poll-h:before {
    content: "\ec4a";
    }
        .fi-ss-pollution:before {
    content: "\ec4b";
    }
        .fi-ss-pompebled:before {
    content: "\ec4c";
    }
        .fi-ss-poo:before {
    content: "\ec4d";
    }
        .fi-ss-poo-bolt:before {
    content: "\ec4e";
    }
        .fi-ss-pool-8-ball:before {
    content: "\ec4f";
    }
        .fi-ss-poop:before {
    content: "\ec50";
    }
        .fi-ss-popcorn:before {
    content: "\ec51";
    }
        .fi-ss-popsicle:before {
    content: "\ec52";
    }
        .fi-ss-population:before {
    content: "\ec53";
    }
        .fi-ss-population-globe:before {
    content: "\ec54";
    }
        .fi-ss-portal-enter:before {
    content: "\ec55";
    }
        .fi-ss-portal-exit:before {
    content: "\ec56";
    }
        .fi-ss-portrait:before {
    content: "\ec57";
    }
        .fi-ss-portuguese:before {
    content: "\ec58";
    }
        .fi-ss-postal-address:before {
    content: "\ec59";
    }
        .fi-ss-pot:before {
    content: "\ec5b";
    }
        .fi-ss-potato:before {
    content: "\ec5c";
    }
        .fi-ss-pound:before {
    content: "\ec5d";
    }
        .fi-ss-power:before {
    content: "\ec5e";
    }
        .fi-ss-ppt-file:before {
    content: "\ec5f";
    }
        .fi-ss-practice:before {
    content: "\ec60";
    }
        .fi-ss-praying-hands:before {
    content: "\ec61";
    }
        .fi-ss-prescription:before {
    content: "\ec64";
    }
        .fi-ss-prescription-bottle:before {
    content: "\ec65";
    }
        .fi-ss-prescription-bottle-alt:before {
    content: "\ec66";
    }
        .fi-ss-prescription-bottle-pill:before {
    content: "\ec67";
    }
        .fi-ss-presentation:before {
    content: "\ec68";
    }
        .fi-ss-preview:before {
    content: "\ec69";
    }
        .fi-ss-previous-square:before {
    content: "\ec6a";
    }
        .fi-ss-print:before {
    content: "\ec6b";
    }
        .fi-ss-print-magnifying-glass:before {
    content: "\ec6c";
    }
        .fi-ss-print-slash:before {
    content: "\ec6d";
    }
        .fi-ss-priority-arrow:before {
    content: "\ec6f";
    }
        .fi-ss-priority-arrows:before {
    content: "\ec70";
    }
        .fi-ss-priority-importance:before {
    content: "\ec72";
    }
        .fi-ss-problem-solving:before {
    content: "\ec75";
    }
        .fi-ss-procedures:before {
    content: "\ec76";
    }
        .fi-ss-process:before {
    content: "\ec77";
    }
        .fi-ss-productivity:before {
    content: "\ec79";
    }
        .fi-ss-progress-bar-dotted-empty:before {
    content: "\ec7a";
    }
        .fi-ss-progress-bar-dotted-half:before {
    content: "\ec7b";
    }
        .fi-ss-progress-bar-dotted-line-half:before {
    content: "\ec7c";
    }
        .fi-ss-progress-bar-empty:before {
    content: "\ec7d";
    }
        .fi-ss-progress-bar-half:before {
    content: "\ec7e";
    }
        .fi-ss-progress-bar-square-empty:before {
    content: "\ec7f";
    }
        .fi-ss-progress-bar-square-half:before {
    content: "\ec80";
    }
        .fi-ss-progress-complete:before {
    content: "\ec81";
    }
        .fi-ss-progress-download:before {
    content: "\ec82";
    }
        .fi-ss-progress-upload:before {
    content: "\ec83";
    }
        .fi-ss-projector:before {
    content: "\ec84";
    }
        .fi-ss-protractor:before {
    content: "\ec85";
    }
        .fi-ss-pulse:before {
    content: "\ec86";
    }
        .fi-ss-pump:before {
    content: "\ec87";
    }
        .fi-ss-pump-medical:before {
    content: "\ec88";
    }
        .fi-ss-pumpkin:before {
    content: "\ec89";
    }
        .fi-ss-pumpkin-alt:before {
    content: "\ec8a";
    }
        .fi-ss-pumpkin-alt-2:before {
    content: "\ec8b";
    }
        .fi-ss-puzzle-alt:before {
    content: "\ec8d";
    }
        .fi-ss-puzzle-piece:before {
    content: "\ec8f";
    }
        .fi-ss-puzzle-piece-integration:before {
    content: "\ec90";
    }
        .fi-ss-puzzle-pieces:before {
    content: "\ec91";
    }
        .fi-ss-pyramid:before {
    content: "\ec92";
    }
        .fi-ss-q:before {
    content: "\ec94";
    }
        .fi-ss-QR:before {
    content: "\ec95";
    }
        .fi-ss-qr-scan:before {
    content: "\ec96";
    }
        .fi-ss-qrcode:before {
    content: "\ec97";
    }
        .fi-ss-question:before {
    content: "\ec99";
    }
        .fi-ss-question-square:before {
    content: "\ec9a";
    }
        .fi-ss-queue:before {
    content: "\ec9b";
    }
        .fi-ss-queue-alt:before {
    content: "\ec9c";
    }
        .fi-ss-queue-line:before {
    content: "\ec9d";
    }
        .fi-ss-queue-signal:before {
    content: "\ec9e";
    }
        .fi-ss-quill-pen-story:before {
    content: "\ec9f";
    }
        .fi-ss-quiz:before {
    content: "\eca0";
    }
        .fi-ss-quiz-alt:before {
    content: "\eca1";
    }
        .fi-ss-quote-right:before {
    content: "\eca2";
    }
        .fi-ss-r:before {
    content: "\eca4";
    }
        .fi-ss-rabbit:before {
    content: "\eca5";
    }
        .fi-ss-rabbit-fast:before {
    content: "\eca6";
    }
        .fi-ss-raccoon:before {
    content: "\eca7";
    }
        .fi-ss-racquet:before {
    content: "\eca8";
    }
        .fi-ss-radar:before {
    content: "\eca9";
    }
        .fi-ss-radar-monitoring-track:before {
    content: "\ecaa";
    }
        .fi-ss-radiation:before {
    content: "\ecab";
    }
        .fi-ss-radiation-alt:before {
    content: "\ecac";
    }
        .fi-ss-radio:before {
    content: "\ecad";
    }
        .fi-ss-radio-alt:before {
    content: "\ecae";
    }
        .fi-ss-radio-button:before {
    content: "\ecaf";
    }
        .fi-ss-radio-tower:before {
    content: "\ecb0";
    }
        .fi-ss-radio-waves:before {
    content: "\ecb1";
    }
        .fi-ss-radish:before {
    content: "\ecb2";
    }
        .fi-ss-rainbow:before {
    content: "\ecb3";
    }
        .fi-ss-raindrops:before {
    content: "\ecb4";
    }
        .fi-ss-ram:before {
    content: "\ecb5";
    }
        .fi-ss-ramp-loading:before {
    content: "\ecb6";
    }
        .fi-ss-rank:before {
    content: "\ecb7";
    }
        .fi-ss-ranking-podium:before {
    content: "\ecb8";
    }
        .fi-ss-ranking-podium-empty:before {
    content: "\ecb9";
    }
        .fi-ss-ranking-star:before {
    content: "\ecba";
    }
        .fi-ss-ranking-stars:before {
    content: "\ecbb";
    }
        .fi-ss-raygun:before {
    content: "\ecbd";
    }
        .fi-ss-razor-barber:before {
    content: "\ecbe";
    }
        .fi-ss-react:before {
    content: "\ecbf";
    }
        .fi-ss-rec:before {
    content: "\ecc0";
    }
        .fi-ss-receipt:before {
    content: "\ecc1";
    }
        .fi-ss-recipe:before {
    content: "\ecc2";
    }
        .fi-ss-recipe-book:before {
    content: "\ecc3";
    }
        .fi-ss-record-vinyl:before {
    content: "\ecc4";
    }
        .fi-ss-rectabgle-vertical:before {
    content: "\ecc5";
    }
        .fi-ss-rectangle-barcode:before {
    content: "\ecc6";
    }
        .fi-ss-rectangle-code:before {
    content: "\ecc7";
    }
        .fi-ss-rectangle-history-circle-plus:before {
    content: "\ecc8";
    }
        .fi-ss-rectangle-horizontal:before {
    content: "\ecc9";
    }
        .fi-ss-rectangle-list:before {
    content: "\ecca";
    }
        .fi-ss-rectangle-panoramic:before {
    content: "\eccb";
    }
        .fi-ss-rectangle-pro:before {
    content: "\eccc";
    }
        .fi-ss-rectangle-vertical:before {
    content: "\eccd";
    }
        .fi-ss-rectangle-vertical-history:before {
    content: "\ecce";
    }
        .fi-ss-rectangle-xmark:before {
    content: "\eccf";
    }
        .fi-ss-rectangles-mixed:before {
    content: "\ecd0";
    }
        .fi-ss-recycle:before {
    content: "\ecd1";
    }
        .fi-ss-recycle-bin:before {
    content: "\ecd2";
    }
        .fi-ss-redo:before {
    content: "\ecd5";
    }
        .fi-ss-redo-alt:before {
    content: "\ecd6";
    }
        .fi-ss-reel:before {
    content: "\ecd7";
    }
        .fi-ss-refer:before {
    content: "\ecd8";
    }
        .fi-ss-refer-arrow:before {
    content: "\ecd9";
    }
        .fi-ss-referral:before {
    content: "\ecdc";
    }
        .fi-ss-referral-alt:before {
    content: "\ecdd";
    }
        .fi-ss-referral-link-arrow:before {
    content: "\ecde";
    }
        .fi-ss-referral-user:before {
    content: "\ecdf";
    }
        .fi-ss-reflect:before {
    content: "\ece0";
    }
        .fi-ss-reflect-horizontal:before {
    content: "\ece1";
    }
        .fi-ss-reflect-horizontal-alt:before {
    content: "\ece2";
    }
        .fi-ss-reflect-vertical:before {
    content: "\ece3";
    }
        .fi-ss-refresh:before {
    content: "\ece4";
    }
        .fi-ss-refrigerator:before {
    content: "\ece5";
    }
        .fi-ss-refund:before {
    content: "\ece6";
    }
        .fi-ss-refund-alt:before {
    content: "\ece7";
    }
        .fi-ss-region-pin:before {
    content: "\ece8";
    }
        .fi-ss-region-pin-alt:before {
    content: "\ece9";
    }
        .fi-ss-registered:before {
    content: "\ecea";
    }
        .fi-ss-registration-paper:before {
    content: "\eced";
    }
        .fi-ss-remote-control:before {
    content: "\ecef";
    }
        .fi-ss-remote-control-hand:before {
    content: "\ecf0";
    }
        .fi-ss-remove-folder:before {
    content: "\ecf1";
    }
        .fi-ss-remove-user:before {
    content: "\ecf2";
    }
        .fi-ss-rent:before {
    content: "\ecf3";
    }
        .fi-ss-rent-signal:before {
    content: "\ecf4";
    }
        .fi-ss-replace:before {
    content: "\ecf5";
    }
        .fi-ss-replay-10:before {
    content: "\ecf7";
    }
        .fi-ss-replay-30:before {
    content: "\ecf8";
    }
        .fi-ss-replay-5:before {
    content: "\ecf9";
    }
        .fi-ss-reply-all:before {
    content: "\ecfa";
    }
        .fi-ss-republican:before {
    content: "\ecfb";
    }
        .fi-ss-research-arrows-circle:before {
    content: "\ecfc";
    }
        .fi-ss-reservation-smartphone:before {
    content: "\ecfd";
    }
        .fi-ss-reservation-table:before {
    content: "\ecfe";
    }
        .fi-ss-resistance-band:before {
    content: "\ecff";
    }
        .fi-ss-resize:before {
    content: "\ed00";
    }
        .fi-ss-resources:before {
    content: "\ed01";
    }
        .fi-ss-responsability:before {
    content: "\ed02";
    }
        .fi-ss-restaurant:before {
    content: "\ed03";
    }
        .fi-ss-restock:before {
    content: "\ed04";
    }
        .fi-ss-restroom-simple:before {
    content: "\ed05";
    }
        .fi-ss-resume:before {
    content: "\ed06";
    }
        .fi-ss-Revenue:before {
    content: "\ed07";
    }
        .fi-ss-revenue-alt:before {
    content: "\ed08";
    }
        .fi-ss-revenue-euro:before {
    content: "\ed09";
    }
        .fi-ss-review:before {
    content: "\ed0b";
    }
        .fi-ss-rewind:before {
    content: "\ed0c";
    }
        .fi-ss-rewind-button-circle:before {
    content: "\ed0d";
    }
        .fi-ss-rhombus:before {
    content: "\ed0e";
    }
        .fi-ss-ribbon:before {
    content: "\ed0f";
    }
        .fi-ss-right:before {
    content: "\ed10";
    }
        .fi-ss-right-from-bracket:before {
    content: "\ed11";
    }
        .fi-ss-ring:before {
    content: "\ed13";
    }
        .fi-ss-ring-diamond:before {
    content: "\ed14";
    }
        .fi-ss-rings-wedding:before {
    content: "\ed15";
    }
        .fi-ss-risk:before {
    content: "\ed16";
    }
        .fi-ss-risk-alt:before {
    content: "\ed17";
    }
        .fi-ss-road:before {
    content: "\ed18";
    }
        .fi-ss-road-barrier:before {
    content: "\ed19";
    }
        .fi-ss-road-map-pin:before {
    content: "\ed1a";
    }
        .fi-ss-road-sign-left:before {
    content: "\ed1b";
    }
        .fi-ss-roadmap:before {
    content: "\ed1c";
    }
        .fi-ss-robot:before {
    content: "\ed1d";
    }
        .fi-ss-robotic-arm:before {
    content: "\ed1e";
    }
        .fi-ss-rocket:before {
    content: "\ed1f";
    }
        .fi-ss-rocket-hand:before {
    content: "\ed20";
    }
        .fi-ss-rocket-holding-hand:before {
    content: "\ed21";
    }
        .fi-ss-rocket-lunch:before {
    content: "\ed22";
    }
        .fi-ss-roller-coaster:before {
    content: "\ed23";
    }
        .fi-ss-room-service:before {
    content: "\ed24";
    }
        .fi-ss-rose:before {
    content: "\ed25";
    }
        .fi-ss-rose-alt:before {
    content: "\ed26";
    }
        .fi-ss-rotate-exclamation:before {
    content: "\ed27";
    }
        .fi-ss-rotate-left:before {
    content: "\ed28";
    }
        .fi-ss-rotate-reverse:before {
    content: "\ed29";
    }
        .fi-ss-rotate-right:before {
    content: "\ed2a";
    }
        .fi-ss-rotate-square:before {
    content: "\ed2b";
    }
        .fi-ss-roulette:before {
    content: "\ed2c";
    }
        .fi-ss-route:before {
    content: "\ed2d";
    }
        .fi-ss-route-highway:before {
    content: "\ed2e";
    }
        .fi-ss-route-interstate:before {
    content: "\ed2f";
    }
        .fi-ss-router:before {
    content: "\ed30";
    }
        .fi-ss-router-wifi:before {
    content: "\ed31";
    }
        .fi-ss-router-wifi-alt:before {
    content: "\ed32";
    }
        .fi-ss-rss:before {
    content: "\ed33";
    }
        .fi-ss-rss-alt:before {
    content: "\ed34";
    }
        .fi-ss-ruble-sign:before {
    content: "\ed35";
    }
        .fi-ss-rugby:before {
    content: "\ed36";
    }
        .fi-ss-rugby-helmet:before {
    content: "\ed37";
    }
        .fi-ss-ruler-combined:before {
    content: "\ed38";
    }
        .fi-ss-ruler-horizontal:before {
    content: "\ed39";
    }
        .fi-ss-ruler-triangle:before {
    content: "\ed3a";
    }
        .fi-ss-ruler-vertical:before {
    content: "\ed3b";
    }
        .fi-ss-rules:before {
    content: "\ed3c";
    }
        .fi-ss-rules-alt:before {
    content: "\ed3d";
    }
        .fi-ss-running:before {
    content: "\ed3e";
    }
        .fi-ss-running-track:before {
    content: "\ed3f";
    }
        .fi-ss-rupee-sign:before {
    content: "\ed40";
    }
        .fi-ss-rupiah-sign:before {
    content: "\ed41";
    }
        .fi-ss-russian:before {
    content: "\ed42";
    }
        .fi-ss-rv:before {
    content: "\ed43";
    }
        .fi-ss-s:before {
    content: "\ed44";
    }
        .fi-ss-sack:before {
    content: "\ed45";
    }
        .fi-ss-sack-dollar:before {
    content: "\ed46";
    }
        .fi-ss-sad:before {
    content: "\ed47";
    }
        .fi-ss-sad-cry:before {
    content: "\ed48";
    }
        .fi-ss-sad-tear:before {
    content: "\ed49";
    }
        .fi-ss-safe-box:before {
    content: "\ed4a";
    }
        .fi-ss-sailboat:before {
    content: "\ed4b";
    }
        .fi-ss-salad:before {
    content: "\ed4c";
    }
        .fi-ss-salary-alt:before {
    content: "\ed4e";
    }
        .fi-ss-salt-pepper:before {
    content: "\ed4f";
    }
        .fi-ss-salt-shaker:before {
    content: "\ed50";
    }
        .fi-ss-sandwich:before {
    content: "\ed52";
    }
        .fi-ss-sandwich-alt:before {
    content: "\ed53";
    }
        .fi-ss-satellite:before {
    content: "\ed56";
    }
        .fi-ss-satellite-dish:before {
    content: "\ed57";
    }
        .fi-ss-satisfaction-bar:before {
    content: "\ed58";
    }
        .fi-ss-saturday:before {
    content: "\ed59";
    }
        .fi-ss-sauce:before {
    content: "\ed5a";
    }
        .fi-ss-sausage:before {
    content: "\ed5b";
    }
        .fi-ss-sax-hot:before {
    content: "\ed5c";
    }
        .fi-ss-saxophone:before {
    content: "\ed5d";
    }
        .fi-ss-scale:before {
    content: "\ed5e";
    }
        .fi-ss-scale-comparison:before {
    content: "\ed5f";
    }
        .fi-ss-scale-comparison-alt:before {
    content: "\ed60";
    }
        .fi-ss-scalpel:before {
    content: "\ed61";
    }
        .fi-ss-scalpel-path:before {
    content: "\ed62";
    }
        .fi-ss-scanner-gun:before {
    content: "\ed63";
    }
        .fi-ss-scanner-image:before {
    content: "\ed64";
    }
        .fi-ss-scanner-keyboard:before {
    content: "\ed65";
    }
        .fi-ss-scanner-touchscreen:before {
    content: "\ed66";
    }
        .fi-ss-scarecrow:before {
    content: "\ed67";
    }
        .fi-ss-scarf:before {
    content: "\ed68";
    }
        .fi-ss-school:before {
    content: "\ed69";
    }
        .fi-ss-school-bus:before {
    content: "\ed6a";
    }
        .fi-ss-school-lock:before {
    content: "\ed6b";
    }
        .fi-ss-scissors:before {
    content: "\ed6c";
    }
        .fi-ss-scooter:before {
    content: "\ed6d";
    }
        .fi-ss-screen:before {
    content: "\ed6e";
    }
        .fi-ss-screen-share:before {
    content: "\ed6f";
    }
        .fi-ss-screencast:before {
    content: "\ed71";
    }
        .fi-ss-screw:before {
    content: "\ed72";
    }
        .fi-ss-screw-alt:before {
    content: "\ed73";
    }
        .fi-ss-screwdriver:before {
    content: "\ed74";
    }
        .fi-ss-scribble:before {
    content: "\ed75";
    }
        .fi-ss-script:before {
    content: "\ed76";
    }
        .fi-ss-scroll:before {
    content: "\ed77";
    }
        .fi-ss-scroll-document-story:before {
    content: "\ed78";
    }
        .fi-ss-scroll-old:before {
    content: "\ed79";
    }
        .fi-ss-scroll-torah:before {
    content: "\ed7a";
    }
        .fi-ss-scrubber:before {
    content: "\ed7b";
    }
        .fi-ss-sculpture:before {
    content: "\ed7c";
    }
        .fi-ss-scythe:before {
    content: "\ed7d";
    }
        .fi-ss-sd-card:before {
    content: "\ed7e";
    }
        .fi-ss-sd-cards:before {
    content: "\ed7f";
    }
        .fi-ss-seal:before {
    content: "\ed80";
    }
        .fi-ss-seal-exclamation:before {
    content: "\ed81";
    }
        .fi-ss-seal-question:before {
    content: "\ed82";
    }
        .fi-ss-search:before {
    content: "\ed83";
    }
        .fi-ss-search-alt:before {
    content: "\ed84";
    }
        .fi-ss-search-dollar:before {
    content: "\ed85";
    }
        .fi-ss-search-heart:before {
    content: "\ed86";
    }
        .fi-ss-search-location:before {
    content: "\ed87";
    }
        .fi-ss-seat-airline:before {
    content: "\ed88";
    }
        .fi-ss-seatbelt-safety-driver:before {
    content: "\ed89";
    }
        .fi-ss-second:before {
    content: "\ed8a";
    }
        .fi-ss-second-award:before {
    content: "\ed8b";
    }
        .fi-ss-second-laurel:before {
    content: "\ed8c";
    }
        .fi-ss-second-medal:before {
    content: "\ed8d";
    }
        .fi-ss-seedling:before {
    content: "\ed90";
    }
        .fi-ss-select:before {
    content: "\ed91";
    }
        .fi-ss-selection:before {
    content: "\ed92";
    }
        .fi-ss-sell:before {
    content: "\ed93";
    }
        .fi-ss-seller:before {
    content: "\ed94";
    }
        .fi-ss-seller-store:before {
    content: "\ed95";
    }
        .fi-ss-selling:before {
    content: "\ed97";
    }
        .fi-ss-send-back:before {
    content: "\ed99";
    }
        .fi-ss-send-backward:before {
    content: "\ed9a";
    }
        .fi-ss-send-money:before {
    content: "\ed9b";
    }
        .fi-ss-send-money-smartphone:before {
    content: "\ed9c";
    }
        .fi-ss-sensor:before {
    content: "\ed9d";
    }
        .fi-ss-sensor-alert:before {
    content: "\ed9e";
    }
        .fi-ss-sensor-fire:before {
    content: "\ed9f";
    }
        .fi-ss-sensor-on:before {
    content: "\eda0";
    }
        .fi-ss-sensor-smoke:before {
    content: "\eda1";
    }
        .fi-ss-server-key:before {
    content: "\eda2";
    }
        .fi-ss-settings:before {
    content: "\eda3";
    }
        .fi-ss-settings-sliders:before {
    content: "\eda4";
    }
        .fi-ss-sewing-machine:before {
    content: "\eda5";
    }
        .fi-ss-sewing-machine-alt:before {
    content: "\eda6";
    }
        .fi-ss-share:before {
    content: "\eda7";
    }
        .fi-ss-share-alt-square:before {
    content: "\eda8";
    }
        .fi-ss-share-square:before {
    content: "\eda9";
    }
        .fi-ss-sheep:before {
    content: "\edaa";
    }
        .fi-ss-shekel-sign:before {
    content: "\edab";
    }
        .fi-ss-shelves:before {
    content: "\edac";
    }
        .fi-ss-shelves-empty:before {
    content: "\edad";
    }
        .fi-ss-shield:before {
    content: "\edae";
    }
        .fi-ss-shield-alt:before {
    content: "\edaf";
    }
        .fi-ss-shield-cat:before {
    content: "\edb0";
    }
        .fi-ss-shield-check:before {
    content: "\edb1";
    }
        .fi-ss-shield-cross:before {
    content: "\edb2";
    }
        .fi-ss-shield-divided-four:before {
    content: "\edb3";
    }
        .fi-ss-shield-dog:before {
    content: "\edb4";
    }
        .fi-ss-shield-exclamation:before {
    content: "\edb5";
    }
        .fi-ss-shield-interrogation:before {
    content: "\edb6";
    }
        .fi-ss-shield-keyhole:before {
    content: "\edb7";
    }
        .fi-ss-shield-minus:before {
    content: "\edb8";
    }
        .fi-ss-shield-plus:before {
    content: "\edb9";
    }
        .fi-ss-shield-security-risk:before {
    content: "\edbb";
    }
        .fi-ss-shield-slash:before {
    content: "\edbc";
    }
        .fi-ss-shield-trust:before {
    content: "\edbd";
    }
        .fi-ss-shield-virus:before {
    content: "\edbe";
    }
        .fi-ss-shield-xmark:before {
    content: "\edbf";
    }
        .fi-ss-ship:before {
    content: "\edc0";
    }
        .fi-ss-ship-side:before {
    content: "\edc1";
    }
        .fi-ss-shipping-fast:before {
    content: "\edc2";
    }
        .fi-ss-shipping-timed:before {
    content: "\edc3";
    }
        .fi-ss-shirt:before {
    content: "\edc4";
    }
        .fi-ss-shirt-long-sleeve:before {
    content: "\edc5";
    }
        .fi-ss-shirt-running:before {
    content: "\edc6";
    }
        .fi-ss-shirt-tank-top:before {
    content: "\edc7";
    }
        .fi-ss-shish-kebab:before {
    content: "\edc8";
    }
        .fi-ss-shoe-prints:before {
    content: "\edc9";
    }
        .fi-ss-shop:before {
    content: "\edca";
    }
        .fi-ss-shop-lock:before {
    content: "\edcb";
    }
        .fi-ss-shop-slash:before {
    content: "\edcc";
    }
        .fi-ss-shopping-bag:before {
    content: "\edce";
    }
        .fi-ss-shopping-bag-add:before {
    content: "\edcf";
    }
        .fi-ss-shopping-basket:before {
    content: "\edd0";
    }
        .fi-ss-shopping-cart:before {
    content: "\edd1";
    }
        .fi-ss-shopping-cart-add:before {
    content: "\edd2";
    }
        .fi-ss-shopping-cart-buyer:before {
    content: "\edd3";
    }
        .fi-ss-shopping-cart-check:before {
    content: "\edd4";
    }
        .fi-ss-shopping-cart-nft:before {
    content: "\edd8";
    }
        .fi-ss-shovel:before {
    content: "\edd9";
    }
        .fi-ss-shovel-snow:before {
    content: "\edda";
    }
        .fi-ss-shower:before {
    content: "\eddb";
    }
        .fi-ss-shower-down:before {
    content: "\eddc";
    }
        .fi-ss-shredder:before {
    content: "\eddd";
    }
        .fi-ss-shrimp:before {
    content: "\edde";
    }
        .fi-ss-shuffle:before {
    content: "\eddf";
    }
        .fi-ss-shuttle-van:before {
    content: "\ede0";
    }
        .fi-ss-shuttlecock:before {
    content: "\ede1";
    }
        .fi-ss-Sickle:before {
    content: "\ede2";
    }
        .fi-ss-sidebar:before {
    content: "\ede3";
    }
        .fi-ss-sidebar-flip:before {
    content: "\ede4";
    }
        .fi-ss-sigma:before {
    content: "\ede6";
    }
        .fi-ss-sign-hanging:before {
    content: "\ede7";
    }
        .fi-ss-sign-in-alt:before {
    content: "\ede8";
    }
        .fi-ss-sign-out-alt:before {
    content: "\ede9";
    }
        .fi-ss-sign-posts:before {
    content: "\edea";
    }
        .fi-ss-sign-posts-wrench:before {
    content: "\edeb";
    }
        .fi-ss-sign-up:before {
    content: "\edec";
    }
        .fi-ss-signal-alt:before {
    content: "\eded";
    }
        .fi-ss-signal-alt-1:before {
    content: "\edee";
    }
        .fi-ss-signal-alt-2:before {
    content: "\edef";
    }
        .fi-ss-signal-alt-slash:before {
    content: "\edf0";
    }
        .fi-ss-signal-bars-fair:before {
    content: "\edf1";
    }
        .fi-ss-signal-bars-good:before {
    content: "\edf2";
    }
        .fi-ss-signal-bars-weak:before {
    content: "\edf4";
    }
        .fi-ss-signal-stream:before {
    content: "\edf7";
    }
        .fi-ss-signal-stream-slash:before {
    content: "\edf8";
    }
        .fi-ss-signature:before {
    content: "\edfb";
    }
        .fi-ss-signature-lock:before {
    content: "\edfc";
    }
        .fi-ss-signature-slash:before {
    content: "\edfe";
    }
        .fi-ss-sim-card:before {
    content: "\edff";
    }
        .fi-ss-sim-cards:before {
    content: "\ee00";
    }
        .fi-ss-sink:before {
    content: "\ee01";
    }
        .fi-ss-Siren:before {
    content: "\ee02";
    }
        .fi-ss-siren-on:before {
    content: "\ee03";
    }
        .fi-ss-site:before {
    content: "\ee04";
    }
        .fi-ss-site-alt:before {
    content: "\ee05";
    }
        .fi-ss-site-browser:before {
    content: "\ee07";
    }
        .fi-ss-sitemap:before {
    content: "\ee08";
    }
        .fi-ss-skateboard:before {
    content: "\ee09";
    }
        .fi-ss-skating:before {
    content: "\ee0a";
    }
        .fi-ss-skeleton:before {
    content: "\ee0b";
    }
        .fi-ss-skeleton-ribs:before {
    content: "\ee0c";
    }
        .fi-ss-skewer:before {
    content: "\ee0e";
    }
        .fi-ss-ski-boot-ski:before {
    content: "\ee0f";
    }
        .fi-ss-ski-jump:before {
    content: "\ee10";
    }
        .fi-ss-ski-lift:before {
    content: "\ee11";
    }
        .fi-ss-skiing:before {
    content: "\ee12";
    }
        .fi-ss-skiing-nordic:before {
    content: "\ee13";
    }
        .fi-ss-skill:before {
    content: "\ee14";
    }
        .fi-ss-skill-alt:before {
    content: "\ee15";
    }
        .fi-ss-skill-user:before {
    content: "\ee17";
    }
        .fi-ss-skin:before {
    content: "\ee18";
    }
        .fi-ss-skin-acne:before {
    content: "\ee19";
    }
        .fi-ss-skin-arrow:before {
    content: "\ee1a";
    }
        .fi-ss-skin-drop:before {
    content: "\ee1b";
    }
        .fi-ss-skin-hair:before {
    content: "\ee1c";
    }
        .fi-ss-skin-laser:before {
    content: "\ee1d";
    }
        .fi-ss-skin-uv:before {
    content: "\ee1e";
    }
        .fi-ss-skip-15-seconds:before {
    content: "\ee1f";
    }
        .fi-ss-skull:before {
    content: "\ee20";
    }
        .fi-ss-skull-cow:before {
    content: "\ee21";
    }
        .fi-ss-skull-crossbones:before {
    content: "\ee22";
    }
        .fi-ss-skull-heart:before {
    content: "\ee23";
    }
        .fi-ss-slash:before {
    content: "\ee26";
    }
        .fi-ss-sledding:before {
    content: "\ee28";
    }
        .fi-ss-sleeping-bag:before {
    content: "\ee29";
    }
        .fi-ss-sleeping-cat:before {
    content: "\ee2a";
    }
        .fi-ss-sleigh:before {
    content: "\ee2b";
    }
        .fi-ss-sliders-h-square:before {
    content: "\ee2c";
    }
        .fi-ss-sliders-v:before {
    content: "\ee2d";
    }
        .fi-ss-sliders-v-square:before {
    content: "\ee2e";
    }
        .fi-ss-slot-machine:before {
    content: "\ee30";
    }
        .fi-ss-smart-home:before {
    content: "\ee31";
    }
        .fi-ss-smart-home-alt:before {
    content: "\ee32";
    }
        .fi-ss-smartphone:before {
    content: "\ee33";
    }
        .fi-ss-smile:before {
    content: "\ee34";
    }
        .fi-ss-smile-beam:before {
    content: "\ee35";
    }
        .fi-ss-smile-plus:before {
    content: "\ee36";
    }
        .fi-ss-smile-wink:before {
    content: "\ee37";
    }
        .fi-ss-smiley-comment-alt:before {
    content: "\ee38";
    }
        .fi-ss-smog:before {
    content: "\ee39";
    }
        .fi-ss-smoke:before {
    content: "\ee3a";
    }
        .fi-ss-smoking:before {
    content: "\ee3b";
    }
        .fi-ss-smoking-ban:before {
    content: "\ee3c";
    }
        .fi-ss-snake:before {
    content: "\ee3d";
    }
        .fi-ss-snap:before {
    content: "\ee3e";
    }
        .fi-ss-snooze:before {
    content: "\ee40";
    }
        .fi-ss-snow-blowing:before {
    content: "\ee41";
    }
        .fi-ss-snowboarding:before {
    content: "\ee42";
    }
        .fi-ss-snowflake:before {
    content: "\ee43";
    }
        .fi-ss-snowflake-droplets:before {
    content: "\ee44";
    }
        .fi-ss-snowflakes:before {
    content: "\ee45";
    }
        .fi-ss-snowman-alt:before {
    content: "\ee47";
    }
        .fi-ss-snowman-head:before {
    content: "\ee48";
    }
        .fi-ss-snowmobile:before {
    content: "\ee49";
    }
        .fi-ss-snowplow:before {
    content: "\ee4a";
    }
        .fi-ss-soap:before {
    content: "\ee4b";
    }
        .fi-ss-soap-alt:before {
    content: "\ee4c";
    }
        .fi-ss-social-network:before {
    content: "\ee4d";
    }
        .fi-ss-socks:before {
    content: "\ee4e";
    }
        .fi-ss-sofa:before {
    content: "\ee4f";
    }
        .fi-ss-sofa-size:before {
    content: "\ee50";
    }
        .fi-ss-solar-panel:before {
    content: "\ee51";
    }
        .fi-ss-solar-panel-sun:before {
    content: "\ee52";
    }
        .fi-ss-solar-system:before {
    content: "\ee53";
    }
        .fi-ss-sold-house:before {
    content: "\ee54";
    }
        .fi-ss-sold-signal:before {
    content: "\ee55";
    }
        .fi-ss-sort:before {
    content: "\ee57";
    }
        .fi-ss-sort-alpha-down:before {
    content: "\ee58";
    }
        .fi-ss-sort-alpha-down-alt:before {
    content: "\ee59";
    }
        .fi-ss-sort-alpha-up:before {
    content: "\ee5a";
    }
        .fi-ss-sort-alpha-up-alt:before {
    content: "\ee5b";
    }
        .fi-ss-sort-alt:before {
    content: "\ee5c";
    }
        .fi-ss-sort-amount-down:before {
    content: "\ee5d";
    }
        .fi-ss-sort-amount-down-alt:before {
    content: "\ee5e";
    }
        .fi-ss-sort-amount-up:before {
    content: "\ee5f";
    }
        .fi-ss-sort-amount-up-alt:before {
    content: "\ee60";
    }
        .fi-ss-sort-circle:before {
    content: "\ee61";
    }
        .fi-ss-sort-circle-down:before {
    content: "\ee62";
    }
        .fi-ss-sort-circle-up:before {
    content: "\ee63";
    }
        .fi-ss-sort-down:before {
    content: "\ee64";
    }
        .fi-ss-sort-numeric-down:before {
    content: "\ee65";
    }
        .fi-ss-sort-numeric-down-alt:before {
    content: "\ee66";
    }
        .fi-ss-sort-shapes-down:before {
    content: "\ee67";
    }
        .fi-ss-sort-shapes-up:before {
    content: "\ee68";
    }
        .fi-ss-sort-size-down:before {
    content: "\ee69";
    }
        .fi-ss-sort-size-up:before {
    content: "\ee6a";
    }
        .fi-ss-soup:before {
    content: "\ee6c";
    }
        .fi-ss-source-data:before {
    content: "\ee6d";
    }
        .fi-ss-source-document:before {
    content: "\ee6e";
    }
        .fi-ss-source-document-alt:before {
    content: "\ee6f";
    }
        .fi-ss-spa:before {
    content: "\ee70";
    }
        .fi-ss-space-shuttle:before {
    content: "\ee71";
    }
        .fi-ss-space-station-moon:before {
    content: "\ee72";
    }
        .fi-ss-space-station-moon-alt:before {
    content: "\ee73";
    }
        .fi-ss-spade:before {
    content: "\ee74";
    }
        .fi-ss-spaghetti-monster-flying:before {
    content: "\ee75";
    }
        .fi-ss-spain-map:before {
    content: "\ee76";
    }
        .fi-ss-spanish:before {
    content: "\ee77";
    }
        .fi-ss-sparkles:before {
    content: "\ee79";
    }
        .fi-ss-spartan-helmet:before {
    content: "\ee7a";
    }
        .fi-ss-speaker:before {
    content: "\ee7b";
    }
        .fi-ss-speakers:before {
    content: "\ee7c";
    }
        .fi-ss-speech-bubble-story:before {
    content: "\ee7d";
    }
        .fi-ss-speedometer-arrow:before {
    content: "\ee7e";
    }
        .fi-ss-speedometer-kpi:before {
    content: "\ee7f";
    }
        .fi-ss-sphere:before {
    content: "\ee80";
    }
        .fi-ss-spider:before {
    content: "\ee81";
    }
        .fi-ss-spider-black-widow:before {
    content: "\ee82";
    }
        .fi-ss-spider-web:before {
    content: "\ee83";
    }
        .fi-ss-spinner:before {
    content: "\ee84";
    }
        .fi-ss-split:before {
    content: "\ee85";
    }
        .fi-ss-split-up-relation:before {
    content: "\ee86";
    }
        .fi-ss-splotch:before {
    content: "\ee87";
    }
        .fi-ss-spoon:before {
    content: "\ee88";
    }
        .fi-ss-spray-can:before {
    content: "\ee8b";
    }
        .fi-ss-spray-can-sparkles:before {
    content: "\ee8c";
    }
        .fi-ss-spring-calendar:before {
    content: "\ee8d";
    }
        .fi-ss-sprinkler:before {
    content: "\ee8e";
    }
        .fi-ss-spy:before {
    content: "\ee8f";
    }
        .fi-ss-sql-file:before {
    content: "\ee90";
    }
        .fi-ss-sql-server:before {
    content: "\ee91";
    }
        .fi-ss-square:before {
    content: "\ee92";
    }
        .fi-ss-square-0:before {
    content: "\ee93";
    }
        .fi-ss-square-1:before {
    content: "\ee94";
    }
        .fi-ss-square-2:before {
    content: "\ee95";
    }
        .fi-ss-square-3:before {
    content: "\ee96";
    }
        .fi-ss-square-4:before {
    content: "\ee97";
    }
        .fi-ss-square-5:before {
    content: "\ee98";
    }
        .fi-ss-square-6:before {
    content: "\ee99";
    }
        .fi-ss-square-7:before {
    content: "\ee9a";
    }
        .fi-ss-square-8:before {
    content: "\ee9b";
    }
        .fi-ss-square-9:before {
    content: "\ee9c";
    }
        .fi-ss-square-a:before {
    content: "\ee9d";
    }
        .fi-ss-square-b:before {
    content: "\ee9f";
    }
        .fi-ss-square-bolt:before {
    content: "\eea0";
    }
        .fi-ss-square-c:before {
    content: "\eea1";
    }
        .fi-ss-square-code:before {
    content: "\eea2";
    }
        .fi-ss-square-d:before {
    content: "\eea3";
    }
        .fi-ss-square-dashed:before {
    content: "\eea4";
    }
        .fi-ss-square-dashed-circle-plus:before {
    content: "\eea5";
    }
        .fi-ss-square-divide:before {
    content: "\eea6";
    }
        .fi-ss-square-e:before {
    content: "\eea7";
    }
        .fi-ss-square-ellipsis:before {
    content: "\eea8";
    }
        .fi-ss-square-ellipsis-vertical:before {
    content: "\eea9";
    }
        .fi-ss-square-exclamation:before {
    content: "\eeaa";
    }
        .fi-ss-square-f:before {
    content: "\eeab";
    }
        .fi-ss-square-g:before {
    content: "\eeac";
    }
        .fi-ss-square-h:before {
    content: "\eead";
    }
        .fi-ss-square-heart:before {
    content: "\eeae";
    }
        .fi-ss-square-i:before {
    content: "\eeaf";
    }
        .fi-ss-square-info:before {
    content: "\eeb0";
    }
        .fi-ss-square-j:before {
    content: "\eeb1";
    }
        .fi-ss-square-k:before {
    content: "\eeb2";
    }
        .fi-ss-square-kanban:before {
    content: "\eeb3";
    }
        .fi-ss-square-l:before {
    content: "\eeb4";
    }
        .fi-ss-square-m:before {
    content: "\eeb5";
    }
        .fi-ss-square-minus:before {
    content: "\eeb6";
    }
        .fi-ss-square-n:before {
    content: "\eeb7";
    }
        .fi-ss-square-o:before {
    content: "\eeb8";
    }
        .fi-ss-square-p:before {
    content: "\eeb9";
    }
        .fi-ss-square-phone-hangup:before {
    content: "\eebb";
    }
        .fi-ss-square-plus:before {
    content: "\eebc";
    }
        .fi-ss-square-poll-horizontal:before {
    content: "\eebd";
    }
        .fi-ss-square-poll-vertical:before {
    content: "\eebe";
    }
        .fi-ss-square-q:before {
    content: "\eebf";
    }
        .fi-ss-square-quote:before {
    content: "\eec0";
    }
        .fi-ss-square-r:before {
    content: "\eec1";
    }
        .fi-ss-square-root:before {
    content: "\eec2";
    }
        .fi-ss-square-s:before {
    content: "\eec3";
    }
        .fi-ss-square-small:before {
    content: "\eec4";
    }
        .fi-ss-square-star:before {
    content: "\eec5";
    }
        .fi-ss-square-t:before {
    content: "\eec6";
    }
        .fi-ss-square-terminal:before {
    content: "\eec7";
    }
        .fi-ss-square-u:before {
    content: "\eec8";
    }
        .fi-ss-square-up-right:before {
    content: "\eec9";
    }
        .fi-ss-square-v:before {
    content: "\eeca";
    }
        .fi-ss-square-w:before {
    content: "\eecb";
    }
        .fi-ss-square-x:before {
    content: "\eecc";
    }
        .fi-ss-square-y:before {
    content: "\eecd";
    }
        .fi-ss-square-z:before {
    content: "\eece";
    }
        .fi-ss-squid:before {
    content: "\eecf";
    }
        .fi-ss-squircle:before {
    content: "\eed0";
    }
        .fi-ss-squirrel:before {
    content: "\eed1";
    }
        .fi-ss-staff:before {
    content: "\eed2";
    }
        .fi-ss-stage:before {
    content: "\eed3";
    }
        .fi-ss-stage-concert:before {
    content: "\eed4";
    }
        .fi-ss-stage-theatre:before {
    content: "\eed5";
    }
        .fi-ss-stairs:before {
    content: "\eed6";
    }
        .fi-ss-stamp:before {
    content: "\eed7";
    }
        .fi-ss-standard-definition:before {
    content: "\eed8";
    }
        .fi-ss-star:before {
    content: "\eed9";
    }
        .fi-ss-star-and-crescent:before {
    content: "\eeda";
    }
        .fi-ss-star-christmas:before {
    content: "\eedb";
    }
        .fi-ss-star-comment-alt:before {
    content: "\eedc";
    }
        .fi-ss-star-exclamation:before {
    content: "\eedd";
    }
        .fi-ss-star-octogram:before {
    content: "\eede";
    }
        .fi-ss-star-of-david:before {
    content: "\eedf";
    }
        .fi-ss-star-rating-call:before {
    content: "\eee0";
    }
        .fi-ss-star-sharp-half:before {
    content: "\eee1";
    }
        .fi-ss-star-sharp-half-stroke:before {
    content: "\eee2";
    }
        .fi-ss-star-shooting:before {
    content: "\eee3";
    }
        .fi-ss-starfighter:before {
    content: "\eee5";
    }
        .fi-ss-stars:before {
    content: "\eee6";
    }
        .fi-ss-state-country:before {
    content: "\eee7";
    }
        .fi-ss-stationary-bike:before {
    content: "\eee8";
    }
        .fi-ss-stats:before {
    content: "\eee9";
    }
        .fi-ss-steak:before {
    content: "\eeea";
    }
        .fi-ss-steam-iron:before {
    content: "\eeeb";
    }
        .fi-ss-steering-wheel:before {
    content: "\eeec";
    }
        .fi-ss-step-backward:before {
    content: "\eeed";
    }
        .fi-ss-step-forward:before {
    content: "\eeee";
    }
        .fi-ss-steps-carreer:before {
    content: "\eeef";
    }
        .fi-ss-sterling-sign:before {
    content: "\eef0";
    }
        .fi-ss-stethoscope:before {
    content: "\eef1";
    }
        .fi-ss-sticker:before {
    content: "\eef2";
    }
        .fi-ss-stocking:before {
    content: "\eef4";
    }
        .fi-ss-stomach:before {
    content: "\eef5";
    }
        .fi-ss-stop:before {
    content: "\eef6";
    }
        .fi-ss-stop-circle:before {
    content: "\eef7";
    }
        .fi-ss-stop-square:before {
    content: "\eef8";
    }
        .fi-ss-stopwatch:before {
    content: "\eef9";
    }
        .fi-ss-store-alt:before {
    content: "\eefb";
    }
        .fi-ss-store-buyer:before {
    content: "\eefc";
    }
        .fi-ss-store-lock:before {
    content: "\eefd";
    }
        .fi-ss-store-slash:before {
    content: "\eefe";
    }
        .fi-ss-story-book:before {
    content: "\eeff";
    }
        .fi-ss-story-fairy-tale:before {
    content: "\ef00";
    }
        .fi-ss-story-fantasy:before {
    content: "\ef01";
    }
        .fi-ss-strategy-chess-risk:before {
    content: "\ef03";
    }
        .fi-ss-strawberry:before {
    content: "\ef04";
    }
        .fi-ss-street-view:before {
    content: "\ef05";
    }
        .fi-ss-stretcher:before {
    content: "\ef06";
    }
        .fi-ss-strikethrough:before {
    content: "\ef07";
    }
        .fi-ss-stroopwafel:before {
    content: "\ef09";
    }
        .fi-ss-student:before {
    content: "\ef0a";
    }
        .fi-ss-student-alt:before {
    content: "\ef0b";
    }
        .fi-ss-subfolder:before {
    content: "\ef0c";
    }
        .fi-ss-subscript:before {
    content: "\ef0f";
    }
        .fi-ss-subscription:before {
    content: "\ef10";
    }
        .fi-ss-subscription-alt:before {
    content: "\ef11";
    }
        .fi-ss-subscription-model:before {
    content: "\ef12";
    }
        .fi-ss-subscription-user:before {
    content: "\ef13";
    }
        .fi-ss-subtitles:before {
    content: "\ef18";
    }
        .fi-ss-subtitles-slash:before {
    content: "\ef19";
    }
        .fi-ss-subway:before {
    content: "\ef1a";
    }
        .fi-ss-suitcase-alt:before {
    content: "\ef1b";
    }
        .fi-ss-summary-check:before {
    content: "\ef1c";
    }
        .fi-ss-summer:before {
    content: "\ef1d";
    }
        .fi-ss-sun:before {
    content: "\ef1e";
    }
        .fi-ss-sun-dust:before {
    content: "\ef1f";
    }
        .fi-ss-sun-plant-wilt:before {
    content: "\ef20";
    }
        .fi-ss-sun-salutation-yoga:before {
    content: "\ef21";
    }
        .fi-ss-sunday:before {
    content: "\ef22";
    }
        .fi-ss-sunglasses:before {
    content: "\ef23";
    }
        .fi-ss-sunglasses-alt:before {
    content: "\ef24";
    }
        .fi-ss-sunrise:before {
    content: "\ef25";
    }
        .fi-ss-sunrise-alt:before {
    content: "\ef26";
    }
        .fi-ss-sunscreen:before {
    content: "\ef27";
    }
        .fi-ss-sunset:before {
    content: "\ef28";
    }
        .fi-ss-superscript:before {
    content: "\ef29";
    }
        .fi-ss-supplier:before {
    content: "\ef2a";
    }
        .fi-ss-supplier-alt:before {
    content: "\ef2b";
    }
        .fi-ss-surfing:before {
    content: "\ef2c";
    }
        .fi-ss-surprise:before {
    content: "\ef2d";
    }
        .fi-ss-surveillance-camera:before {
    content: "\ef2e";
    }
        .fi-ss-survey-xmark:before {
    content: "\ef2f";
    }
        .fi-ss-sushi:before {
    content: "\ef30";
    }
        .fi-ss-sushi-alt:before {
    content: "\ef31";
    }
        .fi-ss-sushi-roll:before {
    content: "\ef32";
    }
        .fi-ss-svg:before {
    content: "\ef33";
    }
        .fi-ss-swap:before {
    content: "\ef34";
    }
        .fi-ss-swatchbook:before {
    content: "\ef36";
    }
        .fi-ss-swimmer:before {
    content: "\ef38";
    }
        .fi-ss-swimming-pool:before {
    content: "\ef39";
    }
        .fi-ss-swing:before {
    content: "\ef3a";
    }
        .fi-ss-swipe-down:before {
    content: "\ef3b";
    }
        .fi-ss-swipe-left:before {
    content: "\ef3c";
    }
        .fi-ss-swipe-right:before {
    content: "\ef3d";
    }
        .fi-ss-swipe-up:before {
    content: "\ef3e";
    }
        .fi-ss-sword:before {
    content: "\ef3f";
    }
        .fi-ss-sword-alt:before {
    content: "\ef40";
    }
        .fi-ss-symbol:before {
    content: "\ef41";
    }
        .fi-ss-symbols:before {
    content: "\ef42";
    }
        .fi-ss-synagogue:before {
    content: "\ef43";
    }
        .fi-ss-syringe:before {
    content: "\ef44";
    }
        .fi-ss-syringe-injection-blood:before {
    content: "\ef45";
    }
        .fi-ss-system-cloud:before {
    content: "\ef46";
    }
        .fi-ss-t:before {
    content: "\ef47";
    }
        .fi-ss-t-rex:before {
    content: "\ef49";
    }
        .fi-ss-tab-folder:before {
    content: "\ef4a";
    }
        .fi-ss-table:before {
    content: "\ef4b";
    }
        .fi-ss-table-columns:before {
    content: "\ef4c";
    }
        .fi-ss-table-layout:before {
    content: "\ef4d";
    }
        .fi-ss-table-list:before {
    content: "\ef4e";
    }
        .fi-ss-table-picnic:before {
    content: "\ef4f";
    }
        .fi-ss-table-pivot:before {
    content: "\ef50";
    }
        .fi-ss-table-rows:before {
    content: "\ef51";
    }
        .fi-ss-table-tree:before {
    content: "\ef52";
    }
        .fi-ss-tablet:before {
    content: "\ef53";
    }
        .fi-ss-tablet-android:before {
    content: "\ef54";
    }
        .fi-ss-tablet-android-alt:before {
    content: "\ef55";
    }
        .fi-ss-tablet-rugged:before {
    content: "\ef56";
    }
        .fi-ss-tachometer:before {
    content: "\ef57";
    }
        .fi-ss-tachometer-alt-average:before {
    content: "\ef58";
    }
        .fi-ss-tachometer-alt-fastest:before {
    content: "\ef59";
    }
        .fi-ss-tachometer-alt-slow:before {
    content: "\ef5a";
    }
        .fi-ss-tachometer-alt-slowest:before {
    content: "\ef5b";
    }
        .fi-ss-tachometer-average:before {
    content: "\ef5c";
    }
        .fi-ss-tachometer-fast:before {
    content: "\ef5d";
    }
        .fi-ss-tachometer-fastest:before {
    content: "\ef5e";
    }
        .fi-ss-tachometer-slow:before {
    content: "\ef5f";
    }
        .fi-ss-tachometer-slowest:before {
    content: "\ef60";
    }
        .fi-ss-taco:before {
    content: "\ef61";
    }
        .fi-ss-tags:before {
    content: "\ef62";
    }
        .fi-ss-talent:before {
    content: "\ef63";
    }
        .fi-ss-talent-alt:before {
    content: "\ef64";
    }
        .fi-ss-talent-hand:before {
    content: "\ef65";
    }
        .fi-ss-tally:before {
    content: "\ef66";
    }
        .fi-ss-tally-1:before {
    content: "\ef67";
    }
        .fi-ss-tally-2:before {
    content: "\ef68";
    }
        .fi-ss-tally-3:before {
    content: "\ef69";
    }
        .fi-ss-tally-4:before {
    content: "\ef6a";
    }
        .fi-ss-tamale:before {
    content: "\ef6b";
    }
        .fi-ss-tank-water:before {
    content: "\ef6d";
    }
        .fi-ss-tap:before {
    content: "\ef6e";
    }
        .fi-ss-tape:before {
    content: "\ef6f";
    }
        .fi-ss-target:before {
    content: "\ef70";
    }
        .fi-ss-target-audience:before {
    content: "\ef71";
    }
        .fi-ss-tattoo-machine:before {
    content: "\ef72";
    }
        .fi-ss-tax:before {
    content: "\ef73";
    }
        .fi-ss-tax-alt:before {
    content: "\ef74";
    }
        .fi-ss-taxi:before {
    content: "\ef75";
    }
        .fi-ss-taxi-bus:before {
    content: "\ef76";
    }
        .fi-ss-team-check:before {
    content: "\ef77";
    }
        .fi-ss-team-check-alt:before {
    content: "\ef78";
    }
        .fi-ss-teddy-bear:before {
    content: "\ef79";
    }
        .fi-ss-teeth-open:before {
    content: "\ef7a";
    }
        .fi-ss-telescope:before {
    content: "\ef7c";
    }
        .fi-ss-temperature-down:before {
    content: "\ef7d";
    }
        .fi-ss-temperature-frigid:before {
    content: "\ef7e";
    }
        .fi-ss-temperature-high:before {
    content: "\ef7f";
    }
        .fi-ss-temperature-list:before {
    content: "\ef80";
    }
        .fi-ss-temperature-low:before {
    content: "\ef81";
    }
        .fi-ss-temperature-up:before {
    content: "\ef82";
    }
        .fi-ss-template:before {
    content: "\ef83";
    }
        .fi-ss-template-alt:before {
    content: "\ef84";
    }
        .fi-ss-tenge:before {
    content: "\ef86";
    }
        .fi-ss-tennis:before {
    content: "\ef87";
    }
        .fi-ss-tent-arrow-down-to-line:before {
    content: "\ef88";
    }
        .fi-ss-tent-arrow-left-right:before {
    content: "\ef89";
    }
        .fi-ss-tent-arrow-turn-left:before {
    content: "\ef8a";
    }
        .fi-ss-tent-arrows-down:before {
    content: "\ef8b";
    }
        .fi-ss-tents:before {
    content: "\ef8c";
    }
        .fi-ss-terminal:before {
    content: "\ef8d";
    }
        .fi-ss-terrace:before {
    content: "\ef90";
    }
        .fi-ss-test:before {
    content: "\ef91";
    }
        .fi-ss-test-tube:before {
    content: "\ef92";
    }
        .fi-ss-text:before {
    content: "\ef93";
    }
        .fi-ss-text-box:before {
    content: "\ef94";
    }
        .fi-ss-text-box-dots:before {
    content: "\ef95";
    }
        .fi-ss-text-box-edit:before {
    content: "\ef96";
    }
        .fi-ss-text-check:before {
    content: "\ef97";
    }
        .fi-ss-text-height:before {
    content: "\ef98";
    }
        .fi-ss-text-input-left:before {
    content: "\ef99";
    }
        .fi-ss-text-shadow:before {
    content: "\ef9a";
    }
        .fi-ss-text-size:before {
    content: "\ef9b";
    }
        .fi-ss-text-slash:before {
    content: "\ef9c";
    }
        .fi-ss-text-width:before {
    content: "\ef9d";
    }
        .fi-ss-theater-masks:before {
    content: "\ef9f";
    }
        .fi-ss-thermometer-alt:before {
    content: "\efa0";
    }
        .fi-ss-thermometer-empty:before {
    content: "\efa1";
    }
        .fi-ss-thermometer-full:before {
    content: "\efa2";
    }
        .fi-ss-thermometer-half:before {
    content: "\efa3";
    }
        .fi-ss-thermometer-quarter:before {
    content: "\efa4";
    }
        .fi-ss-thermometer-three-quarters:before {
    content: "\efa5";
    }
        .fi-ss-theta:before {
    content: "\efa6";
    }
        .fi-ss-third:before {
    content: "\efa7";
    }
        .fi-ss-third-award:before {
    content: "\efa8";
    }
        .fi-ss-third-laurel:before {
    content: "\efa9";
    }
        .fi-ss-third-medal:before {
    content: "\efaa";
    }
        .fi-ss-thought-bubble:before {
    content: "\efab";
    }
        .fi-ss-three-direction:before {
    content: "\efad";
    }
        .fi-ss-three-leaf-clover:before {
    content: "\efae";
    }
        .fi-ss-thumbs-up-trust:before {
    content: "\efb1";
    }
        .fi-ss-thumbtack:before {
    content: "\efb2";
    }
        .fi-ss-thumbtack-slash:before {
    content: "\efb3";
    }
        .fi-ss-thunderstorm:before {
    content: "\efb4";
    }
        .fi-ss-thunderstorm-moon:before {
    content: "\efb5";
    }
        .fi-ss-thunderstorm-risk:before {
    content: "\efb6";
    }
        .fi-ss-thunderstorm-sun:before {
    content: "\efb7";
    }
        .fi-ss-thursday:before {
    content: "\efb8";
    }
        .fi-ss-ticket:before {
    content: "\efb9";
    }
        .fi-ss-ticket-airline:before {
    content: "\efba";
    }
        .fi-ss-ticket-alt:before {
    content: "\efbb";
    }
        .fi-ss-tickets:before {
    content: "\efbc";
    }
        .fi-ss-tickets-airline:before {
    content: "\efbd";
    }
        .fi-ss-tie:before {
    content: "\efbe";
    }
        .fi-ss-tilde:before {
    content: "\efc0";
    }
        .fi-ss-time-add:before {
    content: "\efc1";
    }
        .fi-ss-time-check:before {
    content: "\efc2";
    }
        .fi-ss-time-delete:before {
    content: "\efc3";
    }
        .fi-ss-time-fast:before {
    content: "\efc4";
    }
        .fi-ss-time-forward:before {
    content: "\efc5";
    }
        .fi-ss-time-forward-sixty:before {
    content: "\efc6";
    }
        .fi-ss-time-forward-ten:before {
    content: "\efc7";
    }
        .fi-ss-time-half-past:before {
    content: "\efc8";
    }
        .fi-ss-time-oclock:before {
    content: "\efc9";
    }
        .fi-ss-time-past:before {
    content: "\efca";
    }
        .fi-ss-time-quarter-past:before {
    content: "\efcb";
    }
        .fi-ss-time-quarter-to:before {
    content: "\efcc";
    }
        .fi-ss-time-twenty-four:before {
    content: "\efcd";
    }
        .fi-ss-time-watch-calendar:before {
    content: "\efce";
    }
        .fi-ss-timer-clock-call:before {
    content: "\efcf";
    }
        .fi-ss-times-hexagon:before {
    content: "\efd0";
    }
        .fi-ss-tint-slash:before {
    content: "\efd1";
    }
        .fi-ss-tip-button:before {
    content: "\efd2";
    }
        .fi-ss-tip-button-hand:before {
    content: "\efd3";
    }
        .fi-ss-tip-coin:before {
    content: "\efd4";
    }
        .fi-ss-tire:before {
    content: "\efd8";
    }
        .fi-ss-tire-flat:before {
    content: "\efd9";
    }
        .fi-ss-tire-pressure-warning:before {
    content: "\efda";
    }
        .fi-ss-tire-rugged:before {
    content: "\efdb";
    }
        .fi-ss-tired:before {
    content: "\efdc";
    }
        .fi-ss-to-do:before {
    content: "\efdd";
    }
        .fi-ss-to-do-alt:before {
    content: "\efde";
    }
        .fi-ss-together-people:before {
    content: "\efe1";
    }
        .fi-ss-toggle-off:before {
    content: "\efe3";
    }
        .fi-ss-toggle-on:before {
    content: "\efe4";
    }
        .fi-ss-toilet:before {
    content: "\efe5";
    }
        .fi-ss-toilet-paper-blank:before {
    content: "\efe6";
    }
        .fi-ss-toilet-paper-blank-under:before {
    content: "\efe7";
    }
        .fi-ss-toilet-paper-slash:before {
    content: "\efe8";
    }
        .fi-ss-toilet-paper-under:before {
    content: "\efe9";
    }
        .fi-ss-toilet-paper-under-slash:before {
    content: "\efea";
    }
        .fi-ss-toilet-paper-xmark:before {
    content: "\efeb";
    }
        .fi-ss-toilets-portable:before {
    content: "\efec";
    }
        .fi-ss-token:before {
    content: "\efed";
    }
        .fi-ss-tomato:before {
    content: "\efee";
    }
        .fi-ss-tombstone:before {
    content: "\efef";
    }
        .fi-ss-tombstone-alt:before {
    content: "\eff0";
    }
        .fi-ss-tool-box:before {
    content: "\eff1";
    }
        .fi-ss-tool-crop:before {
    content: "\eff2";
    }
        .fi-ss-tool-marquee:before {
    content: "\eff3";
    }
        .fi-ss-tools:before {
    content: "\eff4";
    }
        .fi-ss-tooth:before {
    content: "\eff5";
    }
        .fi-ss-toothbrush:before {
    content: "\eff6";
    }
        .fi-ss-torch-inspiration-leader:before {
    content: "\eff7";
    }
        .fi-ss-torii-gate:before {
    content: "\eff8";
    }
        .fi-ss-tornado:before {
    content: "\eff9";
    }
        .fi-ss-total:before {
    content: "\effa";
    }
        .fi-ss-tour-guide-people:before {
    content: "\effb";
    }
        .fi-ss-tour-virtual:before {
    content: "\effc";
    }
        .fi-ss-tower-control:before {
    content: "\effe";
    }
        .fi-ss-track:before {
    content: "\efff";
    }
        .fi-ss-tractor:before {
    content: "\f000";
    }
        .fi-ss-trademark:before {
    content: "\f001";
    }
        .fi-ss-traffic-cone:before {
    content: "\f002";
    }
        .fi-ss-traffic-light:before {
    content: "\f003";
    }
        .fi-ss-traffic-light-go:before {
    content: "\f004";
    }
        .fi-ss-traffic-light-slow:before {
    content: "\f005";
    }
        .fi-ss-traffic-light-stop:before {
    content: "\f006";
    }
        .fi-ss-trailer:before {
    content: "\f007";
    }
        .fi-ss-train:before {
    content: "\f008";
    }
        .fi-ss-train-journey:before {
    content: "\f009";
    }
        .fi-ss-train-side:before {
    content: "\f00a";
    }
        .fi-ss-train-station:before {
    content: "\f00b";
    }
        .fi-ss-train-station-building:before {
    content: "\f00c";
    }
        .fi-ss-train-subway-tunnel:before {
    content: "\f00d";
    }
        .fi-ss-train-track:before {
    content: "\f00e";
    }
        .fi-ss-train-tram:before {
    content: "\f00f";
    }
        .fi-ss-tram:before {
    content: "\f010";
    }
        .fi-ss-transaction-euro:before {
    content: "\f011";
    }
        .fi-ss-transaction-globe:before {
    content: "\f012";
    }
        .fi-ss-transaction-yen:before {
    content: "\f013";
    }
        .fi-ss-transform:before {
    content: "\f014";
    }
        .fi-ss-transformation-block:before {
    content: "\f015";
    }
        .fi-ss-transformation-circle:before {
    content: "\f016";
    }
        .fi-ss-transformation-design:before {
    content: "\f017";
    }
        .fi-ss-transformation-shapes:before {
    content: "\f018";
    }
        .fi-ss-transformer-bolt:before {
    content: "\f019";
    }
        .fi-ss-transgender:before {
    content: "\f01a";
    }
        .fi-ss-translate:before {
    content: "\f01b";
    }
        .fi-ss-transparency:before {
    content: "\f01c";
    }
        .fi-ss-transporter:before {
    content: "\f01d";
    }
        .fi-ss-transporter-1:before {
    content: "\f01e";
    }
        .fi-ss-transporter-2:before {
    content: "\f01f";
    }
        .fi-ss-transporter-3:before {
    content: "\f020";
    }
        .fi-ss-transporter-4:before {
    content: "\f021";
    }
        .fi-ss-transporter-5:before {
    content: "\f022";
    }
        .fi-ss-transporter-6:before {
    content: "\f023";
    }
        .fi-ss-transporter-7:before {
    content: "\f024";
    }
        .fi-ss-transporter-empty:before {
    content: "\f025";
    }
        .fi-ss-trash:before {
    content: "\f026";
    }
        .fi-ss-trash-bag:before {
    content: "\f027";
    }
        .fi-ss-trash-can-check:before {
    content: "\f028";
    }
        .fi-ss-trash-can-clock:before {
    content: "\f029";
    }
        .fi-ss-trash-can-list:before {
    content: "\f02a";
    }
        .fi-ss-trash-can-plus:before {
    content: "\f02b";
    }
        .fi-ss-trash-can-slash:before {
    content: "\f02c";
    }
        .fi-ss-trash-check:before {
    content: "\f02d";
    }
        .fi-ss-trash-clock:before {
    content: "\f02e";
    }
        .fi-ss-trash-empty:before {
    content: "\f02f";
    }
        .fi-ss-trash-list:before {
    content: "\f030";
    }
        .fi-ss-trash-plus:before {
    content: "\f031";
    }
        .fi-ss-trash-restore:before {
    content: "\f032";
    }
        .fi-ss-trash-restore-alt:before {
    content: "\f033";
    }
        .fi-ss-trash-slash:before {
    content: "\f034";
    }
        .fi-ss-trash-undo:before {
    content: "\f035";
    }
        .fi-ss-trash-undo-alt:before {
    content: "\f036";
    }
        .fi-ss-trash-xmark:before {
    content: "\f037";
    }
        .fi-ss-treadmill:before {
    content: "\f039";
    }
        .fi-ss-treasure-chest:before {
    content: "\f03a";
    }
        .fi-ss-treatment:before {
    content: "\f03b";
    }
        .fi-ss-tree:before {
    content: "\f03c";
    }
        .fi-ss-tree-alt:before {
    content: "\f03d";
    }
        .fi-ss-tree-christmas:before {
    content: "\f03e";
    }
        .fi-ss-tree-deciduous:before {
    content: "\f03f";
    }
        .fi-ss-trees:before {
    content: "\f041";
    }
        .fi-ss-trees-alt:before {
    content: "\f042";
    }
        .fi-ss-triangle:before {
    content: "\f044";
    }
        .fi-ss-triangle-music:before {
    content: "\f045";
    }
        .fi-ss-triangle-person-digging:before {
    content: "\f046";
    }
        .fi-ss-triangle-warning:before {
    content: "\f047";
    }
        .fi-ss-tricycle:before {
    content: "\f048";
    }
        .fi-ss-trillium:before {
    content: "\f049";
    }
        .fi-ss-troph-cap:before {
    content: "\f04c";
    }
        .fi-ss-trophy:before {
    content: "\f04d";
    }
        .fi-ss-trophy-achievement-skill:before {
    content: "\f04e";
    }
        .fi-ss-trophy-star:before {
    content: "\f04f";
    }
        .fi-ss-truck-arrow-left:before {
    content: "\f050";
    }
        .fi-ss-truck-arrow-right:before {
    content: "\f051";
    }
        .fi-ss-truck-bolt:before {
    content: "\f052";
    }
        .fi-ss-truck-box:before {
    content: "\f053";
    }
        .fi-ss-truck-check:before {
    content: "\f054";
    }
        .fi-ss-truck-container:before {
    content: "\f056";
    }
        .fi-ss-truck-container-empty:before {
    content: "\f057";
    }
        .fi-ss-truck-couch:before {
    content: "\f058";
    }
        .fi-ss-truck-droplet:before {
    content: "\f059";
    }
        .fi-ss-truck-fire:before {
    content: "\f05a";
    }
        .fi-ss-truck-flatbed:before {
    content: "\f05b";
    }
        .fi-ss-truck-front:before {
    content: "\f05c";
    }
        .fi-ss-truck-ladder:before {
    content: "\f05d";
    }
        .fi-ss-truck-loading:before {
    content: "\f05e";
    }
        .fi-ss-truck-medical:before {
    content: "\f05f";
    }
        .fi-ss-truck-monster:before {
    content: "\f060";
    }
        .fi-ss-truck-moving:before {
    content: "\f061";
    }
        .fi-ss-truck-pickup:before {
    content: "\f062";
    }
        .fi-ss-truck-plow:before {
    content: "\f063";
    }
        .fi-ss-truck-ramp:before {
    content: "\f064";
    }
        .fi-ss-truck-side:before {
    content: "\f065";
    }
        .fi-ss-truck-tow:before {
    content: "\f066";
    }
        .fi-ss-truck-utensils:before {
    content: "\f067";
    }
        .fi-ss-trumpet:before {
    content: "\f068";
    }
        .fi-ss-trust:before {
    content: "\f069";
    }
        .fi-ss-trust-alt:before {
    content: "\f06a";
    }
        .fi-ss-tshirt:before {
    content: "\f06b";
    }
        .fi-ss-tty:before {
    content: "\f06c";
    }
        .fi-ss-tty-answer:before {
    content: "\f06d";
    }
        .fi-ss-tubes:before {
    content: "\f06e";
    }
        .fi-ss-tuesday:before {
    content: "\f070";
    }
        .fi-ss-tugrik-sign:before {
    content: "\f071";
    }
        .fi-ss-turkey:before {
    content: "\f073";
    }
        .fi-ss-turn-left:before {
    content: "\f074";
    }
        .fi-ss-turn-left-down:before {
    content: "\f075";
    }
        .fi-ss-turn-right:before {
    content: "\f076";
    }
        .fi-ss-turntable:before {
    content: "\f077";
    }
        .fi-ss-turtle:before {
    content: "\f078";
    }
        .fi-ss-tv-music:before {
    content: "\f079";
    }
        .fi-ss-tv-retro:before {
    content: "\f07a";
    }
        .fi-ss-two-nails:before {
    content: "\f080";
    }
        .fi-ss-two-swords:before {
    content: "\f081";
    }
        .fi-ss-typewriter:before {
    content: "\f083";
    }
        .fi-ss-u:before {
    content: "\f084";
    }
        .fi-ss-ufo:before {
    content: "\f087";
    }
        .fi-ss-ufo-beam:before {
    content: "\f088";
    }
        .fi-ss-ui-ux:before {
    content: "\f089";
    }
        .fi-ss-umbrella:before {
    content: "\f08a";
    }
        .fi-ss-umbrella-beach:before {
    content: "\f08b";
    }
        .fi-ss-under-construction:before {
    content: "\f08c";
    }
        .fi-ss-underline:before {
    content: "\f08d";
    }
        .fi-ss-undo:before {
    content: "\f08e";
    }
        .fi-ss-undo-alt:before {
    content: "\f08f";
    }
        .fi-ss-unicorn:before {
    content: "\f090";
    }
        .fi-ss-uniform-martial-arts:before {
    content: "\f091";
    }
        .fi-ss-universal-access:before {
    content: "\f094";
    }
        .fi-ss-unlock:before {
    content: "\f095";
    }
        .fi-ss-up:before {
    content: "\f097";
    }
        .fi-ss-up-from-bracket:before {
    content: "\f098";
    }
        .fi-ss-up-left:before {
    content: "\f099";
    }
        .fi-ss-up-right:before {
    content: "\f09a";
    }
        .fi-ss-up-right-from-square:before {
    content: "\f09b";
    }
        .fi-ss-upload:before {
    content: "\f09c";
    }
        .fi-ss-url:before {
    content: "\f09f";
    }
        .fi-ss-usa-map:before {
    content: "\f0a0";
    }
        .fi-ss-usa-map-pin:before {
    content: "\f0a1";
    }
        .fi-ss-usb-pendrive:before {
    content: "\f0a3";
    }
        .fi-ss-usd-circle:before {
    content: "\f0a4";
    }
        .fi-ss-usd-square:before {
    content: "\f0a5";
    }
        .fi-ss-user:before {
    content: "\f0a7";
    }
        .fi-ss-user-add:before {
    content: "\f0a8";
    }
        .fi-ss-user-alien:before {
    content: "\f0a9";
    }
        .fi-ss-user-astronaut:before {
    content: "\f0aa";
    }
        .fi-ss-user-check:before {
    content: "\f0ab";
    }
        .fi-ss-user-chef:before {
    content: "\f0ac";
    }
        .fi-ss-user-coach:before {
    content: "\f0ad";
    }
        .fi-ss-user-cowboy:before {
    content: "\f0ae";
    }
        .fi-ss-user-crown:before {
    content: "\f0af";
    }
        .fi-ss-user-dj:before {
    content: "\f0b1";
    }
        .fi-ss-user-eating:before {
    content: "\f0b2";
    }
        .fi-ss-user-experience:before {
    content: "\f0b3";
    }
        .fi-ss-user-fast-running:before {
    content: "\f0b4";
    }
        .fi-ss-user-forbidden:before {
    content: "\f0b5";
    }
        .fi-ss-user-forbidden-alt:before {
    content: "\f0b6";
    }
        .fi-ss-user-gear:before {
    content: "\f0b7";
    }
        .fi-ss-user-graduate:before {
    content: "\f0b8";
    }
        .fi-ss-user-hard-work:before {
    content: "\f0b9";
    }
        .fi-ss-user-headset:before {
    content: "\f0ba";
    }
        .fi-ss-user-helmet-safety:before {
    content: "\f0bb";
    }
        .fi-ss-user-india:before {
    content: "\f0bc";
    }
        .fi-ss-user-injured:before {
    content: "\f0bd";
    }
        .fi-ss-user-interface-ui:before {
    content: "\f0be";
    }
        .fi-ss-user-key:before {
    content: "\f0bf";
    }
        .fi-ss-user-language:before {
    content: "\f0c0";
    }
        .fi-ss-user-lock:before {
    content: "\f0c1";
    }
        .fi-ss-user-md:before {
    content: "\f0c2";
    }
        .fi-ss-user-md-chat:before {
    content: "\f0c3";
    }
        .fi-ss-user-minus:before {
    content: "\f0c4";
    }
        .fi-ss-user-music:before {
    content: "\f0c5";
    }
        .fi-ss-user-ninja:before {
    content: "\f0c6";
    }
        .fi-ss-user-nurse:before {
    content: "\f0c7";
    }
        .fi-ss-user-pen:before {
    content: "\f0c9";
    }
        .fi-ss-user-pilot:before {
    content: "\f0ca";
    }
        .fi-ss-user-pilot-tie:before {
    content: "\f0cb";
    }
        .fi-ss-user-police:before {
    content: "\f0cc";
    }
        .fi-ss-user-roadmap:before {
    content: "\f0cd";
    }
        .fi-ss-user-robot:before {
    content: "\f0ce";
    }
        .fi-ss-user-robot-xmarks:before {
    content: "\f0cf";
    }
        .fi-ss-user-salary:before {
    content: "\f0d0";
    }
        .fi-ss-user-shield:before {
    content: "\f0d1";
    }
        .fi-ss-user-skill-gear:before {
    content: "\f0d3";
    }
        .fi-ss-user-slash:before {
    content: "\f0d4";
    }
        .fi-ss-user-speaking:before {
    content: "\f0d5";
    }
        .fi-ss-user-stress:before {
    content: "\f0d6";
    }
        .fi-ss-user-suitcase:before {
    content: "\f0d7";
    }
        .fi-ss-user-tag:before {
    content: "\f0d8";
    }
        .fi-ss-user-time:before {
    content: "\f0d9";
    }
        .fi-ss-user-trust:before {
    content: "\f0da";
    }
        .fi-ss-user-unlock:before {
    content: "\f0db";
    }
        .fi-ss-user-visor:before {
    content: "\f0dc";
    }
        .fi-ss-user-volunteer:before {
    content: "\f0dd";
    }
        .fi-ss-user-xmark:before {
    content: "\f0de";
    }
        .fi-ss-users:before {
    content: "\f0df";
    }
        .fi-ss-users-alt:before {
    content: "\f0e0";
    }
        .fi-ss-users-class:before {
    content: "\f0e1";
    }
        .fi-ss-users-gear:before {
    content: "\f0e4";
    }
        .fi-ss-users-loyalty:before {
    content: "\f0e5";
    }
        .fi-ss-users-medical:before {
    content: "\f0e6";
    }
        .fi-ss-users-slash:before {
    content: "\f0e7";
    }
        .fi-ss-utensils:before {
    content: "\f0e8";
    }
        .fi-ss-utensils-slash:before {
    content: "\f0e9";
    }
        .fi-ss-utility-pole:before {
    content: "\f0ea";
    }
        .fi-ss-utility-pole-double:before {
    content: "\f0eb";
    }
        .fi-ss-ux:before {
    content: "\f0ec";
    }
        .fi-ss-ux-browser:before {
    content: "\f0ed";
    }
        .fi-ss-v:before {
    content: "\f0ee";
    }
        .fi-ss-vacuum:before {
    content: "\f0f0";
    }
        .fi-ss-vacuum-robot:before {
    content: "\f0f1";
    }
        .fi-ss-value-absolute:before {
    content: "\f0f2";
    }
        .fi-ss-vault:before {
    content: "\f0f3";
    }
        .fi-ss-vector:before {
    content: "\f0f4";
    }
        .fi-ss-vector-alt:before {
    content: "\f0f5";
    }
        .fi-ss-vector-circle:before {
    content: "\f0f6";
    }
        .fi-ss-vector-polygon:before {
    content: "\f0f7";
    }
        .fi-ss-venus:before {
    content: "\f0f8";
    }
        .fi-ss-venus-double:before {
    content: "\f0f9";
    }
        .fi-ss-venus-mars:before {
    content: "\f0fa";
    }
        .fi-ss-vest:before {
    content: "\f0fc";
    }
        .fi-ss-vest-patches:before {
    content: "\f0fd";
    }
        .fi-ss-video-arrow-down-left:before {
    content: "\f0fe";
    }
        .fi-ss-video-arrow-up-right:before {
    content: "\f0ff";
    }
        .fi-ss-video-camera:before {
    content: "\f100";
    }
        .fi-ss-video-camera-alt:before {
    content: "\f101";
    }
        .fi-ss-video-duration:before {
    content: "\f102";
    }
        .fi-ss-video-plus:before {
    content: "\f103";
    }
        .fi-ss-video-slash:before {
    content: "\f104";
    }
        .fi-ss-videoconference:before {
    content: "\f105";
    }
        .fi-ss-vihara:before {
    content: "\f107";
    }
        .fi-ss-violin:before {
    content: "\f109";
    }
        .fi-ss-virus:before {
    content: "\f10a";
    }
        .fi-ss-virus-slash:before {
    content: "\f10b";
    }
        .fi-ss-viruses:before {
    content: "\f10c";
    }
        .fi-ss-vision:before {
    content: "\f10e";
    }
        .fi-ss-vision-sense:before {
    content: "\f10f";
    }
        .fi-ss-vision-target:before {
    content: "\f110";
    }
        .fi-ss-visit:before {
    content: "\f111";
    }
        .fi-ss-voicemail:before {
    content: "\f114";
    }
        .fi-ss-volcano:before {
    content: "\f115";
    }
        .fi-ss-volleyball:before {
    content: "\f116";
    }
        .fi-ss-volume:before {
    content: "\f117";
    }
        .fi-ss-volume-down:before {
    content: "\f118";
    }
        .fi-ss-volume-mute:before {
    content: "\f119";
    }
        .fi-ss-volume-off:before {
    content: "\f11a";
    }
        .fi-ss-volume-slash:before {
    content: "\f11b";
    }
        .fi-ss-volunteer-vest:before {
    content: "\f11c";
    }
        .fi-ss-vote-nay:before {
    content: "\f11d";
    }
        .fi-ss-vote-yea:before {
    content: "\f11e";
    }
        .fi-ss-vpn:before {
    content: "\f11f";
    }
        .fi-ss-vpn-globe:before {
    content: "\f120";
    }
        .fi-ss-vpn-laptop:before {
    content: "\f121";
    }
        .fi-ss-vpn-shield:before {
    content: "\f122";
    }
        .fi-ss-vr-cardboard:before {
    content: "\f123";
    }
        .fi-ss-w:before {
    content: "\f124";
    }
        .fi-ss-waffle:before {
    content: "\f125";
    }
        .fi-ss-wagon-covered:before {
    content: "\f126";
    }
        .fi-ss-walker:before {
    content: "\f127";
    }
        .fi-ss-walkie-talkie:before {
    content: "\f128";
    }
        .fi-ss-walking:before {
    content: "\f129";
    }
        .fi-ss-wallet:before {
    content: "\f12a";
    }
        .fi-ss-wallet-arrow:before {
    content: "\f12b";
    }
        .fi-ss-wallet-buyer:before {
    content: "\f12c";
    }
        .fi-ss-wallet-income:before {
    content: "\f12d";
    }
        .fi-ss-wallet-nft:before {
    content: "\f12e";
    }
        .fi-ss-warehouse-alt:before {
    content: "\f131";
    }
        .fi-ss-warranty:before {
    content: "\f132";
    }
        .fi-ss-washer:before {
    content: "\f133";
    }
        .fi-ss-waste:before {
    content: "\f134";
    }
        .fi-ss-waste-pollution:before {
    content: "\f135";
    }
        .fi-ss-watch:before {
    content: "\f136";
    }
        .fi-ss-watch-calculator:before {
    content: "\f137";
    }
        .fi-ss-watch-fitness:before {
    content: "\f138";
    }
        .fi-ss-watch-smart:before {
    content: "\f139";
    }
        .fi-ss-water:before {
    content: "\f13a";
    }
        .fi-ss-water-bottle:before {
    content: "\f13b";
    }
        .fi-ss-water-ladder:before {
    content: "\f13c";
    }
        .fi-ss-water-lower:before {
    content: "\f13d";
    }
        .fi-ss-water-rise:before {
    content: "\f13e";
    }
        .fi-ss-watermelon:before {
    content: "\f13f";
    }
        .fi-ss-wave:before {
    content: "\f141";
    }
        .fi-ss-wave-sine:before {
    content: "\f142";
    }
        .fi-ss-wave-square:before {
    content: "\f143";
    }
        .fi-ss-wave-triangle:before {
    content: "\f144";
    }
        .fi-ss-waveform:before {
    content: "\f145";
    }
        .fi-ss-waveform-path:before {
    content: "\f147";
    }
        .fi-ss-web-design:before {
    content: "\f149";
    }
        .fi-ss-web-test:before {
    content: "\f14a";
    }
        .fi-ss-webcam:before {
    content: "\f14b";
    }
        .fi-ss-webcam-slash:before {
    content: "\f14c";
    }
        .fi-ss-webhook:before {
    content: "\f14d";
    }
        .fi-ss-wednesday:before {
    content: "\f14e";
    }
        .fi-ss-whale:before {
    content: "\f150";
    }
        .fi-ss-wheat:before {
    content: "\f152";
    }
        .fi-ss-wheat-awn:before {
    content: "\f153";
    }
        .fi-ss-wheat-awn-circle-exclamation:before {
    content: "\f154";
    }
        .fi-ss-wheat-awn-slash:before {
    content: "\f155";
    }
        .fi-ss-wheat-slash:before {
    content: "\f156";
    }
        .fi-ss-wheelchair:before {
    content: "\f157";
    }
        .fi-ss-wheelchair-move:before {
    content: "\f158";
    }
        .fi-ss-wheelchair-sleeping:before {
    content: "\f159";
    }
        .fi-ss-whistle:before {
    content: "\f15a";
    }
        .fi-ss-white-space:before {
    content: "\f15b";
    }
        .fi-ss-wifi:before {
    content: "\f15c";
    }
        .fi-ss-wifi-1:before {
    content: "\f15d";
    }
        .fi-ss-wifi-2:before {
    content: "\f15e";
    }
        .fi-ss-wifi-alt:before {
    content: "\f15f";
    }
        .fi-ss-wifi-exclamation:before {
    content: "\f160";
    }
        .fi-ss-wifi-slash:before {
    content: "\f161";
    }
        .fi-ss-wifi-xmark:before {
    content: "\f162";
    }
        .fi-ss-wind:before {
    content: "\f164";
    }
        .fi-ss-wind-turbine:before {
    content: "\f165";
    }
        .fi-ss-wind-warning:before {
    content: "\f166";
    }
        .fi-ss-window-alt:before {
    content: "\f167";
    }
        .fi-ss-window-frame:before {
    content: "\f168";
    }
        .fi-ss-window-frame-open:before {
    content: "\f169";
    }
        .fi-ss-window-maximize:before {
    content: "\f16a";
    }
        .fi-ss-window-minimize:before {
    content: "\f16b";
    }
        .fi-ss-window-restore:before {
    content: "\f16c";
    }
        .fi-ss-windsock:before {
    content: "\f16e";
    }
        .fi-ss-wine-bottle:before {
    content: "\f16f";
    }
        .fi-ss-wine-glass-crack:before {
    content: "\f170";
    }
        .fi-ss-wine-glass-empty:before {
    content: "\f171";
    }
        .fi-ss-wisdom:before {
    content: "\f173";
    }
        .fi-ss-wishlist-heart:before {
    content: "\f174";
    }
        .fi-ss-wishlist-star:before {
    content: "\f175";
    }
        .fi-ss-woman-head:before {
    content: "\f177";
    }
        .fi-ss-woman-scientist:before {
    content: "\f178";
    }
        .fi-ss-won-sign:before {
    content: "\f179";
    }
        .fi-ss-work-in-progress:before {
    content: "\f17b";
    }
        .fi-ss-workflow:before {
    content: "\f17c";
    }
        .fi-ss-workflow-alt:before {
    content: "\f17d";
    }
        .fi-ss-workflow-setting:before {
    content: "\f17e";
    }
        .fi-ss-workflow-setting-alt:before {
    content: "\f17f";
    }
        .fi-ss-workshop:before {
    content: "\f181";
    }
        .fi-ss-world:before {
    content: "\f182";
    }
        .fi-ss-worldwide-network:before {
    content: "\f183";
    }
        .fi-ss-worm:before {
    content: "\f184";
    }
        .fi-ss-wreath:before {
    content: "\f185";
    }
        .fi-ss-wrench-alt:before {
    content: "\f186";
    }
        .fi-ss-wrench-simple:before {
    content: "\f187";
    }
        .fi-ss-x:before {
    content: "\f18a";
    }
        .fi-ss-x-ray:before {
    content: "\f18b";
    }
        .fi-ss-y:before {
    content: "\f18e";
    }
        .fi-ss-yen:before {
    content: "\f192";
    }
        .fi-ss-yin-yang:before {
    content: "\f193";
    }
        .fi-ss-yoga-mat:before {
    content: "\f196";
    }
        .fi-ss-yoga-moon:before {
    content: "\f197";
    }
        .fi-ss-yoga-posture:before {
    content: "\f198";
    }
        .fi-ss-z:before {
    content: "\f19a";
    }
        .fi-ss-zero-percent:before {
    content: "\f19b";
    }
        .fi-ss-zip-file:before {
    content: "\f19c";
    }
        .fi-ss-zoom-in:before {
    content: "\f19e";
    }
        .fi-ss-zoom-out:before {
    content: "\f19f";
    }
        .fi-ss-it-computer:before {
    content: "\f106";
    }
        .fi-ss-pinata:before {
    content: "\f108";
    }
