
.agency-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 24px;
    /*border-bottom: 1px solid var(--border-bd-1);*/
}

.with-border{
    border-bottom: 1px solid var(--border-bd-1);
}
.agency-details .card-fields {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.agency-details .card-fields .heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--font-color);
    display: flex;
    justify-content: space-between;
}

.agency-details .field-section .form-label {
    margin-bottom: 0px !important
}

.agency-details .form-control:focus {
    border-color: var(--primary-background) !important;
    box-shadow: unset !important;
}

.agency-details .file-selection {
    border: 1px solid var(--border-bd-1);
    height: 38px;
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.375rem 0rem 0.375rem 0.75rem;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: var(--secondary-color);
    cursor: pointer;
}

.agency-details .field-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
    align-items: center;
    position: relative;
}

.agency-details .file-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.app-main-content .container {
    padding: 0px;
}
.subaccount-detailed-view{
    width: 100%;
}
.text-right{
    text-align: right;
}
.account-details{
    padding: 10px 20px 0px 20px;
}
.subaccount-detailed-view .agent-other-details{
    height: calc(100% - 122px);
    min-height: calc(100vh - 122px);
}
.back-action img{
    height: 25px;
}
.cursor-pointer{
    cursor: pointer;
    color: var(--font-color);
}
.cursor-pointer:hover{
    cursor: pointer;
    color: var(--font-color);
}
.rdw-image-left{
    display: flex;
    align-items: center;
}
.provider-container{
    padding: 10px;
}
.provider-info{
    padding: 10px;
}
.account-general-info .card-fields{
    flex-direction: unset !important;
}

.account-pricing-container{
    width: 100%;
    color: var(--font-color);
}
.account-pricing-container .pricing-header{
    margin-top: 30px;
}

.pricing-header .pricing-header-right{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1 1;
    padding: 0 1.5rem;
}

.pricing-header-right .add-plan-btn{
    align-items: center;
    display: flex;
    margin-left: auto;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover{
    color: var(--dark-grey);
    text-decoration: none;
    background-color: var(--icon-bg)
}
.subaccount-list{
    width: 100% !important;
    overflow-x: auto;
}
.mobile-view .subaccount-list{
    width: 100vw !important;
}
.table-list{
    width: 100% !important;
}
.list-view-header{
    height: 0px;
    padding: 10px;
}

.add-plan-madal .input-group-text img{
    width: 20px;
    height: 20px;
}

.form-wizard-step-doing::before,  
.form-wizard-step-done::before,
.form-wizard-step-doing::after, 
.form-wizard-step-done::after,
.form-wizard-step-done em,
.form-wizard-step-doing em{
    background: var(--font-color) !important;
}

.add-plan-madal .plan-cycle-select {
    width: 420px;
}

.pricing-body .plans-btn{
    margin-left: 40%;
}

.plans-list .plan-card{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    border: 1px solid var(--border-bd-1, #D1D5DB);
    /* max-width: 325px;
    min-width: 180px; */
    border-radius: 8px;
    flex: 1;
    justify-content: space-between;
    background: var(--white-bg);
    box-shadow: unset;
    cursor: pointer;
}
.plan-card .plan-details{
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
}
.plans-list .plan-card .plan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.most-popular-plan .plan-name, 
.most-popular-plan .plan-price,
.most-popular-plan .plan-feature-text,
.most-popular-plan .plan-title-desc,
.most-popular-plan .offered-plan-price{
    /* color: var(--w-color) !important; */
    color: var(--plan-font-color) !important;
}
.dark-theme .most-popular-plan .plan-name, 
.dark-theme .most-popular-plan .plan-price,
.dark-theme .most-popular-plan .plan-feature-text,
.dark-theme .most-popular-plan .plan-title-desc,
.dark-theme .most-popular-plan .offered-plan-price{
    color: var(--table-header-bg) !important;
}
.dark-theme .selected-plan .plan-name,
.dark-theme .selected-plan .plan-price,
.dark-theme .selected-plan .plan-title-desc,
.dark-theme .selected-plan .offered-plan-price,
.dark-theme .selected-plan .plan-feature-text{
    color: var(--table-header-bg) !important;
}
.dark-theme .plans-list .selected-plan{
    background-color: var(--btn-theme-color);
}
.plans-list .plan-card .plan-name{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px; 
    text-align: left;
    color: var(--plan-font-color);
    display: flex;
    justify-content: start;
}
.plans-list .plan-card .plan-price{
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: var(--plan-font-color);
}
.plans-list .plan-card .offered-plan-price{
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: var(--plan-font-color);
}
.plan-feature-description{
    display: flex;
    align-items: center;
} 
.plan-feature-description .plan-feature-text{
    padding: 4px 3px;
    color: var(--plan-font-color);
}
.plan-title-desc{
    color: var(--plan-font-color);
}

.plan-details .plan-features{
    min-height: 325px;
}
.btn-link{
    color: var(--font-color);
}
.plans-list .selected-plan{
    background-color: var(--selected-plan);
}
.plans-list .current-plan {
    background-color: var(--current-plan);
}
.custom-list-title{
    font-size: 14px;
    font-weight: normal;
    color: var(--secondary-color);
}
.highlight-name{
    font-weight: bold !important;
    color: var(--font-color);
}
.agency-details .header-desc, 
.cancel-page .content{
    font-size: 16px !important;
    margin-top: 2px !important;
}
.field-details .field-section{
    margin-top: 32px !important;
}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}
.max-width-54{
    max-width: 54rem !important;
}
.max-width-60{
    max-width: 60rem !important;
}
.max-width-65{
    max-width: 65rem !important;
}
.vertical-nav-menu .metismenu-container .metismenu-link.active i.metismenu-icon{
    color: var(--theme-main);
}
.vertical-nav-menu .metismenu-container .metismenu-link.active, 
.vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > .metismenu-link.active{
    color: var(--selected-menu-color);
    background: var(--theme-menu-bg) !important;
}
.plan-details .custom-checkbox {
    height: 24px;
    cursor: pointer;
    accent-color: var(--theme-main); /* This applies green to the checkbox for modern browsers */
}
.plan-details .custom-checkbox:checked {
    background-color: var(--theme-main); /* Fallback for browsers that don't support accent-color */
    border-color: var(--theme-main);
}

.sender-list{
    list-style: none;
}
.sender-div{
    display: flex;
    align-items: center;
}
.not-valid-provider{
    height: 40vh;
    max-width: 50rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.not-valid-provider span{
    font-size: 25px;
    font-weight: bold;
}
.del-subaccount-header{
    color: var(--secondary-color);
    font-size: 14px !important;
    font-weight: 400 !important;
}

.plan-popular{
    display: flex;
    align-items: center;
    justify-content: end;
}
.dns-copy-text{
    color : var(--bg-gradiant);
    display: flex;
    justify-content:left;
}
.dns-verified{
    color: var(--login-box);
}
.dns-checkbox {
    font-size: 20px;
    padding-top: 0.10rem !important;
}
.custom-domain-record{
    /* color: var(--theme-main);
    background-color: var(--theme-menu-bg); */
}
.justify-end{
    justify-content: end;
}
.ml-1{
    margin-left: 3px;
}
.input-group-text-width{
    width: 90%;
}
.input-group-text-box{
    margin-bottom: 0px !important;
}
.cursor-not-allowed{
    cursor: not-allowed !important;
}
.mobile-view .app-inner-layout .app-inner-layout__wrapper{
    width: 1024px !important;
    overflow-y: scroll !important;
    background-color: var(--theme-secondary-bg);
}
.header-notify.mobile-app-header > .app-header__content{
    top: 30px !important;
}
.mobile-view .custom-row{
    --bs-gutter-x: unset !important;
}

.app-menu-sub-container {
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ff-row-reverse{
    flex-flow: row-reverse
}

.call-logs-csv{
    color: var(--bg-gradiant);
    font-size: 15px;
    font-weight: 500;
    position: inherit;
    margin-top: -4px;
}
.call-logs-csv:hover {
    color: var(--bg-gradiant);
}
.custom-field-type{
    width: 70% !important;
}

.dark-theme .cursor-pointer:hover{
    cursor: pointer;
    color: var(--w-color);
}

.email-btn{
    height: 36px !important;
}

.ck.ck-editor__main>.ck-editor__editable{
    background: var(--white-bg) !important;
}
.custom-plan-btn-selected{
    background-color: #eeeeee
}