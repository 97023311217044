.dashboard-container .summary-container{
    margin: 25px;
}

.dashboard-footerheader {
    padding-left: 5px;
}

.cus-tooltip-box {
    background-color: var(--white-bg);
    color: #333;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    text-align: left;
}
  
.cus-tooltip-header {
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--white-bg);
    margin-bottom: 5px;
}
  
.cus-tooltip-body {
    padding-top: 5px;
}

.vi-success-text {
    color: var(--primary-background);
}

.widget-chart .widget-numbers{
    font-size: 2rem !important;
}
.widget-chart .widget-calllog-numbers{
    font-size: 1.8rem !important;
}

.date-option-container .input-group-text{
    padding: 0rem 0.75rem !important;
    color: var(--font-color);
    background-color: var(--white-bg);
}

.summary-container .widget-subheading, 
.call-log-charts .widget-subheading,
.summary-box-container .box-header{
    font-size: 16px;
    color: var(--font-color);
    font-weight: 600;
    opacity: unset !important;
}
.scrollable-table .card-body{
    padding: 0.5rem 0.5rem !important;
}

.header-notification{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    max-height: 30px;
    background: var(--warning);
    position: relative;
    height: 50px;
}

.header-notification-body> :not(style)~ :not(style) {
  margin-top: 0px;
  margin-inline: 0.5rem 0px;
  margin-bottom: 0px;
}

.header-notification-body {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  flex: 1 1 auto;
}
.mobile-header{
    margin-top: 15px;
    min-height: 50px !important;
}
.mobile-header .btn-close{
    margin-bottom: 20px;
}
.header-notify{
    top: unset !important;
}

.header-notify-active .conversation-details{
   height: 71vh !important; 
} 

.header-notify-active .chat-layout.app-inner-layout .app-inner-layout__sidebar {
    height : 86vh !important;
}

.demo-data-popup{
    position: relative;
    top: 0%;
    left: 32% !important;
    right: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 12;
    color: var(--white-bg);
    background-color: var(--primary-background);
    border-radius: 0 0 8px 6px;
    padding: 8px 0 0 12px;

}

.demo-data-popup button {
    padding: 0px 0px 0px 0px !important;
    border-radius: 0 0 8px 6px;
}
.popup-content span {
 color: #FFFFFF !important;
}

.demo-switch{
    margin-right: 18px !important;
}
.agent-dashboard-header{
    width: 888px !important;
}
.agent-dashboard-header .date-filter-toggle{
    height: 36px !important;
}
.filter-width{
    width: 20% !important;
}