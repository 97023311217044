:root{
    --border-bd-1: #D1D5DB;
    --gray-200: #E5E7EB;
    --white-bg: #FFFFFF;
    --primary-color: #070A0C;
    --icon-bg: #F3F4F6;
    --secondary-color: #747D82;
    --black-color : #000000;
    --invalid-color : #DC2626;
    --domain-color : #34373A;
    --theme-main : #034737;
    --primary-background: #034737;
    --shadow-theme-g: #76f7bb;
    --default-color: #343a40;
    --agency-badge: #004cff;
    --agency-badge-lite: #b3caff;
    --font-color: #171717;
    --text-light : #757575;
    --colors-gray-dark-gray-4 : #111928;
    --bg-gradiant: #47c389;
    --bg-gradiant-dark: #034737;
    --login-box-error : #d92550;
    --login-box : #23ae73;
    --dark-color : #000;
    --social-btn : #2d333a;
    --dark-grey : #212529;
    --theme-main : #034737;
    --theme-menu-bg: #03473733;
    --dark-bg : #0000001A;
    --menu-hover-bg: #F3F4F6;
    --theme-secondary-bg: #FFFFFF;
    --w-color: #FFFFFF;
    --b-color: #0000001A;
    --select-bg: #26f59f;
    --black-color: #000000;
    --link-color: #545cd8;
    --selected-plan: #c6e1d4;
    --warning: #fff345;
    --basic-border-color: #dfdfdf;
    --box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    --calls-bg: #f3f4f6;
    --calls-selected-bg: #f3f4f6;
    --og-bg: #E5E7EB;
    --table-header-bg: #f5f5f7;
    --info-color: #16aaff;
    --warning-color: #ffa802;
    --success-color: #23ae73;
    --upload-bg: #f7f7f7;
    --import-uploader: #8592a3;
    --import-info-th: #566a7f;
    --datepicker-hover: #034737;
    --disabled-input: #eeeff1;
    --current-plan: #E5E7EB;
    --error-background : #ffe3e3;
    --btn-theme-color: #FFFFFF;
    --border-bottom: #dee2e6;
    --label-color: #333638;
    --plan-font-color: #171717;
    --description-font: #565b5e;
    
}
.dark-theme {
    --white-bg: #121417b3;
    --font-color: #eee;
    --theme-secondary-bg: #1c1e21;
    --menu-hover-bg: #222;
    --og-bg: #495057;
    --selected-plan: #03a086;
    --border-bd-1: #313135;
    --box-shadow: 0 0 #0000,0 0 #0000,0 1px 2px 0 #0003;
    --theme-menu-bg: #023b30;
    --calls-bg: #121417b3;
    --calls-selected-bg: #121417b3;
    --table-header-bg: #000;
    --disabled-input: #292b2e;
    --current-plan: #3a3a3a;
    --error-background : #121417b3;
    --primary-background: #03a086;
    --selected-menu-color: #56d2b6;
    --btn-theme-color: #FFFFFF;
    --link-color : #56d2b6;
    --tab-color: #e8eaed;
    --border-bottom: #2a2a2a;
    --label-color: #abaeb0;
    --plan-font-color: #eee;
    --dark--selected-bg:#38383c;
    --description-font:#7b7b7b;
}
.apexcharts-text tspan{
    fill: var(--font-color) !important;
}
.apexcharts-legend-text{
    color: var(--font-color) !important;
}
.dropdown-menu::before{
    background: var(--white-bg) !important;
    border-top: 1px solid var(--menu-hover-bg) !important;
    border-left: 1px solid var(--menu-hover-bg) !important;
}
.dropdown-menu{
    border: 1px solid var(--menu-hover-bg) !important;
}
.dropdown-menu .dropdown-item{
    color: var(--font-color) !important;
}
/* Style the scrollbar for WebKit browsers */
::-webkit-scrollbar {
    width: 8px;  /* Width of the scrollbar */
    height: 6px; /* Height of the scrollbar for horizontal scrollbars */
}
::-webkit-scrollbar {
    /*display: none;*/
}
::-webkit-scrollbar-track {
    background: var(--w-color);  /* Color of the scrollbar track */
    border-radius: 10px;  /* Optional: Rounded corners on the track */
}

::-webkit-scrollbar-thumb {
    background-color: #888;  /* Color of the scrollbar thumb */
    border-radius: 10px;  /* Rounded corners on the thumb */
    border: 2px solid var(--border-bd-1);  /* Optional: Add a border around the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;  /* Darker color when hovering over the scrollbar */
}

.app-theme-white.fixed-header .app-header__logo{
    background: var(--white-bg) !important;
}
.vertical-nav-menu .metismenu-container .metismenu-link:hover{
    background: var(--menu-hover-bg) !important;
}
.vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > .metismenu-link:hover{
    color: var(--font-color) !important;
}
.wa-select__single-value, .rdt_TableCol{
    color: var(--font-color) !important;
}
.wa-select__control{
    background-color: var(--white-bg) !important;
    color: var(--font-color) !important;
}
.app-theme-white .app-sidebar{
    background: var(--white-bg);
}
.rdt_Table, .rdt_TableHeadRow, .rdt_TableRow,.form-control{
    background-color: var(--white-bg) !important;
    color: var(--font-color) !important;
}
.card{
    background-color: var(--white-bg);
    box-shadow: var(--box-shadow) !important;
}
.rdt_Pagination{
    background-color: var(--white-bg) !important;
    color: var(--font-color) !important;
}
.rdt_TableCell, .rdt_TableCol, .wa-select__control{
    border-color: var(--border-bd-1) !important;
}
.pl-1{
    padding-left: 0.2rem;
}
.form-control:focus{
    border-color: var(--primary-background) !important;
    box-shadow: unset !important;
}
.dropdown-menu{
    color: var(--font-color);
    background-color: var(--white-bg) !important;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover{
    color: var(--font-color) !important;
    background-color: var(--og-bg) !important;
}
.dropdown-menu i{
    color: var(--font-color) !important;
}
.justify-end{
    justify-content: flex-end !important;
}
.theme-c-g{
    color: var(--primary-background) !important;
}
.theme-bg-g{
    color: var(--white-bg) !important;
    background-color: var(--primary-background) !important;
    border-color: var(--primary-background) !important;
}
.theme-bg-g:hover{
    color: var(--white-bg) !important;
    background-color: var(--primary-background) !important;
    border-color: var(--primary-background) !important;
}
.dark-theme button.theme-bg-g{
    color:var(--btn-theme-color) !important;
}
.dark-theme button.theme-bg-g:hover{
    color:var(--btn-theme-color) !important;
}
.theme-c-link{
    color: var(--link-color) !important;
}
.theme-c-link:hover{
    color: var(--link-color) !important;
}
.text-d-underline{
    text-decoration: underline !important;
}
body{
    overflow: hidden;
    scrollbar-width: none;
}
.fixed-header .app-main{
    padding-top: 60px !important;
}
.app-header__logo .logo-src{
    height: 100% !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    background: unset !important;
}
.app-header{
    background-color: var(--white-bg) !important;
    border-bottom: 1px solid var(--border-bd-1);
}
.app-header__logo{
    border-right: 1px solid var(--border-bd-1);
}
.app-sidebar .app-sidebar__inner{
    padding: 2px 1rem 1rem !important;
    padding-top: 12px !important;
}
.app-sidebar .sidebar-top-adjust{
    padding-top: 0px !important;
}
.app-sidebar{
    border-right: 1px solid var(--border-bd-1) !important;
}
.app-header-title,  .app-layout-title{
    display: flex !important;
    padding: 1rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: var(--font-color);
    justify-content: space-between;
}

.account-settings-menu{
    position: absolute !important;
    bottom: 20px;
    width: 82%;
}
.fixed-footer .app-main .app-main__outer{
    padding-bottom: unset !important;
}

.split-main .app-sidebar{
    margin-top: unset !important;
}

.split-main .app-main__inner{
    padding: unset !important;
}
.app-inner-layout__sidebar{
    border-right: 1px solid var(--border-bd-1) !important;
}

.app-inner-layout {
    background-color: var(--white-bg);
}
.app-inner-layout__content ._loading_overlay_wrapper{
    width: 100%;
}
.hvh-100 ._loading_overlay_wrapper{
    height: 100vh !important;
}
.app-inner-layout .app-inner-layout__header .app-inner-layout__content {
    flex: 1 1;
    display: flex;
}
.app-inner-layout .app-inner-layout__header .app-inner-layout__sidebar {
    list-style: none;
    text-align: left;
    order: -1;
    display: flex;
    margin: 0;
    position: relative;
}
.app-inner-layout__wrapper .app-inner-layout__sidebar,.app-inner-layout__wrapper .app-inner-layout__content, .app-main-content{
    display: flex !important;
    /*padding:10px;*/
}
.app-main-content{
    height: 90vh;
    /* min-width: 100vh; */
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--theme-secondary-bg);
}
.split-app-outer, .app-normal__inner{
    background-color: var(--white-bg);
    padding: 0 !important;
}
.app-split-header__content .app-header-left{
    width: 360px !important;
    border-right: 1px solid var(--border-bd-1) !important;
}
.app-header .app-header__content, .app-header .app-header-title, .app-header .app-split-header__content{
    padding: unset !important;
}
.app-header .app-split-header__content .app-header-right{
    margin-left: unset !important;
}
.app-theme-white.app-container{
    background-color: var(--white-bg);
}
.chat-layout.app-inner-layout .app-inner-layout__sidebar{
    height: 90vh !important;
    overflow-y: scroll;
    scrollbar-width: none;
}
.app-inner-layout .app-inner-layout__wrapper .app-inner-layout__content{
    height: 100vh !important;
    overflow-y: scroll;
    scrollbar-width: none;
}
.app-main__content{
    height: 100vh !important;
    overflow-y: scroll;
    scrollbar-width: none;
}
.app-split-header__content .app-header-left, .app-split-header__content .app-header-right, .app-header .app-header__content .app-header-left{
    height: 100%;
    padding: 10px;
    text-align: left;
}

.header-desc{
    font-size: 12px;
    color: var(--secondary-color);
    line-height: 1.3;
}
.card-heading{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--font-color);
    display: flex;
    justify-content: space-between;
}
.rdw-image-right{
    align-items: center;
}
.bb-1{
    border-bottom: 1px solid var(--border-bd-1);
}
.search-wrapper .input-holder .search-icon span::before{
    background-color: var(--primary-background) !important;
}
.search-wrapper .input-holder .search-icon span::after{
    border:  solid !important;
}

.search-phonenumbers{
    display: flex !important;
    align-items: center !important;
    margin-left: 15px;
}

.profile-desc{
    line-height: 21px;
}
.profile-container-footer{
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--font-color);
    border: none;
    padding: 7px 15px;
    border: 1px solid var(--Border-bd-1);
    display: flex;
    align-items: center;
    gap: 8px;
}
.logout-img{
    height: 11px;
}
.pl-0{
    padding-left: 0px !important;
}
.pr-0{
    padding-right: 0px !important;
}
.pt-0{
    padding-top: 0px !important;
}
.pb-0{
    padding-bottom: 0px !important;
}
.domain-btn{
    border-radius: 5px;
}
.domain-container {
    border-right: 1px solid var(--border-bd-1);
    position: relative !important;
    width: 100% !important;
}
.custom-field-border{
    padding: 0.5rem 0.5rem;
    width: 100%;
    background-color: var(--white-bg);
    border: 1px solid var(--border-bd-1);
    border-radius: 4px;
    max-height: 2.4rem;
}
.custom-row{
    --bs-gutter-x: 0;
}
.dns-records-container{
    display:flex;
    align-items:center;
}
.dns-records-container span{
   padding-left: 5px;
}
.domain-secondary-btn{
    height: 33px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--domain-color);
    color: var(--white-bg);
}
.dns-row{
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 5px;
}
.dns-btn{
    width: 125px;
    padding-top: 8px;
}
.domain-img{
    height: 20px;
    padding-top: 2px;
}
.cs-domain{
    padding-top: 6px;
}
.container-footer-save{
    background: var(--font-color);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--icon-bg);
    border: none;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 1px 2px -1px #0000001A;
    box-shadow: 0px 1px 3px 0px
}
.container-footer-save-icon{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container-heading-count{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--secondary-color); 
}

.container-heading{
    height: 64px;
    padding: 1rem;
    border-bottom: 1px solid var(--Border-bd-1);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    display: flex;
    color: var(--font-color);
    justify-content: space-between;
}
.container-footer-cancel{
    background: var(--white-bg);
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--font-color);
    border: none;
    padding: 7px 20px;
    border: 1px solid var(--Border-bd-1);
    display: flex;
    align-items: center;
    gap: 8px;
}
.settings-phonenumber-titlebody{
    display: flex;
    gap: 8px;
}
.app-header .app-header__content .app-header-right{
    margin-right: 20px
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid var(--white-bg);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header, .modal-footer {
     background: var(--white-bg); 
}

.search-numbers{
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
    margin-bottom: 2px;
    border-bottom: 1px solid var(--border-bd-1);
}
.number-list-wrap{
    border: 1px solid var(--border-bd-1);
    border-radius: 8px;
    height: fit-content;
    overflow-y: scroll;
}

.modal-footer{
    border-top: 1px solid var(--white-bg);
}
.seacrh-box{
    padding-left: 15px;
}
.modal-dialog{
    box-shadow: none !important;
}
.delete-number button {
    border: 1px solid var(--invalid-color);
    color: var(--invalid-color);
}
.error{
    color: var(--invalid-color);

}
.align-center{
    align-items: center;
}
.ml-2{
    margin-left: 5px;
}
.rdt_TableHeadRow .rdt_TableCol:first-child {
    position: sticky;
    left: 0;
    background-color: var(--white-bg);
    color: var(--font-color) !important;
    z-index: 3; /* Ensure header stays on top */
}
/* .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
    position: sticky;
    left: 150px;
    background-color: white;
    z-index: 3; 
}*/
.vm-data-table-grid{
    grid-template-rows: 70px auto;
    height: 90vh;
}
/*.rdt_TableRow:nth-child(even){
    background-color: var(--white-bg);
}*/
.black-color{
    color: var(--font-color) !important;
}
.font-bold{
    font-weight: bold;
}
.rdt_TableRow:hover{
    background-color: var(--white-bg);
}
.hover-underline:hover{
    text-decoration: underline !important;
}
.app-header__logo .logo-src img{
    background: unset !important;
}
.main-table-list {
    width: 1035px;
}

.numbers-not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.field-error{
    font-style: normal;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}
.vertical-nav-menu .metismenu-container i.metismenu-icon{
    align-items: center;
    display: flex;
    justify-content: center;
}
ul > .metismenu-item:not(:first-child), .help-link-container{
    margin-top: 2px;
}
.metismenu-container .metismenu-container .metismenu-item {
    margin-top: unset !important;
}
.help-link-container .dropdown-item a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--default-color) !important;
}
.help-link-container .dropdown-item .link-icon{
    height: 15px;
    width: 15px;
    margin-right: 5px; 
}
.no-records-container{
    height:80vh;
    max-width: 50rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--font-color)
}
.no-records-container span{
    font-size: 16px;
    font-weight: bold;
}
.agency-details .form-label{
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
    display: flex;
    align-items: center;
    position: relative;
}
i[class*=" fi-"]:before{
    line-height: unset !important;
}
.custom-display{
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-display span{
    padding-left: 5px;
}
.subaccount-list .rdt_TableCol:last-child{
    padding: 0;
    margin: 0;
    justify-content: center;
}
.head-info i{
    padding-top: 3px;
    padding-left: 3px;
}
.rdt_TableCell, .rdt_TableCol{
    min-width: 135px !important;
}
.subaccount-detailed-view .agent-other-details{
    padding-top: 10px !important;
}
.sticky-component{
    position: sticky;
    bottom: 0;
    z-index: 10;
    border-top: 1px solid var(--border-bd-1) !important;
}
.conversation-details{
    height: 75vh !important;
}

.agent-footer{
    right: 0 !important;
    bottom: 1px !important;
    position: fixed;

} 
.agent-popover{
    display: flex ;
    position: fixed;
    bottom: 1rem ;
    right: 1rem ;
    border: 1px solid var(--purple-700, #7E22CE) ;
    background: #020617 ;
    width: auto ;
    padding: 24px ;
    gap: 1rem ;
    border-radius: 8px ;
    flex-direction: column ;
    align-items: center ;
    min-width: 360px ;
}

.agents-call-details{
    gap: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #F1F5F9;
    display: flex;
    align-items: center;
    max-width: 270px;
    flex-direction: column;
}

.agent-popover-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
}

.agent-popover-footer{
    width: 100%;
    padding: 1rem 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.agent-user-img{
    width: 30px;
    height: 30px;
    background: white;
}
.agent-cancel-call{
    width: 40px;
    height: 40px;
    cursor : pointer;
}
.agency-badge{
    color: var(--agency-badge) !important;
    background: var(--agency-badge-lite) !important;
    font-weight: bold;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 5px;
    text-align: center;
    border-radius: 5px;
}
.wa-select__control:hover {
    border-color: var(--primary-background) !important;
}
.wa-select__control--is-focused {
    border: 0px solid var(--primary-background) !important;
    box-shadow: 0 0 0 1px var(--primary-background) !important;
}
.wa-select__option:hover{
    background-color: var(--primary-background) !important;
    color: var(--font-color) !important;
}
.wa-select__option--is-selected {
    background-color: var(--primary-background) !important;
    color: red !important;
}
.dark-theme .wa-select__option--is-selected {
    color: var(--font-color) !important;
}
.wa-select__option--is-focused {
    background-color: var(--primary-background) !important;
    color: var(--white-bg) !important;
}
.vertical-nav-menu .metismenu-container .metismenu-link.active{
    font-weight: 500 !important;
}
.vertical-nav-menu .metismenu-container i.metismenu-icon{
    text-align: center;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 10px;
    top: 7%;
    margin-top: 0px;
    font-size: 1rem;
    transition: color 300ms;
    opacity: unset !important;
    color: var(--font-color) !important
}
.vertical-nav-menu .metismenu-container .metismenu-link{
    color: var(--font-color);
    font-weight: 400 !important;
}
.vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > .metismenu-link {
    color: var(--font-color);
}
.theme-icon{
    font-size: 1.5rem;
}
.pr-2{
    padding-right: 10px;
}
.light-bg {
    color: var(--b-color);
}
.dark-bg{
    color: var(--w-color);
}
.unset-btn-group{
    display: flex !important;
    width: 100% !important;
    vertical-align: unset !important;
}
.unset-btn-group .metismenu-link {
    display: flex !important;
    justify-content: flex-start;
}
.unset-btn-group .dropdown-item a{
    color: var(--font-color) !important;
}
.metismenu-item .metismenu-link{
    font-size: 0.88rem !important;
    font-weight: 400 !important
}
.modal-title{
    font-weight: bold;
    font-size: medium;
}
.agency-details .layout-title{
    font-size: 18px !important;
}
.scrollable-table{
    border-radius: 1rem !important;
    width: 100% !important;
    overflow-x: auto;
    overflow-x: auto;
    /*scrollbar-width: thin; 
    scrollbar-color: rgba(161, 161, 161, 0.6) #ffffff;*/

}
.no-records-container img{
    margin: 0 auto;
}
.no-records-container .no-record-title{
    font-size: 18px;
    font-weight: bold;
}
.no-records-container .no-record-desc{
    font-size: 16px;
    font-weight: normal !important;
}
.pr-1{
    padding-right: 5px;
}
.app-header-right .dropdown-menu{
    min-width: 7rem !important;
}
.access-account-btn{
    padding: 5px 10px;
}
.d-container{
    margin-top: 10px !important; 
}
.field-desc{
    font-size: 12px;
}
.dark-theme .widget-subheading{
    color: var(--w-color) !important;
}
.dark-theme .vi-success-text{
    color: var(--font-color) !important;
}
.dark-theme .widget-description{
    color: var(--w-color) !important;
}
.dark-theme .theme-c-g{
    color: var(--w-color) !important;
}
.dark-theme .back-action img{
    color: var(--w-color) !important;
}
.modal-content{
    background-color: var(--white-bg) !important;
    border-color: var(--border-bd-1);
}
.modal-content .form-label {
    color: var(--font-color) !important;
}
.modal-header{
    color: var(--font-color) !important;
}
.dashboard-table-container .rdt_Pagination span{
    display: none !important;
}
.rdt_Pagination button svg{
    height: 20px;
    width: 20px;
}
.rdt_Pagination select, .rdt_Pagination select:focus-visible {
    border: 1px solid var(--basic-border-color) !important;
    border-radius: 5px !important;
    outline: none !important;
}
.form-control{
    line-height: 1.7 !important;
}
.compact-padding{
    padding: 0.5rem 0.5rem !important;
    width: 135px !important;
}
.pl-2{
    padding-left: 5px;
}
.pl-3{
    padding-left: 10px;
}
.inv-usr-pro-check {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.inv-usr-chk-lbl {
    line-height: 17px;
    text-align: left;
    color: var(--font-color);
    display: flex;
    gap: 5px;
}

.form-check-input-custom:checked{
    accent-color: var(--primary-background);
}

.form-check-input-custom:focus{
    accent-color: var(--primary-background);
}
.form-check-input-custom{
   height: none;
}
.theme-bg-danger{
    color: var(--invalid-color) !important;
    background-color: var(--white-bg) !important;
    border-color: var(--white-bg) !important;
}
.theme-bg-info{
    color: #07A6F0 !important;
    background-color: var(--white-bg) !important;
    border-color: var(--white-bg) !important;
}
.user-account-btn{
    padding: 5px 4px;
}
.resend-icon {
    font-size: 15px;
}
.rdt_Pagination button{
    fill: var(--font-color) !important;
}
.app-header{
    justify-content: unset !important;
}
.app-header .app-header__logo{
    display: flex !important;
}
.sidebar-mobile-open .sidebar-mobile-overlay{
    display: none !important;
}
.mobile-app-header .app-header__content {
    visibility: visible !important;
    opacity: unset !important;
    position: fixed !important;
    right: 0px !important;
    left: unset !important;
    overflow: unset !important;
    width: auto !important;
    box-shadow : unset !important;
    border-radius: unset !important;
}
.mobile-app-header .app-header__content .app-header-left{
    display: none !important;
}
.mobile-app-header .unset-btn-group{
    display: none !important;
}
.mobile-app-header .app-header__menu, .app-header__mobile-menu{
    padding: unset !important;
}
.mobile-app-header .app-header__logo{
    padding: unset !important;
}
.mobile-view .help-support-menu .dropdown-menu{
    position: fixed !important;
    z-index: 50;
    left: 5% !important;
    top: 86% !important;
    width: 90% !important;
    transform: translateY(-110%) !important;
    min-width: 10px !important;
}
.dark-theme .btn-close {
    background-color: var(--font-color);
}
.dark-theme .modal-header {
    background-color: var(--theme-secondary-bg) !important;
    color: var(--font-color);
}  
.dark-theme .modal-content {
    background-color: var(--theme-secondary-bg) !important;
    color: var(--font-color);
}
.dark-theme .modal-footer {
    background-color: var(--theme-secondary-bg) !important;
}
 .dark-theme .swal-modal {
    background-color: var(--theme-secondary-bg) !important;
 }   
.dark-theme .swal-modal .swal-button--cancel{
    background-color: var(--theme-secondary-bg) !important;
} 
.dark-theme .swal-title,.swal-text {
    color: var(--font-color) !important;
    background-color: var(--theme-secondary-bg) !important;
    margin-bottom: 8px;
    font-size: 18px;
 }
.dark-theme button.theme-bg-g {
    color: var(--btn-theme-color) !important;
    box-shadow: none;
}
.dark-theme .list-data-table .rdt_TableRow {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.dark-theme .billing-content-wrapper .billing-subscription-table,.no-border-table tbody tr{
    box-shadow: none !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: none !important;
}
.dark-theme .plan-footer button.theme-bg-g{
    /* background-color: #eeeeee !important; */
    background-color: var(--selected-plan) !important;
    border: none !important;
    /* color: var(--black-color) !important; */
    color: var(--font-color) !important;

}
.dark-theme .current-plan-container .current-plan-notification  {
    background-color: var(--theme-secondary-bg) !important;
    color: var(--black-color) !important;
}
.dark-theme .basic-select{
    background-color: var(--theme-secondary-bg) !important;
}  
.dark-theme .wa-select__menu {
    background-color: var(--theme-secondary-bg) !important;
    color : var(--font-color) !important; 
}
.dark-theme .input-group-text{
    background-color: var(--theme-secondary-bg) !important;
    color : var(--font-color) !important; 
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.app-inner-layout__wrapper .app-inner-layout__sidebar,
.app-inner-layout__wrapper .app-inner-layout__content,
.app-main-content.header-msg {
    height : 86vh !important;
}  
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
    background-color: var(--white-bg) !important;
    color : #1e2125 !important
}
.dark-theme .react-datepicker__day--in-selecting-range,
.dark-theme .react-datepicker__day--in-range {
    background-color: #1e1e1e !important; 
    color: #495057 !important;
}    
.react-datepicker__day--selected,.react-datepicker__day--selecting-range-end{
    background-color: var(--primary-background) !important;
    color: var(--w-color) !important;
}
.dark-theme .react-datepicker__day--selected,.dark-theme .react-datepicker__day--selecting-range-end {
    background-color: var(--primary-background) !important;
    color: #ffffff !important;
}
.dark-theme .dropdown-menu.dropdown-menu-right{
    background-color: var(--theme-secondary-bg) !important;
    color : var(--font-color) !important; 
}
.disable-sub-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: '100%';
    font-size: 12;
    color: white;
}
.enable-sub-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: '100%';
    font-size: 12;
    color: white;
}
.dark-theme .current-plan-container .current-plan-notification{
    background-color: var(--black-color) !important;
    color: var(--font-color) !important;
}
.has-switch {
    display: flex;
    align-items: center;
    width: 6.50rem !important;
}

.has-switch > div {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    transition: all 0.25s ease-out;
    border-radius: 30px; 
}

.has-switch > div span {
    text-align: center;
    flex: 1;
    opacity: 0 !important;
}

.has-switch > div.switch-on span.switch-left,
.has-switch > div.switch-off span.switch-right {
    visibility: visible;
    opacity: 1 !important;
    font-size: 13px;
}
.has-switch > div span.switch-left{
    opacity: 1 !important;
}
.dark-theme .call-details-type{
    color: var(--font-color) !important;
}
  .profile-remove-icon {
    font-size: 14px;
    line-height: 21px;
    flex-grow: 1;
}

.invalid-feedback {
    display: revert;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #d92550;
    }

.custom-contact-checkbox{
    height: 20px;
    width: 20px;
    box-shadow: none !important;
}
.custom-contact-checkbox:checked{
    background-color: var(--primary-background) !important;
}

.field-container-title{
    font-weight: 600;
}

.data-container-contacts{
    position: sticky !important;
    z-index: 1000;
}
.data-container-calllogs{
    position: absolute !important;
    z-index: 1000;
    width: 100%;
    margin-top: 6px;
}
.list-container{
    margin-top: 51px;
}
.dark-theme .search-wrapper .input-holder .search-icon {
    background: var(--gray-200) !important;
}
.react-datepicker__day:hover {
    background-color: var(--primary-background) !important;
    color: #fff !important;
}
.wa-select__option {
    background-color: var(--white-bg)!important; 
    color: var(--font-color) !important;
}
.wa-select__option:hover {
    background-color: var(--primary-background)!important; 
    color: var(--theme-secondary-bg) !important;
}
.dark-theme .wa-select__option:hover {
    color: var(--font-color) !important;
}
.wa-select__option--is-selected {
    background-color: var(--primary-background) !important;
    color: var(--w-color) !important;
}
.agent-save-btn{
    width: 100% !important;
    padding-left: 30% !important;
}
.z-high{
    z-index: 9999;
}
.custom-btn-1{
    background-color: white !important;
    color: var(--primary-background) !important;
    border-color: var(--primary-background) !important;
}
.custom-btn-1:hover{
    background-color: white !important;
    color: var(--primary-background) !important;
    border-color: var(--primary-background) !important;
}
.create-field-btn {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    flex-wrap: wrap; 
    width: 80% !important;
    box-sizing: border-box; 
}

.dark-theme .dropzone {
    background-color: 2px dashed var(--border-bd-1); 
    border: 2px dashed var(--border-bd-1) !important;
    border-radius: 5px;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    transition: background-color .2s ease;
}

.dark-theme  .form-preview-document {
    align-items: center;
    border-radius: 5px  dashed var(--border-bd-1) !important;
    color: var(--import-uploader);
    display: flex;
    font-size: 13px;
    padding: 4px 8px;
    width: 100%;
    background-color: dashed var(--border-bd-1);
}
.dark-theme .import-border {
    border : 1px solid var(--border-bd-1);
}
.import-border {
    border : 1px solid var(--border-bd-1);
}

.dark-theme .app-main-content{
    height: 92vh;
    /* min-width: 100vh; */
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--theme-secondary-bg);
}
.api-btn-height{
    height: 38px !important;
}

.custom-time-input::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%);
}

.dark-theme .custom-time-input::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(100%) opacity(0.6);
}


.skiptranslate{
    display: none !important;
}
.basic-multi-select-lan{
    z-index: 1000;
}
.goog-te-banner-frame {
    display: none !important;
  }
  body {
    top: 0 !important;
  }
  .goog-te-combo {
    display: none !important;
  }
.w-60{
    width: 60% !important;
}
.cal-action{
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0;
}
.cal-action .modal-content {
    min-height: 100vh; 
    max-width: 91%;
    margin-left:3rem !important;  
}
.cal-action-time {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
}

.cal-action-time .modal-content {
    min-height: 100vh; 
    max-width: 96%;
    margin-left: 1.7rem !important;
}
.work-time-container{
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.day-container{
    display: flex;
    gap:0.5rem !important;
    align-items: center;
}

.agent-action-dropdown {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 98% !important;
}
.week-btn-container{
    display: flex;
    justify-content: space-between;
}
.week-btn{
    height: 80% !important;
    width: 10% !important;
    justify-content: center;
    display: flex;
    background-color: var(--basic-border-color);
    color: var(--font-color);
    border-radius: 50% !important;
}
.agent-action-details {
    overflow-y: auto;
    scrollbar-width: none;
    position: relative;
    height: 70vh !important;
}
.agent-action-details .agent-nodata{
    overflow-y: hidden;
    height: 63vh !important;
}

.agent-action-details .RRT__tabs {
    position: sticky !important;
    top: 0;
    background-color: var(--theme-secondary-bg);
}
.agent-action-details .RRT__inkbar-wrapper {
    z-index: 10 !important;
    position: sticky !important;
}
.agent-action-details .RRT__inkbar {
    background-color: var(--primary-background) !important;
    margin-bottom: 30px;
}
.dark-theme .vertical-nav-menu .metismenu-container .dropdown-menu {
    background-color: #121417fa !important;
}
.search-wrapper.active .input-holder .search-icon {
    height: 40px !important;
    top: 2px !important;
}
.custom-save{
    color:var(--w-color) !important;
}
.custom-save:hover{
    color: var(--w-color) !important;
}
.provider-url-btn{
    color: var(--bg-gradiant-dark) !important;
}
.dark-provider-url-btn{
    color: var(--link-color) !important;
}

.integration-card{
    /* display: flex !important; */
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px;
    height: calc(100vh - 64px);
    align-content: flex-start;
}

.integration-card .card-br{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* padding: 1rem; */
        border-radius: 8px;
        /* border: 1px solid var(--Border-bd-1, #D4D4D4); */
        /* width: 300px; */
        justify-content: space-between;
        max-height: 202px;
    
}

.integration-mobile-description {
    margin-top: -18px;
    margin-inline-start: 3px;
}
.test-btn{
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    width: 55% !important;
}

.mobile-img{
    width: 60px;
}

.intagration-card-img {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }
  
  .intagration-card-heading {
    font-size: 16px;
    /*margin: 8px 0;*/
    margin-top: -5px;
    color: var(--primary-color);
  }
  
  .intagration-card-subheading {
      background: #F3F4F6;
      border-radius: 6px;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      width: -moz-fit-content;
      width: fit-content;
      color: var(--primary-color);
  
  }
  
  
  .intagration-card-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    display: flex;
    color: var(--secondary-color);
    height: 50px;
  }
  .intagration-comingsoon{
    background: #eff5fd;
    padding: 7px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--secondary-color);
    border-radius: 0.25rem;
  }
  
  /* Styling for buttons container */
  .intagration-card-button {
    /* // display: flex; */
    /* // gap: 8px; */
    margin-top: 40px;
  }
  
  .dark-theme .intagration-card-heading {
    font-size: 16px;
    /* // margin: 8px 0; */
    margin-top: -5px;
    color: var(--w-color) !important;
  }
  .dark-theme .intagration-card-subheading {
    background:  var(--font-color) !important;
    border-radius: 6px;
    padding: 2px 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--primary-color);
  
  }
  
  .intagration-card-details-heading {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      text-align: left;
      color: var(--primary-color);
  }
  
  .dark-theme .intagration-card-details-heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: var(--w-color) !important;
  }
  
  .intagration-card-detail-img {
        width: 55px !important;
        height: 55px !important;
  }
  .intagration-card-details-subheading{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: var(--primary-color);
      margin-bottom: 5px;
  }
  
  .dark-theme .intagration-card-details-subheading{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: var(--w-color) !important;
      margin-bottom: 5px;
  }
  
  .intagration-card-details-description{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      display: flex;
      color: var(--secondary-color);
/* margin-bottom: 1rem !important; */
  }
  .intagration-font{
    color: var(--primary-color);
  }
  
  .dark-theme .intagration-font{
    color: var(--w-color) !important;
  }
.contact-data-container .card-body {
    height: 80vh !important; 
    display: flex !important;
    flex-direction: column !important;
}
.contact-data-container-min .card {
    margin-top: 3rem !important;
    height: auto !important; 
    display: flex !important;
    flex-direction: column !important;
}
.search-wrapper.active .input-holder .search-icon {
    height: 40px !important;
    top: 2px !important;
}
.custom-save{
    color:var(--w-color) !important;
}
.custom-save:hover{
    color: var(--w-color) !important;
}
.provider-url-btn{
    color: var(--bg-gradiant-dark) !important;
}

.integration-card{
    /* display: flex !important; */
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px;
    height: calc(100vh - 64px);
    align-content: flex-start;
}

.integration-card .card-br{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* padding: 1rem; */
        border-radius: 8px;
        /* border: 1px solid var(--Border-bd-1, #D4D4D4); */
        /* width: 300px; */
        justify-content: space-between;
        max-height: 202px;
    
}

.integration-mobile-description {
    margin-top: -18px;
    margin-inline-start: 3px;
}
.test-btn{
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    width: 55% !important;
}

.mobile-img{
    width: 60px;
}

.intagration-card-img {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }
  
  .intagration-card-heading {
    font-size: 16px;
    /*margin: 8px 0;*/
    margin-top: -5px;
    color: var(--primary-color);
  }
  
  .intagration-card-subheading {
      background: #F3F4F6;
      border-radius: 6px;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      width: -moz-fit-content;
      width: fit-content;
      color: var(--primary-color);
  
  }
  
  
  .intagration-card-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    display: flex;
    color: var(--secondary-color);
    height: 50px;
  }
  .intagration-comingsoon{
    background: #eff5fd;
    padding: 7px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--secondary-color);
    border-radius: 0.25rem;
  }
  
  /* Styling for buttons container */
  .intagration-card-button {
    /* // display: flex; */
    /* // gap: 8px; */
    margin-top: 40px;
  }
  
  .dark-theme .intagration-card-heading {
    font-size: 16px;
    /* // margin: 8px 0; */
    margin-top: -5px;
    color: var(--w-color) !important;
  }
  .dark-theme .intagration-card-subheading {
    background:  var(--font-color) !important;
    border-radius: 6px;
    padding: 2px 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--primary-color);
  
  }
  
  .intagration-card-details-heading {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      text-align: left;
      color: var(--primary-color);
  }
  
  .dark-theme .intagration-card-details-heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: var(--w-color) !important;
  }
  
  .intagration-card-detail-img {
        width: 55px !important;
        height: 55px !important;
  }
  .intagration-card-details-subheading{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: var(--primary-color);
      margin-bottom: 5px;
  }
  
  .dark-theme .intagration-card-details-subheading{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: var(--w-color) !important;
      margin-bottom: 5px;
  }
  
  .intagration-card-details-description{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      display: flex;
      color: var(--secondary-color);
/* margin-bottom: 1rem !important; */
  }
  .intagration-font{
    color: var(--primary-color);
  }
  
  .dark-theme .intagration-font{
    color: var(--w-color) !important;
  }
.contact-data-container .card-body {
    height: 80vh !important; 
    display: flex !important;
    flex-direction: column !important;
}
.contact-data-container-min .card {
    margin-top: 3rem !important;
    height: auto !important; 
    display: flex !important;
    flex-direction: column !important;
}
.search-wrapper.active .input-holder .search-icon {
    height: 40px !important;
    top: 2px !important;
}
.custom-save{
    color:var(--w-color) !important;
}
.custom-save:hover{
    color: var(--w-color) !important;
}
.provider-url-btn{
    color: var(--bg-gradiant-dark) !important;
}

.integration-card{
    /* display: flex !important; */
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px;
    height: calc(100vh - 64px);
    align-content: flex-start;
}

.integration-card .card-br{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* padding: 1rem; */
        border-radius: 8px;
        /* border: 1px solid var(--Border-bd-1, #D4D4D4); */
        /* width: 300px; */
        justify-content: space-between;
        max-height: 202px;
    
}

.integration-mobile-description {
    margin-top: -18px;
    margin-inline-start: 3px;
}
.test-btn{
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    width: 55% !important;
}

.mobile-img{
    width: 60px;
}

.intagration-card-img {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }
  
  .intagration-card-heading {
    font-size: 16px;
    /*margin: 8px 0;*/
    margin-top: -5px;
    color: var(--primary-color);
  }
  
  .intagration-card-subheading {
      background: #F3F4F6;
      border-radius: 6px;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      width: -moz-fit-content;
      width: fit-content;
      color: var(--primary-color);
  
  }
  
  
  .intagration-card-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    display: flex;
    color: var(--secondary-color);
    height: 50px;
  }
  .intagration-comingsoon{
    background: #eff5fd;
    padding: 7px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--secondary-color);
    border-radius: 0.25rem;
  }
  
  /* Styling for buttons container */
  .intagration-card-button {
    /* // display: flex; */
    /* // gap: 8px; */
    margin-top: 40px;
  }
  
  .dark-theme .intagration-card-heading {
    font-size: 16px;
    /* // margin: 8px 0; */
    margin-top: -5px;
    color: var(--w-color) !important;
  }
  .dark-theme .intagration-card-subheading {
    background:  var(--font-color) !important;
    border-radius: 6px;
    padding: 2px 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--primary-color);
  
  }
  
  .intagration-card-details-heading {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      text-align: left;
      color: var(--primary-color);
  }
  
  .dark-theme .intagration-card-details-heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: var(--w-color) !important;
  }
  
  .intagration-card-detail-img {
        width: 55px !important;
        height: 55px !important;
  }
  .intagration-card-details-subheading{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: var(--primary-color);
      margin-bottom: 5px;
  }
  
  .dark-theme .intagration-card-details-subheading{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: var(--w-color) !important;
      margin-bottom: 5px;
  }
  
  .intagration-card-details-description{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      display: flex;
      color: var(--secondary-color);
/* margin-bottom: 1rem !important; */
  }
  .intagration-font{
    color: var(--primary-color);
  }
  
  .dark-theme .intagration-font{
    color: var(--w-color) !important;
  }
  .custom-mention-style {
    background-color: var(--white-bg) !important;
    border: 1px solid var(--theme-main) !important;
    width: 100% !important; 
    max-width: 100% !important; 
    line-height: 1.5 !important;
}

.p-mention-item {
    color: var(--primary-background) !important; 
    background-color: var(--white-bg) !important; 
}
.p-mention-items{
    padding-left: 0 !important;
}
.p-mention-item:hover {
    background-color: var(--primary-background) !important;
    color: var(--white-bg) !important;  
}
.p-mention .p-mention-panel{
  background-color: var(--white-bg) !important; 
  min-width: 0px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  color: #4b5563;
  border: 0 none;
  border-radius: 6px;
  border: 1px solid var(--theme-main) !important;
}
  .react-datepicker__day--keyboard-selected{
    background-color: var(--primary-background) !important;
    color: #fff !important;
  }
.dark-theme .vertical-nav-menu .metismenu-container .dropdown-menu {
    background-color: #121417fa !important;
}
.ck.ck-balloon-panel {
    z-index: 9999 !important;
}

.template-editor-modal .modal-content{
    min-height: 100vh !important;
  }
  .template-editor-modal .modal-body{
    min-height: 90vh !important;
  }
  .template-editor .ck-editor__editable, .template-editor-modal .ck-editor__editable, .template-editor-modal .ck-source-editing-area{
    height: 63.5vh !important;
  }
  .template-editor .sms-editor-container .ck-editor__editable, .template-editor-modal .sms-editor-container  .ck-editor__editable,.template-editor-modal  .sms-editor-container .ck-source-editing-area{
    height: 70vh !important;
  }
  .ck.ck-balloon-panel{
    z-index: 10000 !important;
  }
  /*.ck-editor__main p+p{
    margin-top: 0px !important;
  }
  .ck-editor__main p{
    margin-bottom: 0px !important;
    margin-top: 0 !important;
  }*/
  .ck.ck-list__item>.ck-button.ck-on{
    background: var(--theme-main) !important;
    color: #fffffF !important;
  }
  .ck.ck-button.ck-on, a.ck.ck-button.ck-on{
    color: var(--theme-main) !important;
  }
  .ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:active, a.ck.ck-button:focus{
    border: var(--theme-main) !important;
  }
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
    border: 1px solid var(--theme-main) !important;
  }
  .dark-theme .ck.ck-list__item>.ck-button.ck-on{
    background: var(--primary-background) !important;
    color: #fffffF !important;
  }
  .ck-powered-by{
    display: none !important;
  }
  .textbox-mention {
    resize: none; /* Prevent resizing */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent wrapping */
    height: auto; /* Let it expand if needed */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  } 
  .textbox-mention:focus {
    outline: none; /* Remove focus outline */
  }
  .setup-container .MuiDataGrid-columnHeader:focus, .setup-container .MuiDataGrid-columnHeader:focus-within{
    outline: unset !important;
  }
  .setup-container .MuiTablePagination-selectLabel, .setup-container .MuiTablePagination-displayedRows{
    margin-bottom: 0px !important;
  }
  .setup-container .Mui-selected{
    background-color: #40d85061 !important;
  }
  .MuiDataGrid-cell:focus{
    outline: unset !important;
  }
  .ck-editor__editable {
    padding: 0.8em 1em !important;
  }
  .ck-source-editing-area textarea:not([readonly]):focus{
    border: 1px solid var(--theme-main) !important;
  }
  .ck.ck-toolbar__items svg {
    font-size: 8px;
  }
  .populartag {
    position: absolute;
    top: 10px; 
    right: 10px;
    padding: 5px 10px; 
    border-radius: 15px; 
    font-size: 12px; 
    font-weight: bold; 
    background: linear-gradient(to right, #efca7f 8%, #eae1ca 18%, #e0b244 33%);
    background-size: 1000px 100%;
    animation: shimmer 2.6s cubic-bezier(1, 0.97, 0, 0.04) infinite forwards;
    width: 110px;
  }
  .justify-none{
    justify-content: unset !important;
  }
  .filter-component .custom-view-popover {
    height: 35px !important;
  }
  
  .filter-component .custom-view-popover .ct-dropbtn-link{
    height: 35px !important;
    display: block;
    padding-top: 0.2rem;
  }

  .ck-content .mention {
    background: none !important;
    color: var(--font-color) !important;
}

.rdw-mention-link {
    text-decoration: none;
    color: var(--theme-main);
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
  }
  
  .rdw-editor-toolbar{
    background-color: #f5f8fa !important;
    padding: 3px 5px 0 !important;
  }
  .rdw-option-wrapper{
    background-color: #f5f8fa;
    border-radius: 4px;
    border: none;
    height: 30px;
  }
  .rdw-option-wrapper:hover, .rdw-option-active, .rdw-dropdown-wrapper:hover{
    background-color: #d9efff !important;
    border-radius: 4px !important;
  }
  .rdw-dropdown-wrapper{
    background: #f5f8fa !important;
    border: none !important;
  }
  .width-temp-subject{
    min-height: 20px !important;
    padding-left: 12px;
    color: var(--theme-main);
  }
  .panel-mention-subject{
    bottom: 1px;
  }
  .p-mention-panel .p-mention-items .p-mention-item.p-highlight .p-component:focus{
    color: var(--theme-main);
  }
  
 
  .p-mention-items{
    padding-left: 0rem;
  }
    
  .custom-suggestion-editor{
    height: 15px;
    /* padding-top: 8px; */
    font-size: 15px;
    flex-direction: column !important;
    color: var(--font-color);
  }
  
  .hint-temp-modal{
    margin-left: 100px;
  }
  .custom-toolbar-container{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  
  .custom-toolbar-box{
    border: 0px !important;
  }
  
  .partnercode{
    width: 450px;
    margin-inline: auto;
  }
  .partnerbtn{
    width: 415px;
    padding-left: 170px;
  }
  .partnerdiv{
    padding-top: 225px;
  }
  
  .disabled-button {
    pointer-events: none;
    opacity: 0.5; /* Optional: to visually indicate the button is disabled */
    cursor: not-allowed; /* Optional: to show the cursor as not-allowed */
  }
  
  .content-box-container {
    display: flex;
    padding: .5rem 1rem;
    border-bottom: 1px solid #f0f5f8;
    position: relative;
    height: 35px;
  }
  
  .rdw-suggestion-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* border: 1px solid var(--main-green) !important; */
    min-width: 300px !important;
    max-height: 150px;
    overflow: auto;
    background: white;
    z-index: 100;
    border-width: unset !important;
  
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
    color: #4b5563;
    border: 0 none;
    border-radius: 6px;
    border: 1px solid #1b9d66;
    left: 15px;
  }
  
  .rdw-editor-main{
    height: 200px !important;
  }
  
  .rdw-suggestion-option {
    padding: 7px 5px;
    border-bottom: 1px solid var(--theme-main) !important;
    padding-left: 1rem;
  }
  
  .rdw-suggestion-option:hover, .rdw-suggestion-option:active, .rdw-suggestion-option:focus {
    background-color: #eef2ff  !important;
  }
  
  .rdw-suggestion-option:active{
    color: var(--theme-main) !important;
  }
  
  .rdw-mention-link {
    text-decoration: none;
    color: var(--theme-main);
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
  }
  
  .rdw-suggestion-option-active{
    color: var(--theme-main) !important;
  }
  
  .rdw-editor-toolbar{
    background-color: #f5f8fa !important;
    padding: 3px 5px 0 !important;
  }
  .rdw-option-wrapper{
    background-color: #f5f8fa;
    border-radius: 4px;
    border: none;
    height: 30px;
  }
  .rdw-option-wrapper:hover, .rdw-option-active, .rdw-dropdown-wrapper:hover{
    background-color: #d9efff !important;
    border-radius: 4px !important;
  }
  .rdw-dropdown-wrapper{
    background: #f5f8fa !important;
    border: none !important;
  }
  
  .p-mention .p-mention-panel{
    min-width: 0px !important;
    /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
    background: #ffffff;
    border: 0 none;
    border-radius: 6px;
    border: 1px solid var(--theme-main) !important;
  }
   
  .p-mention-item:hover, .p-mention-item:active, .p-mention-item:focus ,.p-inputwrapper:focus{
    color: 1px solid var(--primary-background) !important;
  }
  
  .textbox-mention {
    resize: none; /* Prevent resizing */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent wrapping */
    height: auto; /* Let it expand if needed */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  } 
  .textbox-mention:focus {
    outline: none; /* Remove focus outline */
  }
  .p-inputwrapper-filled{
    bottom: 3px;
  }

  .p-inputtext:enabled:hover{
    border-color : var(--theme-main) !important; 
  }
  .p-inputtext:enabled:focus{
    box-shadow :  var(--theme-main) !important;
    border-color: var(--theme-main) !important;
  }
.tooltip {
    background-color: transparent !important;
}

.p-mention-panel .p-mention-items .p-mention-item{
    padding-left: 10px !important;
    padding-right: 60px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.p-component ul{
    margin-bottom: 0px !important;
}
.p-mention-panel .p-mention-items {
    padding-bottom: 3px !important;
    padding-top:  3px !important;
}

.p-mention-item .custom-suggestion-editor:hover{
    color: var(--white-bg) !important;
}
.br-1{
    border-right: 1px solid var(--border-bd-1) !important;
}
.permission-desc{
    font-size: 15px !important;
    line-height: 1.3 !important;
}
.agency-trial-end-popup .modal-content{
    background-color: var(--theme-main) !important;
    border-color: var(--theme-main) !important;
    text-align: center;
    color: var(--w-color);
}
.agency-trial-end-popup .buy-btn{
    background-color: var(--white-bg);
    color: var(--theme-main);
    width: 75%;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    display: inline-block;
    border-radius: 5px;
    transform-origin: center;
}
.agency-trial-end-popup .buy-btn:hover {
    transform: scale(1.2);
}

.notification-text{
    margin-bottom: 0rem;
}

.dropdown-container {
    position: relative;
}
  
.dropdown-button {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--border-bd-1);
    border-radius: 5px;
    background: var(--white-bg);
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-color);
}
  
.dropdown-search {
    position: absolute;
    width: 100%;
    top: 100%;
    background: var(--theme-secondary-bg);
    border-radius: 5px;
    margin-top: 2px;
    z-index: 10;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid var(--theme-main);
}
  
.dropdown-search-container {
    position: relative;
    padding: 5px;
}

.dropdown-search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--secondary-color);
}

.dropdown-search-input {
    width: 100%;
    padding: 8px 8px 8px 30px;
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--border-bd-1);
    background-color: var(--white-bg);
    color: var(--font-color) !important;
}

.dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    color: var(--font-color);
    scrollbar-width: none;
}

.dropdown-option {
    padding: 8px;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: var(--white-bg);
}

.no-options {
    padding: 8px;
    color: var(--secondary-color);
}
.search-icon-1 {
    margin-left: 8px;
    color: var(--og-bg);
}

.search-input-1 {
    flex: 1;
    padding: 5px;
    border: none;
    outline: none;
}

.dropdown-options {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
}

.dropdown-option {
    padding: 10px;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: var(--primary-background);
    color: var(--w-color);
    font-weight: 400;
}
.no-options {
    padding: 10px;
    color: gray;
    text-align: center;
}
.select-down-icon{
    margin-left: 8px;
    color: var(--og-bg);
}

.dns-error-icon{
    color: var(--invalid-color);
}
.dns-error{
    background: var(--error-background);
}
.dns-copy-msg {
    opacity: 1; /* Start visible */
    animation: fadeOut 1s ease-in forwards;
    animation-delay: 1s; /* Wait 3 seconds before hiding */
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.doman-thead{
    background: var(--disabled-input);
}

.domain-table{
    border: var(--og-bg) !important;
    color: var(--text-light);
}

.domain-ip-verifed{
    color: var(--warning-color);
}

.agent-option .agent-option:hover{
    color: var(--font-color) !important;
    cursor: pointer;
}
.payment-scs-btn{
    width:30% !important;
}
.custom-field-outside {
    transform: translateX(40%); 
    width: 0% !important;
    padding-left: 0.5rem !important;
    padding-top: 0.5rem !important;
}
.ck-content .table table td, .ck-content .table table th{
    padding: unset !important;
}
label{
    color: var(--label-color);
}
.profile-desc{
    color: var(--label-color);
}
.btn-link:hover{
    color: var(--font-color);
}
.form-check-input:checked {
    background-color: var(--primary-background);
    border-color: var(--primary-background);
}

.dns-note-link{
    color: var(--link-color);
}

.dark-theme .react-datepicker__header {
  text-align: center;
  background-color: var(--menu-hover-bg) !important;
  border-bottom: 1px solid var(--datepicker-border-color);
  border-top-left-radius: var(--datepicker-border-radius);
  border-top-right-radius: var(--datepicker-border-radius);
  padding-top: 10px;
  position: relative;
}

/* Time-specific styling */
.dark-theme .react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.dark-theme .react-datepicker__current-month,
.dark-theme .react-datepicker-time__header {
  margin-top: 0;
  color: #ffffff !important;
  font-weight: bold;
  font-size: calc(var(--datepicker-font-size) * 1.1);
}

/* Dark theme background & text color */
.dark-theme .react-datepicker {
  background-color: #1e1e1e !important;
  color: var(--theme-secondary-bg) !important;
  border: 1px solid #444 !important;
}

.gen-description {
    color: var(--description-font) !important;
}
 
.text-bold{
    font-weight: 800 !important;
}
.calls-heading{
    border-bottom: none !important;
}
.permission-switch{
    margin-left: 1.1rem !important;
}
.permission-switch-desc{
    padding-left: 3.3rem !important;

}
