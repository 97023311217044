.summary-container-calls{
    margin: 10px 15px 0px 15px; 
}

.mobile .filter-width{
    width: 100% !important;
    padding: 5px;
}

.mobile .filter-callog-width{
    width: 22% !important;
    padding: 5px;
}

.mobile .filter-container {
    padding: 12px 0px 12px 0px !important;
    border-bottom: 1px solid var(--border-bd-1) !important;
    z-index: 700 !important;
    position: relative !important; 
}

.mobile .filter-calllogs-width{
    width: 18% !important;
}

.widget-subheading{
    font-size: 16px;
    color: var(--font-color);
    font-weight: 600;
    opacity: unset !important;
}

.filter-container .dropdown .btn{
    height: 36px !important;
}
.custom-card-height{
    height: 492px !important;
}

.no-recode-space{
    margin-top: 160px !important;
}

.call-table {
    width: 100%;
}

.table-row {
    display: flex;
}

.table-cell {
    padding: 8px;
    box-shadow: 0 0 0 0.1px rgb(163, 163, 163);
    padding: 10px; 
    text-align: left;
    width: 33%;
    height: 40px; 
    display: flex;
    align-items: center;
    color: var(--font-color);
}

.dark-table-cell{
    box-shadow: 0 0 0 0.1px rgb(255, 255, 255); 
}

.header {
    color: var(--font-color);
    font-weight: bold;
    background-color: #f4f4f4;
}

.table-cell-center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.web .filter-width{
    width: 25% !important;
}
.web .custome-filter{
    width: 750px !important;
}

.call-count{
    font-size: 30px;
}

.call-sub-count{
    font-size: 15px;
}

.call-footer{
    font-size: 1rem;
}
.calllogs-footer{
    font-size: 12px;
    font-weight: 700;
}

.demon-header{
    color: var(--font-color);
    font-size: 15px;
}

.btn-h{
    height: 37px !important;
}

.dark-header{
    background-color: var(--border-bd-1)
}

.calllog-filter-container{
    padding: 1rem 0 1rem 0;
    z-index: 11;
    position: relative;
}

.callog-summary-header{
    font-size: 14px !important;
}

.callog-summary-footer{
    font-size: 1.4rem !important;
}