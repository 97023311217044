.webhooks-btn{
    display: inline-block;
    padding: 0.35rem 0.5rem;
    border: 1px solid var(--app-theme-color);
    border-radius: 0.2667rem;
    letter-spacing: 0.018rem;
    user-select: none;
    cursor: pointer;
    color: #fff !important;
    text-align: center;
    line-height: 1.6;
}
.float-right{
    float: right;
}
.googleads-column-header{
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin-top: 20px;
}
.dark-theme .googleads-column-header{
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin-top: 20px;
  color: var(--label-color);
}
.le-table thead {
    color: #515159;
    padding-left: 2rem;
    font-size: 0.92rem;
  }

  
  .le-clickable{
    color: var(--link-color) !important;
    cursor: pointer;
    text-decoration: underline;
  }
  .invalid-feedback {
    display: revert;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #d92550;
  }
  .googleads-mapping-wrapper{
    padding-right: 2rem;
  }
  .webhooks-mapping-wrapper{
    margin-left: -16px;
  }
  .faXMark {
    display: flex;
    align-items: center; 
    position: relative;
}
.drop-down-list {
    max-height: 400px;
    overflow-y: auto;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 9999;
}
.drop-down-list li {
    padding: 8px;
    cursor: pointer;
    background-color: white;
    color: black;
}
.drop-down-list li:hover {
    background-color: var(--primary-background);
}
.input-variation-remove{
    align-self: center;
    visibility: hidden;
}
.input-variation:hover > .input-variation-remove,
.leadupdate-variation:hover .input-variation-remove,
.google-ads-mapper:hover .input-variation-remove {
    visibility: visible;
    cursor: pointer;
}
.text-left {
    text-align: left;
}
.no-border-webhook thead tr,
.no-border-webhook thead th,
.no-border-webhook tbody tr,
.no-border-webhook td {
  border-width: 0px !important;
}

.no-border-webhook tbody tr {
  border-bottom-width: 1px !important;
}
.webhook-scroll {
  overflow-x: hidden; 
}
.webhooksave{
  width: 85% !important;
}
.webhook-sample{
  box-sizing: border-box;
  border: 1px solid var(--border-bd-1);
  width: 65% !important;
  border-radius: 10px;
  font-family: 'monospace';
}
.desc-text{
  font-size: 12px;
}
.x-mark-outside {
  position: absolute;
  top: 50%;
  cursor: pointer;
  right: 0; 
  transform: translateX(150%) translateY(-50%); 
}
.save-btn{
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  width: 93% !important;
}
.create-btn{
  /* width: 40% !important; */
  display: flex !important;
  justify-content: flex-end;
}
.webhook-column-header{
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin-top: 20px;
  margin-left: 9%;
  margin-bottom: 0%;
}
.text-header{
  font-size: 17px !important;
}
.googleads-mapping-header{
  font-size: 15px;
  text-align: left;
  margin-top: 1.1rem !important;

}