.outbound-main-container{
    width: 100%;
}
.outbound-main-container .body-header{
    display: flex;
}
.body-header-web{
    position: relative;
    right: 13%;
}
.outbound-main-container .outbound-title{
    font-size: 16px;
    font-weight: 500;
    color: var(--font-color);
    width: 250px;
}
.outbound-config .operation-button{
    margin-left: auto;
}
.outbound-config .step-header-label{
    font-size: 16px;
    font-weight: 600;
    color: var(--font-color);
}
.outbound-config .outbound-step{
    border-left: 2px solid var(--primary-background);
}

.outbound-config .outbound-form{
    color: var(--font-color);
}
.vertical-timeline-without-image{
    border-left: 2px solid var(--primary-background);
/*     margin-left: 48px;
 */    position: relative;
}
.outbound-section .outbound-stepnumber{
    width: 24px;
    height: 24px;
    border: 1px solid var(--primary-background);
    background-color:  var(--primary-background);
    color: var(--w-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    left: -13px;
    position: absolute;
    font-size: small;
}
.outbound-section .group-header{
    font-size: 16px;
    font-weight: 600;
    color: var(--font-color);
}
.outbound-section .outbound-step-text{
    width: 40px;
    height: 25px;
    border: 1px solid var(--primary-background);
    border-radius: 15%;
    background-color: var(--primary-background);
    color: var(--white-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    left: -20px;
    position: absolute;
    font-size: small;
}
.outbound-config-container .switch{
    z-index: 0 !important;
}
.outbound-main-container .basic-select{
    z-index: 10 !important;
}

.off-switch-btn{
    color: var(--w-color) !important;
    padding-left: 5px;
    padding-top: 3px;
}
.on-switch-btn{
    color: var(--w-color) !important;
    text-align: center;
    position: relative;
    top: 2px;
}
.body-header .add-btn{
    width: 150px;
    left: 15px;
}
.icon-with-txt{
    display: flex;
    justify-content: center;
    align-items: center;
}
.operation-buttons-container .badge-btn{
    height: 34px;
}
.operation-buttons-container .badge-info{
    color: var(--info-color);
    background-color: var(--w-color);
    border: 1px solid var(--info-color);
}
.pb-5p{
    padding-bottom: 5%;
}
.pb-10p{
    padding-bottom: 10%;
}
.pb-15p{
    padding-bottom: 15%;
}
.pb-20p{
    padding-bottom: 20%;
}
.pb-25p{
    padding-bottom: 25%;
}
.pb-50p{
    padding-bottom: 50%;
}
.badge-theme{
    color: var(--primary-background);
    background-color: var(--white-bg);
    border: 1px solid var(--theme-main);
}
.badge-theme,
.badge-theme:active,
.badge-theme:hover,
.badge-theme:focus,
.badge-theme:visited{
    color: var(--primary-background);
    background-color: var(--white-bg);
    border: 1px solid var(--primary-background);
}
.react-switch-handle:active,
.react-switch-handle:focus,
.react-switch-handle:visited{
    box-shadow: var(--primary-background) 0px 0px 2px 3px !important;
}
.swal-icon--custom{
    width: 75px;
    height: 75px;
}
@media (min-width: 650px){
    .swal-modal{
        width: 450px
    }
}
.outbound-main-container .disabled{
    cursor: not-allowed;
}

.dark-theme .outbound-section .outbound-step-text{
    width: 40px;
    height: 25px;
    border: 1px solid var(--primary-background);
    border-radius: 15%;
    background-color: var(--primary-background);
    color: var(--w-color);
    display: flex;
    justify-content: center;
    align-items: center;
    left: -20px;
    position: absolute;
    font-size: small;
}
.react-datepicker__time-list-item--selected{
    background-color: var(--primary-background) !important;
}
.group-header{
    font-size: 16px;
    color: var(--font-color);
}
.swal-title{
    font-size: 24px !important;
}
.swal-text{
    font-size: 18px !important;
}