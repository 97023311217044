
.cloud-number-name-number {
    display: flex;
    align-items: center;
    width: 100%;
}
.variable-dropdown {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    border: 1px solid var(--gray-200);
    padding: 8px;
    gap: 8px;
    background-color: var(--white-bg);
}
.variable-dropdown:focus {
    outline: none;
    box-shadow: none;
}
.variable-dropdown:hover{
    color: var(--primary-color);
    background-color: var(--white-bg);
    border-color: var(--border-bd-1);
}
.variable-dropdown:active{
    color: var(--primary-color);
    background-color: var(--white-bg);
    border-color: var(--primary-color);
}
.number-icon {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid var(--border-bd-1);
    background: var(--icon-bg);
    color: var(--primary-color);
    font-size: 20px;
}
.cloud-number-name-number .name1{
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--primary-color);
    margin: 0;
}
.filter-dropdown-icon{
    color: var(--primary-color);
    width: 18px !important;
    height: 18px !important;
}
.filter-dropdown .btn-secondary{
    background-color: transparent !important;
    border: unset !important;
}
.filter-dropdown .dropdown .btn-group{
    width: 100% !important;
}
.filter-dropdown .dropdown-menu{
    width: 100% !important;
    margin: 0px;
    margin-top: 10px;
    padding: 0px;
    border-radius: 10px !important;
}
.convo-logs-container .chat-list-item:hover{
    background-color: var(--calls-selected-bg) !important;
}
.convo-logs-container{
    height: 100vh; 
    overflow-y: auto;
    scrollbar-width: none;
}
.convo-logs-container .chat-list-item{
    width: 100%;
    background-color: transparent;
    display: flex;
    /*padding: 8px;*/
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
}
.convo-logs-container .selected-bg{
    background-color: #dfe3e3 !important;
}
.dark-theme .convo-logs-container .selected-bg{
    background-color: var(--dark--selected-bg) !important;
}
.convo-logs-container .user-pic{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    background: var(--gray-200);
    border-radius: 50%;
}
.convo-logs-container .user-detail-wrap {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: calc(100% - 56px);
}
.convo-logs-container .user-name-label-time-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.convo-logs-container .user-name-label-wrap {
    display: flex;
    align-items: center;
    height: 21px;
    gap: 8px;
}
.convo-logs-container .time-wrap {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--secondary-color);
    min-width: 50px;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
}
.convo-logs-container .user-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--primary-color);
    padding: 0%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.convo-logs-container .chat-sinppet-with-count {
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.convo-logs-container .tick-marks-msg-wrap{
    display: flex;
    gap: 4px;
    align-items: center;
    height: 100%;
    overflow: hidden;
}
.convo-logs-container .chat-sinppet{

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translateY(2px);
}
.convo-logs-container .chat-snippet-source{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-200);
    border-radius: 50%;
}
.convo-logs-container .chat-snippet-source img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.convo-logs-container .call-conversations{
    padding: 8px 1rem;
    padding-right: 0.4rem !important;
    padding-left: 1.5rem !important;
    max-height: 100vh !important;
    overflow-y:auto !important; 
}
.container-heading{
    height: 60px;
    padding: 1rem;
    border-bottom: 1px solid var(--border-bd-1);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    display: flex;
    color: var(--font-color);
    justify-content: space-between;
}
.container-heading span {
    display: flex;
    gap: 8px;
    align-items: center;
}
.container-heading .user-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    background: var(--gray-200);
    border-radius: 50%;
}
.container-heading .call-details {
    display: flex;
    flex-direction: column;
    align-items: baseline !important;
    gap: 0px !important;
}
.container-heading .call-details-type {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--primary-color);
}
.container-heading .call-details-direction {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--secondary-color);
}
.agent-prompt, .agent-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 24px;
    border-bottom: 1px solid var(--border-bd-1);
}
.audio-player {
    display: flex;
    align-items: center;
    gap: 1.4rem;
}
.audio-player button {
    height: 32px;
    width: 32px;
    background: var(--gray-200);
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    border: none !important;
    padding: 6px;
}
.main-container{
    width: 100%;
}
.audio-player .w-65{
    width: 70% !important;
}
.hide{
    display: none !important;
}
.agent-other-details{
    height: calc(100% - 154px);
    overflow-y: auto;
    scrollbar-width: none;
}
.agent-other-details .RRT__tab--selected, .agent-other-details .RRT__tab:hover .icon-color{
    color: var(--font-color) !important;
}
.agent-other-details .RRT__inkbar{
    background-color: var(--primary-background) !important;
}
.agent-other-details .RRT__tab{
    font-size: 14px !important;
    color: var(--tab-color);
    
}
.body-tabs{
    border-bottom: var(--border-bottom) solid 2px;
}
.user-pic img , .number-icon img{
    height: 24px;
    width: 24px;
}
.count {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    padding-left: 2px;  
    color: var(--secondary-color);
}
.filter-dropdown .dropdown-menu{
    background-color: unset !important;
    border: unset !important;
    box-shadow: unset !important;
}
.calls-overview-fieldlabel{
    width: 120px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--font-color);
}
.calls-overview-fieldvalue{
    width: fit-content;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
}
.calls-overview-div{
    display: flex;
    min-height: 35px;
    align-items: flex-start;
    padding-left: 8px;
    gap: 45px;
}

.call-overview-info{
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: var(--font-color);
    margin-bottom: 8px;
}
.overview-container, .call-summary-container, .call-transcript-container{
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px;
}
.overview-contact-name{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
}
.overview-contact-img{
    border-radius: 50%;
}
.call-summary-label{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}
.call-summary-content{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    padding: 8px 0;
    display: flex;
}
.overview-header{
    display: flex;
}
.transcript-bot-msg{
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
    padding-bottom: 10px;
    padding-top: 10px;
}
.transcript-bot-msg-width{
    align-items: flex-end;
    display: flex;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--gray-black);
    max-width: 80%;
    width: fit-content;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border-radius: 8px;
    background: var(--gray-200);
}

.transcript-dur{
    display: flex;
    font-weight: 500;
    font-size: 13px;
}
.transcript-bot-dur-span{
    padding-right: 5px;
}
.transcript-bot-name-div{
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--gray-black);
    max-width: 80%;
    width: fit-content;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border-radius: 8px;
    background: var(--gray-200);
    text-align: left;
}
.transcript-bot-name{
    padding-right: 5px;
    padding-left: 5px;
}

.transcript-user-msg{
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.transcript-user-dur{
    height: 18px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background-color: var(--icon-bg);
}
.transcript-user-img-span{
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background: var(--gray-200);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.transcript-user-img{
    width: 10px;
    height: 10px;
}

.transcript-user-name{
    padding: 0 8px 0 4px;
}

.transcript-user{
    height: 18px;
    position: relative;
    background: var(--icon-bg);
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-radius: 8px;
}
.transcript-user-header{

    display: flex;
    gap: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    align-items: center;
}
.card-fields{
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* justify-content: center; */
}

.RRT__tab:hover{
    color: var(--font-color) !important;
}
.heading{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--font-color);
    display: flex;
    gap: 8px;
}

.subheading {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--secondary-color);
}

.agent_details-wid{
    width: 100%;
}

.number-hover{
    border-radius: 10px;
    padding: 6px;
    display: flex;
    gap: 8px;
    cursor: pointer;
    align-items: center;
}

.cloud-number-name-number .name {
    color: var(--font-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.number {
    color: var(--text-light);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
}
.mobile_icon{
    width: 25px;
    height: 25px;
}

.cloud-number-logo i{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}
.settings-i-cont{
    font-size: 25px;
}
.cloud-number-logo {
    border-radius: 50%;
    border: 1px solid var(--border-br-1);
    background: var(--background-bg-3, var(--gray-200));
    width: 36px;
    height: 36px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
}

.phone-numbers-container .cloud-number-name-number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 76px);
    justify-content: center;
}
.phone-numbers-container .cloud-number-name-number .number {
    color: var(--text-light);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
}

.container-heading-title{
    display: flex;
    gap: 6px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.emoji-icon {
    position: sticky;
    width: 14px;
    height: 14px;
    transform: translate(-8px, 14px);
    display: flex;
}

.mat-icon {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.number-count {
    text-align: left;
    padding-left: 11px;
}

.provider-creaditials-select{
    width: 30%;
    margin-bottom: 20px;
}
.modal-close{

    width: 30px;
    height: 30px;
    margin-left: 300px;
    cursor: pointer;
}
.search-icon-size{
    font-size: 20px;
    color: var(--primary-color);
}
.calls-details {
    overflow-y: auto;
    scrollbar-width: none;
    position: relative;
}
.calls-details .RRT__tabs {
    position: sticky;
    top: 0;
    background-color: var(--theme-secondary-bg);
}
.RRT__tab{
    color: var(--tab-color);
}
.calls-details .RRT__tab--selected,
.calls-details .RRT__tab.static {
    color: var(--font-color) !important;
}
.calls-details .RRT__inkbar-wrapper {
    z-index: 10 !important;
    position: static !important;
}
.calls-details .RRT__inkbar {
    background-color: var(--primary-background) !important;
    margin-bottom: 30px;
}
.calls-details .RRT__container .RRT__panel .search-wrapper {
    position: fixed;
}
.search-wrapper .btn-close{
    margin-top: -13px !important;
    margin-left: 1px !important;
}
.permission-row {
    padding-top: 0 !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}
.bt-1{
    border-top: 1px solid var(--border-bd-1) !important;
    padding-top: 1rem !important;
}


